import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Col } from 'react-bootstrap';
import useProduct from '../../hooks/useProduct';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';

const AutoFillProductInput = () => {
    const [queryProduct, setQueryProduct] = useState('');
    const [queryProductSku, setQueryProductSku] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const API_URL = process.env.REACT_APP_API_URL;
    const { showForSku } = useProduct();
    const [selectedProduct, setSelectedProduct] = useState(false);
    //const { codeApiFilterProduct, filterProductFromAPI } = useContext(UserLoggedContext);
    const { globalCodeApiFilterProduct, globalFilterProductFromAPI } = useContext(UserLoggedContext);

    useEffect(() => {
        const fetchSuggestions = async () => {
            try {
                const response = await axios.get(API_URL + 'all-products-auto-fill-input')
                setSuggestions(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        /*
        if (codeApiFilterProduct == 0){
            setQueryProduct('');
        }
        */

        fetchSuggestions();
    }, [queryProduct]);

    const handleChange = (e) => {
        setQueryProduct(e.target.value);
    };

    const handleSuggestionClick = (sku, name) => {
        console.log(sku);
        console.log(name);
        setQueryProduct(sku + ' - ' + name);
        setQueryProductSku(sku);
        setSuggestions([]);
    };

    const handleFilterProduct = () => {
        //console.log(queryProduct);
        console.log(queryProductSku);
        setSelectedProduct(true);
        showForSku(queryProductSku);
    }

    return (
        <>
            <Form.Label>SKU equipo</Form.Label>
            <div className="search-container">
                <div className="search-inner">
                    <InputGroup>
                        <Form.Control
                            placeholder="GES24ECO-INV-VP"
                            name="productSkuInput"
                            value={queryProduct}
                            onChange={handleChange} />
                        <Button variant="outline-primary" id="button-addon2" onClick={() => { handleFilterProduct() }} >
                            Filtrar equipo
                        </Button>
                    </InputGroup>
                </div>
                <div className="dropdown-product-search">
                    {suggestions ? (
                        <>
                            {suggestions.filter(item => {
                                const searchTterm = queryProduct.toLowerCase();
                                const fullNameProduct = item["sku"].toLowerCase();
                                return searchTterm && fullNameProduct.startsWith(searchTterm);
                            })
                                .map((item, index) => (
                                    <div key={index} onClick={() => handleSuggestionClick(item.sku, item.name)} className="dropdown-product-search-row">
                                        <small>
                                            <b>({item.sku})</b> - {item.name}
                                        </small>
                                    </div>
                                ))}
                        </>
                    ) : (null)}
                </div>
            </div>
            <br></br>
            {globalCodeApiFilterProduct === 200 && selectedProduct ? (
                <>
                    <Row>
                        <Col>
                            <small><b>Detalle de equipo filtrado</b></small>
                        </Col>
                    </Row>
                    {
                        globalFilterProductFromAPI.map((product, index) =>//fix
                        (
                            <>
                                <Row>
                                    <Col>
                                        <Table striped bordered hover responsive>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <small>
                                                            Nombre
                                                        </small>
                                                    </th>
                                                    <th>
                                                        <small>
                                                            SKU
                                                        </small>
                                                    </th>
                                                    <th>
                                                        <small>
                                                            Modelo
                                                        </small>
                                                    </th>
                                                    <th>
                                                        <small>
                                                            BTU
                                                        </small>
                                                    </th>
                                                    <th>
                                                        <small>
                                                            Tecnología
                                                        </small>
                                                    </th>
                                                    <th>
                                                        <small>
                                                            Voltaje
                                                        </small>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr key={index}>
                                                    <td>
                                                        <small>
                                                            {product.name}
                                                        </small>
                                                    </td>
                                                    <td>
                                                        <small>
                                                            {product.sku}
                                                        </small>
                                                    </td>
                                                    <td>
                                                        <small>
                                                            {product.model}
                                                        </small>
                                                    </td>
                                                    <td>
                                                        <small>
                                                            {product.btu}
                                                        </small>
                                                    </td>
                                                    <td>
                                                        <small>
                                                            {product.tech}
                                                        </small>
                                                    </td>
                                                    <td>
                                                        <small>
                                                            {product.voltage}
                                                        </small>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </>
                        ))
                    }
                </>
            ) : (null)}
        </>
    );
}

export default AutoFillProductInput;
