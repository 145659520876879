import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Col } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';
import { Card } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import useClient from "../../hooks/useClient";
import useUtilities from "../../hooks/useUtilities";
import useSmsNotification from "../../hooks/useSmsNotification";
import { useQuery, useMutation, useQueryClient } from 'react-query';

function DataSetClientNoShopping() {
    const { destroy, deleteAll, show, showModal, filterClientFromAPI, index, lastPageFromAPI, totalDataFromAPI, totalClients, totalActiveClients, totalInactiveClients, clientNoShopping } = useClient();
    const { truncateString, calculateDaysForMaintenance, formatDate } = useUtilities();
    const { smsManualSend } = useSmsNotification();
    const [loading, setLoading] = useState(true);
    const { totalClientsGlobal } = useContext(UserLoggedContext);
    const [showDelete, setShowDelete] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    const [showDeleteAllClients, setShowDeleteAllClients] = useState(false);
    const [showClientSmsNot, setShowClientSmsNot] = useState(false);
    const handleCloseDeleteAllClients = () => setShowDeleteAllClients(false);
    const handleCloseClientSmsSend = () => setShowClientSmsNot(false);
    const [desableDeleteBtn, setDesableDeleteBtn] = useState(false);
    const [desableDeleteAllBtn, setDesableDeleteAllBtn] = useState(false);
    const queryClient = useQueryClient();
    const [clientForDelete, setCllientForDelete] = useState(0);
    const [messageSuccessClientDelete, setMessageSuccessClientDelete] = useState(false);
    const [messageErrorClientDelete, setMessageErrorClientDelete] = useState(false);
    const [messageSuccessClientDeleteAll, setMessageSuccessClientDeleteAll] = useState(false);
    const [messageErrorClientDeleteAll, setMessageErrorClientDeleteAll] = useState(false);
    const [pageNumber, setPageNumer] = useState(1);
    const [hiddenBtnNotifi, setHiddenBtnNotifi] = useState(true);
    const [hiddenBtnNotifiForIndiClient, setHiddenBtnNotifiForIndiClient] = useState(true);
    const [isCheckedComboBoxAllClientSelected, setIsCheckedComboBoxAllClientSelected] = useState(false);
    const [isCheckedComboBoxClientSelected, setIsCheckedComboBoxClientSelected] = useState(false);
    const [clientListForSmsSelected, setClientListForSmsSelected] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [nameClientSelectedToNotSms, setNameClientSelectedToNotSms] = useState();
    const [telephoneClientSelectedToNotSms, setTelephoneClientSelectedToNotSms] = useState();
    const [idClientSelectedToNotSms, setIdClientSelectedToNotSms] = useState();
    const [desableSendSmsNotclient, setDesableSendSmsNotclient] = useState(false);
    const msgSmsNot = `Estimado Cliente de Climatización Rancagua, \nRegistra mantenciones vencidas en Equipos de Aire Acondicionado. \nHablemos: https://api.whatsapp.com/send?phone=56967345720'`;

    const { mutate: mutateSms, isLoading: isLoadingMutationSms, error } = useMutation({
        mutationFn: smsManualSend,
        onSuccess: () => {
            setDesableSendSmsNotclient(true);
            queryClient.invalidateQueries(['clients']);
            queryClient.invalidateQueries(['all_sms_notifications']);
            setTimeout(() => {
                setLoading(false);
                handleCloseClientSmsSend();
                setDesableSendSmsNotclient(false);
            }, 2000);
        },
        onError: () => {
            //errorSuccessNewClient()
        }
    });

    const { isLoading, data: clients_no_shopping, isError: isErrorDataFetchingAllClients } = useQuery(
        ['clients_no_shopping', pageNumber],
        () => clientNoShopping(pageNumber));

    const { data: totalClientsAPI } = useQuery(
        ['totalClientsAPI'],
        () => totalClients());

    const { data: totalActiveClientsAPI } = useQuery(
        ['totalActiveClients'],
        () => totalActiveClients());

    const { data: totalInactiveClientsAPI } = useQuery(
        ['totalInactiveClients'],
        () => totalInactiveClients());

    const { data: dataAllClients } = useQuery({
        queryKey: ['allClients'],
        queryFn: totalClients,
    });

    useEffect(() => {
        //totalClients();
        clientNoShopping();
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const handleDaysCount = (data) => {
        //console.log(calculateDaysForMaintenance(data));
        return calculateDaysForMaintenance(data);
    }

    const deleteClientMutation = useMutation({
        mutationFn: destroy,
        onSuccess: () => {
            successClientDelete();
            queryClient.invalidateQueries(['clients_ok_main']);
            queryClient.invalidateQueries(['clients_with_ex_main']);
            queryClient.invalidateQueries(['clients_no_shopping']);
            queryClient.invalidateQueries(['clients_with_due_main']);
            queryClient.invalidateQueries(['clients']);
            queryClient.invalidateQueries(['totalClientsAPI']);
            queryClient.invalidateQueries(['totalClientsGlobal']);
        },
        onError: () => {
            errorClientDelete()
        }
    });

    const deleteAllClientsMutation = useMutation({
        mutationFn: deleteAll,
        onSuccess: () => {
            successClientDeleteAll();
            queryClient.invalidateQueries(['clients'])
            queryClient.invalidateQueries(['totalClientsAPI'])
        },
        onError: () => {
            errorClientDeleteAll()
        }
    });

    const successClientDelete = () => {
        setMessageSuccessClientDelete(true);
        setDesableDeleteBtn(true);
        setTimeout(() => {
            setMessageSuccessClientDelete(false);
            setDesableDeleteBtn(false);
            setShowDelete(false);
        }, 2000);
    }

    const errorClientDelete = () => {
        setMessageErrorClientDelete(true);
        setTimeout(() => {
            setMessageErrorClientDelete(false);
        }, 2000);
    }

    const successClientDeleteAll = () => {
        setMessageSuccessClientDeleteAll(true);
        setDesableDeleteAllBtn(true);
        setTimeout(() => {
            setMessageSuccessClientDeleteAll(false);
            setDesableDeleteAllBtn(false);
            setShowDeleteAllClients(false);
        }, 2000);
    }

    const errorClientDeleteAll = () => {
        setMessageErrorClientDeleteAll(true);
        setTimeout(() => {
            setMessageErrorClientDeleteAll(false);
        }, 2000);
    }

    const showPopupDelete = (id) => {
        setCllientForDelete(id);
        showModal(id);
        setShowDelete(true);
    }

    const showPopupDeleteAllClients = () => {
        setShowDeleteAllClients(true);
    }

    const showPopupClientSmsNot = () => {
        setShowClientSmsNot(true);
    }

    const handleDeleteClient = () => {
        deleteClientMutation.mutate(clientForDelete);
    }

    const handleDeleteAllClients = () => {
        deleteAllClientsMutation.mutate();
    }

    const handleCheckboxFiftyClientsChange = () => {
        console.log("ok");

    }

    const handleCheckboxClientChange = () => {
        setIsCheckedComboBoxClientSelected(!isCheckedComboBoxClientSelected);
        //console.log(clientId);
    }

    //fix
    const handleCheckboxChange = (id, name, telephone) => {
        console.log(id);
        /*
        console.log(name);
        console.log(telephone);
        */
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(id)) {
                //setHiddenBtnNotifiForIndiClient(true);
                setHiddenBtnNotifiForIndiClient(true);
                console.log("deselected");
                removeClientToListSms(id);
                setNameClientSelectedToNotSms("");
                setTelephoneClientSelectedToNotSms("");
                setIdClientSelectedToNotSms("");
                return prevSelectedItems.filter((item) => item !== id);
            } else {
                setNameClientSelectedToNotSms(name);
                setTelephoneClientSelectedToNotSms(telephone);
                setIdClientSelectedToNotSms(id);
                setHiddenBtnNotifiForIndiClient(false);
                if (clientListForSmsSelected.length > 0) {
                    setHiddenBtnNotifiForIndiClient(false);
                }
                console.log("selected");
                handleAddClientToListSms(name, telephone, id);
                return [...prevSelectedItems, id];
            }
        });
    };

    //logic for save an array
    const handleAddClientToListSms = (param_name, param_telephone_client, param_id_client) => {
        const data = {
            first_name_client: param_name,
            telephone_client: param_telephone_client,
            id_client: param_id_client,
        }

        const updtateListClients = [...clientListForSmsSelected, data];
        setClientListForSmsSelected(updtateListClients);
    }

    const removeClientToListSms = (id) => {
        setClientListForSmsSelected(oldValues => {
            return oldValues.filter(client => client.id_client !== id)
        })
    }

    //console.log(clientListForSmsSelected);
    //console.log(clientListForSmsSelected.length);

    const handleSendClientSmsNot = () => {
        //deleteAllClientsMutation.mutate();
        mutateSms({
            telephone_client: telephoneClientSelectedToNotSms,
            id_client: idClientSelectedToNotSms,
            first_name_client: nameClientSelectedToNotSms,
            message: msgSmsNot,
            type_sms: 1,
            send_date: formatDate(),
        });

        //fix: I need to complete history logic for save after to send a sms notification. 
        /*
        smsHistoryNotifications({
          telephone_client: telephoneClientSelectedToNotSms,
          id_client: idClientSelectedToNotSms,
          message: msgSmsNot,
        });
        */
    }

    if (isLoading) return (
        <>
            <Row>
                <Col>
                    <br></br>
                    <Spinner animation="border" variant="secondary" />
                </Col>
            </Row>
        </>
    );

    if (isErrorDataFetchingAllClients) return (
        <>
            <Row>
                <Col>
                    <Alert key='danger' variant='danger'>
                        Hay un error. Por favor intente más tarde.
                    </Alert>
                </Col>
            </Row>
        </>
    );

    return (
        <>
            {clients_no_shopping ? (
                <>
                    <br></br>
                    <Row hidden={hiddenBtnNotifi}>
                        <Col xs lg="8">
                            <Card>
                                <Card.Header>Opciones de notificación</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <Row>
                                            <Col xs lg="4">
                                                <small><b>Seleccionados: 50</b></small>
                                            </Col>
                                            <Col md="auto">
                                                <Button variant="outline-primary" onClick={showPopupClientSmsNot} size="sm">Notificar mantención</Button>{' '}
                                            </Col>
                                            <Col md="auto">
                                                <Button variant="outline-primary" onClick={showPopupDeleteAllClients} size="sm">Notificar información</Button>{' '}
                                            </Col>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="auto"></Col>
                    </Row>
                    <Row hidden={hiddenBtnNotifiForIndiClient}>
                        <Col xs lg="8">
                            <Card>
                                <Card.Header>Opciones de notificación</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <Row>
                                            <Col xs lg="6">
                                                <small><b>Clientes seleccionados: {selectedItems.length}</b></small>
                                            </Col>
                                            <Col md="auto">
                                                <Button variant="outline-primary" onClick={showPopupClientSmsNot} size="sm">Notificar mantención</Button>{' '}
                                            </Col>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="auto"></Col>
                    </Row>
                    <br></br>
                    <br></br>
                    <Row>
                        <Col></Col>
                        <Col md="auto">
                        </Col>
                        <Col xs lg="4">
                            {totalClientsAPI ? (
                                <Col>
                                    <small>Total de clientes sin compras&nbsp;</small><Badge bg="info">{totalDataFromAPI ? (totalDataFromAPI) : (0)}</Badge>
                                </Col>
                            ) : (null)}
                        </Col>
                    </Row>
                    <p></p>
                    <Row>
                        <Col>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <input
                                                type="checkbox"
                                                id="checkbox-fifty-client-selected"
                                                checked={isCheckedComboBoxAllClientSelected}
                                                onChange={handleCheckboxFiftyClientsChange}
                                            />
                                        </th>
                                        <th><small>Cliente</small></th>
                                        <th><small>Teléfono</small></th>
                                        <th><small>Últ. visita</small></th>
                                        <th><small>Estado mantención</small></th>
                                        <th><small>Estado not. mantención</small></th>
                                        <th><small>Total compras</small></th>
                                        <th><small>Estado nueva ODT</small></th>
                                        <th><small>Eliminar</small></th>
                                        <th><small>Editar</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clients_no_shopping.map((client, index) =>
                                    (
                                        <tr key={index}>
                                            {client.telephone != null ? (
                                                client.telephone.length == 11 ? (
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            id={`checkbox-client-selected-${client.id}`}
                                                            checked={selectedItems.includes(client.id)}
                                                            onChange={() => handleCheckboxChange(client.id, client.first_name, client.telephone)}
                                                        />
                                                    </td>
                                                ) : (
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            id={`checkbox-client-selected-${client.id}`}
                                                            checked={selectedItems.includes(client.id)}
                                                            onChange={() => handleCheckboxChange(client.id, client.first_name, client.telephone)}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                )
                                            ) : (
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        id={`checkbox-client-selected-${client.id}`}
                                                        checked={selectedItems.includes(client.id)}
                                                        onChange={() => handleCheckboxChange(client.id, client.first_name, client.telephone)}
                                                        disabled={true}
                                                    />
                                                </td>
                                            )}

                                            <td>
                                                <Link to={`/gestion-de-cliente/detalle/${client.id}/${client.telephone}`} className='nav-link nav-link-client'>
                                                    <small>{truncateString(client.first_name, 13)}&nbsp;
                                                        {client.last_name ? (
                                                            truncateString(client.last_name, 4)
                                                        ) : (null)}
                                                    </small>
                                                </Link>
                                            </td>
                                            {client.telephone && client.correct_cellphone == 0 ? (
                                                <td>
                                                    <small className="error_telephone">{client.telephone}</small>
                                                </td>
                                            ) : (null)}
                                            {client.telephone && client.correct_cellphone == null ? (
                                                <td>
                                                    {client.telephone.length < 11 || client.telephone.length > 11 ? (
                                                        <small className="error_telephone">
                                                            {client.telephone}
                                                        </small>
                                                    ) : (
                                                        <small>
                                                            {client.telephone}
                                                        </small>
                                                    )}
                                                </td>
                                            ) : (null)}
                                            {client.telephone && client.correct_cellphone == 1 ? (
                                                <td>
                                                    <small>{client.telephone}</small>
                                                </td>
                                            ) : (null)}
                                            {client.telephone == null ? (
                                                <td>
                                                    <small>Sin datos</small>
                                                </td>
                                            ) : (null)}

                                            {client.last_visit == null || client.last_visit == "" || client.last_visit == 0 ? (
                                                <td>
                                                    <small>
                                                        Sin visitas
                                                    </small>
                                                </td>
                                            ) : (
                                                <td>
                                                    <small>
                                                        {client.last_visit}
                                                    </small>
                                                </td>
                                            )}

                                            {client.last_visit == null ? (
                                                <td>
                                                    <small>
                                                        No aplica
                                                    </small>
                                                </td>
                                            ) : (null)}

                                            {client.last_visit && client.count_maintenance_days < 175 ? (
                                                <td>
                                                    <small>
                                                        <span class="badge bg-success">Al día ({client.count_maintenance_days} días)</span>
                                                    </small>
                                                </td>
                                            ) : (null)}

                                            {client.last_visit && client.count_maintenance_days >= 175 && client.count_maintenance_days < 180 ? (
                                                <td>
                                                    <small>
                                                        <span class="badge bg-warning">Por vencer ({client.count_maintenance_days} días)</span>
                                                    </small>
                                                </td>
                                            ) : (null)}

                                            {client.last_visit && client.count_maintenance_days >= 180 ? (
                                                <td>
                                                    <small>
                                                        <span class="badge bg-danger">Vencida (hace {client.count_maintenance_days} días)</span>
                                                    </small>
                                                </td>
                                            ) : (null)}

                                            {client.last_main_notification == null ? (
                                                <td>
                                                    <small>
                                                        Pendiente
                                                    </small>
                                                </td>
                                            ) : (
                                                <td>
                                                    <small>
                                                        <span class="badge bg-success"> (#{client.maintenance_status}) Enviada el ({client.last_main_notification})</span>
                                                    </small>
                                                </td>
                                            )}

                                            {client.total_shopping == 0 || client.total_shopping == null ? (
                                                <td>
                                                    <small>
                                                        0
                                                    </small>
                                                </td>
                                            ) : (
                                                <td>
                                                    <small>
                                                        {client.total_shopping}
                                                    </small>
                                                </td>
                                            )}

                                            {client.last_odt == null || client.last_odt == 0 ? (
                                                <td>
                                                    <small>
                                                        Sin nueva odt.
                                                    </small>
                                                </td>
                                            ) : (
                                                <td>
                                                    <small>
                                                        <span class="badge bg-success">Nueva ODT #{client.last_odt}</span>
                                                    </small>
                                                </td>
                                            )}
                                            <td>
                                                <Link onClick={() => { showPopupDelete(client.id) }} className='nav-link'><AiOutlineDelete /></Link>
                                            </td>
                                            <td>
                                                <Link to={`/gestion-de-cliente/editar/${client.id}/${client.telephone}`} className='nav-link'><AiOutlineEdit /></Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button onClick={() => setPageNumer((page) => page - 1)} disabled={pageNumber === 1} variant="outline-primary">Volver</Button>{' '}
                            <Button onClick={() => setPageNumer((page) => page + 1)} disabled={pageNumber === lastPageFromAPI} variant="outline-primary">Siguiente</Button>{' '}
                        </Col>
                    </Row>
                </>
            ) : (
                <Row>
                    <Col>
                        <Alert key='warning' variant='warning'>
                            <small>Sin clientes ingresados.</small>
                        </Alert>
                    </Col>
                </Row>
            )}

            {filterClientFromAPI ? (
                <Modal show={showDelete} onHide={handleCloseDelete} >
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminación del cliente</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                ¿Quieres eliminar a&nbsp;

                                {filterClientFromAPI.map((c, index) =>
                                (
                                    <small key={index}>
                                        <b>{c.first_name} {c.last_name}</b>
                                    </small>
                                ))
                                }?

                            </Col>
                        </Row>
                        <br></br>
                        <Alert variant="success" show={messageSuccessClientDelete}>
                            <small>Cliente eliminado con éxito.</small>
                        </Alert>
                        <Alert variant="danger" show={messageErrorClientDelete}>
                            <small>Error al eliminar cliente, inténtelo más tarde.</small>
                        </Alert>
                        <br></br>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDelete}>
                            Cerrar
                        </Button>
                        <Button variant="primary" onClick={handleDeleteClient} disabled={desableDeleteBtn}>
                            Si, eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : (null)}

            <Modal show={showDeleteAllClients} onHide={handleCloseDeleteAllClients} >
                <Modal.Header closeButton>
                    <Modal.Title>Eliminación de clientes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            {totalClientsAPI ? (
                                <>
                                    ¿Quieres eliminar <b>{totalClientsAPI['total']}</b> cliente(s)?
                                </>
                            ) : (null)}
                        </Col>
                    </Row>
                    <br></br>
                    <Alert variant="success" show={messageSuccessClientDeleteAll}>
                        <small>Clientes eliminados con éxito.</small>
                    </Alert>
                    <Alert variant="danger" show={messageErrorClientDeleteAll}>
                        <small>Error al eliminar clientes, inténtelo más tarde.</small>
                    </Alert>
                    <br></br>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteAllClients}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleDeleteAllClients} disabled={desableDeleteAllBtn}>
                        Si, eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showClientSmsNot} onHide={handleCloseClientSmsSend} >
                <Modal.Header closeButton>
                    <Modal.Title>Notificación SMS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            Enviará el siguiente mensaje a <b>{selectedItems.length}</b> clientes.
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col md="12">
                            <textarea name="textarea_msg_sms_notif" rows="6" cols="52" disabled="true" value={msgSmsNot}>
                            </textarea>
                        </Col>
                    </Row>
                    <br></br>
                    {isLoadingMutationSms &&
                        <Alert variant="success">
                            <small>Procesando...</small>
                        </Alert>
                    }
                    <br></br>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseClientSmsSend}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleSendClientSmsNot} disabled={desableSendSmsNotclient}>
                        Enviar notificación
                    </Button>
                </Modal.Footer>
            </Modal>

            <br></br>
            <br></br>
        </>
    )
}

export default DataSetClientNoShopping;


