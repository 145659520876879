import React, { useEffect, useContext } from "react";
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import { AiFillCloseCircle } from "react-icons/ai";
import Alert from 'react-bootstrap/Alert';
import { Link } from "react-router-dom";
import useShopping from '../../hooks/useShopping';
import { Row } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { useQuery, useMutation, useQueryClient, queryClient } from 'react-query';
import { UserLoggedContext } from "../../contexts/UserLoggedContext";

function ItemShopping({ idShoppingSaved }) {
  //const { idShoppingSaved, addedItem, filterShoppingFromAPI } = useContext(UserLoggedContext);
  const { showItems, removeShoppingItem } = useShopping();
  const queryClient = useQueryClient();

  const { isLoading, data: itemsShopping, isError } = useQuery(
    ['itemsShopping'],
    () => showItems(idShoppingSaved)
  );

  const mutateRemoveShoppingItem = useMutation({
    mutationFn: removeShoppingItem,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['itemsShopping'] });
      queryClient.invalidateQueries({ queryKey: ['purchased_equipments'] });
    }
  });

  useEffect(() => {
    showItems(idShoppingSaved);
  }, []);

  /*
  return(
      <>
     {new_items ? (
      <>
        <h5>Con data</h5>
      </>
    ) : (
      <h5>Sin data</h5>
    )}
      </>
  );
  */

  const handleRemoveItem = (item) => {
    console.log(item);
    mutateRemoveShoppingItem.mutate(item);
  }


  if (isLoading) return (
    <>
      <Row>
        <Col>
          <br></br>
          <Spinner animation="border" variant="secondary" />
        </Col>
      </Row>
    </>
  );

  if (isError) return (
    <>
      <Row>
        <Col>
          <Alert key='danger' variant='danger'>
            Hay un error. Por favor intente más tarde.
          </Alert>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      {itemsShopping ? (
        <Row>
          <Col>
            <Card>
              <Card.Header>Ítems añadidos</Card.Header>
              <Card.Body>
                <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                <Card.Text>
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th><small>#</small></th>
                        <th><small>Producto/Servicio</small></th>
                        <th><small>Cantidad</small></th>
                        <th><small>Observación</small></th>
                        <th><small>Quitar</small></th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemsShopping.map((item, index) =>
                      (
                        <tr key={index}>
                          <td>
                            <small>
                              {item.id}
                            </small>
                          </td>
                          {item.product ? (
                            <td>
                              <small>
                                <b>(SKU: {item.product_sku})</b> {item.product}
                              </small>
                            </td>
                          ) : (
                            <td>
                              <small>
                                <b>(SKU: {item.service_sku})</b> {item.service}
                              </small>
                            </td>

                          )}
                          <td>
                            <small>
                              {item.cant}
                            </small>
                          </td>
                          <td>
                            {item.note == null ? (
                              <small>
                                Sin notas.
                              </small>
                            ) : (
                              <small>
                                {item.note}
                              </small>
                            )}
                          </td>
                          <td>
                            <Link onClick={() => { handleRemoveItem(item.id) }} className='nav-link'><AiFillCloseCircle /></Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Alert key='warning' variant='warning'>
          <small>Sin ítems ingresados.</small >
        </Alert >
      )}
    </>
  );


}
export default ItemShopping;