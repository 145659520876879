import React, { useState, useContext, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { Card } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { InputGroup } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { AiOutlineExport } from "react-icons/ai";
import useClient from '../../hooks/useClient';
import { Link } from 'react-router-dom';
import { AiFillCloseCircle } from "react-icons/ai";
import useShopping from '../../hooks/useShopping';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';
import { useQuery } from 'react-query';
import Odt from './Odt';

function FormAddOdt() {
    const [runClient, setRunClient] = useState();
    //const [telephoneClient, setTelephoneClient] = useState();
    const [telephoneClient, setTelephoneClient] = useState();
    const [invoice, setInvoice] = useState('');
    const [dateShop, setDateShop] = useState('');
    const [noteShop, setNoteShop] = useState('');
    const [checkNewService, setCheckNewService] = useState("no");
    const { showForRun, filterClientByTelephone, codeApiFilterClientForTelephone, codeApiFilterClientForRun, filterClientFromAPI, filterClientFromRunAPI, setFilterClientFromRunAPI, filterClientFromTelephoneAPI, setFilterClientFromTelephoneAPI } = useClient();
    const [validated, setValidated] = useState(false);
    const [disableMessageErrorFilterTelephone, setDisableMessageErrorFilterTelephone] = useState(true);
    const [disabledBtnClientFilterForRun, setDisabledBtnClientFilterForRun] = useState(false);
    const [disabledBtnClientFilterForTelephone, setDisabledBtnClientFilterForTelephone] = useState(false);
    const [disabledInputClientFilterRut, setDisabledInputClientFilterRut] = useState(false);
    const [disabledInputClientFilterTelephone, setDisabledInputClientFilterTelephone] = useState(false);
    const [disabledInputInvoiceShopping, setDisabledInputInvoiceShopping] = useState(false);
    const [disabledInputDateShopping, setDisabledInputDateShopping] = useState(false);
    const [disabledInputNoteShopping, setDisabledInputNoteShopping] = useState(false);
    const [disableAlertErrorNewShop, setDisableAlertErrorNewShop] = useState(false);
    const [activeFilterClient, setActiveFilterClient] = useState(false);
    const { items, setItems, setIdShoppingSaved, idShoppingSaved, filterClientPhoneNewOdt, setFilterClientPhoneNewOdt, resetDataGrid, globalClientTelephoneParam } = useContext(UserLoggedContext);
    const { store, index, destroy: destroyShoppingForId, storeItem, showShoppingDetailsByClientIdAndState, filterShoppingAndItemsForClient, showShoppingDetailsItem } = useShopping();
    const [itemSelected, setItemSelected] = useState([]);
    const [shoppingIdSelected, setShoppingIdSelected] = useState(0);

    useEffect(() => {
        if ((telephoneClient != 0 || runClient != 0) && (telephoneClient != null || runClient != null) && (telephoneClient != '' || runClient != '') && (activeFilterClient === true)) {
            setDisabledBtnClientFilterForRun(false);
            setDisabledBtnClientFilterForTelephone(false);
        }

    }, []);

    const handleChangeCheckNewService = (e) => {
        setCheckNewService(e.target.value);

        if (checkNewService === "no") {
            setCheckNewService("si");
        }

        if (checkNewService === "si") {
            setCheckNewService("no");
        }
    }

    const deletePosibleClient = () => {
        if (idShoppingSaved > 0) {
            console.log('Id recent shopping save: ' + idShoppingSaved);
        }

        setFilterClientFromRunAPI([]);
        setFilterClientFromTelephoneAPI([]);
        setDisabledInputClientFilterRut(false);
        setDisabledInputClientFilterTelephone(false);
        setDisabledBtnClientFilterForRun(false);
        setDisabledBtnClientFilterForTelephone(false);
        setDisabledInputInvoiceShopping(false);
        setDisabledInputNoteShopping(false);
        setDisabledInputDateShopping(false);
        setInvoice(0);
        setDateShop('');
        setNoteShop('');
        setRunClient(0);
        setTelephoneClient(0);
        setIdShoppingSaved(0);
    }

    const successNewShopping = () => {
        setValidated(false);
        setTimeout(() => {
        }, 3000);
    }

    const errorSuccessNewShopping = () => {
        setDisableAlertErrorNewShop(true);
        setValidated(false);
        setTimeout(() => {
            setDisableAlertErrorNewShop(false);
        }, 3000);
    }

    const handleSubmitCompleteShopping = (e) => {
        e.preventDefault();
        setRunClient('');
        setTelephoneClient('');
        setInvoice('');
        setNoteShop('');
        setDateShop('');
        setDisabledBtnClientFilterForRun(true);
        setDisabledBtnClientFilterForTelephone(true);
        deletePosibleClient();
    };

    const handleSearchClientForTelephone = () => {
        filterClientByTelephone(filterClientPhoneNewOdt);
        showShoppingDetailsByClientIdAndState(filterClientPhoneNewOdt, 0);
    }

    const handleManagerOdt = (e) => {
        //const item = item_id;
        const item = parseInt(e.target.value);
        console.log(item);
        if (item) {
            handleShoppingFilterAccordingSelectItem(item);
        }
        const data = {
            id_item: item
        }
        const updtateItemsList = [data, ...itemSelected];
        setItemSelected(updtateItemsList);

        const totalItemsSelected = itemSelected.map(i => {
            if (i.id_item !== item) {
                const data = {
                    id_item: item
                }
                const updtateItemsList = [data, ...itemSelected];
                setItemSelected(updtateItemsList);
            } else if (i.id_item === item) {
                console.log('item ya existe.');
                setItemSelected(itemSelected.filter(a => a.id_item !== i.id_item));
            }
            return i;
        });
    }

    const handleShoppingFilterAccordingSelectItem = async (paramItem) => {
        let id_shopping = await showShoppingDetailsItem(paramItem);
        console.log(id_shopping[0]['shopping']);
        if (id_shopping != null || id_shopping != 0) {
            setShoppingIdSelected(id_shopping[0]['shopping']);
        }
    }

    return (
        <>
            <br></br>
            <Container fluid="md">
                <Row>
                    <Col>
                        <Form>
                            <Card>
                                <Card.Header>Cliente de la ODT</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        {globalClientTelephoneParam ? (
                                            <>
                                                <Row>
                                                    <Col xs={2}>
                                                        <small>
                                                            <Badge bg="primary">Teléfono sugerido: {globalClientTelephoneParam}</Badge>
                                                        </small>
                                                    </Col>
                                                </Row>
                                            </>) : (null)}
                                        <br></br>
                                        <Row>
                                            <Col xs={6}>
                                                <Form.Group>
                                                    <Form.Label>Buscar cliente por teléfono</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            placeholder="56912345678"
                                                            name='inputTelephone'
                                                            type='number'
                                                            aria-label="Recipient's username"
                                                            aria-describedby="basic-addon2"
                                                            value={filterClientPhoneNewOdt}
                                                            onChange={(e) => { setFilterClientPhoneNewOdt(e.target.value) }} />
                                                        <Button variant="outline-primary" id="button-addon2" onClick={() => { handleSearchClientForTelephone() }} disabled={!filterClientPhoneNewOdt}>
                                                            Filtrar cliente
                                                        </Button>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        {codeApiFilterClientForTelephone === 200 && filterClientFromTelephoneAPI.length > 0 ? (
                                            <>
                                                {
                                                    filterClientFromTelephoneAPI.map((client) =>
                                                    (
                                                        <>
                                                            <Row className="justify-content-start" key={client.id}>
                                                                <Col xs={4}>
                                                                    <small><b>Cliente filtrado:</b>&nbsp;{client.first_name} {client.last_name}</small>&nbsp;
                                                                    <Link to={`/gestion-de-cliente/detalle/${client.id}/${client.telephone}`} target="_blank"><AiOutlineExport /></Link>
                                                                </Col>
                                                                <Col xs={4}>
                                                                    <small><b>Teléfono:</b>&nbsp;{client.telephone}</small>&nbsp;
                                                                </Col>
                                                                <Col xs={4}>
                                                                    <small><b>E-mail:</b>&nbsp;
                                                                        <a href={`mailto:${client.email}`}>{client.email}</a>
                                                                    </small>
                                                                </Col>
                                                            </Row>

                                                            <Row className="justify-content-start">
                                                                <Col xs={4}>
                                                                    <small><b>Dirección:</b>&nbsp;{client.address}</small>&nbsp;
                                                                </Col>
                                                                <Col xs={4}>
                                                                    <small><b>Comuna:</b>&nbsp;{client.address_comune}</small>&nbsp;
                                                                </Col>
                                                                <Col xs={4}>
                                                                    <small><b>Total compras:</b>&nbsp;
                                                                        {client.total_shopping}</small>&nbsp;
                                                                </Col>
                                                            </Row>
                                                        </>

                                                    ))
                                                }
                                            </>
                                        ) : (null)}

                                        {codeApiFilterClientForTelephone === 204 ? (
                                            <Alert key='danger' variant='danger' show={disableMessageErrorFilterTelephone}>
                                                <small>El cliente con teléfono ingresado no existe.</small>
                                            </Alert>
                                        ) : (null)}
                                        <br></br>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <br></br>

                            {filterShoppingAndItemsForClient && codeApiFilterClientForTelephone === 200 && resetDataGrid === false ? (
                                <>
                                    <Card>
                                        <Card.Header>Compras pendientes de ODT</Card.Header>
                                        <Card.Body>
                                            <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                                            <Card.Text>
                                                {filterShoppingAndItemsForClient.length > 0 && codeApiFilterClientForTelephone === 200 ? (
                                                    <Table striped bordered hover responsive>
                                                        <thead>
                                                            <tr>
                                                                <th><small>Id compra</small></th>
                                                                <th><small>Ítem</small></th>
                                                                <th><small>Fecha ingreso</small></th>
                                                                <th><small>Producto/Servicio</small></th>
                                                                <th><small>Cantidad</small></th>
                                                                <th><small>Observación</small></th>
                                                                <th><small>Asignar ODT</small></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {filterShoppingAndItemsForClient.map((shopping) =>
                                                            (
                                                                <tr key={shopping.item_id}>
                                                                    <td>
                                                                        <small>{shopping.shopping_id}&nbsp;<Badge bg="danger">Abierta</Badge></small>
                                                                    </td>
                                                                    <td>
                                                                        <small>{shopping.item_id}</small>
                                                                    </td>
                                                                    <td>
                                                                        <small>{shopping.income_date}</small>
                                                                    </td>
                                                                    {shopping.product ? (
                                                                        <td>
                                                                            <small><b>(SKU: {shopping.product_sku})</b> {shopping.product}</small>
                                                                        </td>
                                                                    ) : (
                                                                        <td>
                                                                            <small><b>(SKU: {shopping.service_sku})</b> {shopping.service}</small>
                                                                        </td>
                                                                    )}
                                                                    <td>
                                                                        <small>{shopping.cant_for_item}</small>
                                                                    </td>
                                                                    <td>
                                                                        {shopping.note != null ? (
                                                                            <small>{shopping.note}</small>
                                                                        ) : (
                                                                            <small>Sin notas.</small>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {['checkbox'].map((type) => (
                                                                            <div key={`default-${type}`} className="mb-3">
                                                                                <Form.Check
                                                                                    value={shopping.item_id}
                                                                                    type={type}
                                                                                    id="check_bok_item"
                                                                                    onChange={handleManagerOdt}
                                                                                />
                                                                            </div>
                                                                        ))}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>

                                                    </Table>
                                                ) : (null)}
                                                <br></br>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </>
                            ) : (
                                <Card>
                                    <Card.Header>Compras pendientes de ODT</Card.Header>
                                    <Card.Body>
                                        <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                                        <Card.Text>
                                            <Table striped bordered hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th><small>Compra</small></th>
                                                        <th><small>Ítem</small></th>
                                                        <th><small>Fecha ingreso</small></th>
                                                        <th><small>Producto/Servicio</small></th>
                                                        <th><small>Cantidad</small></th>
                                                        <th><small>Observación</small></th>
                                                        <th><small>Asignar ODT</small></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={7}>
                                                            <small>Sin compras pendientes de ODT.</small>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <br></br>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            )}
                            <br></br>
                            <Card>
                                <Card.Header>Detalle de ODT</Card.Header>
                                <Card.Body>
                                    <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                                    <Card.Text>
                                        {filterClientFromTelephoneAPI ? (
                                            filterClientFromTelephoneAPI.map((client) => (
                                                <Odt
                                                    shopping={shoppingIdSelected}
                                                    items={itemSelected}//fix
                                                    client={client.id}
                                                    telephoneClient={filterClientPhoneNewOdt}
                                                />
                                            ))
                                        ) : (
                                            <Alert key='warning' variant='warning'>
                                                Sin Información.
                                            </Alert>
                                        )}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <br></br>
        </>
    );
}

export default FormAddOdt;