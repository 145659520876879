import React, { useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { Badge } from 'react-bootstrap';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';
import useProduct from '../../hooks/useProduct';
import { useQuery, useMutation, useQueryClient } from 'react-query';

function FormAddProduct() {
  const [product_name, setProductName] = useState('');
  const [product_sku, setProductSku] = useState('');
  const [product_brand, setProductBrand] = useState('');
  const [product_new_brand, setProductNewBrand] = useState('');
  const [product_btu, setProductBtu] = useState('');
  const [product_tech, setProductTech] = useState('');
  const queryClient = useQueryClient();
  const [product_model, setProductModel] = useState('');
  const [product_voltage, setProductVoltage] = useState('');
  const [product_bomb, setProductBomb] = useState('');
  const [product_provider, setProductProvider] = useState('');
  const [product_note, setProductNote] = useState('');
  const [product_mant_perio, setProductMantPerio] = useState('');
  const [validated, setValidated] = useState(false);
  const { codeApiHttp } = useContext(UserLoggedContext);
  const { store, codeApiStoreProduct } = useProduct();
  const [messageSuccessNewProduct, setMessageSuccessNewProduct] = useState(false);
  const [messageErrorNewProduct, setMessageErrorNewProduct] = useState(false);

  const productBrands = ['Anwo', 'Clark', 'Gree', 'Shoot', 'Midea', 'Airolite', 'Khone', 'Nueva'];
  const productBtu = ['9.000', '12.000', '18.000', '24.000', '36.000', '48.000', '60.000'];
  const productTech = ['Inverter', 'Convencional'];
  const productModel = ['Split Muro', 'Cassette', 'Ducto', 'Ventana', 'Portátil'];
  const productVoltage = ['220', '380'];
  const productBomb = ['Aplica', 'No Aplica'];
  const productProvider = ['Anwo', 'Clark', 'Otro'];

  const { mutate: mutateNewProduct, isLoading: isLoadingMutation, error } = useMutation({
    mutationFn: store,
    onSuccess: () => {
      successNewProduct();
      queryClient.invalidateQueries(['products']);
      queryClient.invalidateQueries({ queryKey: ['totalProductsAPI'] });

    },
    onError: () => {
      errorSuccessNewProduct();
    }
  });

  const handleSubmit = (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() === true) {
      e.preventDefault();
      mutateNewProduct({
        name: product_name,
        sku: product_sku,
        brand: product_brand,
        btu: product_btu,
        tech: product_tech,
        model: product_model,
        voltage: product_voltage,
        bomb: product_bomb,
        provider: product_provider,
        note: product_note,
        maintenance_periodicity: product_mant_perio
      });

      setProductName('');
      setProductSku('');
      setProductBrand('');
      setProductBtu('');
      setProductTech('');
      setProductModel('');
      setProductVoltage('');
      setProductBomb('');
      setProductProvider('');
      setProductNote('');
      setProductMantPerio('');
      setValidated(false);
    }
  };

  const successNewProduct = () => {
    setMessageSuccessNewProduct(true);
    setValidated(false);
    setTimeout(() => {
      setMessageSuccessNewProduct(false);
    }, 2000);
  }

  const errorSuccessNewProduct = () => {
    setMessageErrorNewProduct(true);
    setValidated(false);
    setTimeout(() => {
      setMessageErrorNewProduct(false);
    }, 2000);
  }

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-2">
          <Form.Group as={Col} controlId="inputProductName">
            <Form.Label>Nombre</Form.Label>
            <Form.Control type="text" name="productName" value={product_name} placeholder="Anwo 9000 btu inverter Aire Acondicionado Virus Protect" onChange={(e) => { setProductName(e.target.value) }} required />
            <Form.Control.Feedback type="invalid">Ingrese nombre.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="inputProductSku">
            <Form.Label>SKU</Form.Label>
            <Form.Control type="text" name="productSku" value={product_sku} placeholder="GES9ECO-INV-VP" onChange={(e) => { setProductSku(e.target.value) }} required />
            <Form.Control.Feedback type="invalid">Ingrese SKU.</Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-4">
          <Form.Group as={Col} controlId="selectProductBtu">
            <Form.Label>BTU</Form.Label>
            <Form.Select value={product_btu} name="selectProductBtu" onChange={(e) => { setProductBtu(e.target.value) }} required >
              <option value="">Seleccione...</option>
              <option value={productBtu[0]}>{productBtu[0]}</option>
              <option value={productBtu[1]}>{productBtu[1]}</option>
              <option value={productBtu[2]}>{productBtu[2]}</option>
              <option value={productBtu[3]}>{productBtu[3]}</option>
              <option value={productBtu[4]}>{productBtu[4]}</option>
              <option value={productBtu[5]}>{productBtu[5]}</option>
              <option value={productBtu[6]}>{productBtu[6]}</option>
              <option value={productBtu[7]}>{productBtu[7]}</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">Seleccione BTU.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="selectProductTech">
            <Form.Label>Tecnología</Form.Label>
            <Form.Select value={product_tech} name="selectProductTech" onChange={(e) => { setProductTech(e.target.value) }} required >
              <option value="">Seleccione...</option>
              <option value={productTech[0]}>{productTech[0]}</option>
              <option value={productTech[1]}>{productTech[1]}</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">Seleccione tecnología.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="selectProductModel">
            <Form.Label>Modelo</Form.Label>
            <Form.Select value={product_model} name="selectProductModel" onChange={(e) => { setProductModel(e.target.value) }} required >
              <option value="">Seleccione...</option>
              <option value={productModel[0]}>{productModel[0]}</option>
              <option value={productModel[1]}>{productModel[1]}</option>
              <option value={productModel[2]}>{productModel[2]}</option>
              <option value={productModel[3]}>{productModel[3]}</option>
              <option value={productModel[4]}>{productModel[4]}</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">Seleccione modelo.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="selectProductBrand">
            <Form.Label>Marca</Form.Label>
            <Form.Select value={product_brand} name="selectProductBrand" onChange={(e) => { setProductBrand(e.target.value) }} required >
              <option value="">Seleccione...</option>
              <option value={productBrands[0]}>{productBrands[0]}</option>
              <option value={productBrands[1]}>{productBrands[1]}</option>
              <option value={productBrands[2]}>{productBrands[2]}</option>
              <option value={productBrands[3]}>{productBrands[3]}</option>
              <option value={productBrands[4]}>{productBrands[4]}</option>
              <option value={productBrands[5]}>{productBrands[5]}</option>
              <option value={productBrands[6]}>{productBrands[6]}</option>
              <option value={productBrands[7]}>{productBrands[7]}</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">Seleccione marca.</Form.Control.Feedback>
          </Form.Group>
        </Row>

        {product_brand === "Nueva" ? (
          <Row className="mb-2">
            <Col xs={9}>
            </Col>
            <Col xs={3}>
              <Form.Group as={Col} controlId="inputProductName">
                <Form.Label>Nueva marca</Form.Label>
                <Form.Control type="text" name="productName" value={product_new_brand} placeholder="Samsung" onChange={(e) => { setProductNewBrand(e.target.value) }} />
              </Form.Group>
            </Col>
          </Row>
        ) : (null)}


        <Row className="mb-4">

          <Form.Group as={Col} controlId="selectProductVoltage">
            <Form.Label>Voltaje</Form.Label>
            <Form.Select value={product_voltage} name="selectProductVoltage" onChange={(e) => { setProductVoltage(e.target.value) }} required >
              <option value="">Seleccione...</option>
              <option value={productVoltage[0]}>{productVoltage[0]}</option>
              <option value={productVoltage[1]}>{productVoltage[1]}</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">Seleccione voltaje.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="selectProductBomb">
            <Form.Label>Bomba de Condensado</Form.Label>
            <Form.Select value={product_bomb} name="selectProductBomb" onChange={(e) => { setProductBomb(e.target.value) }} required >
              <option value="">Seleccione...</option>
              <option value={productBomb[0]}>{productBomb[0]}</option>
              <option value={productBomb[1]}>{productBomb[1]}</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">Seleccione bomba.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="selectProductProvider">
            <Form.Label>Proveedor</Form.Label>
            <Form.Select value={product_provider} name="selectProductProvider" onChange={(e) => { setProductProvider(e.target.value) }} >
              <option>Seleccione...</option>
              <option value={productProvider[0]}>{productProvider[0]}</option>
              <option value={productProvider[1]}>{productProvider[1]}</option>
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} controlId="selectProductMaintPerio">
            <Form.Label>Periodicidad de mantención</Form.Label>
            <Form.Select value={product_mant_perio} name="selectProductMaintPerio" onChange={(e) => { setProductMantPerio(e.target.value) }} required >
              <option value="">Seleccione...</option>
              <option value="90">Cada 3 meses</option>
              <option value="180">Cada 6 meses</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">Seleccione periodicidad.</Form.Control.Feedback>
          </Form.Group>

        </Row>
        <Row className="mb-12">
          <Form.Group as={Col} controlId="textProductNote">
            <Form.Label>Observaciones</Form.Label>
            <Form.Control as="textarea" name="textProductNote" value={product_note} rows={3} placeholder="Equipo viene con kit de instalación de regalo." onChange={(e) => { setProductNote(e.target.value) }} />
            <Form.Control.Feedback type="invalid">Ingrese observaciones.</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <br></br>
        <Row>
          <Col>
            <Alert variant="success" show={messageSuccessNewProduct}>
              <small>Equipo ingresado con éxito.</small>
            </Alert>
            <Alert variant="danger" show={messageErrorNewProduct}>
              <small>Error al ingresar el equipo, inténtelo más tarde.</small>
            </Alert>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col>
            <Button variant="primary" type='submit' disabled={isLoadingMutation}>
              {isLoadingMutation ? 'Guardando...' : 'Guardar'}
            </Button>
          </Col>
        </Row>
      </Form>
      <br></br>
      <br></br>

    </>
  );
}

export default FormAddProduct;