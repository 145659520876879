import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import { Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import useProduct from "../../hooks/useProduct";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import useOdt from "../../hooks/useOdt";

function OpenAgendaFilter() {
    const queryClient = useQueryClient();
    const { show, filterOdtFromAPI, codeApiFilterOdt, setFilterOdtFromAPI, filterOdtHistoryByClientTelephone, codeApiFilterOdtHis, filterOdtHisFromAPI, setFilterOdtHisFromAPI, lastPageFromAPI } = useOdt();
    const [clientTelOdtFilter, setClientTelOdtFilter] = useState("");
    const [messageSuccessOdtDelete, setMessageSuccessOdtDelete] = useState(false);
    const [messageErrorOdtDelete, setMessageErrorOdtDelete] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    const [odtForDelete, setOdtForDelete] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    const [desableDeleteBtn, setDesableDeleteBtn] = useState(false);
    const [pageNumber, setPageNumer] = useState(1);

    const handleFilterOdt = (e) => {
        e.preventDefault();
        filterOdtHistoryByClientTelephone(clientTelOdtFilter, pageNumber);
    }


    const deleteFilteredOdtHis = () => {
        setClientTelOdtFilter("");
        setFilterOdtHisFromAPI([]);
    }

    const showPopupDelete = (id) => {
        setOdtForDelete(id);
        //showModal(id);
        setShowDelete(true);
    }

    return (
        <>
            <Row>
                <Col>
                    <Form onSubmit={handleFilterOdt}>
                        <InputGroup className="mb-1">
                            <Form.Control
                                name="clientTelOdtFilter"
                                type="string"
                                value={clientTelOdtFilter}
                                onChange={(e) => { setClientTelOdtFilter(e.target.value) }}
                                placeholder="56945455668"
                                aria-label="odt id sku"
                                aria-describedby="basic-addon2"
                            />
                            <Button type="submit" variant="outline-primary" id="button-addon2" disabled={!clientTelOdtFilter}>
                                Filtrar odt
                            </Button>
                        </InputGroup>
                    </Form>
                </Col>
            </Row>

            {codeApiFilterOdtHis === 200 && filterOdtHisFromAPI.length > 0 ? (
                <>
                    <br></br>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={12} md={11}>
                                    Odts filtradas
                                </Col>
                                <Col xs={12} md={1}>
                                    <Button variant="outline-danger" onClick={deleteFilteredOdtHis} size="sm">Limpiar</Button>{' '}                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    {filterOdtHisFromAPI ? (
                                        <Col>
                                            <small>Total odts&nbsp;</small><Badge bg="info">{filterOdtHisFromAPI['total']}</Badge>
                                        </Col>
                                    ) : (null)}
                                </Row>
                                <Row>
                                    <Col>
                                        <br></br>
                                        <Table striped bordered hover responsive>
                                            <thead>
                                                <tr>
                                                    <th><small>Id ODT</small></th>
                                                    <th><small>Inicio servicio</small></th>
                                                    <th><small>Cierre servicio</small></th>
                                                    <th><small>Dirección</small></th>
                                                    <th><small>Motivo</small></th>
                                                    <th><small>Nombre cliente</small></th>
                                                    <th><small>Teléfono cliente</small></th>
                                                    <th><small>Estado</small></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filterOdtHisFromAPI.map((odt, index) =>
                                                (
                                                    <tr key={index}>
                                                        <td>
                                                            <small>
                                                                <Link to={`/gestion-de-odt/detalle/${odt.id_odt}/${odt.client_telephone}`} className='nav-link nav-link-client'>{odt.id_odt}</Link>
                                                            </small>
                                                        </td>
                                                        {odt.state_odt === "0" ? (
                                                            <td className="td-agenda-resume-danger">
                                                                <small><b>{odt.visiting_date} - {odt.visiting_hour}</b></small>
                                                            </td>
                                                        ) : (null)}
                                                        {odt.state_odt === "1" ? (
                                                            <td className="td-agenda-resume-warning">
                                                                <small><b>{odt.visiting_date} - {odt.visiting_hour}</b></small>
                                                            </td>
                                                        ) : (null)}
                                                        {odt.state_odt === "2" ? (
                                                            <td className="td-agenda-resume-success">
                                                                <small><b>{odt.visiting_date} - {odt.visiting_hour}</b></small>
                                                            </td>
                                                        ) : (null)}


                                                        {odt.state_odt === "0" ? (
                                                            <td className="td-agenda-resume-danger">
                                                                <small><b>Nueva</b></small>
                                                            </td>
                                                        ) : (null)}
                                                        {odt.state_odt === "1" ? (
                                                            <td className="td-agenda-resume-warning">
                                                                <small><b>En proceso</b></small>
                                                            </td>
                                                        ) : (null)}
                                                        {odt.state_odt === "2" ? (
                                                            <td className="td-agenda-resume-success">
                                                                <small> <b>{odt.closing_date} - {odt.closing_hour}</b></small>
                                                            </td>
                                                        ) : (null)}

                                                        <td>
                                                            <small>{odt.address_comune}&nbsp;({odt.client_address})</small>
                                                        </td>
                                                        <td>
                                                            <small>{odt.service_name}</small>
                                                        </td>
                                                        <td>
                                                            <Link to={`/gestion-de-cliente/detalle/${odt.client_id}/${odt.client_telephone}`} className="nav-link nav-link-client" target="_blank"><small>{odt.client_first_name} {odt.client_last_name}</small></Link>
                                                        </td>
                                                        <td>
                                                            <small>
                                                                <Link to={`https://api.whatsapp.com/send?phone=${odt.client_telephone}`} target="_blank"><small>{odt.client_telephone}</small></Link>
                                                            </small>
                                                        </td>
                                                        <td>
                                                            {odt.state_odt === "0" ? (
                                                                <small>
                                                                    <Badge bg="danger">Nueva</Badge>
                                                                </small>
                                                            ) : (null)}
                                                            {odt.state_odt === "1" ? (
                                                                <small>
                                                                    <Badge bg="warning">En proceso</Badge>
                                                                </small>
                                                            ) : (null)}
                                                            {odt.state_odt === "2" ? (
                                                                <small>
                                                                    <Badge bg="success">Cerrada</Badge>
                                                                </small>
                                                            ) : (null)}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button onClick={() => setPageNumer((page) => page - 1)} disabled={pageNumber === 1} variant="outline-primary">Volver</Button>{' '}
                                        <Button onClick={() => setPageNumer((page) => page + 1)} disabled={pageNumber === lastPageFromAPI} variant="outline-primary">Siguiente</Button>{' '}
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </>
            ) : (null)}

            {codeApiFilterOdtHis === 204 ? (
                <>
                    <br></br>
                    <Alert key='danger' variant='danger'>
                        <small>La odt con el teléfono ingresado no existe.</small>
                    </Alert>
                </>
            ) : (null)}

            {filterOdtFromAPI ? (
                <Modal show={showDelete} onHide={handleCloseDelete} >
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminación de ODT</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                ¿Quieres eliminar la ODT &nbsp;

                                {filterOdtFromAPI.map((odt, index) =>
                                (
                                    <small key={index}>
                                        <b>{odt.id}</b>
                                    </small>
                                ))
                                }?

                            </Col>
                        </Row>
                        <br></br>
                        <Alert variant="success" show={messageSuccessOdtDelete}>
                            <small>Odt eliminado con éxito.</small>
                        </Alert>
                        <Alert variant="danger" show={messageErrorOdtDelete}>
                            <small>Error al eliminar odt, inténtelo más tarde.</small>
                        </Alert>
                        <br></br>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDelete}>
                            Cerrar
                        </Button>
                        <Button variant="primary" disabled={desableDeleteBtn}>
                            Si, eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : (null)}

        </>
    );

}
export default OpenAgendaFilter;