import React, { useState, useEffect, useContext } from "react";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { Button, Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { UserLoggedContext } from '../contexts/UserLoggedContext';
import useCustomAuth from "../hooks/useCustomAuth";

function Login() {
  const [validated, setValidated] = useState(false);
  const [user_email, setUserEmail] = useState('');
  const [user_password, setUserPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const { token, codeLogin } = useContext(UserLoggedContext);
  const { login } = useCustomAuth();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    if (token != null) {
      localStorage.clear();
      //setToken('');localStorage.clear();localStorage.removeItem(token);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity() === true) {
      login(user_email, user_password);
    }
  };

  return (
    <>
      {loading ? (
        <>
          <br></br>
          <Spinner animation="border" variant="secondary" />
        </>
      ) : (
        <>
          <br></br>
          <Card>
            <Card.Body className="login-form-card">
              <Card.Title>ERP Grupo EHAC, Chile</Card.Title>
              <Card.Subtitle className="mb-3 text-muted">Inicio de sesión</Card.Subtitle>
              <Card.Text>
                <div className="login-form w-100 m-auto">
                  <Row>
                    <Col xs={12}>
                      <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group className="mb-2" controlId="validationEmail">
                          <Form.Label><small>Tu email</small></Form.Label>
                          <Form.Control type="email" placeholder="juan.perez@gmail.com" value={user_email} onChange={(e) => { setUserEmail(e.target.value) }} required />
                          <Form.Control.Feedback type="invalid">Ingresa tu email.</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-2" controlId="formBasicPassword">
                          <Form.Label><small>Tu contraseña</small></Form.Label>
                          <Form.Control type="password" placeholder="Password" value={user_password} onChange={(e) => { setUserPassword(e.target.value) }} required />
                          <Form.Control.Feedback type="invalid">Ingresa tu contraseña.</Form.Control.Feedback>
                        </Form.Group>

                        {codeLogin === 200 ? (
                          <Form.Group className="mb-2">
                            <Alert key='success' variant='success'>
                              <small>Inicio de sesión exitoso.</small>
                            </Alert>
                          </Form.Group>
                        ) : null}

                        {codeLogin === 400 || codeLogin === 401 ? (
                          <Form.Group className="mb-2">
                            <Alert key='danger' variant='danger'>
                              <small>E-mail y/o clave incorrecta.</small>
                            </Alert>
                          </Form.Group>
                        ) : null}
                        <br></br>
                        <Button variant="primary" type="submit">
                          Ingresar
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                  <br></br>
                  <Row>
                    <Col>
                      {/*<Link to={`/recuperar-cuenta`} className='nav-link nav-link-client'><small>¿Olvidaste la contraseña?</small></Link>*/}
                      <a href="/recuperar-cuenta" className="nav-link nav-link-client"><small>¿Olvidaste la contraseña?</small></a>
                    </Col>
                  </Row>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
}
export default Login;