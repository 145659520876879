import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import { Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import useProduct from "../../hooks/useProduct";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import useOdt from "../../hooks/useOdt";

function OdtFilter() {
    const queryClient = useQueryClient();
    const { showForSku, showDeleteModal, destroy, codeApiFilterProduct, filterProductFromAPI, setFilterProductFromAPI, filterProductFromAPIForDelModal } = useProduct();
    const { show, filterOdtFromAPI, codeApiFilterOdt, setFilterOdtFromAPI } = useOdt();
    const [odtToFilter, setOdtToFilter] = useState("");
    const [messageSuccessOdtDelete, setMessageSuccessOdtDelete] = useState(false);
    const [messageErrorOdtDelete, setMessageErrorOdtDelete] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    const [odtForDelete, setOdtForDelete] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    const [desableDeleteBtn, setDesableDeleteBtn] = useState(false);

    const deleteProductMutation = useMutation({
        mutationFn: destroy,
        onSuccess: () => {
            //successProductDelete();
            queryClient.invalidateQueries(['odts']);
        },
        onError: () => {
            //errorProductDelete();
        }
    });

    const handleFilterOdt = (e) => {
        e.preventDefault();
        show(odtToFilter);
    }


    const deleteFilteredProduct = () => {
        setOdtToFilter("");
        setFilterOdtFromAPI([]);
    }

    const showPopupDelete = (id) => {
        setOdtForDelete(id);
        //showModal(id);
        setShowDelete(true);
    }

    return (
        <>
            <Row>
                <Col>
                    <Form onSubmit={handleFilterOdt}>
                        <InputGroup className="mb-1">
                            <Form.Control
                                name="odtToFilter"
                                type="string"
                                value={odtToFilter}
                                onChange={(e) => { setOdtToFilter(e.target.value) }}
                                placeholder="123"
                                aria-label="odt id sku"
                                aria-describedby="basic-addon2"
                            />
                            <Button type="submit" variant="outline-primary" id="button-addon2" disabled={!odtToFilter}>
                                Filtrar odt
                            </Button>
                        </InputGroup>
                    </Form>
                </Col>
            </Row>

            {codeApiFilterOdt === 200 && filterOdtFromAPI.length > 0 ? (
                <>
                    <br></br>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={12} md={11}>
                                    Odt filtrada
                                </Col>
                                <Col xs={12} md={1}>
                                    <Button variant="outline-danger" onClick={deleteFilteredProduct} size="sm">Limpiar</Button>{' '}                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <br></br>
                                <Row>
                                    <Col>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th><small>Id ODT</small></th>
                                                    <th><small>Fecha ingreso</small></th>
                                                    <th><small>Fecha visita</small></th>
                                                    <th><small>Cliente</small></th>
                                                    <th><small>Teléfono cliente</small></th>
                                                    <th><small>Supervisor</small></th>
                                                    <th><small>Técnico</small></th>
                                                    <th><small>Compra asociada</small></th>
                                                    <th><small>Notificación</small></th>
                                                    <th><small>Estado</small></th>
                                                    <th><small>Eliminar</small></th>
                                                    <th><small>Editar</small></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filterOdtFromAPI.map((odt, index) =>
                                                (
                                                    <tr key={index}>
                                                        <td>
                                                            <small>
                                                                <Link to={`/gestion-de-odt/detalle/${odt.id_odt}/${odt.client_telephone}`} className='nav-link nav-link-client'>{odt.id_odt}</Link>
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <small>{odt.admision_date}</small>
                                                        </td>
                                                        <td>
                                                            <small>{odt.visiting_date} ({odt.visiting_hour})</small>
                                                        </td>
                                                        <td>
                                                            <small>{odt.client_first_name} {odt.client_last_name}</small>
                                                        </td>
                                                        <td>
                                                            <small>{odt.client_telephone}</small>
                                                        </td>
                                                        <td>
                                                            <small>{odt.supervisor}</small>
                                                        </td>
                                                        <td>
                                                            <small>{odt.technical}</small>
                                                        </td>
                                                        {odt.shopping == 0 ? (
                                                            <td>
                                                                <small>
                                                                    Sin ODT
                                                                </small>
                                                            </td>
                                                        ) : (
                                                            <td>
                                                                <small>
                                                                    {odt.shopping}
                                                                </small>
                                                            </td>
                                                        )}
                                                        {odt.notification_status == 0 ? (
                                                            <td>
                                                                <small>
                                                                    <Badge bg="danger">No enviada</Badge>
                                                                </small>
                                                            </td>
                                                        ) : (null)}
                                                        {odt.notification_status == 1 ? (
                                                            <td>
                                                                <small>
                                                                    <Badge bg="success">Enviada</Badge>
                                                                </small>
                                                            </td>
                                                        ) : (null)}
                                                        <td>
                                                            {odt.state_odt === "0" ? (
                                                                <small>
                                                                    <Badge bg="danger">Nueva</Badge>
                                                                </small>
                                                            ) : (null)}
                                                            {odt.state_odt === "1" ? (
                                                                <small>
                                                                    <Badge bg="warning">En proceso</Badge>
                                                                </small>
                                                            ) : (null)}
                                                            {odt.state_odt === "2" ? (
                                                                <small>
                                                                    <Badge bg="success">Cerrada</Badge>
                                                                </small>
                                                            ) : (null)}
                                                        </td>
                                                        <td>
                                                            <Link onClick={() => { showPopupDelete(odt.id_odt) }} className='nav-link'><AiOutlineDelete /></Link>
                                                        </td>

                                                        {odt.state_odt != "2" ? (
                                                            <td>
                                                                <Link to={`/gestion-de-odt/editar/${odt.id}`} className='nav-link'><AiOutlineEdit /></Link>
                                                            </td>
                                                        ) : (
                                                            <td>
                                                                <small>No aplica</small>
                                                            </td>
                                                        )}

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </>
            ) : (null)}

            {codeApiFilterOdt === 204 ? (
                <>
                    <br></br>
                    <Alert key='danger' variant='danger'>
                        <small>La odt con el id ingresado no existe.</small>
                    </Alert>
                </>
            ) : (null)}

            {filterOdtFromAPI ? (
                <Modal show={showDelete} onHide={handleCloseDelete} >
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminación de ODT</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                ¿Quieres eliminar la ODT &nbsp;

                                {filterOdtFromAPI.map((odt, index) =>
                                (
                                    <small key={index}>
                                        <b>{odt.id}</b>
                                    </small>
                                ))
                                }?

                            </Col>
                        </Row>
                        <br></br>
                        <Alert variant="success" show={messageSuccessOdtDelete}>
                            <small>Odt eliminado con éxito.</small>
                        </Alert>
                        <Alert variant="danger" show={messageErrorOdtDelete}>
                            <small>Error al eliminar odt, inténtelo más tarde.</small>
                        </Alert>
                        <br></br>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDelete}>
                            Cerrar
                        </Button>
                        <Button variant="primary" disabled={desableDeleteBtn}>
                            Si, eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : (null)}

        </>
    );

}
export default OdtFilter;