import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { Card } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import useClient from '../../hooks/useClient';
import useUtilities from '../../hooks/useUtilities';
import { useQuery, useMutation, useQueryClient } from 'react-query';
//import fileCsvExample from "../../../public/clients.csv";

function FormAddClient() {
  const clientState = ["Activo", "Inactivo"];
  const [client_run, setClientRun] = useState('');
  const [client_first_name, setClientFirstName] = useState('');
  const [client_last_name, setClientLastName] = useState('');
  const [client_telephone, setClientTelephone] = useState('');
  const [client_date_save, setClientDateSave] = useState('');
  const [client_address, setClientAddress] = useState('');
  const [client_address_comune, setClientAddressComune] = useState('');
  const [client_email, setClientEmail] = useState('');
  const [client_state, setClientState] = useState('');
  const [client_notes, setClientNotes] = useState('');
  const [business, setBusiness] = useState('');
  const [business_name, setBusinessName] = useState('');
  const [business_line, setBusinessLine] = useState('');
  const [validated, setValidated] = useState(false);
  const { store, index, codeApi, greet } = useClient();
  const [messageSuccessNewClient, setMessageSuccessNewClient] = useState(false);
  const [messageErrorNewClient, setMessageErrorNewClient] = useState(false);
  const [messageSuccessImportClients, setMessageSuccessImportClients] = useState(false);
  const [messageErrorImportClients, setMessageErrorImportClients] = useState(false);
  const queryClient = useQueryClient();
  const [showImport, setShowImport] = useState(false);
  const [fileCsvImportClients, setFileCsvImportClients] = useState("");
  const fileReader = new FileReader();
  const [pageNumber, setPageNumer] = useState(1);
  const [csvToArray, setCsvToArray] = useState([]);
  const [disabledButtonNewClient, setDisabledButtonNewClient] = useState(true);
  const { calculateDaysForMaintenance } = useUtilities();

  const { isLoading, data: clients, isError: isErrorDataFetchingAllClients } = useQuery(
    ['clients', pageNumber],
    () => index(pageNumber));

  useEffect(() => {
    index();
  }, []);

  const { mutate: mutateNewClient, isLoading: isLoadingMutation, error } = useMutation({
    mutationFn: store,
    onSuccess: () => {
      successNewClient();
      queryClient.invalidateQueries(['clients']);
      queryClient.invalidateQueries(['totalClientsAPI']);
      queryClient.invalidateQueries(['totalClientsGlobal']);

    },
    onError: () => {
      errorSuccessNewClient()
    }
  });

  const { mutate: mutateNewClientFromCsv } = useMutation({
    mutationFn: store,
    onSuccess: () => {
      successNewClientFromCsv()
      queryClient.invalidateQueries(['clients']);
      queryClient.invalidateQueries(['totalClientsAPI']);
      queryClient.invalidateQueries(['totalClientsGlobal']);
    },
    onError: () => {
      errorSuccessNewClientFromCsv()
    }
  });

  const successNewClient = () => {
    setMessageSuccessNewClient(true);
    setValidated(false);
    setTimeout(() => {
      setMessageSuccessNewClient(false);
    }, 2000);
  }

  const errorSuccessNewClient = () => {
    setMessageErrorNewClient(true);
    setValidated(false);
    setTimeout(() => {
      setMessageErrorNewClient(false);
    }, 2000);
  }

  //fix
  const successNewClientFromCsv = () => {
    setMessageSuccessImportClients(true);
    setTimeout(() => {
      setMessageSuccessImportClients(false);
      setShowImport(false);
    }, 2000);
  }

  const errorSuccessNewClientFromCsv = () => {
    setMessageErrorImportClients(true);
    setTimeout(() => {
      setMessageErrorImportClients(false);
    }, 2000);
  }

  const handleSubmit = (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() === true) {
      e.preventDefault();
      mutateNewClient({
        run: client_run,
        first_name: client_first_name,
        last_name: client_last_name,
        telephone: client_telephone,
        address: client_address,
        address_comune: client_address_comune,
        email: client_email,
        state: client_state,
        note: client_notes,
        shopping: 0,
        odt: 0,
        business: business,
        business_name: business_name,
        business_line: business_line,
        income_type: "app",
        count_maintenance_days: 0,
      });

      setClientRun('');
      setClientFirstName('');
      setClientLastName('');
      setClientFirstName('');
      setClientDateSave('');
      setClientAddress('');
      setClientTelephone('');
      setClientAddressComune('');
      setClientEmail('');
      setClientState('');
      setClientFirstName('');
      setClientNotes('');
      setBusiness('');
      setBusinessName('');
      setBusinessLine('');
      setValidated(false);
    }
  };

  const showPopupImportClients = () => {
    setShowImport(true);
  }

  const convertCsvToArray = (str, delim = ',') => {
    const headers = str.slice(0, str.indexOf('\r\n')).split(delim);
    const rows = str.slice(str.indexOf('\n') + 1).split('\r\n');
    const newArray = rows.map(row => {
      const values = row.split(delim);
      //console.log(values);
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i];
        return obj;
      }, {})
      return eachObject;
    })
    setCsvToArray(newArray);
    //console.log(csvToArray);
    /*
        if (csvToArray.length === 0) {
          console.log('ok');
          for (let i = 0; i <= 2; i++) {
            handleMutateImportClients();
          }
        }
        */
  }

  const handleImportClients = (e) => {
    e.preventDefault();
    const file = fileCsvImportClients;
    const reader = new FileReader();
    reader.onload = function (e) {
      const text = e.target.result;
      convertCsvToArray(text);
    }
    reader.readAsText(file);
    handleMutateImportClients();
    if (csvToArray.length) {
      //console.log('ok!');
    }
  }

  const handleChangeInputFile = (e) => {
    setFileCsvImportClients(e.target.files[0]);
    //console.log('csv uploaded!');

  }
  //console.log(fileCsvImportClients);


  const handleCloseImport = () => {
    setShowImport(false);
    setFileCsvImportClients();
  }

  const handleDaysCount = (data) => {
    console.log(calculateDaysForMaintenance(data));
    return calculateDaysForMaintenance(data);
  }

  const handleMutateImportClients = () => {
    if (csvToArray.length > 0) {
      csvToArray.map((f, index) => (
        <div key={index}>
          {mutateNewClientFromCsv({
            run: f.run,
            rut: f.rut,
            first_name: f.first_name,
            last_name: f.last_name,
            role: f.role,
            business: f.business,
            business_name: f.business_name,
            business_line: f.business_line,
            telephone: f.telephone,
            additional_telephone: f.additional_telephone,
            email: f.email,
            address: f.address,
            address_comune: f.address_comune,
            maintenance_status: f.maintenance_status,
            client_status: f.client_status,
            last_visit: f.last_visit,
            //count_maintenance_days: handleDaysCount(f.last_visit),
            count_maintenance_days: 0,
            note: f.note,
            shopping: f.shopping,
            odt: f.odt,
            correct_cellphone: f.correct_cellphone,
            income_type: "imp",
          })}
        </div>
      ))
      setMessageSuccessImportClients(true);
    }
  }



  return (
    <>
      <Row>
        <Col xs={5}>
        </Col>
        <Col xs={5}>
        </Col>
        <Col xs={1}>
          <Button onClick={() => showPopupImportClients()} variant="outline-primary">Importar</Button>
        </Col>
        <Col xs={1}>
          <Button variant="outline-primary" disabled={!clients}>Exportar</Button>
        </Col>
      </Row>
      <br></br>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-4">
          <Form.Group as={Col} controlId="inputClientRun">
            <Form.Label>RUN/RUT</Form.Label>
            <Form.Control type="text" name="inputClientRun" value={client_run} placeholder="15991122-1" onChange={(e) => { setClientRun(e.target.value) }} />
          </Form.Group>

          <Form.Group as={Col} controlId="inputClientFirstName">
            <Form.Label>Nombres</Form.Label>
            <Form.Control type="text" name="clientFirstName" value={client_first_name} placeholder="Juan Carlos" onChange={(e) => { setClientFirstName(e.target.value) }} required />
            <Form.Control.Feedback type="invalid">Ingrese nombres.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="inputClientLastName">
            <Form.Label>Apellidos</Form.Label>
            <Form.Control type="text" name="clientLastName" value={client_last_name} placeholder="Pérez Potapo" onChange={(e) => { setClientLastName(e.target.value) }} required />
            <Form.Control.Feedback type="invalid">Ingrese apellidos.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="inputClientTelephone">
            <Form.Label>Teléfono</Form.Label>
            <Form.Control type="number" name="clientTelephone" value={client_telephone} placeholder="56912345678" min="11" onChange={(e) => { setClientTelephone(e.target.value) }} required />
            <Form.Control.Feedback type="invalid">Ingrese teléfono.</Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="inputClientEmail">
            <Form.Label>E-mail</Form.Label>
            <Form.Control type="email" name="clientEmail" value={client_email} placeholder="juan.perez@gmail.com" onChange={(e) => { setClientEmail(e.target.value) }} />
          </Form.Group>

          <Form.Group as={Col} controlId="inputClientAddress">
            <Form.Label>Dirección (Calle)</Form.Label>
            <Form.Control type="text" name="clientAddress" value={client_address} placeholder="Los Lirios #2111" onChange={(e) => { setClientAddress(e.target.value) }} required />
            <Form.Control.Feedback type="invalid">Ingrese dirección.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="inputClientAddressComune">
            <Form.Label>Comuna</Form.Label>
            <Form.Control type="text" name="clientAddressComune" value={client_address_comune} placeholder="Rancagua" onChange={(e) => { setClientAddressComune(e.target.value) }} required />
            <Form.Control.Feedback type="invalid">Ingrese comuna.</Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="inputClientEmail">
            <Form.Label>Nombre empresa</Form.Label>
            <Form.Control type="text" name="business" value={business} placeholder="Walmart" onChange={(e) => { setBusiness(e.target.value) }} />
          </Form.Group>

          <Form.Group as={Col} controlId="inputClientAddress">
            <Form.Label>Razón social</Form.Label>
            <Form.Control type="text" name="businessName" value={business_name} placeholder="Walmart S.A." onChange={(e) => { setBusinessName(e.target.value) }} />
          </Form.Group>

          <Form.Group as={Col} controlId="inputClientAddressComune">
            <Form.Label>Giro</Form.Label>
            <Form.Control type="text" name="businessLine" value={business_line} placeholder="Cadena de supermercados minoristas" onChange={(e) => { setBusinessLine(e.target.value) }} />
          </Form.Group>
        </Row>

        <Row className="mb-2">
          <Form.Group as={Col} controlId="inputClientState">
            <Form.Label>Estado</Form.Label>
            <Form.Select defaultValue="Choose..." name="clientState" onChange={(e) => { setClientState(e.target.value) }} required >
              <option value="">Seleccione...</option>
              <option value="1">{clientState[0]}</option>
              <option value="0">{clientState[1]}</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">Seleccione estado.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="inputClientNotes">
            <Form.Label>Observaciones</Form.Label>
            <Form.Control as="textarea" name="clientNotes" value={client_notes} rows={3} placeholder="Cliente sin compras aún." onChange={(e) => { setClientNotes(e.target.value) }} />
            <Form.Control.Feedback type="invalid">Ingrese observaciones.</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <br></br>
        <Row>
          <Col>
            <Alert variant="success" show={messageSuccessNewClient}>
              <small>Cliente ingresado con éxito.</small>
            </Alert>
            <Alert variant="danger" show={messageErrorNewClient}>
              <small>Error al ingresar el cliente, inténtelo más tarde.</small>
            </Alert>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col>
            <Button variant="primary" type='submit' disabled={isLoadingMutation}>
              {isLoadingMutation ? 'Guardando...' : 'Guardar'}
            </Button>
          </Col>
        </Row>
      </Form>
      <br></br>



      <Modal show={showImport} onHide={handleCloseImport} >
        <Modal.Header closeButton>
          <Modal.Title>Importación de clientes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Alert variant="info">
                <Alert.Heading><h6>Información de ayuda:</h6></Alert.Heading>
                <small>
                  El archivo csv(valores separados por coma) a subir debe ser idéntico al archivo base de clientes de muestra.
                </small>
              </Alert>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <Form>
                <Row className="mb-1">
                  <div className="input-group custom-file-button">
                    <label className="input-group-text" htmlFor="inputImportCsvClients">Agregar archivo</label>
                    <input type="file" onChange={handleChangeInputFile} accept={".csv"} name="inputImportCsvClients" className="form-control" id="inputImportCsvClients" />
                    <Form.Control.Feedback type="invalid">Seleccione archivo.</Form.Control.Feedback>
                  </div>
                </Row>
                <br></br>
                {csvToArray.length > 0 ? (
                  <Row>
                    <Col>
                      Total de registros: <b>{csvToArray.length}</b>
                    </Col>
                  </Row>
                ) : (null)}
                <Row className="mb-1">
                  <Col>
                    <Link to="/files/base_clientes.csv" target="_blank" download><small>Descargar base de clientes de muestra</small></Link>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <br></br>
          <Alert variant="success" show={messageSuccessImportClients}>
            <small>Clientes importados con éxito.</small>
          </Alert>
          <Alert variant="danger" show={messageErrorImportClients}>
            <small>Error al importar clientes, revise su archivo csv.</small>
          </Alert>
          <br></br>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseImport}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={(e) => handleImportClients(e)} disabled={!fileCsvImportClients}>
            Importar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FormAddClient;