import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { UserLoggedContext } from '../contexts/UserLoggedContext';

function useShopping() {
    const API_URL = process.env.REACT_APP_API_URL;
    const [totalItems, setTotalItems] = useState();
    const [codeApiStoreShopping, setCodeApiStoreShopping] = useState(0);
    const [codeApiStoreShoppingItem, setCodeApiStoreShoppingItem] = useState(0);
    const [filterShoppingAndItemsForClient, setFilterShoppingAndItemsForClient] = useState([]);
    const [filterShoppingForItem, setFilterShoppingForItem] = useState([]);
    const { idShoppingSaved, setIdShoppingSaved, setFilterShoppingFromAPI } = useContext(UserLoggedContext);
    const [codeApiFilterShoppingForId, setCodeApiFilterShoppingForId] = useState(0);
    const [filterShoppingForIdAPI, setFilterShoppingForIdAPI] = useState([]);
    const [filterShoppingForTelephoneClientAPI, setFilterShoppingForTelephoneClientAPI] = useState([]);
    const [codeApiFilterShoppingForTelephoneClient, setCodeApiFilterShoppingForTelephoneClient] = useState(0);
    const [lastPageFromAPI, setLastPageFromAPI] = useState(0);
    const [totalDataFromAPI, setTotalDataFromAPI] = useState(0);

    const totalShoppings = () => {
        return axios.get(API_URL + 'shopping')
            .then(function (response) {
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

/*
bk
    const index = () => {
        return axios.get(API_URL + 'shopping')
            .then(function (response) {
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }
*/

const index = ($pageNumber) => {
    return axios.get(API_URL + 'shopping/?' + 'limit=5' + '&page=' + $pageNumber)
        .then(function (response) {
            if(response.data.code != 204){
                setTotalDataFromAPI(response.data.data.total);
                setLastPageFromAPI(response.data.data.last_page);
            }
            console.log(response.data.data.data);
            return response.data.data.data;
        })
        .catch(function (error) {
        })
        .finally(function () {
        });
}

    const updateOdt = (data) => {
        return axios.put(API_URL + 'shopping-update-odt', data)
            .then(function (response) {
                console.log(response.data.message);
                return(response.data.code);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }
    
    const show = (id) => {
        return axios.get(API_URL + 'shopping' + '/' + id)
            .then(function (response) {
                console.log(response);
                //setCodeApiFilterShoppingForId(response.data.code);
                //setFilterShoppingForIdAPI(response.data.data);
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const filterShoppingById = (id) => {
        return axios.get(API_URL + 'filter-shopping-by-id' + '/' + id)
            .then(function (response) {
                console.log(response);
                setCodeApiFilterShoppingForId(response.data.code);
                setFilterShoppingForIdAPI(response.data.data);
                console.log(response.data.code);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    /*
    const index = ($pageNumber) => {
    return axios.get(API_URL + 'shopping/?' + 'limit=5' + '&page=' + $pageNumber)
        .then(function (response) {
            console.log(response.data.data.total);
            if(response.data.code != 204){
                setTotalDataFromAPI(response.data.data.total);
                setLastPageFromAPI(response.data.data.last_page);
            }
            return response.data.data.data;
        })
        .catch(function (error) {
        })
        .finally(function () {
        });
}
     */
    const filterShoppingByClientTelephone = ($telephone, $pageNumber) => {
        return axios.get(API_URL + 'filter-shopping-by-client-telephone' + '/' + $telephone)
        ////return axios.get(API_URL + 'client/?' + 'limit=5' + '&page=' + $pageNumber)
            .then(function (response) {
                if(response.data.code != 204){
                    console.log(response);
                    setTotalDataFromAPI(response.data.data.total);
                    setLastPageFromAPI(response.data.data.last_page);
                }
                return response.data.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const showPurchasedEquipmentByClientTelephone = ($telephone, $pageNumber) => {
        return axios.get(API_URL + 'purchased-equipment-by-client-telephone' + '/' + $telephone)
            .then(function (response) {
                    setTotalDataFromAPI(response.data.data.total);
                    setLastPageFromAPI(response.data.data.last_page);
                return response.data.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }


    /*
    const filterShoppingByClientTelephone = (telephone) => {
        return axios.get(API_URL + 'filter-shopping-by-client-telephone' + '/' + telephone)
            .then(function (response) {
                console.log(response.data.data);
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }
    */

    const showItems = (id) => {
        return axios.get(API_URL + 'shopping-items' + '/' + id)
            .then(function (response) {
                console.log(response.data.data);
                return response.data.data;
                //setFilterShoppingFromAPI(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    //fix
    const showShoppingDetails = (id) => {
        return axios.get(API_URL + 'shopping/shopping-details' + '/' + id)
            .then(function (response) {
                console.log(response);
                setCodeApiFilterShoppingForId(response.data.code);
                setFilterShoppingFromAPI(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    //possible bug
    const showShoppingDetailsItem = (id) => {
        return axios.get(API_URL + 'shopping-item-details' + '/' + id)
            .then(function (response) {
                console.log(response.data.data);
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const showShoppingDetailsByClientIdAndState = (telephone_client, state_shopping) => {
        return axios.get(API_URL + 'shopping-details-odt' + '/' + telephone_client + '/' + state_shopping)
            .then(function (response) {
                console.log(response);
                console.log(response.data.data);
                //setFilterShoppingFromAPI(response.data.data);
                setFilterShoppingAndItemsForClient(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const store = (data) => {
        return axios.post(API_URL + "shopping", data)
            .then(function (response) {
                console.log(response);
                console.log(response.data.message['id']);                
                setIdShoppingSaved(response.data.message['id']);
                if (response.data.code === 200) {
                    console.log('Ingreso correcto.');
                }
                setCodeApiStoreShopping(response.data.code);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    };

    const storeItem = (data) => {
        return axios.post(API_URL + "shopping-items", data)
            .then(function (response) {
                console.log(response);
                if (response.data.code === 200) {
                    console.log('Ingreso correcto.');
                }
                setCodeApiStoreShoppingItem(response.data.code);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    };

    const destroy = (id) => {
           return axios.delete(API_URL + 'shopping' + '/' + id)
            .then(function (response) {
                console.log(response);
                setIdShoppingSaved(0);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const removeShoppingItem = (id) => {
        return axios.delete(API_URL + 'shopping-items' + '/' + id)
         .then(function (response) {
             console.log(response);
         })
         .catch(function (error) {
             console.log(error);
         })
         .finally(function () {
         });
 }

    const updateInfoClientByShopping = (data) => {
        return axios.post(API_URL + "update-info-client-by-shopping", data)
         .then(function (response) {
             console.log(response);
             setIdShoppingSaved(0);
         })
         .catch(function (error) {
             console.log(error);
         })
         .finally(function () {
         });
 }

 const deleteAll = () => {
    return axios.delete(API_URL + 'shopping-delete-all')
        .then(function (response) {
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {
        });
}

    return {
        store,
        storeItem,
        index,
        show,
        showItems,
        showShoppingDetails,
        showShoppingDetailsByClientIdAndState,
        destroy,
        filterShoppingAndItemsForClient,
        showShoppingDetailsItem,
        filterShoppingForItem,
        updateOdt,
        codeApiFilterShoppingForId,
        filterShoppingById,
        filterShoppingForIdAPI,
        setFilterShoppingForIdAPI,     
        lastPageFromAPI,
        totalDataFromAPI,
        filterShoppingByClientTelephone,
        codeApiFilterShoppingForTelephoneClient,
        filterShoppingForTelephoneClientAPI,
        updateInfoClientByShopping,
        removeShoppingItem,
        showPurchasedEquipmentByClientTelephone,
        totalShoppings,
        deleteAll,
    }
}

export default useShopping;