import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { AiOutlineEnvironment } from "react-icons/ai";
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import useOdt from "../../hooks/useOdt";
import useShopping from "../../hooks/useShopping";
import useOdtDetail from "../../hooks/useOdtDetail";
import useClient from "../../hooks/useClient";
import Alert from 'react-bootstrap/Alert';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Row } from 'react-bootstrap';
import { AiOutlineFileSearch } from "react-icons/ai";
import { Card } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';
import useWorkOdt from "../../hooks/useWorkOdt";
import BreadCrumbs from "../../utils/BreadCrumbs";

function OdtDetailHistory() {
    let odtIdParam = useParams().odt_id;
    let clientTelephoneParam = useParams().client_telephone;
    const queryClient = useQueryClient();
    const [validated, setValidated] = useState(false);
    const { show, updateStateOdt, updateStateOdtWithImages, updateStateOdtApproved } = useOdt();
    const { showOdtDetail } = useOdtDetail();
    const { show: showByOdt } = useWorkOdt();
    const { updateClientAccoLastOdtFin, updateClientAccoLastOdt, filterClientByTelephone } = useClient();
    const { userRole, globalClientIdParam, globalClientTelephoneParam } = useContext(UserLoggedContext);
    const [messageSuccessCheckOdt, setMessageSuccessCheckOdt] = useState(false);
    const [messageErrorCheckOdt, setMessageErrorCheckOdt] = useState(false);
    const [messageSuccessOkOdt, setMessageSuccessOkOdt] = useState(false);
    const [messageErrorOkOdt, setMessageErrorOkOdt] = useState(false);
    const [noteTechOdt, setNoteTechOdt] = useState();
    const [noteSupOdt, setNoteSubOdt] = useState();
    const [imagesOdt, setImagesOdt] = useState([]);
    const [prevOdtImages, setPrevOdtImages] = useState([]);
    const [odtStateFiltered, setOdtStateFiltered] = useState(0);
    const { showShoppingDetailsItem, updateOdt } = useShopping();
    const [additionalParams, setAdditionalParams] = useState({
        param1: odtIdParam,
    });
    let path_test = "gestion-de-cliente/detalle/" + globalClientIdParam + "/" + globalClientTelephoneParam;

    const { isLoading: isLoadingOdtImages, data: odt_images, isError: isErrorOdImages } = useQuery(
        ["odt_images", odtIdParam],
        () => showByOdt(odtIdParam)
    );

    const { isLoading: isLoadingClientDetail, data: odt_client_detail_filtered, isError: isErrorClientDetail } = useQuery(
        ["odt_client_detail_filtered", clientTelephoneParam],
        () => filterClientByTelephone(clientTelephoneParam)
    );

    const { isLoading: isLoadingOdtDetail, data: odt_detail_filtered, isError: isErrorOdtDetail } = useQuery(
        ["odt_detail_filtered", odtIdParam],
        () => showOdtDetail(odtIdParam)
    );

    const { mutate: shoppingUpdateOdt } = useMutation({
        mutationFn: updateOdt,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['shoppings'] }) //history_of_shoppings
            queryClient.invalidateQueries({ queryKey: ['history_of_shoppings'] })
        }
    });

    const { mutate: updateClientAccoLastOdtFinished } = useMutation({
        mutationFn: updateClientAccoLastOdtFin,
        onSuccess: () => {

        }
    });

    const { mutate: mutateNewOdtCheck, isLoading: isLoadingMutation, error } = useMutation({
        mutationFn: updateStateOdt,
        onSuccess: () => {
            successCheckOdt();
            queryClient.invalidateQueries(['odts']);
            queryClient.invalidateQueries(['odt_images']);

        },
        onError: () => {
            errorSuccessCheckOdt();
        }
    });

    const { mutate: mutateNewOdtApproved, isLoading: isLoadingMutationApproved, error: errorOdtApproved } = useMutation({
        mutationFn: updateStateOdtApproved,
        onSuccess: () => {
            successApprovedOdt();
            setTimeout(() => {

                odt_detail_filtered.map((odt, index) => {
                    <div key={index}>
                        {shoppingUpdateOdt({
                            shopping_id: odt.shopping,
                            odt: odtIdParam,
                            state_shopping: 2,
                        })}
                    </div>
                });

                updateClientLastOdt({
                    client_telephone: clientTelephoneParam,
                    last_odt: null,
                });

            }, 2000);

            queryClient.invalidateQueries(['odts']);
            queryClient.invalidateQueries(['history_of_odts']);
            queryClient.invalidateQueries(['clients']);
        },
        onError: () => {
            errorApprovedOdt();
        }
    });

    const { isLoading: isLoadingOdt, data: odt_filtered, isError: isErrorOdt } = useQuery(
        ["odt_filtered", odtIdParam],
        () => show(odtIdParam),
        {
            onSuccess: (data) => {
                if (data) {
                    data.map(odt => { setOdtStateFiltered(odt.state_odt) }
                    )
                }
            }
        },
    );

    useEffect(() => {

    }, []);

    const successCheckOdt = () => {
        setMessageSuccessCheckOdt(true);
        setNoteTechOdt("");
        setImagesOdt([]);
        setPrevOdtImages([]);
        setValidated(false);
        setTimeout(() => {
            setMessageSuccessCheckOdt(false);
        }, 2000);
    }

    const errorSuccessCheckOdt = () => {
        setMessageErrorCheckOdt(true);
        setValidated(false);
        setTimeout(() => {
            setMessageErrorCheckOdt(false);
        }, 2000);
    }

    const successApprovedOdt = () => {
        setMessageSuccessOkOdt(true)
        setNoteSubOdt("");
        setValidated(false);
        setTimeout(() => {
            setMessageSuccessOkOdt(false);
        }, 2000);
    }

    const errorApprovedOdt = () => {
        setMessageErrorOkOdt(true);
        setValidated(false);
        setTimeout(() => {
            setMessageErrorOkOdt(false);
        }, 2000);
    }

    const handleSubmitOdtCheck = (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }

        setValidated(true);

        if (form.checkValidity() === true) {
            e.preventDefault();
            mutateNewOdtCheck({
                id: odtIdParam,
                new_odt_state: 1,
                note_tech_odt: noteTechOdt,
            });

            const formData = new FormData();
            for (let file of imagesOdt) {
                formData.append('images[]', file);
            }

            Object.keys(additionalParams).forEach((key) => {
                formData.append(key, additionalParams[key]);
            });

            updateStateOdtWithImages(formData);
        }
    }

    //fix. I need to recover and save last visit date into a field last_visit in client model. Do this after that Supervisor accept the ODT.
    const handleSubmitOdtApproved_ = (e) => {
        e.preventDefault();
        console.log("ok");
    }

    const handleSubmitOdtApproved = (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }

        setValidated(true);

        if (form.checkValidity() === true) {
            e.preventDefault();
            console.log('approved');

            {
                odt_filtered.map((odt, index) =>
                    <div key={index}>{
                        mutateNewOdtApproved({
                            id_odt: odtIdParam,
                            new_odt_state: 2,
                            note_sup_odt: noteSupOdt,
                            client_telephone: clientTelephoneParam,
                            visiting_date: odt.visiting_date,
                            client_id: odt.client_id,
                        })
                    }
                    </div>
                )
            }
        }
    }

    const { mutate: updateClientLastOdt } = useMutation({
        mutationFn: updateClientAccoLastOdt,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['clients'] })
        }
    });

    const handleImageChange = (e) => {
        setImagesOdt(e.target.files);
        const files = Array.from(e.target.files);
        const imageUrls = files.map((file) => URL.createObjectURL(file));
        setPrevOdtImages((prevImages) => [...prevImages, ...imageUrls]);
    };

    if (isLoadingOdt) return (
        <>
            <Row>
                <Col>
                    <br></br>
                    <Spinner animation="border" variant="secondary" />
                </Col>
            </Row>
        </>
    );

    if (isErrorOdt) return (
        <>
            <Row>
                <Col>
                    <Alert key='danger' variant='danger'>
                        Hay un error. Por favor intente más tarde.
                    </Alert>
                </Col>
            </Row>
        </>
    );

    if (odt_filtered) return (
        <>
            <br></br>
            <Row>
                <Col>
                    <BreadCrumbs
                        paramSecondPage="Gestión de cliente"
                        paramSecondPageLink="gestion-de-cliente"
                        paramThirdPage="Detalle de cliente"
                        paramThirdPageLink={path_test}
                        paramCurrentPage="Detalle de odt"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs lg="5">
                </Col>
                <Col md="auto">
                    <h5>Detalle de ODT</h5><br></br>
                    {odt_filtered.map((odt, index) =>
                        <small key={index}><Badge bg="secondary">Odt filtrada: {odt.id_odt}</Badge></small>
                    )}
                </Col>
                <Col xs lg="5">
                </Col>
            </Row>
            <br></br>
            {odt_filtered.map((odt, index) =>
                <>
                    <Card key={index}>
                        <Card.Header>Información de ODT</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    <Col xs={3}>
                                        <small>
                                            <b>Id:</b>
                                            {odt.id_odt}
                                        </small>
                                    </Col>
                                    <Col xs={3}>
                                        <small><b>Estado:&nbsp;</b></small>
                                        {odt.state_odt === "0" ? (
                                            <small>
                                                <Badge bg="danger">Nueva</Badge>
                                            </small>
                                        ) : (null)}
                                        {odt.state_odt === "1" ? (
                                            <small>
                                                <Badge bg="warning">En proceso</Badge>
                                            </small>
                                        ) : (null)}
                                        {odt.state_odt === "2" ? (
                                            <small>
                                                <Badge bg="success">Cerrada</Badge>
                                            </small>
                                        ) : (null)}
                                    </Col>
                                    <Col xs={3}>
                                        <small>
                                            <b>Cliente: </b>
                                            {odt.client_first_name} {odt.client_last_name}
                                        </small>
                                    </Col>
                                    <Col xs={3}>
                                        <small>
                                            <b>Teléfono cliente: </b>
                                            {odt.client_telephone}
                                        </small>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col xs={3}>
                                        <small>
                                            <b>Supervisor: </b>
                                            {odt.supervisor}
                                        </small>
                                    </Col>
                                    <Col xs={3}>
                                        <small>
                                            <b>Técnico: </b>
                                            {odt.technical}
                                        </small>
                                    </Col>
                                    <Col xs={3}>
                                        <small>
                                            <b>Ingresado por: </b>
                                            {odt.admin_user}
                                        </small>
                                    </Col>
                                    <Col xs={3}>
                                        <small>
                                            <b>Contacto: </b>
                                            {odt.contact_name}
                                        </small>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                        {odt.service_address != null ? (
                                            <Col xs={4}>
                                                <small>
                                                    <b>Dirección del servicio: </b>
                                                    <Link to={`https://www.google.com/maps/search/?api=1&query=${odt.service_address}`} target="_blank"> <AiOutlineEnvironment /></Link>
                                                    {odt.service_address}
                                                </small>
                                            </Col>
                                        ) : (
                                            odt_client_detail_filtered.map((client, index) =>
                                                <Col key={index} xs={4}>
                                                    <small>
                                                        <b>Dirección del servicio: </b>
                                                        <Link to={`https://www.google.com/maps/search/?api=1&query=${client.address} ${client.address_comune}`} target="_blank"> <AiOutlineEnvironment /></Link>
                                                        {client.address}, {client.address_comune}
                                                    </small>
                                                </Col>
                                            )
                                        )}
                                    <Col xs={3}>
                                        <small>
                                            <b>Fecha de visita: </b>
                                            {odt.visiting_date}
                                        </small>
                                    </Col>
                                    <Col xs={2}>
                                        <small>
                                            <b>Hora de visita: </b>
                                            {odt.visiting_hour}
                                        </small>
                                    </Col>
                                    <Col xs={3}>
                                        <small>
                                            <b>Teléfono contacto: </b>
                                            {odt.contact_telephone}
                                        </small>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col xs={3}>
                                        <small>
                                            <b>Fecha de ingreso: </b>
                                            {odt.admision_date}
                                        </small>
                                    </Col>
                                    <Col xs={3}>
                                        {odt.notification_status == 0 ? (
                                            <small>
                                                <b>Notificación: </b>
                                                <Badge bg="danger">No enviada</Badge>
                                            </small>
                                        ) : (null)}
                                        {odt.notification_status == 1 ? (
                                            <small>
                                                <b>Notificación: </b>
                                                <Badge bg="success">Enviada</Badge>
                                            </small>

                                        ) : (null)}
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col xs={12}>
                                        <small>
                                            <b>Observaciones: </b><br></br>
                                            {odt.note === "" || odt.note === null ? (
                                                <>Sin notas.</>
                                            ) : (odt.note)}
                                        </small>
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </>
            )}

            <br></br>
            {odt_detail_filtered ? (
                <>
                    <Card>
                        <Card.Header>Desglose de tareas</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    <Col>
                                        <br></br>
                                        <Table striped bordered hover responsive>
                                            <thead>
                                                <tr>
                                                    <th><small>Id ítem</small></th>
                                                    <th><small>Id compra</small></th>
                                                    <th><small>Ítem</small></th>
                                                    <th><small>Cantidad</small></th>
                                                    <th><small>Nota</small></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {odt_detail_filtered.map((od, index) =>
                                                    <tr key={index}>
                                                        <td>
                                                            <small>
                                                                {od.id_odt_detail}
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <small>
                                                                {od.shopping}
                                                            </small>
                                                        </td>

                                                        {od.product ? (
                                                            <td>
                                                                <small>
                                                                    {od.product} <b>SKU: </b>{od.product_sku}
                                                                </small>
                                                            </td>
                                                        ) : (
                                                            <td>
                                                                <small>
                                                                    {od.service} <b>SKU: </b>{od.service_sku}
                                                                </small>
                                                            </td>
                                                        )}

                                                        <td>
                                                            <small>
                                                                {od.cant_item}
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <small>
                                                                {od.note_item === "" || od.note_item === null ? (
                                                                    <>Sin notas.</>
                                                                ) : (od.note_item)}
                                                            </small>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </>
            ) : (null)}
            <br></br>

            {userRole === "1" && odtStateFiltered === "0" ? (
                <>
                    <Card>
                        <Card.Header>Tareas del Técnico</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <>
                                    <Row>
                                        <Col>
                                            <Alert variant="info">
                                                <small>Antes de enviar la ODT para su aprobación por su Supervisor, debe adjuntar las fotografías del trabajo hecho y observaciones.</small>
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <br></br>
                                    <Form noValidate validated={validated} onSubmit={handleSubmitOdtCheck}>
                                        <Row className="mb-1">
                                            <div className="input-group custom-file-button">
                                                <label className="input-group-text" htmlFor="imageUpload">Agregar fotografías</label>
                                                <Form.Control
                                                    type="file"
                                                    multiple
                                                    id="imageUpload"
                                                    accept="image/*"
                                                    onChange={handleImageChange}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">Agregar fotografías.</Form.Control.Feedback>
                                            </div>
                                        </Row>
                                        <br></br>
                                        <Row>
                                            {prevOdtImages.map((imageUrl, index) => (
                                                <img key={index} src={imageUrl} alt={`Image ${index}`} style={{ maxWidth: '200px', maxHeight: '200px', margin: '5px' }} />
                                            ))}
                                        </Row>
                                        <br></br>
                                        <Row className="mb-12">
                                            <Form.Group as={Col} controlId="noteTechOdt">
                                                <Form.Label>Observaciones</Form.Label>
                                                <Form.Control as="textarea" value={noteTechOdt} onChange={(e) => { setNoteTechOdt(e.target.value) }} name="noteTechOdt" rows={3} placeholder="Se instala equipo GES24ECO-INV-VP en cliente y entregan instrucciones básicas de uso." required />
                                                <Form.Control.Feedback type="invalid">Agregar observaciones.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <br></br>
                                        <Alert variant="success" show={messageSuccessCheckOdt}>
                                            <small>Solicitud enviada con éxito.</small>
                                        </Alert>
                                        <Alert variant="danger" show={messageErrorCheckOdt}>
                                            <small>Error de solicitud, inténtelo más tarde.</small>
                                        </Alert>
                                        <br></br>
                                        <Row>
                                            <Col>
                                                <Button variant="primary" type="submit" disabled={isLoadingMutation}>
                                                    {isLoadingMutation ? 'Procesando...' : 'Solicitar aprobación'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <br></br>
                    <Card>
                        <Card.Header>Tareas del Supervisor</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <>
                                    <Row>
                                        <Col>
                                            <Alert variant="info">
                                                <small>Antes de aprobar la ODT, el Supervisor debe ingresar sus observaciones.</small>
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <br></br>
                                    <Form>
                                        <Row className="mb-12">
                                            <Form.Group as={Col} controlId="noteSupOdt">
                                                <Form.Label>Observaciones</Form.Label>
                                                <Form.Control as="textarea" name="noteSupOdt" value={noteSupOdt} rows={3} placeholder="ODT resuelta con éxito y cliente conforme." onChange={(e) => { setNoteSubOdt(e.target.value) }} disabled={true} />
                                            </Form.Group>
                                        </Row>
                                        <br></br>
                                        <Alert variant="success" show={messageSuccessOkOdt}>
                                            <small>ODT aprobada con éxito.</small>
                                        </Alert>
                                        <Alert variant="danger" show={messageErrorOkOdt}>
                                            <small>Error al aprobar la ODT, inténtelo más tarde.</small>
                                        </Alert>
                                        <br></br>
                                        <Row>
                                            <Col>
                                                <Button variant="primary" type="submit" disabled={true}>
                                                    Aprobar ODT
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </>
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </>
            ) : (null)}

            {userRole === "2" && odtStateFiltered === "0" ? (
                <>
                    <Card>
                        <Card.Header>Tareas del Técnico</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <>
                                    <Row>
                                        <Col>
                                            <Alert variant="info">
                                                <small>Antes de enviar la ODT para su aprobación por su Supervisor, debe adjuntar las fotografías del trabajo hecho y observaciones.</small>
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <br></br>
                                    <Form noValidate validated={validated} onSubmit={handleSubmitOdtCheck}>
                                        <Row className="mb-1">
                                            <div className="input-group custom-file-button">
                                                <label className="input-group-text" htmlFor="imageUpload">Agregar fotografías</label>
                                                <Form.Control
                                                    type="file"
                                                    multiple
                                                    id="imageUpload"
                                                    accept="image/*"
                                                    onChange={handleImageChange}
                                                    required
                                                    disabled={true}
                                                />
                                                <Form.Control.Feedback type="invalid">Agregar fotografías.</Form.Control.Feedback>
                                            </div>
                                        </Row>
                                        <br></br>
                                        <Row>
                                            {prevOdtImages.map((imageUrl, index) => (
                                                <img key={index} src={imageUrl} alt={`Image ${index}`} style={{ maxWidth: '200px', maxHeight: '200px', margin: '5px' }} />
                                            ))}
                                        </Row>
                                        <br></br>
                                        <Row className="mb-12">
                                            <Form.Group as={Col} controlId="noteTechOdt">
                                                <Form.Label>Observaciones</Form.Label>
                                                <Form.Control as="textarea" value={noteTechOdt} onChange={(e) => { setNoteTechOdt(e.target.value) }} name="noteTechOdt" rows={3} placeholder="Se instala equipo GES24ECO-INV-VP en cliente y entregan instrucciones básicas de uso." required disabled={true} />
                                                <Form.Control.Feedback type="invalid">Agregar observaciones.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <br></br>
                                        <Alert variant="success" show={messageSuccessCheckOdt}>
                                            <small>Solicitud enviada con éxito.</small>
                                        </Alert>
                                        <Alert variant="danger" show={messageErrorCheckOdt}>
                                            <small>Error de solicitud, inténtelo más tarde.</small>
                                        </Alert>
                                        <br></br>
                                        <Row>
                                            <Col>
                                                <Button variant="primary" type="submit" disabled={true}>
                                                    {isLoadingMutation ? 'Procesando...' : 'Solicitar aprobación'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <br></br>
                    <Card>
                        <Card.Header>Tareas del Supervisor</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <>
                                    <Row>
                                        <Col>
                                            <Alert variant="info">
                                                <small>Antes de aprobar la ODT, el Supervisor debe ingresar sus observaciones.</small>
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <br></br>
                                    <Form>
                                        <Row className="mb-12">
                                            <Form.Group as={Col} controlId="noteSupOdt">
                                                <Form.Label>Observaciones</Form.Label>
                                                <Form.Control as="textarea" name="noteSupOdt" value={noteSupOdt} rows={3} placeholder="ODT resuelta con éxito y cliente conforme." onChange={(e) => { setNoteSubOdt(e.target.value) }} disabled={true} />
                                            </Form.Group>
                                        </Row>
                                        <br></br>
                                        <Alert variant="success" show={messageSuccessOkOdt}>
                                            <small>ODT aprobada con éxito.</small>
                                        </Alert>
                                        <Alert variant="danger" show={messageErrorOkOdt}>
                                            <small>Error al aprobar la ODT, inténtelo más tarde.</small>
                                        </Alert>
                                        <br></br>
                                        <Row>
                                            <Col>
                                                <Button variant="primary" type="submit" disabled={true}>
                                                    Aprobar ODT
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </>
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </>
            ) : (null)}

            {userRole === "3" && odtStateFiltered === "0" ? (
                <>
                    <Card>
                        <Card.Header>Tareas del Técnico</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <>
                                    <Row>
                                        <Col>
                                            <Alert variant="info">
                                                <small>Antes de enviar la ODT para su aprobación por su Supervisor, debe adjuntar las fotografías del trabajo hecho y observaciones.</small>
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <br></br>
                                    <Form noValidate validated={validated} onSubmit={handleSubmitOdtCheck}>
                                        <Row className="mb-1">
                                            <div className="input-group custom-file-button">
                                                <label className="input-group-text" htmlFor="imageUpload">Agregar fotografías</label>
                                                <Form.Control
                                                    type="file"
                                                    multiple
                                                    id="imageUpload"
                                                    accept="image/*"
                                                    onChange={handleImageChange}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">Agregar fotografías.</Form.Control.Feedback>
                                            </div>
                                        </Row>
                                        <br></br>
                                        <Row>
                                            {prevOdtImages.map((imageUrl, index) => (
                                                <img key={index} src={imageUrl} alt={`Image ${index}`} style={{ maxWidth: '200px', maxHeight: '200px', margin: '5px' }} />
                                            ))}
                                        </Row>
                                        <br></br>
                                        <Row className="mb-12">
                                            <Form.Group as={Col} controlId="noteTechOdt">
                                                <Form.Label>Observaciones</Form.Label>
                                                <Form.Control as="textarea" value={noteTechOdt} onChange={(e) => { setNoteTechOdt(e.target.value) }} name="noteTechOdt" rows={3} placeholder="Se instala equipo GES24ECO-INV-VP en cliente y entregan instrucciones básicas de uso." required />
                                                <Form.Control.Feedback type="invalid">Agregar observaciones.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <br></br>
                                        <Alert variant="success" show={messageSuccessCheckOdt}>
                                            <small>Solicitud enviada con éxito.</small>
                                        </Alert>
                                        <Alert variant="danger" show={messageErrorCheckOdt}>
                                            <small>Error de solicitud, inténtelo más tarde.</small>
                                        </Alert>
                                        <br></br>
                                        <Row>
                                            <Col>
                                                <Button variant="primary" type="submit" disabled={isLoadingMutation}>
                                                    {isLoadingMutation ? 'Procesando...' : 'Solicitar aprobación'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <br></br>
                    <Card>
                        <Card.Header>Tareas del Supervisor</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <>
                                    <Row>
                                        <Col>
                                            <Alert variant="info">
                                                <small>Antes de aprobar la ODT, el Supervisor debe ingresar sus observaciones.</small>
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <br></br>
                                    <Form>
                                        <Row className="mb-12">
                                            <Form.Group as={Col} controlId="noteSupOdt">
                                                <Form.Label>Observaciones</Form.Label>
                                                <Form.Control as="textarea" name="noteSupOdt" value={noteSupOdt} rows={3} placeholder="ODT resuelta con éxito y cliente conforme." onChange={(e) => { setNoteSubOdt(e.target.value) }} disabled={true} />
                                            </Form.Group>
                                        </Row>
                                        <br></br>
                                        <Alert variant="success" show={messageSuccessOkOdt}>
                                            <small>ODT aprobada con éxito.</small>
                                        </Alert>
                                        <Alert variant="danger" show={messageErrorOkOdt}>
                                            <small>Error al aprobar la ODT, inténtelo más tarde.</small>
                                        </Alert>
                                        <br></br>
                                        <Row>
                                            <Col>
                                                <Button variant="primary" type="submit" disabled={true}>
                                                    Aprobar ODT
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </>
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </>
            ) : (null)}

            {(userRole === "1" || userRole === "2") && (odtStateFiltered === "1") ? (
                <>
                    <>
                        {odt_filtered.map((odt) =>
                            <Card>
                                <Card.Header>Tareas del Técnico</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <>
                                            <Row>
                                                <Col>
                                                    <Alert variant="success">
                                                        <small>Solicitud de aprobación enviada.</small>
                                                    </Alert>
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <Form>
                                                <Row className="mb-1">
                                                    <Col>
                                                        Fotografías adjuntas:
                                                    </Col>
                                                </Row>
                                                <br></br>
                                                <Row>
                                                    <Col>
                                                        {odt_images ? (
                                                            odt_images.map((image, index) => (
                                                                <img key={index} src={`http://127.0.0.1:8000/odt_images/${image.image_name}`} alt={image.image_name} style={{ maxWidth: '200px', maxHeight: '200px', margin: '5px' }} />
                                                            ))
                                                        ) : (null)}
                                                    </Col>
                                                </Row>
                                                <br></br>
                                                <Row className="mb-12">
                                                    <Form.Group as={Col} controlId="noteTechOdt">
                                                        <Form.Label>Observaciones</Form.Label>
                                                        <Form.Control as="textarea" value={odt.technical_note} />
                                                    </Form.Group>
                                                </Row>
                                                <br></br>
                                            </Form>
                                        </>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        )}
                    </>
                    <br></br>
                    <Card>
                        <Card.Header>Tareas del Supervisor</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <>
                                    <Row>
                                        <Col>
                                            <Alert variant="info">
                                                <small>Antes de aprobar la ODT, el Supervisor debe ingresar sus observaciones.</small>
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <br></br>
                                    <Form noValidate validated={validated} onSubmit={handleSubmitOdtApproved}>
                                        <Row className="mb-12">
                                            <Form.Group as={Col} controlId="noteSupOdt">
                                                <Form.Label>Observaciones</Form.Label>
                                                <Form.Control as="textarea" name="noteSupOdt" value={noteSupOdt} rows={3} placeholder="ODT resuelta con éxito y cliente conforme." onChange={(e) => { setNoteSubOdt(e.target.value) }} required />
                                                <Form.Control.Feedback type="invalid">Agregar observaciones.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <br></br>
                                        <Alert variant="success" show={messageSuccessOkOdt}>
                                            <small>ODT aprobada con éxito.</small>
                                        </Alert>
                                        <Alert variant="danger" show={messageErrorOkOdt}>
                                            <small>Error al aprobar la ODT, inténtelo más tarde.</small>
                                        </Alert>
                                        <br></br>
                                        <Row>
                                            <Col>
                                                <Button variant="primary" type="submit">
                                                    Aprobar ODT
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </>
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </>
            ) : (null)}

            {userRole === "3" && odtStateFiltered === "1" ? (
                <>
                    <>
                        {odt_filtered.map((odt) =>
                            <Card>
                                <Card.Header>Tareas del Técnico</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <>
                                            <Row>
                                                <Col>
                                                    <Alert variant="success">
                                                        <small>Técnico ya solicitó aprobación de actual ODT.</small>
                                                    </Alert>
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <Form>
                                                <Row className="mb-1">
                                                    <Col>
                                                        Fotografías adjuntas:
                                                    </Col>
                                                </Row>
                                                <br></br>
                                                <Row>
                                                    <Col>
                                                        {odt_images ? (
                                                            odt_images.map((image, index) => (
                                                                <img key={index} src={`http://127.0.0.1:8000/odt_images/${image.image_name}`} alt={image.image_name} style={{ maxWidth: '200px', maxHeight: '200px', margin: '5px' }} />
                                                            ))
                                                        ) : (null)}
                                                    </Col>
                                                </Row>
                                                <br></br>
                                                <Row className="mb-12">
                                                    <Form.Group as={Col} controlId="noteTechOdt">
                                                        <Form.Label>Observaciones</Form.Label>
                                                        <Form.Control as="textarea" value={odt.technical_note} />
                                                    </Form.Group>
                                                </Row>
                                                <br></br>
                                            </Form>
                                        </>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        )}
                    </>
                    <br></br>
                    <Card>
                        <Card.Header>Tareas del Supervisor</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <>
                                    <Row>
                                        <Col>
                                            <Alert variant="info">
                                                <small>Antes de aprobar la ODT, el Supervisor debe ingresar sus observaciones.</small>
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <br></br>
                                    <Form noValidate validated={validated} onSubmit={handleSubmitOdtApproved}>
                                        <Row className="mb-12">
                                            <Form.Group as={Col} controlId="noteSupOdt">
                                                <Form.Label>Observaciones</Form.Label>
                                                <Form.Control as="textarea" name="noteSupOdt" value={noteSupOdt} rows={3} placeholder="ODT resuelta con éxito y cliente conforme." onChange={(e) => { setNoteSubOdt(e.target.value) }} required disabled={true} />
                                                <Form.Control.Feedback type="invalid">Agregar observaciones.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <br></br>
                                        <Alert variant="success" show={messageSuccessOkOdt}>
                                            <small>ODT aprobada con éxito.</small>
                                        </Alert>
                                        <Alert variant="danger" show={messageErrorOkOdt}>
                                            <small>Error al aprobar la ODT, inténtelo más tarde.</small>
                                        </Alert>
                                        <br></br>
                                        <Row>
                                            <Col>
                                                <Button variant="primary" type="submit" disabled={true}>
                                                    Aprobar ODT
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </>
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </>
            ) : (null)}

            {(userRole === "1" || userRole === "2" || userRole === "3") && (odtStateFiltered === "2") ? (
                <>
                    <>
                        {odt_filtered.map((odt) =>
                            <Card>
                                <Card.Header>Tareas del Técnico</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <>
                                            <Row>
                                                <Col>
                                                    <Alert variant="success">
                                                        <small>Técnico ya solicitó aprobación de actual ODT.</small>
                                                    </Alert>
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <Form>
                                                <Row className="mb-1">
                                                    <Col>
                                                        Fotografías adjuntas:
                                                    </Col>
                                                </Row>
                                                <br></br>
                                                <Row>
                                                    <Col>
                                                        {odt_images ? (
                                                            odt_images.map((image, index) => (
                                                                <img key={index} src={`http://127.0.0.1:8000/odt_images/${image.image_name}`} alt={image.image_name} style={{ maxWidth: '200px', maxHeight: '200px', margin: '5px' }} />
                                                            ))
                                                        ) : (null)}
                                                    </Col>
                                                </Row>
                                                <br></br>
                                                <Row className="mb-12">
                                                    <Form.Group as={Col} controlId="noteTechOdt">
                                                        <Form.Label>Observaciones</Form.Label>
                                                        <Form.Control as="textarea" value={odt.technical_note} />
                                                    </Form.Group>
                                                </Row>
                                                <br></br>
                                            </Form>
                                        </>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        )}
                    </>
                    <br></br>
                    <Card>
                        <Card.Header>Tareas del Supervisor</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <>
                                    {odt_filtered.map((odt) =>
                                        <>
                                            <Row>
                                                <Col>
                                                    <Alert variant="success">
                                                        <small>La ODT fue aprobada.</small>
                                                    </Alert>
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <Form>
                                                <Row className="mb-12">
                                                    <Form.Group as={Col} controlId="noteSupOdt">
                                                        <Form.Label>Observaciones</Form.Label>
                                                        <Form.Control as="textarea" name="noteSupOdt" value={odt.supervisor_note} rows={3} />
                                                        <Form.Control.Feedback type="invalid">Agregar observaciones.</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                                <br></br>
                                            </Form>
                                        </>
                                    )}
                                </>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </>
            ) : (null)}

            <br></br>
            <Row className="justify-content-md-center">
                <Col xs lg={4}>
                </Col>
                <Col md="auto">
                    <Button variant="outline-primary" href={'/gestion-de-odt'}>
                        Volver
                    </Button>
                </Col>
                <Col xs lg={4}>
                </Col>
            </Row>
            <br></br>
            <br></br>

        </>
    );
}
export default OdtDetailHistory;