import React from "react";

function useUtilities() {
  const API_URL = process.env.REACT_APP_API_URL;
  let codeHttpApi = 0;

  const truncateString = (text, maxLength) => {
    if (text.length <= maxLength) {
      return <span>{text}</span>;
    } else {
      const truncatedText = text.slice(0, maxLength) + '...';
      return <span title={text}>{truncatedText}</span>;
    }
  }

  /*
  const formatDate = (date) => {
    const d = new Date(date);
    const day = (d.getDate() + 1).toString().padStart(2, '0');//warning because I added a plus 1 in d.getDate().
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  }
  */

  //it's ok
  const formatDate = () => {
    const d = new Date();
    const day = (d.getDate()).toString().padStart(2, '0');//warning because I added a plus 1 in d.getDate().
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const formatDateCl = (date) => {
    var parts = date.split('-');
    var formattedDate = parts[2] + '-' + parts[1] + '-' + parts[0];
    return formattedDate;
  }

    const calculateDaysForMaintenance = (lastMaintenanceDate) => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = today.getFullYear();
    const todayFormated = `${day}/${month}/${year}`;

    const date1Parts = todayFormated.split("/");
    const date2Parts = lastMaintenanceDate.split("/");
    
    const d1 = new Date(date1Parts[2], date1Parts[1] - 1, date1Parts[0]);
    const d2 = new Date(date2Parts[2], date2Parts[1] - 1, date2Parts[0]);
    
    const diffMilliseconds = Math.abs(d2 - d1);
    
    return Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
  }

  const calculateDaysForMaintenanceCronJob = (lastMaintenanceDate) => {
    const today = new Date();
    const day = "23";
    const month = "04";
    const year = "2024";
    const todayFormated = `${day}/${month}/${year}`;

    const date1Parts = todayFormated.split("/");
    const date2Parts = lastMaintenanceDate.split("/");
    
    const d1 = new Date(date1Parts[2], date1Parts[1] - 1, date1Parts[0]);
    const d2 = new Date(date2Parts[2], date2Parts[1] - 1, date2Parts[0]);
    
    const diffMilliseconds = Math.abs(d2 - d1);
    
    return Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
  }

  

  const calculateDaysForMaintenanceCorrectFormat = (lastMaintenanceDate) => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = today.getFullYear();
    const todayFormated = `${day}/${month}/${year}`;

    const date1Parts = todayFormated.split("/");
    const date2Parts = lastMaintenanceDate.split("/");
    
    const d1 = new Date(date1Parts[2], date1Parts[1] - 1, date1Parts[0]);
    const d2 = new Date(date2Parts[0], date2Parts[1] - 1, date2Parts[2]);
    //const d2 = new Date(date2Parts[2], date2Parts[1] - 1, date2Parts[0]);
    
    const diffMilliseconds = Math.abs(d2 - d1);
    //const diffMilliseconds = Math.abs(lastMaintenanceDate - d1);
    
    return Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
  }

  return {
    truncateString,
    formatDate,
    calculateDaysForMaintenance,
    calculateDaysForMaintenanceCorrectFormat,
    formatDateCl,
  }
}

export default useUtilities;