import React, { useState, useContext } from 'react';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import useClient from '../../hooks/useClient';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { redirect, useParams, useNavigate } from "react-router-dom";
import BreadCrumbs from '../../utils/BreadCrumbs';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';

function FormUpdateClient() {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [codeApiHttp, setCodeApiHttp] = useState('');
  const { store, show, update } = useClient();
  const [desableUpdateClientBtn, setDesableUpdateClientBtn] = useState(true);
  const [messageSuccessUpdateClient, setMessageSuccessUpdateClient] = useState(false);
  const [messageErrorUpdateClient, setMessageErrorUpdateClient] = useState(false);
  let idClientParam = useParams().client_id;
  const queryClient = useQueryClient();
  const [client, setClient] = useState([]);
  const [client_state, setClientState] = useState('');
  const { setGlobalClientIdParam, setGlobalClientTelephoneParam, globalClientIdParam, globalClientTelephoneParam } = useContext(UserLoggedContext);
  let path_test = "gestion-de-cliente/detalle/" + globalClientIdParam + "/" + globalClientTelephoneParam;

  const { isLoading, data: client_filtered, isError } = useQuery(
    ["client_filtered", idClientParam],
    () => show(idClientParam)
  );

  const { mutate: mutateUpdateClient, isLoading: isLoadingMutation, error } = useMutation({
    mutationFn: update,
    onSuccess: () => {
      successUpdateClient()
      queryClient.invalidateQueries({ queryKey: ['clients'] });
      queryClient.invalidateQueries({ queryKey: ['client_filtered'] });
    },
    onError: () => {
      errorSuccessUpdateClient();
    }
  });

  const clientState = ["Activo", "Inactivo"];

  const successUpdateClient = () => {
    setMessageSuccessUpdateClient(true);
    setValidated(false);
    setTimeout(() => {
      setMessageSuccessUpdateClient(false);
    }, 3000);
  }

  const errorSuccessUpdateClient = () => {
    setMessageErrorUpdateClient(true);
    setValidated(false);
    setTimeout(() => {
      setMessageErrorUpdateClient(false);
    }, 3000);
  }

  const handeInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setClient({ ...client, [name]: value });
    setDesableUpdateClientBtn(false);
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() === true) {
      e.preventDefault();
      mutateUpdateClient({
        id: idClientParam,
        run: client["run"],
        first_name: client["first_name"],
        last_name: client["last_name"],
        telephone: client["telephone"],
        email: client["email"],
        state: client["state"],
        note: client["note"],
        address: client["address"],
        address_comune: client["address_comune"],
        business: client["business"],
        business_name : client["business_name"],
        business_line: client["business_line"],
      });

      setTimeout(() => {
        navigate('/gestion-de-cliente');
      }, 2000);
    }

  };

  if (isLoading) return (
    <>
      <Row>
        <Col>
          <br></br>
          <Spinner animation="border" variant="secondary" />
        </Col>
      </Row>
    </>
  );

  if (isError) return (
    <>
      <Row>
        <Col>
          <Alert key='danger' variant='danger'>
            Hay un error. Por favor intente más tarde.
          </Alert>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      {client_filtered ? (
        <>
          <br></br>
          <Row>
            <Col>
              <BreadCrumbs
                paramSecondPage="Gestión de cliente"
                paramSecondPageLink="gestion-de-cliente"
                paramCurrentPage="Actualizar cliente"
              />
            </Col>
          </Row>
          <Row>
            <Col xs lg="5">
            </Col>
            <Col md="auto">
              <h5>Actualizar cliente</h5>
              {client_filtered.map((client, index) =>
                <small key={index}><Badge bg="secondary">Cliente filtrado: {client.first_name} {client.last_name}</Badge></small>
              )}
            </Col>
            <Col xs lg="5">
            </Col>
          </Row>
          <br></br>
          {client_filtered.map((client, index) => (
            <Form noValidate validated={validated} onSubmit={handleSubmit} key={index}>
              <Row className="mb-4">
                <Form.Group as={Col} controlId="run">
                  <Form.Label>RUN/RUT</Form.Label>
                  <Form.Control type="text" name="run" defaultValue={client.run} placeholder="15991122-1" onChange={handeInputChange} />
                </Form.Group>

                <Form.Group as={Col} controlId="first_name">
                  <Form.Label>Nombres</Form.Label>
                  <Form.Control type="text" name="first_name" defaultValue={client.first_name} placeholder="Juan Carlos" onChange={handeInputChange} required />
                  <Form.Control.Feedback type="invalid">Ingrese nombres.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} controlId="last_name">
                  <Form.Label>Apellidos</Form.Label>
                  <Form.Control type="text" name="last_name" defaultValue={client.last_name} placeholder="Pérez Potapo" onChange={handeInputChange} required />
                  <Form.Control.Feedback type="invalid">Ingrese apellidos.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} controlId="telephone">
                  <Form.Label>Teléfono</Form.Label>
                  <Form.Control type="number" name="telephone" defaultValue={client.telephone} placeholder="56912345678" onChange={handeInputChange} required />
                  <Form.Control.Feedback type="invalid">Ingrese teléfono.</Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="email">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control type="email" name="email" defaultValue={client.email} placeholder="juan.perez@gmail.com" onChange={handeInputChange} />
                </Form.Group>

                <Form.Group as={Col} controlId="address">
                  <Form.Label>Dirección (Calle)</Form.Label>
                  <Form.Control type="text" name="address" defaultValue={client.address} placeholder="Los Lirios #2111" onChange={handeInputChange} required />
                  <Form.Control.Feedback type="invalid">Ingrese dirección.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} controlId="address_comune">
                  <Form.Label>Comuna</Form.Label>
                  <Form.Control type="text" name="address_comune" defaultValue={client.address_comune} placeholder="Rancagua" onChange={handeInputChange} required />
                  <Form.Control.Feedback type="invalid">Ingrese comuna.</Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="inputClientEmail">
                  <Form.Label>Nombre empresa</Form.Label>
                  <Form.Control type="text" name="business" defaultValue={client.business} placeholder="Walmart" onChange={handeInputChange} />
                </Form.Group>

                <Form.Group as={Col} controlId="inputClientAddress">
                  <Form.Label>Razón social</Form.Label>
                  <Form.Control type="text" name="business_name" defaultValue={client.business_name} placeholder="Walmart S.A." onChange={handeInputChange} />
                </Form.Group>

                <Form.Group as={Col} controlId="inputClientAddressComune">
                  <Form.Label>Giro</Form.Label>
                  <Form.Control type="text" name="business_line" defaultValue={client.business_line} placeholder="Cadena de supermercados minoristas" onChange={handeInputChange} />
                </Form.Group>
              </Row>

              <Row className="mb-2">
                <Form.Group as={Col} controlId="state">
                  <Form.Label>Estado</Form.Label>
                  <Form.Select defaultValue={client.client_status} name="state" onChange={handeInputChange} >
                    <option value="">Seleccione...</option>
                    <option value="1">{clientState[0]}</option>
                    <option value="0">{clientState[1]}</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="note">
                  <Form.Label>Observaciones</Form.Label>
                  <Form.Control as="textarea" name="note" defaultValue={client.note} rows={3} placeholder="Cliente sin compras aún." onChange={handeInputChange} />
                  <Form.Control.Feedback type="invalid">Ingrese observaciones.</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <br></br>
              <Alert variant="success" show={messageSuccessUpdateClient}>
                <small>Cliente actualizado con éxito.</small>
              </Alert>
              <Alert variant="danger" show={messageErrorUpdateClient}>
                <small>Error al actualizar el cliente, inténtelo más tarde.</small>
              </Alert>
              <br></br>
              <Row className="justify-content-md-center">
                <Col xs lg={2}>
                </Col>
                <Col md="auto">
                  <Button variant="primary" type='submit' disabled={desableUpdateClientBtn}>
                    {isLoadingMutation ? 'Actualizando...' : 'Actualizar'}
                  </Button>
                </Col>
                <Col md="auto">
                  <Button variant="outline-primary" href={'/gestion-de-cliente'}>
                    Volver
                  </Button>
                </Col>
                <Col xs lg={2}>
                </Col>
              </Row>
            </Form>
          ))}
        </>
      ) : (
        <>
          <br></br>
          <Row>
            <Col>
              <Alert key='danger' variant='danger'>
                <small>No existe el cliente con Id filtrado. </small> <Link to={'/gestion-de-cliente'} className='nav-link'><small>Volver a Clientes.</small></Link>
              </Alert>
            </Col>
          </Row>
        </>
      )}
      <br></br>
      <br></br>
    </>
  );


}

export default FormUpdateClient;