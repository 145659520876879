import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';
import { Button } from 'react-bootstrap';
import { Card } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import useService from "../../hooks/useService";
import ServiceFilter from "./ServiceFilter";

function ServiceList() {
    const [loading, setLoading] = useState(true);
    const queryClient = useQueryClient();
    const [showDelete, setShowDelete] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    const { totalServices, index, filterServiceFromAPIForDelModal, showDeleteModal, setFilterServiceFromAPI, destroy, deleteAll, totalServiceFromAPI, lastPageFromAPI } = useService();
    const [serviceForDelete, setServiceForDelete] = useState(0);
    const [showDeleteAllServices, setShowDeleteAllServices] = useState(false);
    const handleCloseDeleteAllServices = () => setShowDeleteAllServices(false);
    const [messageSuccessServiceDelete, setMessageSuccessServiceDelete] = useState(false);
    const [messageErrorServiceDelete, setMessageErrorServiceDelete] = useState(false);
    const [messageSuccessAllServicesDelete, setMessageSuccessAllServicesDelete] = useState(false);
    const [messageErrorAllServicesDelete, setMessageErrorAllServicesDelete] = useState(false);
    const [desableDeleteBtn, setDesableDeleteBtn] = useState(false);
    const [desableDeleteAllBtn, setDesableDeleteAllBtn] = useState(false);
    const [pageNumber, setPageNumer] = useState(1);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const { isLoading, data: services, isError, error } = useQuery(
        ['services', pageNumber],
        () => index(pageNumber));

    const { data: totalServicesAPI } = useQuery(
        ['totalServicesAPI'],
        () => totalServices());

    const showPopupDelete = (id) => {
        setServiceForDelete(id);
        showDeleteModal(id);
        setShowDelete(true);
    }

    const deleteServiceMutation = useMutation({
        mutationFn: destroy,
        onSuccess: () => {
            successServiceDelete();
            queryClient.invalidateQueries(['services']);
        },
        onError: () => {
            errorServiceDelete();
        }
    });

    const deleteAllServicesMutation = useMutation({
        mutationFn: deleteAll,
        onSuccess: () => {
            successServiceDeleteAll();
            queryClient.invalidateQueries(['services'])
        },
        onError: () => {
            errorServiceDeleteAll();
        }
    });

    const successServiceDeleteAll = () => {
        setMessageSuccessAllServicesDelete(true);
        setDesableDeleteAllBtn(true);
        setTimeout(() => {
            setMessageSuccessAllServicesDelete(false);
            setDesableDeleteAllBtn(false);
            setShowDeleteAllServices(false);
        }, 2000);
    }

    const errorServiceDeleteAll = () => {
        setMessageErrorAllServicesDelete(true);
        setTimeout(() => {
            setMessageErrorAllServicesDelete(false);
        }, 2000);
    }

    const handleDeleteAllServices = () => {
        deleteAllServicesMutation.mutate();
    }

    const successServiceDelete = () => {
        setMessageSuccessServiceDelete(true);
        setDesableDeleteBtn(true);
        setServiceForDelete("");
        setTimeout(() => {
            setMessageSuccessServiceDelete(false);
            setDesableDeleteBtn(false);
            setFilterServiceFromAPI([]);
            setShowDelete(false);
        }, 2000);
    }

    const errorServiceDelete = () => {
        setMessageErrorServiceDelete(true);
        setTimeout(() => {
            setMessageErrorServiceDelete(false);
        }, 2000);
    }

    const handleDeleteService = () => {
        deleteServiceMutation.mutate(serviceForDelete);
    }

    const showPopupDeleteAllServices = () => {
        //setShowDeleteAllProducts(true);
        setShowDeleteAllServices(true);
    }

    if (isLoading) return (
        <>
            {isLoading ? (
                <Row>
                    <Col>
                        <br></br>
                        <Spinner animation="border" variant="secondary" />
                    </Col>
                </Row>
            ) : (null)}
        </>
    );

    if (isError) return (
        <>
            <Row>
                <Col>
                    <Alert key='danger' variant='danger'>
                        Hay un error. Por favor intente más tarde.
                    </Alert>
                </Col>
            </Row>
        </>
    );

    return (
        <>
            {services ? (
                <>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col>
                                    Filtro de servicios
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <ServiceFilter />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <br></br>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col>
                                    Todos los servicios
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <br></br>
                                <Row>
                                    <Col></Col>
                                    <Col md="auto">
                                        <small>Total de servicios&nbsp;</small><Badge bg="info">{totalServiceFromAPI ? (totalServiceFromAPI) : (0)}</Badge>
                                    </Col>
                                    <Col xs lg="3">
                                        <Col xs={{ order: 12 }}><Button variant="outline-danger" onClick={showPopupDeleteAllServices} size="sm">Eliminar todo</Button>{' '}</Col>
                                    </Col>
                                </Row>
                                <p></p>
                                <Row>
                                    <Col>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th><small>Nombre servicio</small></th>
                                                    <th><small>SKU</small></th>
                                                    <th><small>HH promedio estimada</small></th>
                                                    <th><small>Observación</small></th>
                                                    <th><small>Eliminar</small></th>
                                                    <th><small>Editar</small></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {services.map((service) =>
                                                (
                                                    <tr key={service.id}>
                                                        <td>
                                                            <small>
                                                                <Link className='nav-link nav-link-client' to={`/gestion-de-servicio/detalle/${service.id}`}>{service.name}</Link>
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <small>
                                                                {service.sku}
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <small>
                                                                {service.hh}
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <small>
                                                                {service.note}
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <Link onClick={() => { showPopupDelete(service.id) }} className='nav-link'><AiOutlineDelete /></Link>
                                                        </td>
                                                        <td>
                                                            <Link to={`/gestion-de-servicio/editar/${service.id}`} className='nav-link'><AiOutlineEdit /></Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button onClick={() => setPageNumer((page) => page - 1)} disabled={pageNumber === 1} variant="outline-primary">Volver</Button>{' '}
                                        <Button onClick={() => setPageNumer((page) => page + 1)} disabled={pageNumber === lastPageFromAPI} variant="outline-primary">Siguiente</Button>{' '}
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>

                </>
            ) : (
                <Row>
                    <Col>
                        <Alert key='warning' variant='warning'>
                            Sin Servicios ingresados.
                        </Alert>
                    </Col>
                </Row>
            )}

            {filterServiceFromAPIForDelModal ? (
                <Modal show={showDelete} onHide={handleCloseDelete} >
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminación del servicio</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                ¿Quieres eliminar a&nbsp;

                                {filterServiceFromAPIForDelModal.map((service, index) =>
                                (
                                    <small key={index}>
                                        <b>{service.name}</b>
                                    </small>
                                ))
                                }?

                            </Col>
                        </Row>
                        <br></br>
                        <Alert variant="success" show={messageSuccessServiceDelete}>
                            <small>Servicio eliminado con éxito.</small>
                        </Alert>
                        <Alert variant="danger" show={messageErrorServiceDelete}>
                            <small>Error al eliminar servicio, inténtelo más tarde.</small>
                        </Alert>
                        <br></br>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDelete}>
                            Cerrar
                        </Button>
                        <Button variant="primary" onClick={handleDeleteService} disabled={desableDeleteBtn}>
                            Si, eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : (null)}

            <Modal show={showDeleteAllServices} onHide={handleCloseDeleteAllServices} >
                <Modal.Header closeButton>
                    <Modal.Title>Eliminación de servicios</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            {totalServicesAPI ? (
                                <>
                                    ¿Quieres eliminar <b>{totalServicesAPI['total']}</b> servicio(s)?
                                </>
                            ) : (null)}
                        </Col>
                    </Row>
                    <br></br>
                    <Alert variant="success" show={messageSuccessAllServicesDelete}>
                        <small>Servicios eliminados con éxito.</small>
                    </Alert>
                    <Alert variant="danger" show={messageErrorAllServicesDelete}>
                        <small>Error al eliminar servicios, inténtelo más tarde.</small>
                    </Alert>
                    <br></br>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteAllServices}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleDeleteAllServices} disabled={desableDeleteAllBtn}>
                        Si, eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default ServiceList;