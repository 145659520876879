import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { AiOutlineExport } from "react-icons/ai";
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import { AiOutlineFileSearch } from "react-icons/ai";
import { AiOutlineEnvironment } from "react-icons/ai";
import { Link } from 'react-router-dom';
import useClient from "../../hooks/useClient";
import Alert from 'react-bootstrap/Alert';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Row } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import useShopping from "../../hooks/useShopping";
import HistoryOfShoppings from "../shopping/HistoryOfShoppings";
import HistoryOfOdts from "../odt/HistoryOfOdts";
import HistoryOfNotifications from "./HistoryOfNotifications";
import BreadCrumbs from "../../utils/BreadCrumbs";
import { UserLoggedContext } from "../../contexts/UserLoggedContext";

function Client() {
    const { setGlobalClientIdParam, setGlobalClientTelephoneParam, globalClientIdParam, globalClientTelephoneParam } = useContext(UserLoggedContext);
    let clientIdParam = useParams().client_id;
    let clientTelephoneParam = useParams().client_telephone;
    setGlobalClientIdParam(clientIdParam);
    setGlobalClientTelephoneParam(clientTelephoneParam);
    const { store, show, update } = useClient();
    const { filterShoppingByClientTelephone } = useShopping();

    console.log(globalClientIdParam);
    console.log(globalClientTelephoneParam);

    const { isLoading, data: client_filtered, isError } = useQuery(
        ["client_filtered", clientIdParam],
        () => show(clientIdParam),
        onSuccess => {
            sayHello(client_filtered)
        }
    );

    const sayHello = (clientData) => {
        //console.log(clientData);
        alert(clientData);
    }

    if (isLoading) return (
        <>
            <Row>
                <Col>
                    <br></br>
                    <Spinner animation="border" variant="secondary" />
                </Col>
            </Row>
        </>
    );

    if (isError) return (
        <>
            <Row>
                <Col>
                    <Alert key='danger' variant='danger'>
                        Hay un error. Por favor intente más tarde.
                    </Alert>
                </Col>
            </Row>
        </>
    );

    return (
        <>
            {client_filtered ? (
                <>
                    <br></br>
                    <Row>
                        <Col>
                            <BreadCrumbs
                                paramSecondPage="Gestión de cliente"
                                paramSecondPageLink="gestion-de-cliente"
                                paramCurrentPage="Detalle de cliente"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs lg="5">
                        </Col>
                        <Col md="auto">
                            <h5>Detalle de cliente</h5>
                            {client_filtered.map((client, index) =>
                                <small key={index}><Badge bg="secondary">Cliente filtrado: {client.first_name} {client.last_name}</Badge></small>
                            )}
                        </Col>
                        <Col xs lg="5">
                        </Col>
                    </Row>
                    <br></br>
                    {client_filtered.map((client, index) =>
                        <>
                            <Card key={index}>
                                <Card.Header>Información del cliente</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <Row>
                                            <Col xs={3}>
                                                <small>
                                                    <b>Id: </b>
                                                    {client.id}&nbsp;
                                                    {client.income_type == "app" ? (
                                                        <span class="badge bg-secondary">a</span>
                                                    ) : (null)}
                                                    {client.income_type == "imp" ? (
                                                        <span class="badge bg-secondary">i</span>
                                                    ) : (null)}
                                                </small>
                                            </Col>
                                            <Col xs={3}>
                                                {client.run ? (
                                                    <small>
                                                        <b>Run: </b>
                                                        {client.run}
                                                    </small>
                                                ) : (
                                                    <small>
                                                        <b>Run: </b>
                                                        Sin datos
                                                    </small>
                                                )}
                                            </Col>
                                            <Col xs={3}>
                                                <small>
                                                    <b>Nombre: </b>
                                                    {client.first_name} {client.last_name}
                                                </small>
                                            </Col>
                                            <Col xs={3}>
                                                {client.telephone ? (
                                                    <small>
                                                        <b>Teléfono celular: </b>
                                                        <Link to={`https://api.whatsapp.com/send?phone=${client.telephone}`} target="_blank"><small>{client.telephone}</small></Link>
                                                    </small>
                                                ) : (
                                                    <small>
                                                        <b>Teléfono: </b>
                                                        Sin datos
                                                    </small>
                                                )}
                                            </Col>
                                        </Row>
                                        <br></br>
                                        <Row>
                                        <Col xs={3}>
                                                {client.additional_telephone ? (
                                                    <small>
                                                        <b>Teléfono fijo: </b>
                                                        {client.additional_telephone}
                                                    </small>
                                                ) : (
                                                    <small>
                                                        <b>Teléfono fijo: </b>
                                                        Sin datos
                                                    </small>
                                                )}
                                            </Col>
                                            <Col xs={3}>
                                                {client.email ? (
                                                    <small>
                                                        <b>Email: </b>
                                                        <a href={`mailto:${client.email}`}>{client.email}</a>
                                                    </small>
                                                ) : (
                                                    <small>
                                                        <b>Email: </b>
                                                        Sin datos
                                                    </small>
                                                )}
                                            </Col>
                                            <Col xs={3}>
                                                {client.address ? (
                                                    <small>
                                                        <b>Dirección: </b>
                                                        <Link to={`https://www.google.com/maps/search/?api=1&query=${client.address} ${client.address_comune}`} target="_blank"> <AiOutlineEnvironment /></Link>
                                                        {client.address}, {client.address_comune}
                                                    </small>
                                                ) : (
                                                    <small>
                                                        <b>Dirección: </b>
                                                        Sin datos
                                                    </small>
                                                )}
                                            </Col>
                                        </Row>
                                        <br></br>
                                        <Row>
                                        <Col xs={3}>
                                                {client.last_visit ? (
                                                    <small>
                                                        <b>Últ. visita: </b>
                                                        {client.last_visit}
                                                    </small>
                                                ) : (
                                                    <small>
                                                        <b>Últ. visita: </b>
                                                        Sin visitas
                                                    </small>
                                                )}
                                            </Col>
                                            <Col xs={5}>
                                                {client.last_visit == null ? (
                                                    <small>
                                                        <b>Estado mantención: </b>
                                                        No aplica
                                                    </small>
                                                ) : (null)}

                                                {client.last_visit != null && client.count_maintenance_days <= 174 ? (
                                                    <small>
                                                        <b>Estado mantención: </b>
                                                        <span class="badge bg-success">Al día ({client.count_maintenance_days} días)</span>
                                                    </small>
                                                ) : (null)}
                                                
                                                {client.last_visit && client.count_maintenance_days >= 175 && client.count_maintenance_days < 180 ? (
                                                    <small>
                                                        <b>Estado mantención: </b>
                                                        <span class="badge bg-warning">Por vencer ({client.count_maintenance_days} días)</span>
                                                    </small>
                                                ) : (null)}

                                                {client.last_visit && client.count_maintenance_days >= 180 ? (
                                                    <small>
                                                        <b>Estado mantención: </b>
                                                        <span class="badge bg-danger">Vencida (hace {client.count_maintenance_days} días)</span>
                                                    </small>
                                                ) : (null)}
                                            </Col>
                                        </Row>
                                        <br></br>
                                        <Row>
                                            <Col xs={12}>
                                                {client.note ? (
                                                    <small>
                                                        <b>Última nota: </b>
                                                        {client.note}
                                                    </small>
                                                ) : (
                                                    <small>
                                                        <b>Última nota: </b>
                                                        Sin datos
                                                    </small>
                                                )}
                                            </Col>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <br></br>
                            <Card>
                                <Card.Header>Información de empresa</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <Row>
                                            <Col xs={3}>
                                                {client.run ? (
                                                    <small>
                                                        <b>Rut: </b>
                                                        {client.run}
                                                    </small>
                                                ) : (
                                                    <small>
                                                        <b>Rut: </b>
                                                        Sin datos
                                                    </small>
                                                )}
                                            </Col>
                                            <Col xs={3}>
                                                {client.business ? (
                                                    <small>
                                                        <b>Nombre empresa: </b>
                                                        {client.business}
                                                    </small>
                                                ) : (
                                                    <small>
                                                        <b>Nombre empresa: </b>
                                                        Sin datos
                                                    </small>
                                                )}
                                            </Col>
                                            <Col xs={3}>
                                                {client.business_name ? (
                                                    <small>
                                                        <b>Razón social: </b>
                                                        {client.business_name}
                                                    </small>
                                                ) : (
                                                    <small>
                                                        <b>Razón social: </b>
                                                        Sin datos
                                                    </small>
                                                )}
                                            </Col>
                                            <Col xs={3}>
                                                {client.business_line ? (
                                                    <small>
                                                        <b>Giro: </b>
                                                        {client.business_line}
                                                    </small>
                                                ) : (
                                                    <small>
                                                        <b>Giro: </b>
                                                        Sin datos
                                                    </small>
                                                )}
                                            </Col>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </>
                    )}
                    <br></br>
                    <Card>
                        <Card.Header>Historial de compras</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <HistoryOfShoppings
                                    paramClientTelephone={clientTelephoneParam}
                                />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <br></br>
                    {client_filtered.map((client, index) =>
                        <>
                            <Card key={index}>
                                <Card.Header>Historial de ODTs</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <HistoryOfOdts
                                            paramClientTelephone={clientTelephoneParam}
                                        />
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <br></br>
                            <Card key={index}>
                                <Card.Header>Historial de notificaciones</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <HistoryOfNotifications
                                            paramClientTelephone={clientTelephoneParam}
                                        />
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <br></br>
                            <Row className="justify-content-md-center">
                                <Col xs lg={2}>
                                </Col>
                                <Col md="auto">
                                    <Button variant="outline-primary" href={'/gestion-de-cliente'}>
                                        Volver
                                    </Button>
                                </Col>
                                <Col xs lg={2}>
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            ) : (
                <>
                    <br></br>
                    <Row>
                        <Col>
                            <Alert key='danger' variant='danger'>
                                <small>No existe el cliente con id y teléfono filtrado. <Link to={'/gestion-de-cliente'} className='nav-link'><b>Volver a clientes.</b></Link></small>
                            </Alert>
                        </Col>
                    </Row>
                </>
            )}
            <br></br>
            <br></br>
        </>
    );

}
export default Client;