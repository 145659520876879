import React, { useEffect, useState, useContext } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TabCustom from "../../utils/TabsCustom";
import Spinner from 'react-bootstrap/Spinner';
import BreadCrumbs from "../../utils/BreadCrumbs";
import { UserLoggedContext } from '../../contexts/UserLoggedContext';

function ODTAdministrator() {
  const [loading, setLoading] = useState(true);
  const containerType = 'ODTAdministrator';
  const { globalClientIdParam, globalClientTelephoneParam, userRole } = useContext(UserLoggedContext);
  let path_test = "gestion-de-cliente/detalle/" + globalClientIdParam + "/" + globalClientTelephoneParam;


  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);


  return (
    <>
      {loading ? (
        <>
          <br></br>
          <Spinner animation="border" variant="secondary" />
        </>
      ) : (
        <>
          <br></br>
          <Row>
            <Col>
              {globalClientIdParam != "" && globalClientTelephoneParam != "" ? (
                <BreadCrumbs
                  paramSecondPage="Gestión de odt"
                  paramSecondPageLink="gestion-de-odt"
                  paramThirdPage="Detalle de cliente"
                  paramThirdPageLink={path_test}
                  paramCurrentPage="Gestión de odt"
                />
              ) : (
                <BreadCrumbs
                  paramCurrentPage="Gestión de odt"
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Gestión de ODT</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <br></br>
              <TabCustom
                containerType={containerType}
                option_one='Ingresar ODT'
                option_two='Lista ODTs'
                option_three='ODTs asignadas'
                option_four='Resumen agenda'
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default ODTAdministrator;