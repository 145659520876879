import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import useClient from "../../hooks/useClient";
import Alert from 'react-bootstrap/Alert';
import { Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { AiOutlineExport } from "react-icons/ai";
import Badge from 'react-bootstrap/Badge';
import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineFileSearch } from "react-icons/ai";
import useShopping from "../../hooks/useShopping";

function ShoppingFilter() {
    const { destroy, showModal, showForTelephone, filterClientFromTelephoneAPI, codeApiFilterClientForTelephone, setFilterClientFromRunAPI, setFilterClientFromTelephoneAPI } = useClient();
    const { filterShoppingById, filterShoppingForIdAPI, setFilterShoppingForIdAPI, codeApiFilterShoppingForId } = useShopping();
    const [shoppingToFilter, setShoppingToFilter] = useState("");
    const [clientForDelete, setCllientForDelete] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    const queryClient = useQueryClient();
    const [messageSuccessClientDelete, setMessageSuccessClientDelete] = useState(false);
    const [show, setShow] = useState(false);

    const deleteClientMutation = useMutation({
        mutationFn: destroy,
        onSuccess: () => {
            successClientDelete();
            queryClient.invalidateQueries(['clients'])
            queryClient.invalidateQueries(['totalClientsAPI'])
        },
        onError: () => {
            //errorClientDelete()
        }
    });

    const successClientDelete = () => {
        setMessageSuccessClientDelete(true);
        setShoppingToFilter("");
        //setDesableDeleteBtn(true);
        setTimeout(() => {
            setMessageSuccessClientDelete(false);
            setFilterClientFromTelephoneAPI([]);
            setShowDelete(false);
        }, 2000);
    }

    const showPopupDelete = (id) => {
        setCllientForDelete(id);
        showModal(id);
        setShowDelete(true);
    }

    const handleShow = () => {
        //setIdClient(id);
        setShow(true);
    }

    const handleFilterShopping = (e) => {
        e.preventDefault();
        filterShoppingById(shoppingToFilter);
    }

    const deleteFilteredShopping = () => {
        setShoppingToFilter("");
        setFilterShoppingForIdAPI([]);
    }

    const handleDeleteClient = () => {
        deleteClientMutation.mutate(clientForDelete);
    }

    return (
        <>
            <Row>
                <Col>
                    <Form onSubmit={handleFilterShopping}>
                        <InputGroup className="mb-1">
                            <Form.Control
                                name="shoppingToFilter"
                                type="number"
                                value={shoppingToFilter}
                                onChange={(e) => { setShoppingToFilter(e.target.value) }}//fix
                                placeholder="23"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                            />
                            <Button type="submit" variant="outline-primary" id="button-addon2" disabled={!shoppingToFilter}>
                                Filtrar compra
                            </Button>
                        </InputGroup>
                    </Form>
                </Col>
            </Row>

            {codeApiFilterShoppingForId === 200 && filterShoppingForIdAPI.length > 0 ? (
                <>
                    <br></br>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={12} md={11}>
                                    Compra filtrada
                                </Col>
                                <Col xs={12} md={1}>
                                    <Button variant="outline-danger" onClick={deleteFilteredShopping} size="sm">Limpiar</Button>{' '}
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th><small>Id compra</small></th>
                                            <th><small>RUN/RUT cliente</small></th>
                                            <th><small>Cliente</small></th>
                                            <th><small>Teléfono cliente</small></th>
                                            <th><small>Fecha ingreso</small></th>
                                            <th><small>ODT asociada</small></th>
                                            <th><small>Estado</small></th>
                                            <th><small>Eliminar</small></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filterShoppingForIdAPI.map((shop) =>
                                        (
                                            <tr key={shop.id}>
                                                <td>
                                                    <small>
                                                        <Link to={`/gestion-de-compra/detalle/${shop.id}`} className='nav-link nav-link-client'>{shop.id}</Link>
                                                    </small>
                                                </td>
                                                {shop.run_rut != null ? (
                                                    <td>
                                                        <small>{shop.run_rut}</small>
                                                    </td>
                                                ) : (
                                                    <td>
                                                        <small>Sin información.</small>
                                                    </td>
                                                )}
                                                <td>
                                                    <small>{shop.first_name} {shop.last_name}</small>
                                                </td>
                                                {shop.telephone != null ? (
                                                    <td>
                                                        <small>{shop.telephone}</small>
                                                    </td>
                                                ) : (
                                                    <td>
                                                        <small>Sin información.</small>
                                                    </td>
                                                )}
                                                <td>
                                                    <small>{shop.date_shop}</small>
                                                </td>
                                                {shop.odt == 0 ? (
                                                    <td>
                                                        <small>
                                                            Sin ODT
                                                        </small>
                                                    </td>
                                                ) : (
                                                    <td>
                                                        <small>
                                                            {shop.odt} <Link to={`/gestion-de-odt/detalle/${shop.odt}`} target="_blank"> <AiOutlineExport /></Link>
                                                        </small>
                                                    </td>
                                                )}
                                                {shop.state_shopping == 0 ? (
                                                    <td>
                                                        <small>
                                                            <Badge bg="danger">Abierta</Badge>
                                                        </small>
                                                    </td>
                                                ) : (null)}
                                                {shop.state_shopping == 1 ? (
                                                    <td>
                                                        <small>
                                                            <Badge bg="success">Cerrada</Badge>
                                                        </small>
                                                    </td>
                                                ) : (null)}
                                                <td>
                                                    <Link onClick={() => { handleShow(shop.id) }} className='nav-link'><AiOutlineDelete /></Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </>
            ) : (null)}

            {codeApiFilterShoppingForId === 204 ? (
                <>
                    <br></br>
                    <Alert key='danger' variant='danger'>
                        <small>La compra con id ingresado no existe.</small>
                    </Alert>
                </>
            ) : (null)}

            {codeApiFilterClientForTelephone === 200 && filterClientFromTelephoneAPI.length > 0 ? (
                <Modal show={showDelete} onHide={handleCloseDelete} >
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminación del cliente</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                ¿Quiere eliminar a&nbsp;

                                {filterClientFromTelephoneAPI.map((c, index) =>
                                (
                                    <small key={index}>
                                        <b>{c.first_name} {c.last_name}</b>
                                    </small>
                                ))
                                }?

                            </Col>
                        </Row>
                        <br></br>
                        <Alert variant="success" show={messageSuccessClientDelete}>
                            <small>Cliente eliminado con éxito.</small>
                        </Alert>
                        <br></br>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDelete}>
                            Cerrar
                        </Button>
                        <Button variant="primary" onClick={handleDeleteClient} disabled={!filterClientFromTelephoneAPI}>
                            Si, eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : (null)}
        </>
    );
}
export default ShoppingFilter;