import React, { useEffect, useState, useContext } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TabCustom from "../../utils/TabsCustom";
import Spinner from 'react-bootstrap/Spinner';
import BreadCrumbs from "../../utils/BreadCrumbs";
import TabCustomClientTable from "../../utils/TabCustomClientTable";

function ClientTableAdministrator() {
    const [loading, setLoading] = useState(true);
    const containerType = 'ClientTable';

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);


    return (
        <>
            {loading ? (
                <>
                    <br></br>
                    <Spinner animation="border" variant="secondary" />
                </>
            ) : (
                <>
                    <Row>
                        <Col>
                            <br></br>
                            <TabCustomClientTable
                                containerType={containerType}
                                option_one='Todos'
                                option_two='Al día'
                                option_three='Por vencer'
                                option_four='Vencidas'
                                option_five='Sin compras'
                            />
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
}

export default ClientTableAdministrator;