import React, { useState, useContext } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormUser from '../components/user/FormAddUser';
import ClientList from '../components/client/ClientList';
import FormAddOdt from '../components/odt/FormAddOdt';
import OdtList from '../components/odt/OdtList';
import UserList from '../components/user/UserList';
import FormAddProduct from '../components/product/FormAddProduct';
import ProductList from '../components/product/ProductList';
import ServiceList from '../components/service/ServiceList';
import { UserLoggedContext } from '../contexts/UserLoggedContext';
import FormAddShopping from '../components/shopping/FormAddShopping';
import ShoppingList from '../components/shopping/ShopingList';
import FormAddService from '../components/service/FormAddService';
import FormAddClient from '../components/client/FormAddClient';
import OdtListAssigned from '../components/odt/OdtListAssigned';
import FormAddTag from '../components/tags/FormAddTag';
import ClientListNotificationCenter from '../components/client/ClientListNotificationCenter';
import NotificationCenter from '../components/controlCenter/NotificationCenter';
import OpenAgendaList from '../components/odt/OpenAgendaList';
import FormAddUser from '../components/user/FormAddUser';
import Dashboard from '../components/dashboard/Dashboard';

function TabCustom({ containerType, option_one, option_two, option_three, option_four, client_telephone }) {
  const { clients, setClients, userRole } = useContext(UserLoggedContext);

  const addNewClient = (client) => {
    console.log(client);
    const updatedClientList = [client, ...clients];
    setClients(updatedClientList);
  }

  return (
    <>
      {
        (() => {
          if (containerType === 'DashboardAdministrator') {
            return (
              userRole != 3 && userRole != 2 ? (
                <Tabs
                defaultActiveKey="option_one"
                id="uncontrolled-tab-example"
                className="mb-3">
                <Tab eventKey="option_one" title={option_one}>
                  <Row>
                    <Col>
                      <Dashboard />
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
              ) : (null)
            )
          } else if (containerType === 'ClientAdministrator') {
            return (
              <Tabs
                defaultActiveKey="option_one"
                id="uncontrolled-tab-example"
                className="mb-3">
                <Tab eventKey="option_one" title={option_one}>
                  <Row>
                    <Col>
                      <h6><b>{option_one}</b></h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormAddClient />
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="option_two" title={option_two}>
                  <Row>
                    <Col>
                      <h6><b>{option_two}</b></h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ClientList />
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="option_three" title={option_three}>
                  <Row>
                    <Col>
                      <h6><b>{option_three}</b></h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ClientListNotificationCenter />
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            )
          } else if (containerType === 'ShoppingAdministrator') {
            return (
              <Tabs
                defaultActiveKey="option_one"
                id="uncontrolled-tab-example"
                className="mb-3">
                <Tab eventKey="option_one" title={option_one}>
                  <Row>
                    <Col>
                      <h6><b>{option_one}</b></h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormAddShopping />
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="option_two" title={option_two}>
                  <Row>
                    <Col>
                      <ShoppingList />
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            )
          } else if (containerType === 'ODTAdministrator') {
            return (
              userRole != 3 && userRole != 2 ? (
                <Tabs
                  defaultActiveKey="option_one"
                  id="uncontrolled-tab-example"
                  className="mb-3">
                  <Tab eventKey="option_one" title={option_one}>
                    <Row>
                      <Col>
                        <h6><b>{option_one}</b></h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormAddOdt />
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="option_two" title={option_two}>
                    <Row>
                      <Col>
                        <OdtList />
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="option_four" title={option_four}>
                    <Row>
                      <Col>
                        <OpenAgendaList />
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              ) : (
                <Tabs
                  defaultActiveKey="option_three"
                  id="uncontrolled-tab-example"
                  className="mb-3">
                  <Tab eventKey="option_three" title={option_three}>
                    <Row>
                      <Col>
                        <h6><b>{option_three}</b></h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <OdtListAssigned />
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              )
            )
          } else if (containerType === 'UserAdministrator') {
            return (
              <Tabs
                defaultActiveKey="option_one"
                id="uncontrolled-tab-example"
                className="mb-3">
                <Tab eventKey="option_one" title={option_one}>
                  <Row>
                    <Col>
                      <h6><b>{option_one}</b></h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormAddUser />
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="option_two" title={option_two}>
                  <Row>
                    <Col>
                      <h5>{option_two}</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <UserList />
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            )
          } else if (containerType === 'TagAdministrator') {
            return (
              <Tabs
                defaultActiveKey="option_one"
                id="uncontrolled-tab-example"
                className="mb-3">
                <Tab eventKey="option_one" title={option_one}>
                  <Row>
                    <Col>
                      <h5>{option_one}</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormAddTag />
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="option_two" title={option_two}>
                  <Row>
                    <Col>
                      <h5>{option_two}</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <UserList />
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            )
          } else if (containerType === 'ProductAdministrator') {
            return (
              <Tabs
                defaultActiveKey="option_one"
                id="uncontrolled-tab-example"
                className="mb-3">
                <Tab eventKey="option_one" title={option_one}>
                  <Row>
                    <Col>
                      <h6><b>{option_one}</b></h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormAddProduct />
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="option_two" title={option_two}>
                  <Row>
                    <Col>
                      <ProductList />
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            )
          } else if (containerType === 'ServiceAdministrator') {
            return (
              <Tabs
                defaultActiveKey="option_one"
                id="uncontrolled-tab-example"
                className="mb-3">
                <Tab eventKey="option_one" title={option_one}>
                  <Row>
                    <Col>
                      <h6><b>{option_one}</b></h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormAddService />
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="option_two" title={option_two}>
                  <Row>
                    <Col>
                      <ServiceList />
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            )
          }
        })(null)
      }
    </>
  );
}

export default TabCustom;