import React, { useEffect, useContext, useState } from "react";
import { UserLoggedContext } from '../contexts/UserLoggedContext';
import AuthService from "../services/AuthService";
import ClientService from "../services/ClientService";
import ProductService from "../services/ProductService";
import axios from "axios";

function useService(){
    const API_URL = process.env.REACT_APP_API_URL;
    let codeHTTP = 0;
    const [codeApiFilterClient, setCodeApiFilterClient] = useState(0);
    //const { setCodeApiHttp, setFilterServiceFromAPI, filterServiceFromAPI } = useContext(UserLoggedContext);  
    const [codeApiFilterService, setCodeApiFilterService] = useState(0);
    const [filterServiceFromAPI, setFilterServiceFromAPI] = useState([]);
    const [filterServiceFromAPIForDelModal, setFilterServiceFromAPIForDelModal] = useState([]);
    const [totalServiceFromAPI, setTotalServiceFromAPI] = useState(0);
    const [lastPageFromAPI, setLastPageFromAPI] = useState(0);
    const { setGlobalCodeApiFilterService, setGlobalFilterServiceFromAPI } = useContext(UserLoggedContext); 

    const index = ($pageNumber) => {
        return axios.get(API_URL + 'service/?' + 'limit=5' + '&page=' + $pageNumber)
            .then(function (response) {
                if(response.data.code != 204){
                    setTotalServiceFromAPI(response.data.data.total);
                    setLastPageFromAPI(response.data.data.last_page);
                }
                //return response.data.data.data;
                return response.data.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const store = (data) => {
        return axios.post(API_URL + "service", data)
            .then(function (response) {
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    };


    //fix
    const show = ($id) => {
        return axios.get(API_URL + 'service' + '/' + $id)
            .then(function (response) {
                setCodeApiFilterService(response.data.code);
                setFilterServiceFromAPI(response.data.data);
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const showForSku = (sku) => {
        return axios.get(API_URL + 'service/filter_sku_service' + '/' + sku)
            .then(function (response) {
                setCodeApiFilterService(response.data.code);
                setFilterServiceFromAPI(response.data.data);
                setGlobalCodeApiFilterService(response.data.code);
                setGlobalFilterServiceFromAPI(response.data.data);
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const showDeleteModal = (id) => {
        return axios.get(API_URL + 'service' + '/' + id)
            .then(function (response) {
                setFilterServiceFromAPIForDelModal(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const destroy = (id) => {
        return axios.delete(API_URL + 'service' + '/' + id)
            .then(function (response) {
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const update = (data) => {
        return axios.put(API_URL + 'service-update', data)
            .then(function (response) {
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const totalServices = () => {
        return axios.get(API_URL + 'service')
            .then(function (response) {
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const deleteAll = () => {
        return axios.delete(API_URL + 'service-delete-all')
            .then(function (response) {
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    return {
        index,
        store,
        show,
        showForSku,
        filterServiceFromAPI,
        codeApiFilterClient,
        codeApiFilterService,
        setFilterServiceFromAPI,
        filterServiceFromAPIForDelModal,
        showDeleteModal,
        destroy,
        update,
        totalServiceFromAPI,
        lastPageFromAPI,
        totalServices,
        deleteAll,
    }
}

export default useService;