import React, { useState } from "react";
import useShopping from "../../hooks/useShopping";
import { Button } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { useQuery } from 'react-query';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import { AiOutlineExport } from "react-icons/ai";
import { AiOutlineFileSearch } from "react-icons/ai";
import { AiOutlineFileAdd } from "react-icons/ai";
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';

function HistoryOfShoppings({ paramClientTelephone }) {
    const { filterShoppingByClientTelephone, lastPageFromAPI, totalDataFromAPI } = useShopping();
    const [pageNumber, setPageNumer] = useState(1);

    const { isLoading, data: history_of_shoppings, isError } = useQuery(
        ["history_of_shoppings", paramClientTelephone, pageNumber],
        () => filterShoppingByClientTelephone(paramClientTelephone, pageNumber)
    );

    if (isLoading) return (
        <>
            <Row>
                <Col>
                    <br></br>
                    <Spinner animation="border" variant="secondary" />
                </Col>
            </Row>
        </>
    );

    if (isError) return (
        <>
            <Row>
                <Col>
                    <Alert key='danger' variant='danger'>
                        Hay un error. Por favor intente más tarde.
                    </Alert>
                </Col>
            </Row>
        </>
    );

    return (
        <>
            {history_of_shoppings ? (
                <>
                    <Row>
                        <Col>
                            <small>Total compras&nbsp;</small><Badge bg="info">{totalDataFromAPI ? (totalDataFromAPI) : (0)}</Badge>
                        </Col>
                        <Col>
                            <Link to='/gestion-de-compra'><small>Nueva compra</small></Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <br></br>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th><small>Id compra</small></th>
                                        <th><small>Fecha ingreso</small></th>
                                        <th><small>Observación</small></th>
                                        <th><small>ODT asociada</small></th>
                                        <th><small>Estado</small></th>
                                        <th><small>Ver detalle</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {history_of_shoppings.map((shop, index) =>

                                        <tr key={index}>
                                            <td>
                                                <small>{shop.id}</small>
                                            </td>
                                            <td>
                                                <small>{shop.date_shop}</small>
                                            </td>
                                            <td>
                                                {shop.note_shop ? (
                                                    <small>{shop.note_shop}</small>
                                                ) : (
                                                    <small>Sin datos.</small>
                                                )}
                                            </td>
                                            {shop.odt == 0 ? (
                                                <td>
                                                    <small>
                                                        Sin ODT
                                                    </small>&nbsp;
                                                        <Link to={`/gestion-de-odt/`}><AiOutlineFileAdd /></Link>
                                                </td>
                                            ) : (
                                                <td>
                                                    <small>
                                                        {shop.odt} <Link to={`/gestion-de-odt/detalle/${shop.odt}/${paramClientTelephone}`} target="_blank"> <AiOutlineExport /></Link>
                                                    </small>
                                                </td>
                                            )}
                                            {shop.state_shopping == 0 ? (
                                                <td>
                                                    <small>
                                                        <Badge bg="danger">Nueva</Badge>
                                                    </small>
                                                </td>
                                            ) : (null)}
                                            {shop.state_shopping == 1 ? (
                                                <td>
                                                    <small>
                                                        <Badge bg="warning">ODT asignada</Badge>
                                                    </small>
                                                </td>
                                            ) : (null)}
                                            {shop.state_shopping == 2 ? (
                                                <td>
                                                    <small>
                                                        <Badge bg="success">Cerrada</Badge>
                                                    </small>
                                                </td>
                                            ) : (null)}
                                            <td>
                                                <Link to={`/gestion-de-cliente/historial-compras/detalle/${shop.id}`} className='nav-link'><AiOutlineFileSearch /></Link>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button onClick={() => setPageNumer((page) => page - 1)} disabled={pageNumber === 1} variant="outline-primary">Volver</Button>{' '}
                            <Button onClick={() => setPageNumer((page) => page + 1)} disabled={pageNumber === lastPageFromAPI} variant="outline-primary">Siguiente</Button>{' '}
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <small>Sin compras registradas.</small> <Link to='/gestion-de-compra'><small>Nueva compra</small></Link>
                </>
            )}
        </>
    )
}
export default HistoryOfShoppings;