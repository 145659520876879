import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import { AiFillCaretDown } from "react-icons/ai";
import Spinner from 'react-bootstrap/Spinner';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Col } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { AiOutlineClockCircle } from "react-icons/ai";
import Alert from 'react-bootstrap/Alert';
import { Card } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineFileSearch } from "react-icons/ai";
import useClient from "../../hooks/useClient";
import useUtilities from "../../hooks/useUtilities";
import useSmsNotification from "../../hooks/useSmsNotification";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import ClientFilter from "./ClientFilter";
import ClientTableAdministrator from "./ClientTableAdministrator";

function ClientList() {
  const { destroy, deleteAll, show, showModal, filterClientFromAPI, index, lastPageFromAPI, totalDataFromAPI, totalClients, totalActiveClients, totalInactiveClients } = useClient();
  const { truncateString, calculateDaysForMaintenance, formatDate } = useUtilities();
  const { smsManualSend } = useSmsNotification();
  const [loading, setLoading] = useState(true);
  const { totalClientsGlobal } = useContext(UserLoggedContext);
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const [showDeleteAllClients, setShowDeleteAllClients] = useState(false);
  const [showClientSmsNot, setShowClientSmsNot] = useState(false);
  const handleCloseDeleteAllClients = () => setShowDeleteAllClients(false);
  const handleCloseClientSmsSend = () => setShowClientSmsNot(false);
  const [desableDeleteBtn, setDesableDeleteBtn] = useState(false);
  const [desableDeleteAllBtn, setDesableDeleteAllBtn] = useState(false);
  const queryClient = useQueryClient();
  const [clientForDelete, setCllientForDelete] = useState(0);
  const [messageSuccessClientDelete, setMessageSuccessClientDelete] = useState(false);
  const [messageErrorClientDelete, setMessageErrorClientDelete] = useState(false);
  const [messageSuccessClientDeleteAll, setMessageSuccessClientDeleteAll] = useState(false);
  const [messageErrorClientDeleteAll, setMessageErrorClientDeleteAll] = useState(false);
  const [pageNumber, setPageNumer] = useState(1);
  const [hiddenBtnNotifi, setHiddenBtnNotifi] = useState(true);
  const [hiddenBtnNotifiForIndiClient, setHiddenBtnNotifiForIndiClient] = useState(true);
  const [isCheckedComboBoxAllClientSelected, setIsCheckedComboBoxAllClientSelected] = useState(false);
  const [isCheckedComboBoxClientSelected, setIsCheckedComboBoxClientSelected] = useState(false);
  const [clientListForSmsSelected, setClientListForSmsSelected] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedFiftyItems, setSelectedFiftyItems] = useState([]);
  const [nameClientSelectedToNotSms, setNameClientSelectedToNotSms] = useState();
  const [telephoneClientSelectedToNotSms, setTelephoneClientSelectedToNotSms] = useState();
  const [idClientSelectedToNotSms, setIdClientSelectedToNotSms] = useState();
  const [desableSendSmsNotclient, setDesableSendSmsNotclient] = useState(false);
  //const msgSmsNot = `Estimado Cliente de Climatización Rancagua, \nUsted registra mantenciones vencidas de sus Equipos de Aire Acondicionado. \nContáctenos al Whatsapp +56 9 67345720`;
  const msgSmsNot = `Estimado Cliente de Climatización Rancagua, \nRegistra mantenciones vencidas en Equipos de Aire Acondicionado. \nHablemos: https://api.whatsapp.com/send?phone=56967345720'`;

  const { mutate: mutateSms, isLoading: isLoadingMutationSms, error } = useMutation({
    mutationFn: smsManualSend,
    onSuccess: () => {
      setDesableSendSmsNotclient(true);
      queryClient.invalidateQueries(['clients']);
      queryClient.invalidateQueries(['all_sms_notifications']);
      setTimeout(() => {
        setLoading(false);
        handleCloseClientSmsSend();
        setDesableSendSmsNotclient(false);
      }, 2000);
    },
    onError: () => {
      //errorSuccessNewClient()
    }
  });

  const { isLoading, data: clients, isError: isErrorDataFetchingAllClients } = useQuery(
    ['clients', pageNumber],
    () => index(pageNumber));

  const { data: totalClientsAPI } = useQuery(
    ['totalClientsAPI'],
    () => totalClients());

  const { data: totalActiveClientsAPI } = useQuery(
    ['totalActiveClients'],
    () => totalActiveClients());

  const { data: totalInactiveClientsAPI } = useQuery(
    ['totalInactiveClients'],
    () => totalInactiveClients());

  const { data: dataAllClients } = useQuery({
    queryKey: ['allClients'],
    queryFn: totalClients,
  });

  useEffect(() => {
    totalClients();
    index();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleDaysCount = (data) => {
    //console.log(calculateDaysForMaintenance(data));
    return calculateDaysForMaintenance(data);
  }

  const deleteClientMutation = useMutation({
    mutationFn: destroy,
    onSuccess: () => {
      successClientDelete();
      queryClient.invalidateQueries(['clients'])
      queryClient.invalidateQueries(['totalClientsAPI'])
      queryClient.invalidateQueries(['totalClientsGlobal']);
    },
    onError: () => {
      errorClientDelete()
    }
  });

  const deleteAllClientsMutation = useMutation({
    mutationFn: deleteAll,
    onSuccess: () => {
      successClientDeleteAll();
      queryClient.invalidateQueries(['clients'])
      queryClient.invalidateQueries(['totalClientsAPI'])
    },
    onError: () => {
      errorClientDeleteAll()
    }
  });

  const successClientDelete = () => {
    setMessageSuccessClientDelete(true);
    setDesableDeleteBtn(true);
    setTimeout(() => {
      setMessageSuccessClientDelete(false);
      setDesableDeleteBtn(false);
      setShowDelete(false);
    }, 2000);
  }

  const errorClientDelete = () => {
    setMessageErrorClientDelete(true);
    setTimeout(() => {
      setMessageErrorClientDelete(false);
    }, 2000);
  }

  const successClientDeleteAll = () => {
    setMessageSuccessClientDeleteAll(true);
    setDesableDeleteAllBtn(true);
    setTimeout(() => {
      setMessageSuccessClientDeleteAll(false);
      setDesableDeleteAllBtn(false);
      setShowDeleteAllClients(false);
    }, 2000);
  }

  const errorClientDeleteAll = () => {
    setMessageErrorClientDeleteAll(true);
    setTimeout(() => {
      setMessageErrorClientDeleteAll(false);
    }, 2000);
  }

  const showPopupDelete = (id) => {
    setCllientForDelete(id);
    showModal(id);
    setShowDelete(true);
  }

  const showPopupDeleteAllClients = () => {
    setShowDeleteAllClients(true);
  }

  const showPopupClientSmsNot = () => {
    setShowClientSmsNot(true);
  }

  const handleDeleteClient = () => {
    deleteClientMutation.mutate(clientForDelete);
  }

  const handleDeleteAllClients = () => {
    deleteAllClientsMutation.mutate();
  }

  const handleCheckboxFiftyClientsChange = () => {
    console.log("ok");

  }

  const handleCheckboxClientChange = () => {
    setIsCheckedComboBoxClientSelected(!isCheckedComboBoxClientSelected);
    //console.log(clientId);
  }

  //fix
  const handleCheckboxChange = (id, name, telephone) => {
    console.log(id);
    /*
    console.log(name);
    console.log(telephone);
    */
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(id)) {
        //setHiddenBtnNotifiForIndiClient(true);
        setHiddenBtnNotifiForIndiClient(true);
        console.log("deselected");
        removeClientToListSms(id);
        setNameClientSelectedToNotSms("");
        setTelephoneClientSelectedToNotSms("");
        setIdClientSelectedToNotSms("");
        return prevSelectedItems.filter((item) => item !== id);
      } else {
        setNameClientSelectedToNotSms(name);
        setTelephoneClientSelectedToNotSms(telephone);
        setIdClientSelectedToNotSms(id);
        setHiddenBtnNotifiForIndiClient(false);
        if (clientListForSmsSelected.length > 0) {
          setHiddenBtnNotifiForIndiClient(false);
        }
        console.log("selected");
        handleAddClientToListSms(name, telephone, id);
        return [...prevSelectedItems, id];
      }
    });
  };

  //logic for save an array
  const handleAddClientToListSms = (param_name, param_telephone_client, param_id_client) => {
    const data = {
      first_name_client: param_name,
      telephone_client: param_telephone_client,
      id_client: param_id_client,
    }

    const updtateListClients = [...clientListForSmsSelected, data];
    setClientListForSmsSelected(updtateListClients);
  }

  const removeClientToListSms = (id) => {
    setClientListForSmsSelected(oldValues => {
      return oldValues.filter(client => client.id_client !== id)
    })
  }

  //console.log(clientListForSmsSelected);
  //console.log(clientListForSmsSelected.length);

  const handleSendClientSmsNot = () => {
    //deleteAllClientsMutation.mutate();
    mutateSms({
      telephone_client: telephoneClientSelectedToNotSms,
      id_client: idClientSelectedToNotSms,
      first_name_client: nameClientSelectedToNotSms,
      message: msgSmsNot,
      type_sms: 1,
      send_date: formatDate(),
    });

    //fix: I need to complete history logic for save after to send a sms notification. 
    /*
    smsHistoryNotifications({
      telephone_client: telephoneClientSelectedToNotSms,
      id_client: idClientSelectedToNotSms,
      message: msgSmsNot,
    });
    */
  }

  if (isLoading) return (
    <>
      <Row>
        <Col>
          <br></br>
          <Spinner animation="border" variant="secondary" />
        </Col>
      </Row>
    </>
  );

  if (isErrorDataFetchingAllClients) return (
    <>
      <Row>
        <Col>
          <Alert key='danger' variant='danger'>
            Hay un error. Por favor intente más tarde.
          </Alert>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      {clients ? (
        <>
          <Card>
            <Card.Header>
              <Row>
                <Col>
                    Filtro de clientes
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Card.Text>
              <ClientFilter />
              </Card.Text>
            </Card.Body>
          </Card>
          <br></br>
          <Card>
            <Card.Header>
              <Row>
                <Col>
                    Clientes
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <ClientTableAdministrator />
              </Card.Text>
            </Card.Body>
          </Card>
        </>
      ) : (
        <Row>
          <Col>
            <Alert key='warning' variant='warning'>
              <small>Sin clientes ingresados.</small>
            </Alert>
          </Col>
        </Row>
      )}

      <br></br>
      <br></br>
    </>
  )
}

export default ClientList;

