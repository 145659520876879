import React, { useContext } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import { UserLoggedContext } from '../contexts/UserLoggedContext';

function BreadCrumbs({ paramSecondPageLink, paramSecondPage, paramThirdPageLink, paramThirdPage, paramFourthPageLink, paramFourthPage, paramCurrentPage }) {
    const { userRole } = useContext(UserLoggedContext);

    return (
        <Breadcrumb>
        {userRole == 1 ? (
            <Breadcrumb.Item><Link to={`/dashboard/`}><small>Inicio</small></Link></Breadcrumb.Item>
        ) : (null)}

            {paramSecondPageLink != null && paramSecondPage != null ? (
                <Breadcrumb.Item><Link to={`/${paramSecondPageLink}/`}><small>{paramSecondPage}</small></Link></Breadcrumb.Item>
            ) : (null)}

            {paramThirdPageLink != null && paramThirdPage != null ? (
                <Breadcrumb.Item><Link to={`/${paramThirdPageLink}/`}><small>{paramThirdPage}</small></Link></Breadcrumb.Item>
            ) : (null)}
            
            {paramFourthPageLink != null && paramFourthPage != null ? (
                <Breadcrumb.Item><Link to={`/${paramFourthPageLink}/`}><small>{paramFourthPage}</small></Link></Breadcrumb.Item>
            ) : (null)}


            <Breadcrumb.Item active><small>{paramCurrentPage}</small></Breadcrumb.Item>

        </Breadcrumb>
    );
}

export default BreadCrumbs;