import React, { useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { InputGroup } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';
import { AiOutlineExport } from "react-icons/ai";
import useClient from '../../hooks/useClient';
import { Link } from 'react-router-dom';
import useProduct from '../../hooks/useProduct';
import useUtilities from '../../hooks/useUtilities';
import ItemShopping from './ItemShopping';
import { useMutation, useQueryClient } from 'react-query';
import useShopping from '../../hooks/useShopping';
import FormProductItem from './FormProductItem';
import FormServiceItem from './FormServiceItem';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';
import BreadCrumbs from "../../utils/BreadCrumbs";
import HistoryOfShoppingsSimple from './HistoryOfShoppingsSimple';

function FormAddShopping() {
    const [telephoneClient, setTelephoneClient] = useState();
    const [invoice, setInvoice] = useState();
    const [dateShop, setDateShop] = useState();
    const [noteShop, setNoteShop] = useState();
    const { formatDate, formatDateCl } = useUtilities();
    const { showForRun, filterClientByTelephone, codeApiFilterClientForTelephone, filterClientFromRunAPI, setFilterClientFromTelephoneAPI, filterClientFromTelephoneAPI } = useClient();
    const [validated, setValidated] = useState(false);
    const { showForSku } = useProduct();
    const { store, index, show: showAllShopping, show: showShoppingForInvoice, destroy: destroyShoppingForId, storeItem, filterShoppingFromAPI } = useShopping();
    const [messageErrorSaveNewShopping, setMessageErrorSaveNewShopping] = useState(false);
    const [disableMessageErrorFilterTelephone, setDisableMessageErrorFilterTelephone] = useState(true);
    const [disableBtnNewShop, setDisableBtnNewShop] = useState(false);
    const [disableAlertSuccessNewShop, setDisableAlertSuccessNewShop] = useState(false);
    const [disableAlertSuccessCompleteNewShop, setDisableAlertSuccessCompleteNewShop] = useState(false);
    const [disableAlertSuccessDestroyShop, setDisableAlertSuccessDestroyShop] = useState(false);
    const [disabledBtnClientFilterForTelephone, setDisabledBtnClientFilterForTelephone] = useState(false);
    const [disabledInputClientFilterTelephone, setDisabledInputClientFilterTelephone] = useState(false);
    const [disabledInputInvoiceShopping, setDisabledInputInvoiceShopping] = useState(false);
    const [disabledInputDateShopping, setDisabledInputDateShopping] = useState(false);
    const [disabledInputNoteShopping, setDisabledInputNoteShopping] = useState(false);
    const [disableAlertErrorNewShop, setDisableAlertErrorNewShop] = useState(false);
    const [disabledInputFilterTelephone, setDisabledInputFilterTelephone] = useState(false);
    const [activeFilterClient, setActiveFilterClient] = useState(false);
    const { addedItem, setAddedItem, idShoppingSaved, setIdShoppingSaved, totalClientsGlobal, setPurchaseCompleted, purchaseCompleted, userId, globalClientIdParam, globalClientTelephoneParam } = useContext(UserLoggedContext);
    const queryClient = useQueryClient();
    let path_test = "gestion-de-cliente/detalle/" + globalClientIdParam + "/" + globalClientTelephoneParam;

    console.log(filterClientFromTelephoneAPI);

    //I neeed to evaluate because possible will replace.
    const handleFormatDate = (date) => {
        console.log(date);
        let newDateShop = formatDateCl(date);
        setDateShop(newDateShop);
    }

    const { mutate: mutateNewShopping, isLoading: isLoadingMutationNewShopping, error: errorNewShopping } = useMutation({
        mutationFn: store,
        onSuccess: () => {
            successNewShopping()
            queryClient.invalidateQueries({ queryKey: ['shoppings'] });
        },
        onError: () => {
            errorSuccessNewShopping()
        }
    });

    const { mutate: mutateNewItemForShopping, isLoading: isLoadingMutation, error: errorNewItemForShopping } = useMutation({
        mutationFn: storeItem,
        onSuccess: () => {
            //successNewItem()
        },
        onError: () => {
            //errorSuccessNewItem()
        }
    });

    const { mutate: mutateDestroyShoppingForId, isLoading: isLoadingMutationDestroyShoppingForId, error: errorDestroyShoppingForId } = useMutation({
        mutationFn: destroyShoppingForId,
        onSuccess: () => {
            successDestroyShoppingForId()
            queryClient.invalidateQueries({ queryKey: ['shoppings'] })
        },
        onError: () => {
            //errorSuccessNewShopping()
        }
    });

    const successNewShopping = () => {
        setDisableAlertSuccessNewShop(true);
        setValidated(false);
        setTimeout(() => {
            setDisableAlertSuccessNewShop(false);
        }, 2000);
    }

    const successDestroyShoppingForId = () => {
        setDisableAlertSuccessDestroyShop(true);
        setTimeout(() => {
            setDisableAlertSuccessDestroyShop(false);
        }, 3000);
    }

    const errorSuccessNewShopping = () => {
        setDisableAlertErrorNewShop(true);
        setValidated(false);
        setTimeout(() => {
            setDisableAlertErrorNewShop(false);
        }, 3000);
    }

    const handleSubmitCompleteShopping = (e) => {
        e.preventDefault();
        setDisabledInputFilterTelephone(false);
        setFilterClientFromTelephoneAPI([]);
        setDisableBtnNewShop(false);
        setIdShoppingSaved(0);
        setTelephoneClient('');
        setInvoice('');
        setNoteShop('');
        setDateShop('');
        setDisabledBtnClientFilterForTelephone(false);
        setDisableAlertSuccessCompleteNewShop(true);
        setTimeout(() => {
            setDisableAlertSuccessCompleteNewShop(false);
            setAddedItem(false);
            setPurchaseCompleted(true);
        }, 2000);
    };

    const handleSearchClientForTelephone = () => {
        filterClientByTelephone(telephoneClient);
    }

    const handleSubmitNewShop = (e) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }

        setValidated(true);

        if (form.checkValidity() === true) {
            e.preventDefault();
            console.log("ok");

            filterClientFromTelephoneAPI.map((c, index) => (
                <div key={index}>
                    {mutateNewShopping({
                        run_rut: null,
                        client_id: c.id,
                        user_id: userId,
                        client_telephone: c.telephone,
                        state_shopping: 0,
                        invoice: invoice,
                        date_shop: dateShop,//fix
                        note_shop: noteShop,
                    })}
                </div>
            ));

            setDisableBtnNewShop(true);
            //setDisabledInputFilterTelephone(true);
            //setDisabledBtnClientFilterForTelephone(true);
        }
    };

    return (
        <>
            {!totalClientsGlobal ? (
                <Alert variant="warning">
                    <small>Debe ingresar un cliente antes de ingresar una compra. <Link to={'/ayuda'} className='nav-link'>Ver ayuda</Link></small>
                </Alert>
            ) : (null)}
            <Card>
                <Card.Header>Información de la compra</Card.Header>
                <Card.Body>
                    <Card.Text>
                        <Form noValidate validated={validated} onSubmit={handleSubmitNewShop}>
                            {globalClientTelephoneParam ? (
                                <>
                                    <Row>
                                        <Col xs={2}>
                                            <small>
                                                <Badge bg="primary">Teléfono sugerido: {globalClientTelephoneParam}</Badge>
                                            </small>
                                        </Col>
                                    </Row>
                                </>) : (null)}
                            <br></br>
                            <Row>
                                <Col xs={6}>
                                    <Form.Group>
                                        <Form.Label>Buscar cliente por teléfono</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder="56912345678"
                                                name='inputTelephone'
                                                type='number'
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                                value={telephoneClient}
                                                onChange={(e) => { setTelephoneClient(e.target.value) }}
                                                disabled={disabledInputFilterTelephone}
                                            />
                                            <Button variant="outline-primary" id="button-addon2" onClick={() => { handleSearchClientForTelephone() }} disabled={!telephoneClient || disabledBtnClientFilterForTelephone}>
                                                Filtrar cliente
                                            </Button>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Label>Factura o Boleta asociada</Form.Label>
                                    <Form.Control type="number" name="envoice" value={invoice} placeholder="0" onChange={(e) => { setInvoice(e.target.value) }} disabled={disabledInputInvoiceShopping} />
                                </Col>
                            </Row>

                            {codeApiFilterClientForTelephone === 200 && filterClientFromTelephoneAPI.length > 0 ? (
                                <>
                                    <br></br>
                                    {
                                        filterClientFromTelephoneAPI.map((client) =>
                                        (
                                            <Row key={client.id}>
                                                <Col xs={12}>
                                                    <small>La compra será asignada al cliente&nbsp;<b>{client.first_name} {client.last_name}</b></small> &nbsp;
                                                    <Link to={`/gestion-de-cliente/detalle/${client.id}/${client.telephone}`} target="_blank"><AiOutlineExport /></Link>
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                </>
                            ) : (null)}

                            {codeApiFilterClientForTelephone === 204 ? (
                                <Alert key='danger' variant='danger' show={disableMessageErrorFilterTelephone}>
                                    <small>El cliente con teléfono ingresado no existe.</small>
                                </Alert>
                            ) : (null)}
                            <br></br>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label>Fecha de ingreso</Form.Label>
                                    <Form.Control type="date" name="dateShop" placeholder="0" onChange={(e) => { handleFormatDate(e.target.value) }} disabled={disabledInputDateShopping} required />
                                    <Form.Control.Feedback type="invalid">Ingrese fecha.</Form.Control.Feedback>
                                    {/*<Form.Control type="date" name="dateShop" placeholder="0" onChange={(e) => { handleFormatDate(e.target.value) }} disabled={disabledInputDateShopping} />*/}
                                </Col>
                                <Col xs={8}>
                                    <Form.Label>Observaciones</Form.Label>
                                    <Form.Control as="textarea" name="noteShop" value={noteShop} rows={3} placeholder="Factura se agregará después." onChange={(e) => { setNoteShop(e.target.value) }} disabled={disabledInputNoteShopping} />
                                </Col>
                            </Row>
                            <br></br>
                            <Alert variant="success" show={disableAlertSuccessNewShop}>
                                <small>Compra ingresada con éxito.</small>
                            </Alert>
                            <Alert variant="danger" show={disableAlertErrorNewShop}>
                                <small>Error al ingresar la Compra, inténtelo más tarde.</small>
                            </Alert>
                            <Alert variant="danger" show={messageErrorSaveNewShopping}>
                                <small>Debe ingresar un Teléfono y/o RUT/RUN asociado al cliente.</small>
                            </Alert>
                            <Alert variant="warning" show={disableAlertSuccessDestroyShop}>
                                <small>Compra eliminada con éxito.</small>
                            </Alert>
                            <Row>
                                <Col>
                                    <Button variant="primary" type="submit" disabled={codeApiFilterClientForTelephone === 204 || disableBtnNewShop === true}>
                                        {isLoadingMutationNewShopping ? 'Guardando...' : 'Guardar'}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Text>
                </Card.Body>
            </Card>
            <br></br>
            <Card>
                <Card.Header>Ítems de la compra</Card.Header>
                <Card.Body>
                    <Card.Text>
                        {idShoppingSaved > 0 ? (
                            <Row>
                                <Col>
                                    <Alert key='info' variant='info' show={true}>
                                        <small>
                                            Los ítems serán añadidos a la compra con Id&nbsp;
                                            <Badge bg="success">
                                                {idShoppingSaved}
                                            </Badge>
                                        </small>
                                    </Alert>
                                </Col>
                            </Row>
                        ) : (null)}
                        <br></br>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <Card.Header>Ingreso de equipo</Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            <Row>
                                                <Col xs={12}>
                                                    <FormProductItem />
                                                </Col>
                                            </Row>
                                            <Row className="justify-content-md-center">
                                                <Col xs lg={2}>
                                                </Col>
                                                <Col md="auto">
                                                    Ingresar nuevo equipo&nbsp;<Link to={`/gestion-de-equipo/`} target="_blank"><AiOutlineExport /></Link>
                                                </Col>
                                                <Col md="auto">
                                                    Lista de equipos&nbsp;<Link to={`/gestion-de-equipo/`} target="_blank"><AiOutlineExport /></Link>
                                                </Col>
                                                <Col xs lg={2}>
                                                </Col>
                                            </Row>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <Card.Header>Ingreso de servicio</Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            <Row>
                                                <Col xs={12}>
                                                    <FormServiceItem />
                                                </Col>
                                            </Row>
                                            <Row className="justify-content-md-center">
                                                <Col xs lg={2}>
                                                </Col>
                                                <Col md="auto">
                                                    Ingresar nuevo servicio&nbsp;<Link to={`/gestion-de-servicio/`} target="_blank"><AiOutlineExport /></Link>
                                                </Col>
                                                <Col md="auto">
                                                    Listar servicios&nbsp;<Link to={`/gestion-de-servicio/`} target="_blank"><AiOutlineExport /></Link>
                                                </Col>
                                                <Col xs lg={2}>
                                                </Col>
                                            </Row>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <Card.Header>Lista equipos comprados</Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            <Row>
                                                <Col xs={12}>
                                                    {filterClientFromTelephoneAPI ? (
                                                        <>
                                                            {
                                                                filterClientFromTelephoneAPI.map((client, index) =>
                                                                (
                                                                    <div key={index}>
                                                                        {client.total_shopping > 0 ? (
                                                                            <HistoryOfShoppingsSimple
                                                                                paramClientTelephone={client.telephone}
                                                                            />

                                                                        ) : (<>
                                                                            <Alert key='warning' variant='warning'>
                                                                                <small>Cliente sin compra de equipos.</small>
                                                                            </Alert>
                                                                        </>)}
                                                                    </div>
                                                                ))
                                                            }
                                                        </>
                                                    ) : (null)}

                                                    {/*
                                                    {codeApiFilterClientForTelephone === 200 && filterClientFromTelephoneAPI.length > 0 ? (
                                                        <>
                                                            {
                                                                filterClientFromTelephoneAPI.map((client, index) =>
                                                                (
                                                                    <div key={index}>
                                                                        {client.total_shopping > 0 ? (
                                                                            <HistoryOfShoppingsSimple
                                                                                paramClientTelephone={client.telephone}
                                                                            />
                                                                        ) : (<><small>Cliente sin compra de equipos.</small></>)}
                                                                    </div>
                                                                ))
                                                            }
                                                        </>
                                                    ) : (null)}
                                                    */}
                                                </Col>
                                            </Row>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br></br>
                        <>
                            {addedItem ? (
                                <>
                                    <Row>
                                        <Col>
                                            <ItemShopping
                                                idShoppingSaved={idShoppingSaved}
                                            />
                                        </Col>
                                    </Row>
                                    <br></br>
                                </>
                            ) : (
                                <>
                                    <Row>
                                        <Col>
                                            <Card>
                                                <Card.Header>Ítems añadidos</Card.Header>
                                                <Card.Body>
                                                    <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                                                    <Card.Text>
                                                        <Alert key='warning' variant='warning'>
                                                            <small>Sin ítems ingresados.</small >
                                                        </Alert >
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <br></br>
                                </>
                            )}
                            <Row>
                                <Col>
                                    <Alert variant="success" show={disableAlertSuccessCompleteNewShop}>
                                        <small>Compra finalizada con éxito.</small>
                                    </Alert>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col>
                                    <Button variant="primary" onClick={(e) => { handleSubmitCompleteShopping(e) }} disabled={purchaseCompleted}>
                                        Finalizar compra
                                    </Button>
                                </Col>
                            </Row>
                        </>
                        <br></br>
                    </Card.Text>
                </Card.Body>
            </Card>
            <br></br>
        </>
    );
}

export default FormAddShopping;