import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';
import { Card } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineFileSearch } from "react-icons/ai";
import useOdt from "../../hooks/useOdt";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import ClientFilter from "../client/ClientFilter";
import OdtFilterOptional from "./OdtFilterOptional";

function OdtListAssigned() {
    const { index, destroy, totalOdts, deleteAll, filterOdtFromAPI, showModal, lastPageFromAPI, setLastPageFromAPI, filterOdtByTechnicialAsig } = useOdt();
    const [loading, setLoading] = useState(true);
    const [showDelete, setShowDelete] = useState(false);
    const [showDeleteAllOdts, setShowDeleteAllOdts] = useState(false);
    const [desableDeleteAllBtn, setDesableDeleteAllBtn] = useState(false);
    const queryClient = useQueryClient();
    const [odtForDelete, setOdtForDelete] = useState(0);
    const [pageNumber, setPageNumer] = useState(1);
    const { userId } = useContext(UserLoggedContext);

    /*
    const { isLoading, data: odts, isError: isErrorDataFetchingAllClients } = useQuery(
        ['odts', pageNumber],
        () => index(pageNumber));
        */

    const { isLoading, data: odts, isError: isErrorDataFetchingAllClients } = useQuery(
        ['odts', userId],
        () => filterOdtByTechnicialAsig(userId));

    const { data: totalOdtsAPI } = useQuery(
        ['totalOdtsAPI'],
        () => totalOdts());

    useEffect(() => {
        totalOdts();
        index();
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    if (isLoading) return (
        <>
            <Row>
                <Col>
                    <br></br>
                    <Spinner animation="border" variant="secondary" />
                </Col>
            </Row>
        </>
    );

    if (isErrorDataFetchingAllClients) return (
        <>
            <Row>
                <Col>
                    <Alert key='danger' variant='danger'>
                        Hay un error. Por favor intente más tarde.
                    </Alert>
                </Col>
            </Row>
        </>
    );

    return (
        <>
            {odts ? (
                <>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col>
                                    Filtro de ODTs
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <OdtFilterOptional />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <br></br>
                    <br></br>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={{ order: 12 }}>ODT(Orden de trabajo) asignadas</Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    {totalOdtsAPI ? (
                                        <Col>
                                            <small>Total odts&nbsp;</small><Badge bg="info">{totalOdtsAPI['total']}</Badge>
                                        </Col>
                                    ) : (null)}
                                </Row>
                                <Row>
                                    <Col>
                                        <br></br>
                                        <Table striped bordered hover responsive>
                                            <thead>
                                                <tr>
                                                    <th><small>Id ODT</small></th>
                                                    <th><small>Fecha del servicio</small></th>
                                                    <th><small>Hora del servicio</small></th>
                                                    <th><small>Cliente</small></th>
                                                    <th><small>Supervisor</small></th>
                                                    <th><small>Técnico</small></th>
                                                    <th><small>Notificación</small></th>
                                                    <th><small>Estado</small></th>
                                                    <th><small>Procesar</small></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {odts.map((odt) =>
                                                (
                                                    <tr key={odt.id_odt}>
                                                        <td>
                                                            <small>{odt.odt}</small>
                                                        </td>
                                                        <td>
                                                            <small>{odt.visiting_date}</small>
                                                        </td>
                                                        <td>
                                                            <small>{odt.visiting_hour}</small>
                                                        </td>
                                                        <td>
                                                            <small>{odt.client_first_name} {odt.client_last_name} ({odt.client_telephone})</small>
                                                        </td>
                                                        <td>
                                                            <small>{odt.sup_name}</small>
                                                        </td>
                                                        <td>
                                                            <small>{odt.tech_name}</small>
                                                        </td>
                                                        {odt.notification_status == 0 ? (
                                                            <td>
                                                                <small>
                                                                    <Badge bg="danger">No enviada</Badge>
                                                                </small>
                                                            </td>
                                                        ) : (null)}
                                                        {odt.notification_status == 1 ? (
                                                            <td>
                                                                <small>
                                                                    <Badge bg="success">Enviada</Badge>
                                                                </small>
                                                            </td>
                                                        ) : (null)}
                                                        {odt.state_odt === "0" ? (
                                                            <td>
                                                                <small>
                                                                    <Badge bg="danger">Nueva</Badge>
                                                                </small>
                                                            </td>
                                                        ) : (null)}
                                                        {odt.state_odt === "1" ? (
                                                            <td>
                                                                <small>
                                                                    <Badge bg="warning">En proceso</Badge>
                                                                </small>
                                                            </td>
                                                        ) : (null)}
                                                        {odt.state_odt === "2" ? (
                                                            <td>

                                                                <small>
                                                                    <Badge bg="success">Cerrada</Badge>
                                                                </small>
                                                            </td>
                                                        ) : (null)}

                                                        <td>
                                                            <Link to={`/gestion-de-odt/detalle/${odt.odt}/${odt.client_telephone}`} className='nav-link'><AiOutlineCheckCircle /></Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button onClick={() => setPageNumer((page) => page - 1)} disabled={pageNumber === 1} variant="outline-primary">Volver</Button>{' '}
                                        <Button onClick={() => setPageNumer((page) => page + 1)} disabled={pageNumber === lastPageFromAPI} variant="outline-primary">Siguiente</Button>{' '}
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </>
            ) : (
                <Row>
                    <Col>
                        <Alert key='warning' variant='warning'>
                            <small>Sin odts asignadas aún.</small>
                        </Alert>
                    </Col>
                </Row>
            )}

            {filterOdtFromAPI ? (
                <></>
            ) : (null)}

            <br></br>
            <br></br>
        </>
    )
}

export default OdtListAssigned;

