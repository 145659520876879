import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AiOutlineExport } from "react-icons/ai";
import { AiOutlineFileAdd } from "react-icons/ai";
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import useClient from "../../hooks/useClient";
import useShopping from "../../hooks/useShopping";
import useProduct from "../../hooks/useProduct";
import Alert from 'react-bootstrap/Alert';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Row } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import BreadCrumbs from "../../utils/BreadCrumbs";
import useService from "../../hooks/useService";

function Service() {
    let serviceIdParam = useParams().service_id;
    //const { show, showForSku } = useProduct();
    const { show } = useService();

    const { isLoading: isLoadingServiceFiltered, data: service_filtered, isError: isErrorServiceFiltered } = useQuery(
        ["service_filtered", serviceIdParam],
        () => show(serviceIdParam)
    );

    if (isLoadingServiceFiltered) return (
        <>
            <Row>
                <Col>
                    <br></br>
                    <Spinner animation="border" variant="secondary" />
                </Col>
            </Row>
        </>
    );

    if (isErrorServiceFiltered) return (
        <>
            <Row>
                <Col>
                    <Alert key='danger' variant='danger'>
                        <small>Hay un error. Por favor intente más tarde.</small>
                    </Alert>
                </Col>
            </Row>
        </>
    );

    return (
        <>
            {service_filtered ? (
                <>
                    <br></br>
                    <Row>
                        <Col>
                            <BreadCrumbs
                                paramSecondPage="Gestión de servicio"
                                paramSecondPageLink="gestion-de-servicio"
                                paramCurrentPage="Detalle del servicio"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs lg="5">
                        </Col>
                        <Col md="auto">
                            <h5>Detalle del servicio</h5><br></br>
                            {service_filtered.map((service, index) =>
                                <small key={index}><Badge bg="secondary">Servicio filtrado: {service.sku}</Badge></small>
                            )}
                        </Col>
                        <Col xs lg="5">
                        </Col>
                    </Row>
                    <br></br>
                    {service_filtered.map((service, index) =>
                        <>
                            <Card key={index}>
                                <Card.Header>Información del servicio</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <Row>
                                        <Col xs={6}>
                                                <small>
                                                    <b>Nombre servicio:</b>
                                                    &nbsp;{service.name}
                                                </small>
                                            </Col>
                                            <Col xs={2}>
                                                <small>
                                                    <b>SKU:</b>
                                                    &nbsp;{service.sku}
                                                </small>
                                            </Col>
                                            <Col xs={2}>
                                                <small>
                                                    <b>HH estimada:</b>
                                                    &nbsp;{service.hh}
                                                </small>
                                            </Col>
                                        </Row>
                                        <br></br>
                                        <Row>
                                            <Col xs={12}>
                                                <small>
                                                    <b>Observaciones:</b>
                                                    &nbsp;{service.note}
                                                </small>
                                            </Col>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </>
                    )}
                    <br></br>
                    <Row className="justify-content-md-center">
                        <Col>
                            <Button variant="outline-primary" href={'/gestion-de-servicio'}>
                                Volver
                            </Button>
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <br></br>
                    <Row>
                        <Col>
                            <Alert key='danger' variant='danger'>
                                <small>No existe el servicio con id filtrado.</small> <Link to={'/gestion-de-servicio'} className='nav-link'><b><small>Volver a servicios.</small></b></Link>
                            </Alert>
                        </Col>
                    </Row>
                </>
            )
            }
            <br></br>
            <br></br>
        </>
    );


}
export default Service;