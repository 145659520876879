import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import Alert from 'react-bootstrap/Alert';
import { Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import useProduct from "../../hooks/useProduct";
import useUser from "../../hooks/useUser";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";

function UserFilter() {
    const queryClient = useQueryClient();
    const [userToFilter, setUserToFilter] = useState("");
    const [messageSuccessUserDelete, setMessageSuccessUserDelete] = useState(false);
    const [messageErrorUserDelete, setMessageErrorUserDelete] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    //const [show, setShow] = useState(false);
    const [userForDelete, setUserForDelete] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    const [desableDeleteBtn, setDesableDeleteBtn] = useState(false);
    const { show, destroy, filterUserFromAPI, showModal, filterUserByTelephone, codeApiFilterUserForTelephone, filterUserFromTelephoneAPI, setFilterUserFromTelephoneAPI } = useUser();

    const deleteUserMutation = useMutation({
        mutationFn: destroy,
        onSuccess: () => {
            successUserDelete();
            queryClient.invalidateQueries(['users']);
        },
        onError: () => {
            errorUserDelete();
        }
    });

    const handleDeleteUser = () => {
        deleteUserMutation.mutate(userForDelete);
    }

    const successUserDelete = () => {
        setMessageSuccessUserDelete(true);
        setDesableDeleteBtn(true);
        setUserToFilter("");
        setTimeout(() => {
            setMessageSuccessUserDelete(false);
            setDesableDeleteBtn(false);
            setFilterUserFromTelephoneAPI([]);
            setShowDelete(false);
        }, 2000);
    }

    const errorUserDelete = () => {
        setMessageErrorUserDelete(true);
        setTimeout(() => {
            setMessageErrorUserDelete(false);
        }, 2000);
    }

    const handleFilterUser = (e) => {
        e.preventDefault();
        filterUserByTelephone(userToFilter);
    }

    const showPopupDelete = (id) => {
        setUserForDelete(id);
        showModal(id);
        setShowDelete(true);
    }

    const deleteFilteredUser = () => {
        setUserToFilter("");
        setFilterUserFromTelephoneAPI([]);
    }

    return (
        <>
            <Row>
                <Col>
                    <Form onSubmit={handleFilterUser}>
                        <InputGroup className="mb-1">
                            <Form.Control
                                name="userToFilter"
                                type="string"
                                value={userToFilter}
                                onChange={(e) => { setUserToFilter(e.target.value) }}
                                placeholder="56987654321"
                                aria-label="user"
                                aria-describedby="basic-addon2"
                            />
                            <Button type="submit" variant="outline-primary" id="button-addon2" disabled={!userToFilter}>
                                Filtrar usuario
                            </Button>
                        </InputGroup>
                    </Form>
                </Col>
            </Row>

            {codeApiFilterUserForTelephone === 200 && filterUserFromTelephoneAPI.length > 0 ? (
                <>
                    <br></br>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={12} md={11}>
                                    Usuario filtrado
                                </Col>
                                <Col xs={12} md={1}>
                                    <Button variant="outline-danger" onClick={deleteFilteredUser} size="sm">Limpiar</Button>{' '}                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <br></br>
                                <Row>
                                    <Col>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th><small>Cuenta</small></th>
                                                    <th><small>Nombre y apellido</small></th>
                                                    <th><small>Teléfono</small></th>
                                                    <th><small>Rol</small></th>
                                                    <th><small>Eliminar</small></th>
                                                    <th><small>Editar</small></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filterUserFromTelephoneAPI.map((user, index) =>
                                                (
                                                    <tr key={index}>
                                                        <td>
                                                            <small>
                                                                <Link className='nav-link nav-link-client' to={`/gestion-de-usuario/detalle/${user.id}`}>{user.email}</Link>
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <small>{user.name}</small>
                                                        </td>
                                                        <td>
                                                            <small>
                                                                {user.telephone}
                                                            </small>
                                                        </td>
                                                        <td>
                                                            {user.role === '1' ? (
                                                                <>Administrador</>
                                                            ) : (null)}
                                                            {user.role === '2' ? (
                                                                <>Supervisor</>
                                                            ) : (null)}
                                                            {user.role === '3' ? (
                                                                <>Técnico</>
                                                            ) : (null)}
                                                        </td>
                                                        <td>
                                                            <Link onClick={() => { showPopupDelete(user.id) }} className='nav-link'><AiOutlineDelete /></Link>
                                                        </td>
                                                        <td>
                                                            <Link to={`/gestion-de-usuario/editar/${user.id}`} className='nav-link'><AiOutlineEdit /></Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </>
            ) : (null)}

            {codeApiFilterUserForTelephone === 204 ? (
                <>
                    <br></br>
                    <Alert key='danger' variant='danger'>
                        <small>El usuario con el id ingresado no existe.</small>
                    </Alert>
                </>
            ) : (null)}
            {filterUserFromAPI ? (
                <Modal show={showDelete} onHide={handleCloseDelete} >
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminación del usuario</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                ¿Quiere eliminar a&nbsp;

                                {filterUserFromAPI.map((u, index) =>
                                (
                                    <small key={index}>
                                        <b>{u.name}</b>
                                    </small>
                                ))
                                }?

                            </Col>
                        </Row>
                        <br></br>
                        <Alert variant="success" show={messageSuccessUserDelete}>
                            <small>Usuario eliminado con éxito.</small>
                        </Alert>
                        <Alert variant="danger" show={messageErrorUserDelete}>
                            <small>Error al eliminar usuario, inténtelo más tarde.</small>
                        </Alert>
                        <br></br>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDelete}>
                            Cerrar
                        </Button>
                        <Button variant="primary" onClick={handleDeleteUser} disabled={desableDeleteBtn}>
                            Si, eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : (null)}

        </>
    );

}
export default UserFilter;