import React, { useState, useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { v4 as uiidv4 } from 'uuid';
import { InputGroup } from 'react-bootstrap';
import useProduct from '../../hooks/useProduct';
import useShopping from '../../hooks/useShopping';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import AutoFillProductInput from '../product/AutoFillProductInput';

function FormProductItem() {
    const [productItem, setProductItem] = useState('');
    const [cantItem, setCantItem] = useState(1);
    const [noteItem, setNoteItem] = useState('');
    const [disabledInputSku, setDisabledInputSku] = useState(false);
    const [disabledBtnSkuFilter, setDisabledBtnSkuFilter] = useState(false);
    const [disabledInputCant, setDisabledInputCant] = useState(false);
    const [disabledInputNoteItem, setDisabledInputNoteItem] = useState(false);
    const { showForSku } = useProduct();
    //const { items, idShoppingSaved, setAddedItem, setPurchaseCompleted, codeApiFilterProduct, setCodeApiFilterProduct, filterProductFromAPI } = useContext(UserLoggedContext);
    const { items, idShoppingSaved, setAddedItem, setPurchaseCompleted, setGlobalCodeApiFilterProduct, globalCodeApiFilterProduct, globalFilterProductFromAPI } = useContext(UserLoggedContext);
    const [disableAlertSuccessNewItemAded, setDisabledAlertSuccessNewItemAded] = useState(false);
    const { storeItem } = useShopping();
    const queryClient = useQueryClient();
    const [productIdFiltered, setProductIdFiltered] = useState();

    const { mutate: mutateNewShoppingItem, isLoading: isLoadingMutationNewShopping, error: errorNewShopping } = useMutation({
        mutationFn: storeItem,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['shoppings'] });
            queryClient.invalidateQueries({ queryKey: ['itemsShopping'] });
            queryClient.invalidateQueries({ queryKey: ['purchased_equipments'] });
        }
    });

    useEffect(() => {
    }, []);

    const handleFilterProduct = () => {
        showForSku(productItem);
    }

    const handleAddItem = (e) => {
        e.preventDefault();
        globalFilterProductFromAPI.map((product) => (
            mutateNewShoppingItem({
                shopping: idShoppingSaved,
                state: 0,
                product: product.id,
                service: 0,
                cant: cantItem,
                note: noteItem,
            })
        ));

        setAddedItem(true);
        setProductItem('');
        setCantItem(1);
        setNoteItem('');
        setDisabledAlertSuccessNewItemAded(true);
        setDisabledAlertSuccessNewItemAded(true);
        setPurchaseCompleted(false);
        setTimeout(() => {
            //setCodeApiFilterProduct(0);
            setGlobalCodeApiFilterProduct(0);
        }, 2000);

        setTimeout(() => {
            setDisabledAlertSuccessNewItemAded(false);
        }, 2000);
    }

    return (
        <>
            <Form>
                <Row>
                    <Col xs={12}>
                        <AutoFillProductInput />
                    </Col>
                </Row>
                <br></br>
                <Row>
                    <Col xs={2}>
                        <Form.Label>Cantidad</Form.Label>
                        <Form.Control type="number" name="clientLastName" value={cantItem} placeholder="1" onChange={(e) => { setCantItem(e.target.value) }} disabled={disabledInputCant} />
                    </Col>
                    <Col xs={10}>
                        <Form.Label>Observación del ítem</Form.Label>
                        <Form.Control as="textarea" name="inputNote" value={noteItem} rows={3} placeholder="Equipo incluye filtro de fábrica..." onChange={(e) => { setNoteItem(e.target.value) }} disabled={disabledInputNoteItem} />
                    </Col>
                </Row>
                <br></br>
                {globalCodeApiFilterProduct === 200 ? (
                    <>
                        {
                            globalFilterProductFromAPI.map((product, index) =>
                            (
                                <Row key={index}>
                                    <Col>
                                        <small><b>Detalle de ítem: </b><Badge bg="secondary"> {product.sku} - {product.name}</Badge><b>&nbsp;X&nbsp;{cantItem}</b></small>
                                    </Col>
                                </Row>
                            ))
                        }
                    </>
                ) : (null)}
                <br></br>
                <Row>
                    <Col>
                        <Button variant="outline-primary" onClick={handleAddItem} disabled={!globalCodeApiFilterProduct || globalCodeApiFilterProduct === 204}>Añadir equipo</Button>
                    </Col>
                </Row>
                <br></br>
                <Row>
                    <Col>
                        <Alert variant="success" show={disableAlertSuccessNewItemAded}>
                            <small>Ítem añadido.</small>
                        </Alert>
                    </Col>
                </Row>
                <br></br>
            </Form>
        </>
    );
}
export default FormProductItem;