import useClient from "../hooks/useClient";
import React, { useState, useEffect, createContext } from "react";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import useOdt from "../hooks/useOdt";

export const UserLoggedContext = createContext();

export function UserLoggedProvider({ children }) {
    const [token, setToken] = useState();
    const [userLogged, setUserLogged] = useState();
    const [imagesOdt, setImagesOdt] = useState([]);
    const [userRole, setUserRole] = useState();
    const [userId, setUserId] = useState(0);
    const [codeLogin, setCodeLogin] = useState(0);
    const [codeDeleteClient, setCodeDeleteClient] = useState(0);
    const [clients, setClients] = useState([]);
    const [users, setUsers] = useState([]);
    const [products, setProducts] = useState([]);
    const [filterClientForDelete, setFilterClientForDelete] = useState([]);
    const [items, setItems] = useState([]);
    const [idShoppingSaved, setIdShoppingSaved] = useState(0);
    const [addedItem, setAddedItem] = useState(false);
    const [pendingShopping, setPendingShopping] = useState(0);
    const [countNotification, setCountNotification] = useState(0);
    const [filterShoppingFromAPI, setFilterShoppingFromAPI] = useState([]);
    const [idOdtSaved, setIdOdtSaved] = useState(0);
    const [purchaseCompleted, setPurchaseCompleted] = useState(true);
    let cantNotifications = 0;
    const { totalClients } = useClient();
    //const { totalOdts } = useOdt();
    /*states for new odt*/
    const [filterClientPhoneNewOdt, setFilterClientPhoneNewOdt] = useState();
    /*states for new odt*/
    const [resetDataGrid, setResetDataGrid] = useState(false);
    //let clientIdParam = useParams().client_id;
    //let clientTelephoneParam = useParams().client_telephone;
    const [globalClientIdParam, setGlobalClientIdParam] = useState("");
    const [globalClientTelephoneParam, setGlobalClientTelephoneParam] = useState("");
    const [globalCodeApiFilterProduct, setGlobalCodeApiFilterProduct] = useState(0);
    const [codeApiFilterService, setCodeApiFilterService] = useState(0);
    const [globalFilterProductFromAPI, setGlobalFilterProductFromAPI] = useState([]);
    const [filterServiceFromAPI, setFilterServiceFromAPI] = useState([]);
    const [globalFilterServiceFromAPI, setGlobalFilterServiceFromAPI] = useState([]);
    const [globalCodeApiFilterService, setGlobalCodeApiFilterService] = useState(0);

    const { data: totalClientsGlobal } = useQuery(
        ['totalClientsGlobal'],
        () => totalClients());
        
    useEffect(() => {
        totalClients();
        handleNotification();
    }, []);

    const handleNotification = () => {
        if (pendingShopping < 1) {
            cantNotifications = 1;
            setCountNotification(cantNotifications);
        } else {
            cantNotifications = 1;
            setCountNotification(cantNotifications);
        }

    }

    return (
        <UserLoggedContext.Provider value={{
            token,
            setToken,
            userLogged,
            setUserLogged,
            userRole,
            setUserRole,
            codeLogin,
            setCodeLogin,
            clients,
            setClients,
            products,
            setProducts,
            users,
            setUsers,
            codeDeleteClient,
            setCodeDeleteClient,
            filterClientForDelete,
            setFilterClientForDelete,
            items,
            setItems,
            idShoppingSaved,
            setIdShoppingSaved,
            pendingShopping,
            setPendingShopping,
            countNotification,
            setFilterShoppingFromAPI,
            filterShoppingFromAPI,
            setIdOdtSaved,
            idOdtSaved,
            totalClientsGlobal,
            purchaseCompleted,
            setPurchaseCompleted,
            setAddedItem,
            addedItem,
            setUserId,
            userId,
            setImagesOdt,
            imagesOdt,
            filterClientPhoneNewOdt, 
            setFilterClientPhoneNewOdt,
            resetDataGrid, 
            setResetDataGrid,
            setGlobalClientIdParam,
            setGlobalClientTelephoneParam,
            globalClientIdParam,
            globalClientTelephoneParam,
            globalFilterProductFromAPI,
            setGlobalFilterProductFromAPI,
            globalCodeApiFilterProduct,
            setGlobalCodeApiFilterProduct,
            setCodeApiFilterService, 
            codeApiFilterService,
            setFilterServiceFromAPI,
            filterServiceFromAPI,
            setGlobalCodeApiFilterService,
            globalCodeApiFilterService,
            setGlobalFilterServiceFromAPI,
            globalFilterServiceFromAPI,
        }}>
            {children}
        </UserLoggedContext.Provider>
    );
}


