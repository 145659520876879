import React, { useState, useContext, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { Calendar, Whisper, Popover, Panel, Badge as BadgeCalendar } from 'rsuite';
import { CustomProvider } from 'rsuite';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Row } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import es_ES from 'rsuite/locales/es_ES';
import { Col } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { AiOutlineFileSearch } from "react-icons/ai";
import { Badge } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import useOdt from '../../hooks/useOdt';
import useUser from '../../hooks/useUser';
import useOdtDetail from '../../hooks/useOdtDetail';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import BreadCrumbs from "../../utils/BreadCrumbs";

function FormUpdateOdt() {
  let idOdtParam = useParams().odt_id;
  const { show: showOdt, showForOdtUpdate, update, updateOdtDate, filterOdtByTechnicial, filterOdtByTechnicialAndDaySelected } = useOdt();
  const { showOdtDetail } = useOdtDetail();
  const { show: showUserForId } = useUser();
  const handleClose = () => setShow(false);
  const [tasks, setTasks] = useState([]);
  const queryClient = useQueryClient();
  const [techAssigned, setTechAssigned] = useState(0);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [hourOdt, setHourOdt] = useState();
  const [dateOdt, setDateOdt] = useState();
  const [customDay, setCustomDay] = useState("");
  const [customMonth, setCustomMonth] = useState("");
  const [customYear, setCustomYear] = useState("");
  const [odtDayFiltered, setOdtDayFiltered] = useState("");
  const [odtHourFiltered, setOdtHourFiltered] = useState("");
  const [daySelected, setDaySelected] = useState("");
  let oldDataTask = [];
  let allOdtsForDay = [];
  const [odtsForDaySelected, setOdtsForDaySelected] = useState([]);
  let dataTask = [];
  //let displayList = 0;
  let date_complete = "";
  const [dateComplete, setDateComplete] = useState("");
  const [dateScheduledToEdit, setDateScheduledToEdit] = useState("");
  const [hourScheduledToEdit, setHourScheduledToEdit] = useState("");
  const [messageSuccessPopupAgenda, setMessageSuccessPopupAgenda] = useState(false);
  let data = [];
  const [odtFilterByTechAndDay, setOdtFilterByTechAndDay] = useState(false);

  useEffect(() => {
  }, []);


  const { isLoading, data: odt_filtered, isError } = useQuery(["odt_filtered", idOdtParam], () => showOdt(idOdtParam), {
    onSuccess: handleOdtDayFiltered,
  });

  const { data: odt_filtered_to_update } = useQuery(["odt_filtered_to_update", idOdtParam], () => showForOdtUpdate(idOdtParam), {
    onSuccess: handleTechAgenda,
  });

  const { isLoading: isLoadingOdtDetail, data: odt_detail_filtered, isError: isErrorOdtDetail } = useQuery(
    ["odt_detail_filtered", idOdtParam],
    () => showOdtDetail(idOdtParam)
  );

  function handleTechAgenda(data) {
    setTechAssigned(data[0]["odt_technical_id"]);
  }

  function handleOdtDayFiltered(data) {
    console.log(data);

    //console.log(data[0]["odt_technical_id"]);
    setOdtDayFiltered(data[0]["visiting_date"]);//visiting_hour
    setOdtHourFiltered(data[0]["visiting_hour"]);
  }

  /*
  const { data: technician_agenda } = useQuery(
    ["technician_agenda", techAssigned],
    () => filterOdtByTechnicial(techAssigned)
  );
*/

  console.log(odtsForDaySelected);
  const hadleOdtsByDaySelected = async (tech_id, odt_day_scheduling) => {
    data = await filterOdtByTechnicialAndDaySelected(tech_id, odt_day_scheduling);
    let codeApi = data.code;
    console.log(codeApi);
    if (codeApi == 200) {
      setOdtFilterByTechAndDay(true);
      const updateData = [data, ...odtsForDaySelected];
      setOdtsForDaySelected(updateData);
    } else if (codeApi == 204) {
      setOdtFilterByTechAndDay(false);
      setOdtsForDaySelected([]);
    }
  }
  console.log(odtsForDaySelected);
  /*
  const { data: technician_agenda } = useQuery(["technician_agenda", techAssigned], () => filterOdtByTechnicial(techAssigned), {
    onSuccess: hadleOdtsByDaySelected(techAssigned, daySelected),
  });
  */

  const { data: technician_agenda } = useQuery(["technician_agenda", techAssigned], () => filterOdtByTechnicial(techAssigned));

  //console.log(techAssigned);

  const { mutate: mutateUpdateOdt, isLoading: isLoadingMutation, error } = useMutation({
    mutationFn: updateOdtDate,
    onSuccess: () => {
      //successUpdateClient()
      queryClient.invalidateQueries({ queryKey: ['technician_agenda'] });
      queryClient.invalidateQueries({ queryKey: ['odt_filtered'] });
      queryClient.invalidateQueries({ queryKey: ['odts'] });
      queryClient.invalidateQueries({ queryKey: ['history_of_odts'] });
    },
    onError: () => {
      //errorSuccessUpdateClient();
    }
  });

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    console.log("ok");
  }

  const handeTechnicalSelectChange = (e) => {
    let techId = e.preventDefault();
    console.log(techId);
  };

  function renderCell(date) {
    const daySelected = customDay + '/' + customMonth + "/" + customYear;
    setDaySelected(daySelected);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    date_complete = day + '/' + month + "/" + year;
    setDateComplete(dateComplete);
    //let displayList = [];

    if (tasks.length) {
      dataTask = tasks.filter(task => task.date_odt === date_complete);
    } else if (technician_agenda) {
      oldDataTask = technician_agenda.filter(task => task.visiting_date === date_complete);
      //displayList = oldDataTask.filter((item, index) => index < 1);
    }

    //nueva cita.
    if (dataTask.length) {
      return (
        <>
          {dataTask.length ? (
            <ul className="calendar-todo-list">
              {dataTask.map((task, index) => (
                <li key={index}>
                  <BadgeCalendar /> <small><b>Visita:</b> {task.hour_odt}</small>
                </li>
              ))}
            </ul>
          ) : (<></>)}
        </>
      )
    }

    //citas que ya están agendadas.
    if (oldDataTask.length) {
      const displayList = oldDataTask.filter((item, index) => index < 3);
      //console.log(displayList);
      const moreCount = oldDataTask.length - displayList.length;
      const moreItem = (
        <li>
          <Whisper
            placement="top"
            trigger="click"
            speaker={
              <Popover>
                <ul className="calendar-todo-list">
                  {oldDataTask.map((oldTask, index) => (
                    oldTask.visiting_date == odtDayFiltered && oldTask.visiting_hour === odtHourFiltered ? (
                      setDateScheduledToEdit(oldTask.visiting_date),
                      setHourScheduledToEdit(oldTask.visiting_hour),
                      <li className="day-selected" key={index}>
                        <BadgeCalendar /> <small><b>{oldTask.visiting_hour}</b> - Visita</small>
                      </li>
                    ) : (
                      <li key={index}>
                        <BadgeCalendar /> <small><b>{oldTask.visiting_hour}</b> - Visita</small>
                      </li>
                    )
                  ))}
                </ul>
              </Popover>
            }
          >
            <a>{moreCount} más</a>
          </Whisper>
        </li>
      );

      return (
        <>
          {oldDataTask.length ? (
            <ul className="calendar-todo-list">
              {oldDataTask.map((oldTask, index) => (
                oldTask.visiting_date == odtDayFiltered && oldTask.visiting_hour === odtHourFiltered ? (
                  setDateScheduledToEdit(oldTask.visiting_date),
                  setHourScheduledToEdit(oldTask.visiting_hour),
                  <li key={index}>
                    <BadgeCalendar /> <Badge bg="warning" text="dark"><b>{oldTask.visiting_hour}</b> - Visita</Badge>
                  </li>
                ) : (
                  <li key={index}>
                    <BadgeCalendar /> <small><b>{oldTask.visiting_hour}</b> - Visita</small>
                  </li>
                )
              ))}
              {moreCount ? moreItem : null}
            </ul>
          ) : (null)}
        </>
      )
    }
    return null;
  }

  const handleDateSelection = (date) => {
    setCustomDay(date.getDate());
    setCustomMonth(date.getMonth() + 1);
    setCustomYear(date.getFullYear());
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const date_complete = day + '/' + month + "/" + year;
    console.log("day selected: " + date_complete);
    //fix
    if (techAssigned != null) {
      hadleOdtsByDaySelected(techAssigned, date_complete);//filter odts according to tech and day selected.
    }
    handleShow();
  };

  console.log(odtsForDaySelected.length);

  const handleSuccessOdtUpdate = () => {
    try {
      console.log(idOdtParam);
      console.log(daySelected);
      console.log(hourOdt);
      mutateUpdateOdt({
        odtId: idOdtParam,
        visiting_date: daySelected,
        visiting_hour: hourOdt,
      });

      setMessageSuccessPopupAgenda(true);
      setTimeout(() => {
        handleClose();
        setMessageSuccessPopupAgenda(false);
      }, 2000);


    } catch (error) {
      console.log("Error: ", error);
    }
  }

  if (isLoading) return (
    <>
      <Row>
        <Col>
          <br></br>
          <Spinner animation="border" variant="secondary" />
        </Col>
      </Row>
    </>
  );

  if (isError) return (
    <>
      <Row>
        <Col>
          <Alert key='danger' variant='danger'>
            <small>Hay un error. Por favor intente más tarde.</small>
          </Alert>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      {odt_filtered ? (
        <>
          <br></br>
          <Row>
            <Col>
              <BreadCrumbs
                paramSecondPage="Gestión de odt"
                paramSecondPageLink="gestion-de-odt"
                paramCurrentPage="Actualizar odt"
              />
            </Col>
          </Row>
          <Row>
            <Col xs lg="5">
            </Col>
            <Col md="auto">
              <h5>Actualizar ODT</h5><br></br>
              {odt_filtered.map((odt, index) =>
                <div key={index}>
                  <Badge bg="secondary"><small>Odt filtrada: {odt.id_odt}</small></Badge>
                </div>
              )}
            </Col>
            <Col xs lg="5">
            </Col>
          </Row>
          <br></br>
          {odt_filtered.map((odt, index) =>
            <>
              <Card key={index}>
                <Card.Header>Información de ODT</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <Row>
                      <Col xs={3}>
                        <small>
                          <b>Id:</b>
                          {odt.id_odt}
                        </small>
                      </Col>
                      <Col xs={3}>
                        <small><b>Estado:&nbsp;</b></small>
                        {odt.state_odt === "0" ? (
                          <small>
                            <Badge bg="danger">Nueva</Badge>
                          </small>
                        ) : (null)}
                        {odt.state_odt === "1" ? (
                          <small>
                            <Badge bg="warning">En proceso</Badge>
                          </small>
                        ) : (null)}
                        {odt.state_odt === "2" ? (
                          <small>
                            <Badge bg="success">Cerrada</Badge>
                          </small>
                        ) : (null)}
                      </Col>
                      <Col xs={3}>
                        <small>
                          <b>Cliente: </b>
                          {odt.client_first_name} {odt.client_last_name}
                        </small>
                      </Col>
                      <Col xs={3}>
                        <small>
                          <b>Teléfono cliente: </b>
                          {odt.client_telephone}
                        </small>
                      </Col>
                    </Row>
                    <br></br>
                    <Row>
                      <Col xs={3}>
                        <small>
                          <b>Supervisor: </b>
                          {odt.supervisor}
                        </small>
                      </Col>
                      <Col xs={3}>
                        <small>
                          <b>Técnico: </b>
                          {odt.technical}
                        </small>
                      </Col>
                      <Col xs={3}>
                        <small>
                          <b>Ingresado por: </b>
                          {odt.admin_user}
                        </small>
                      </Col>
                      <Col xs={3}>
                        <small>
                          <b>Contacto: </b>
                          {odt.contact_name}
                        </small>
                      </Col>
                    </Row>
                    <br></br>
                    <Row>
                      <Col xs={3}>
                        <small>
                          <b>Dirección del servicio: </b>
                          {odt.service_address}&nbsp;<Link href="#" target="_blank"> <AiOutlineFileSearch /></Link>
                        </small>
                      </Col>
                      <Col xs={3}>
                        <small>
                          <b>Fecha del servicio: </b>
                          <Badge bg="secondary">
                            {odt.visiting_date}
                          </Badge>
                        </small>
                      </Col>
                      <Col xs={3}>
                        <small>
                          <b>Hora del servicio: </b>
                          <Badge bg="secondary">
                            {odt.visiting_hour}
                          </Badge>
                        </small>
                      </Col>
                      <Col xs={3}>
                        <small>
                          <b>Teléfono contacto: </b>
                          {odt.contact_telephone}
                        </small>
                      </Col>
                    </Row>
                    <br></br>
                    <Row>
                      <Col xs={3}>
                        <small>
                          <b>Fecha de ingreso: </b>
                          {odt.admision_date}
                        </small>
                      </Col>
                      <Col xs={3}>
                        {odt.notification_status == 0 ? (
                          <small>
                            <b>Notificación: </b>
                            <Badge bg="danger">No enviada</Badge>
                          </small>
                        ) : (null)}
                        {odt.notification_status == 1 ? (
                          <small>
                            <b>Notificación: </b>
                            <Badge bg="success">Enviada</Badge>
                          </small>
                        ) : (null)}
                      </Col>
                    </Row>
                    <br></br>
                    <Row>
                      <Col xs={12}>
                        <small>
                          <b>Observaciones: </b><br></br>
                          {odt.note === "" || odt.note === null ? (
                            <>Sin notas.</>
                          ) : (odt.note)}
                        </small>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </>
          )}
        </>
      ) : (
        <>
          <br></br>
          <Row>
            <Col>
              <Alert key='danger' variant='danger'>
                <small>No existe la ODT con Id filtrado. </small> <Link to={'/gestion-de-odt'} className='nav-link'><small>Volver a Odts.</small></Link>
              </Alert>
            </Col>
          </Row>
        </>
      )}
      <br></br>
      {odt_detail_filtered ? (
        <>
          {odt_detail_filtered.map((odt_detail, index) =>
            <Card key={index}>
              <Card.Header>Desglose de tareas</Card.Header>
              <Card.Body>
                <Card.Text>
                  <Row>
                    <Col>
                      <br></br>
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th><small>#</small></th>
                            <th><small>Compra</small></th>
                            <th><small>Ítem</small></th>
                            <th><small>Cantidad</small></th>
                            <th><small>Nota</small></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <small>
                                {odt_detail.id_odt_detail}
                              </small>
                            </td>
                            <td>
                              <small>
                                {odt_detail.shopping}
                              </small>
                            </td>

                            {odt_detail.product ? (
                              <td>
                                <small>
                                  {odt_detail.product} <b>SKU: </b>{odt_detail.product_sku}
                                </small>
                              </td>
                            ) : (
                              <td>
                                <small>
                                  {odt_detail.service} <b>SKU: </b>{odt_detail.service_sku}
                                </small>
                              </td>
                            )}

                            <td>
                              <small>
                                {odt_detail.cant_item}
                              </small>
                            </td>
                            <td>
                              <small>
                                {odt_detail.note_item === "" || odt_detail.note_item === null ? (
                                  <>Sin notas.</>
                                ) : (odt_detail.note_item)}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          )}
        </>
      ) : (
        <>
          <br></br>
          <Row>
            <Col>
              <Alert key='danger' variant='danger'>
                <small>Existe un error con el detalle de la ODT. </small> <Link to={'/gestion-de-odt'} className='nav-link'><small>Volver a Odts.</small></Link>
              </Alert>
            </Col>
          </Row>
        </>
      )}
      <br></br>
      {odt_filtered_to_update ? (
        <>
          <br></br>
          {odt_filtered_to_update.map((odt_to_update, index) =>
            <>
              <Card>
                <Card.Header>Editar agenda de ODT</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <Form onSubmit={handleSubmit} key={index}>
                      <Row className="mb-2">

                        <Form.Group as={Col} controlId="odt_supervisor">
                          <Form.Label>Supervisor</Form.Label>
                          <Form.Select name="odt_supervisor">
                            <option value={odt_to_update.odt_supervisor}>
                              {odt_to_update.odt_supervisor}
                            </option>
                          </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} controlId="odt_technical">
                          <Form.Label>Técnico</Form.Label>
                          <Form.Select name="odt_technical" onChange={handeTechnicalSelectChange}>
                            <option value={odt_to_update.odt_technical_id}>
                              {odt_to_update.odt_technical}
                            </option>
                          </Form.Select>
                        </Form.Group>

                      </Row>
                      <Row className="mb-12">
                        <Form.Group as={Col} controlId="note">
                          <Form.Label>Observaciones</Form.Label>
                          {odt_to_update.odt_note != null ? (
                            <Form.Control as="textarea" name="note" value={odt_to_update.odt_note} rows={3} />
                          ) : (
                            <Form.Control as="textarea" name="note" value="Sin notas." rows={3} />
                          )}
                        </Form.Group>
                      </Row>
                      <br></br>
                      <Row className='mb-12'>
                        {technician_agenda ? (
                          <>
                            <Col>
                              Agenda del Técnico seleccionado
                            </Col>
                            <br></br>
                            {technician_agenda ? (
                              technician_agenda.map((agenda, index) =>
                                <div key={index}>
                                  <small>Día: {agenda.visiting_date} - Hora: {agenda.visiting_hour}</small>
                                </div>
                              )
                            ) : (null)}
                          </>
                        ) : (
                          <>
                            Agenda del Técnico seleccionado:
                          </>
                        )}
                      </Row>
                      <br></br>
                      <Row className='mb-12'>
                        <Panel>
                          <CustomProvider locale={es_ES}>
                            <Calendar
                              bordered
                              onSelect={(date) => handleDateSelection(date)}
                              renderCell={renderCell}
                            />
                          </CustomProvider>
                        </Panel>
                      </Row>
                      {/*
                      <Alert variant="success" show={messageSuccessUpdateClient}>
                        <small>Cliente actualizado con éxito.</small>
                      </Alert>
                      <Alert variant="danger" show={messageErrorUpdateClient}>
                        <small>Error al actualizar el cliente, inténtelo más tarde.</small>
                      </Alert>
                      */}
                      <br></br>
                      <Row className="justify-content-md-center">
                        <Col xs lg={2}>
                        </Col>
                        <Col md="auto">
                          <Button variant="outline-primary" href={'/gestion-de-odt'}>
                            Volver
                          </Button>
                        </Col>
                        <Col xs lg={2}>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Text>
                </Card.Body>
              </Card>
            </>
          )}
        </>
      ) : (
        <>
          <br></br>
          <Row>
            <Col>
              <Alert key='danger' variant='danger'>
                <small>No existe la ODT con Id filtrado. </small> <Link to={'/gestion-de-odt'} className='nav-link'><small>Volver a Odts.</small></Link>
              </Alert>
            </Col>
          </Row>
        </>
      )}

      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title><h5>{daySelected ? (daySelected) : (null)}</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  <b>Citas del día</b>
                </Col>
              </Row>
              <Row>
                <Col>
                  {/*fix*/}
                  {odtFilterByTechAndDay == true ? (
                    <>
                      {odtsForDaySelected != undefined && odtsForDaySelected.length > 0 ? (
                        <>
                          {odtsForDaySelected[0]['data'].map((odt, index) =>
                            <ul key={index}>
                              <li>
                                <BadgeCalendar /> <small><b>{odt.visiting_hour}</b> - Visita</small>
                              </li>
                            </ul>
                          )}
                        </>
                      ) : (null)}
                    </>
                  ) : (
                    <>
                      <ul>
                        <li>
                          <BadgeCalendar /><small> Sin visitas</small>
                        </li>
                      </ul>
                    </>
                  )}

                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Cita a editar</b>
                </Col>
              </Row>
              <Row>
                <Col>
                  <table>
                    <tr>
                      <td>
                        <Badge bg="warning" text="dark">
                          Día:&nbsp;{dateScheduledToEdit}&nbsp;-&nbsp;Hora:&nbsp;{hourScheduledToEdit}
                        </Badge>
                      </td>
                    </tr>
                  </table>
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Nueva cita</b>
                </Col>
              </Row>
              <Row>
                <Col>
                  <table>
                    <tr>
                      <td>
                        <Badge bg="warning" text="dark">
                          Día:&nbsp;{daySelected}&nbsp;-&nbsp;Hora:&nbsp;{hourOdt ? (hourOdt) : ("Elija una hora")}
                        </Badge>
                      </td>
                    </tr>
                  </table>
                </Col>
              </Row>
              <Row className="mb-1">
                <Form.Group as={Col} controlId="inputProductName">
                  <Form.Label><small>Ingrese nueva hora de cita</small></Form.Label>
                  <Form.Control type="time" name="hourOdt" value={hourOdt} onChange={(e) => setHourOdt(e.target.value)} />
                </Form.Group>
              </Row>
              <Row className="mb-1">
                <Col>
                  <Alert key='success' variant='success' show={messageSuccessPopupAgenda}>
                    <small>Visita reagendada con éxito.</small>
                  </Alert>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={() => handleSuccessOdtUpdate()} disabled={!hourOdt}>
              {isLoadingMutation ? 'Reagendando...' : 'Reagendar'}
            </Button>
          </Modal.Footer>
        </Modal>
      </>

      <br></br>
      <br></br>
    </>
  );

}

export default FormUpdateOdt;