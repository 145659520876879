import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { UserLoggedContext } from '../contexts/UserLoggedContext';

function useUser() {
    const API_URL = process.env.REACT_APP_API_URL;
    const [filterUserForRoleSupervisor, setFilterUserForRoleSupervisor] = useState([]);
    const [filterUserForRoleTech, setFilterUserForRoleTech] = useState([]);
    const [filterUserForRoleAdmin, setFilterUserForRoleAdmin] = useState([]);
    const [filterUserForRole, setFilterUserForRole] = useState([]);
    const [filterUserFromAPI, setFilterUserFromAPI] = useState([]);
    const [codeApiFilterUserForTelephone, setCodeApiFilterUserForTelephone] = useState(0);
    const [filterUserFromTelephoneAPI, setFilterUserFromTelephoneAPI] = useState([]);
    const [lastPageFromAPI, setLastPageFromAPI] = useState(0);
    const [totalDataFromAPI, setTotalDataFromAPI] = useState(0);

    const store = (data) => {
        return axios.post(API_URL + "register", data)
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    };
    
    const index_original = () => {
        return axios.get(API_URL + 'user')
            .then(function (response) {
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const index = ($pageNumber) => {
        return axios.get(API_URL + 'user/?' + 'limit=5' + '&page=' + $pageNumber)
            .then(function (response) {
                console.log(response);
                if (response.data.code != 204) {
                    setTotalDataFromAPI(response.data.data.total);
                    setLastPageFromAPI(response.data.data.last_page);
                }
                return response.data.data.data;
            })
            .catch(function (error) {
            })
            .finally(function () {
            });
    }

    const showModal = (id) => {
        return axios.get(API_URL + 'user-filter' + '/' + id)
            .then(function (response) {
                console.log(response);
                setFilterUserFromAPI(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const show = (id) => {
        return axios.get(API_URL + 'user-filter' + '/' + id)
            .then(function (response) {
                console.log(response.data.data);
                //setFilterClientFromAPI(response.data.message);
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const filterUserByTelephone = (telephone) => {
        return axios.get(API_URL + 'filter_telephone_user' + '/' + telephone)
            .then(function (response) {
                setCodeApiFilterUserForTelephone(response.data.code);
                setFilterUserFromTelephoneAPI(response.data.data);
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const showUserForRole = (id) => {
        return axios.get(API_URL + 'user-role' + '/' + id)
            .then(function (response) {
                //setFilterUserForRole(response.data.data);
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const destroy = (id) => {
        return axios.delete(API_URL + 'user-delete' + '/' + id)
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const update = (data) => {
        return axios.put(API_URL + 'user-update', data)
            .then(function (response) {
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    return {
        filterUserForRoleSupervisor,
        filterUserForRoleAdmin,
        filterUserForRoleTech,
        index,
        filterUserForRole,
        store,
        filterUserFromAPI,
        showModal,
        show,
        destroy,
        showUserForRole,
        update,
        filterUserByTelephone,
        codeApiFilterUserForTelephone,
        filterUserFromTelephoneAPI,
        setFilterUserFromTelephoneAPI,
        lastPageFromAPI,
    }
}

export default useUser;