import React, { useState, useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from "react-router-dom";
import { Row } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import BreadCrumbs from "./BreadCrumbs";

function HelpPage() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    return (
        <>
            {loading ? (
                <div>
                    <br></br>
                    <Spinner animation="border" variant="secondary" />
                </div>
            ) : (
                <>
                    <br></br>
                    <Row>
                        <Col>
                            <BreadCrumbs
                                paramCurrentPage="Ayuda"
                            />
                        </Col>
                    </Row>
                    <h5>Ayuda</h5>
                    <br></br>
                    <Card>
                        <Card.Header>Módulo Dashboard</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    <Col xs={12}>
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Información</Accordion.Header>
                                                <Accordion.Body>
                                                    <ul className="ul-help-page">
                                                            <li>
                                                                <small>
                                                                    Es el consolidado de clientes ingresados al sistema y muestra información de sus diferentes estados para cada una de las pestañas(Todos, Al día, Por vencer, Vencidas y Sin compras).
                                                                </small>
                                                            </li>
                                                            <li>
                                                                <small>
                                                                    Del módulo cliente puede revisar el detalle de cada cliente, crear una nueva compra y hasta asignar una ODT(orden de trabajo) relacionada a la compra de un equipo o servicio.
                                                                </small>
                                                            </li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <br></br>
                    <br></br>
                    <Card>
                        <Card.Header>Módulo Cliente</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    <Col xs={12}>
                                        <Accordion>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>Información</Accordion.Header>
                                                <Accordion.Body>
                                                    <ul>
                                                            <li>
                                                                <small>
                                                                    Para el ingreso de clientes al sistema mediante el llenado del formulario o la importación.
                                                                </small>
                                                            </li>
                                                            <li>
                                                                <small>
                                                                   Además podrá revisar el total de clientes ingresados al sistema, filtrado, eliminación y edición de la información del cliente.
                                                                </small>
                                                            </li>
                                                            <li>
                                                                <small>
                                                                   El centro de notificaciones lista el total de notificaciones SMS(mensaje de texto) enviados al cliente anteriormente.
                                                                </small>
                                                            </li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>Ingreso de ODT</Accordion.Header>
                                                <Accordion.Body>
                                                    Se ingreso una nueva ODT y para saber más, accede desde <Link to='/gestion-de-odt'>aquí</Link>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="5">
                                                <Accordion.Header>Proxima mantención</Accordion.Header>
                                                <Accordion.Body>
                                                    El Cliente ... tiene muy pronto su próxima mantención, conctáctate con el desde <Link to='/gestion-de-cliente'>aquí</Link>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <br></br>
                    <br></br>
                    <Card>
                        <Card.Header>Módulo ODT(orden de trabajo)</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    <Col xs={12}>
                                        <Accordion>
                                            <Accordion.Item eventKey="7">
                                                <Accordion.Header>Instrucciones de llenado</Accordion.Header>
                                                <Accordion.Body>
                                                    <ul className="ul-help-page">
                                                        <li>
                                                            Debe existir el cliente, tener una compra y un servicio asociado para posteriormente
                                                            ingresar una ODT(orden de trabajo).
                                                        </li>
                                                        <li>

                                                        </li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Accordion>
                                            <Accordion.Item eventKey="7">
                                                <Accordion.Header>Estados de una ODT</Accordion.Header>
                                                <Accordion.Body>
                                                    <ul className="ul-help-page">
                                                        <small>
                                                            <li>
                                                                Abierta: El administrador del sistema ingresó una ODT y asignó un supervisor y un técnico de instalación.
                                                            </li>
                                                            <li>
                                                                En proceso: El técnico de instalación realizó el trabajo y envió los recursos a su supervisor y a la espera de ser aprobada
                                                                o rechazada la ODT.
                                                            </li>
                                                            <li>
                                                                Cerrada: Cuando el supervisor aprueba los recursos, comentarios y finalmente el trabajo hecho por el técnico de instalación.
                                                            </li>
                                                        </small>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <br></br>
                    <br></br>
                </>
            )}
        </>
    );
}
export default HelpPage;