import React, { useState, useEffect } from "react";
import { Badge } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Button } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import useShopping from '../../hooks/useShopping';
import useUser from "../../hooks/useUser";
import CalendarComponent from "./CalendarComponent";
import useUtilities from "../../hooks/useUtilities";

function Odt({ shopping, items, client, telephoneClient }) {
    const { showShoppingDetailsItem } = useShopping();
    const { formatDate } = useUtilities();
    const { showUserForRole } = useUser();
    const [assignTech, setAssignTech] = useState();
    const [assignSupervisor, setAssignSupervisor] = useState();
    const [contactName, setContactName] = useState();
    const [contactTelephone, setContactTelephone] = useState();
    const [serviceAddress, setServiceAddress] = useState();
    const [noteOdt, setNoteOdt] = useState();
    const [admisionDate, setAdmisionDate] = useState();
    const [admisionHour, setAdmisionHour] = useState();
    let itemSelected = [0];

    const type_odt = [
        "Servicio",
        "Mantención"
    ];

    const { isLoading: isLoadingSupervisors, data: supervisors, isError: isErrorSupervisors } = useQuery(
        ['supervisors'],
        () => showUserForRole(2));

    const { isLoading: isLoadingTechnician, data: technicians, isError: isErrorTechnicians } = useQuery(
        ['technicians'],
        () => showUserForRole(3));

    useEffect(() => {
    }, []);

    const handleFormatDate = (date) => {
        let newDateAdmision = formatDate(date);
        setAdmisionDate(newDateAdmision);
    }

    const proccessFetchData = () => {
        let key = 0;
        items.map(i => {
            key = i.id_item;
            itemSelected = i.id_item;
        });

        if (itemSelected) {
            showShoppingDetailsItem(itemSelected);
        }
    }

    return (
        <>
            {items.length > 0 ? (
                <>
                    <Row>
                        <Col xs lg="12">
                            <small>Total de ítems seleccionados <Badge bg="success"><small>{items.length}</small></Badge></small>
                        </Col>
                    </Row>
                    <br></br>
                </>
            ) : (null)}

            <>
                <Row>
                    <Col xs={12}>
                        <Form>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="inputProductName">
                                    <Form.Label>Nombre contacto adicional</Form.Label>
                                    <Form.Control type="text" name="contactName" placeholder="Juan Pérez" value={contactName} onChange={(e) => { setContactName(e.target.value) }} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="inputClientTelephone">
                                    <Form.Label>Teléfono contacto adicional</Form.Label>
                                    <Form.Control type="number" name="contactTelephone" placeholder="56987654321" value={contactTelephone} onChange={(e) => { setContactTelephone(e.target.value) }} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="inputProductSku">
                                    <Form.Label>Dirección distinta del cliente</Form.Label>
                                    <Form.Control type="text" name="odtAddress" placeholder="Rancagua, Los Alercer #3223" value={serviceAddress} onChange={(e) => { setServiceAddress(e.target.value) }} />
                                </Form.Group>
                            </Row>
                            <br></br>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="inputProductName">
                                    <Form.Label>Fecha ingreso</Form.Label>
                                    <Form.Control type="date" name="admisionDate" onChange={(e) => { handleFormatDate(e.target.value) }} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="inputClientTelephone">
                                    <Form.Label>Hora ingreso <small>(Hasta las 18:00 hrs)</small></Form.Label>
                                    <Form.Control type="time" name="admisionHour" value={admisionHour} onChange={(e) => { setAdmisionHour(e.target.value) }} />
                                </Form.Group>
                            </Row>
                            <br></br>
                            <Row className="mb-2">
                                {supervisors ? (
                                    <>
                                        <Form.Group as={Col} controlId="selectProductBrand">
                                            <Form.Label>Supervisor</Form.Label>
                                            <Form.Select name="selectSupervisor" value={assignSupervisor} onChange={(e) => { setAssignSupervisor(e.target.value) }} disabled={items.length <= 0}>
                                                <option value="">Seleccione...</option>
                                                {supervisors.map((s) =>
                                                    <option value={s.id}>
                                                        {s.name}
                                                    </option>
                                                )}
                                            </Form.Select>
                                        </Form.Group>
                                    </>
                                ) : (null)}
                                {technicians ? (
                                    <>
                                        <Form.Group as={Col} controlId="selectProductBrand">
                                            <Form.Label>Técnico</Form.Label>
                                            <Form.Select name="selectTech" value={assignTech} onChange={(e) => { setAssignTech(e.target.value) }} disabled={items.length <= 0}>
                                                <option value="">Seleccione...</option>
                                                {technicians.map((t) =>
                                                    <option value={t.id}>
                                                        {t.name}
                                                    </option>
                                                )}
                                            </Form.Select>
                                        </Form.Group>
                                    </>
                                ) : (null)}
                            </Row>
                            <br></br>
                            <Row className="mb-1">
                                <Form.Group as={Col}>
                                    <Form.Label>Observaciones</Form.Label>
                                    <Form.Control as="textarea" name="textServiceNote" rows={3} placeholder="Tiempo promedio estimado para la instalación de (1) equipo es de 2 hh." value={noteOdt} onChange={(e) => { setNoteOdt(e.target.value) }} />
                                    <Form.Control.Feedback type="invalid">Ingrese observaciones.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <br></br>
                            {assignTech && assignSupervisor ? (
                                <Row>
                                    <Col xs lg="2">
                                    </Col>
                                    <Col md="auto">
                                        <CalendarComponent
                                            shopping={shopping}
                                            contactName={contactName}
                                            contactTelephone={contactTelephone}
                                            serviceAddress={serviceAddress}
                                            noteOdt={noteOdt}
                                            items={items}
                                            assignSupervisor={assignSupervisor}
                                            assignTech={assignTech}
                                            client={client}
                                            admisionDate={admisionDate}
                                            admisionHour={admisionHour}
                                            telephoneClient={telephoneClient}
                                        />
                                    </Col>
                                    <Col xs lg="2">
                                    </Col>
                                </Row>
                            ) : (
                                <Row>
                                    <Col>
                                        <Alert key='warning' variant='warning'>
                                            <small>Seleccione un Supervisor y un Técnico para ver su agenda.</small>
                                        </Alert>
                                    </Col>
                                </Row>
                            )}
                        </Form>
                    </Col>
                </Row>
            </>

        </>
    );
}
export default Odt;