import React, { useState, useContext } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { UserLoggedContext } from '../contexts/UserLoggedContext';
import DataSetClient from '../components/client/DataSetAllClients';
import DataSetAllClients from '../components/client/DataSetAllClients';
import DataSetClientExpMain from '../components/client/DataSetClientExpMain';
import DataSetClientNoShopping from '../components/client/DataSetClientNoShopping';
import DataSetClientOkMain from '../components/client/DataSetClientOkMain';
import DataSetClientMainDue from '../components/client/DataSetClientMainDue';

function TabCustomClientTable({ containerType, option_one, option_two, option_three, option_four, option_five }) {
    const { clients, setClients, userRole } = useContext(UserLoggedContext);

    const addNewClient = (client) => {
        console.log(client);
        const updatedClientList = [client, ...clients];
        setClients(updatedClientList);
    }

    return (
        <>
            {
                (() => {
                    if (containerType === 'ClientTable') {
                        return (
                            <Tabs
                                defaultActiveKey="option_one"
                                id="uncontrolled-tab-example"
                                className="mb-3">
                                <Tab eventKey="option_one" title={option_one}>
                                    <Row>
                                        <Col>
                                            <DataSetAllClients />
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="option_two" title={option_two}>
                                    <Row>
                                        <Col>
                                            <DataSetClientOkMain />
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="option_three" title={option_three}>
                                    <Row>
                                        <Col>
                                            <DataSetClientMainDue />
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="option_four" title={option_four}>
                                    <Row>
                                        <Col>
                                            <DataSetClientExpMain />
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="option_five" title={option_five}>
                                    <Row>
                                        <Col>
                                            <DataSetClientNoShopping />
                                        </Col>
                                    </Row>
                                </Tab>
                            </Tabs>
                        )
                    }
                })(null)
            }
        </>
    );
}

export default TabCustomClientTable;