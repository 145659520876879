import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';
import { Card } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineFileSearch } from "react-icons/ai";
import useSmsNotification from "../../hooks/useSmsNotification";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import ClientFilter from "./ClientFilter";

function ClientListNotificationCenter() {
  
  const [loading, setLoading] = useState(true);
  const { setTotalClientsGlobal, totalClientsGlobal, greet } = useContext(UserLoggedContext);
  const queryClient = useQueryClient();
  const [pageNumber, setPageNumer] = useState(1);
  const { index, totalSmsNotifications, lastPageFromAPI } = useSmsNotification();

  const { isLoading, data: all_sms_notifications, isError: isErrorDataFetchingAllClients } = useQuery(
    ['all_sms_notifications', pageNumber],
    () => index(pageNumber));

    const { data: totalSmsNotificationsAPI } = useQuery(
      ['totalSmsNotificationsAPI'],
      () => totalSmsNotifications());

  useEffect(() => {
    index();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  if (isLoading) return (
    <>
      <Row>
        <Col>
          <br></br>
          <Spinner animation="border" variant="secondary" />
        </Col>
      </Row>
    </>
  );

  if (isErrorDataFetchingAllClients) return (
    <>
      <Row>
        <Col>
          <Alert key='danger' variant='danger'>
            Hay un error. Por favor intente más tarde.
          </Alert>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      {all_sms_notifications ? (
        <>
          <ClientFilter />
          <br></br>
          <Card>
            <Card.Header>
              <Row>
                <Col xs={{ order: 12 }}>Todas las notificaciones</Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <Row>
                  {totalSmsNotificationsAPI ? (
                    <Col>
                      <small>Total notificaciones enviadas&nbsp;</small><Badge bg="info">0</Badge>
                    </Col>
                  ) : (null)}
                </Row>
                <Row>
                  <Col>
                    <br></br>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th><small>Id</small></th>
                          <th><small>Cliente</small></th>
                          <th><small>Teléfono</small></th>
                          <th><small>Estado</small></th>
                          <th><small>Fecha envío</small></th>
                          <th><small>Mensaje</small></th>
                          <th><small>Tipo</small></th>
                        </tr>
                      </thead>
                      <tbody>
                        {all_sms_notifications.map((sms, index) =>
                        (
                          <tr key={index}>
                            <td>
                              <small>{sms.id_sms_not}</small>
                            </td>
                            <td>
                              <small>{sms.client_first_name} {sms.client_last_name}</small>
                            </td>
                            <td>
                              <small>{sms.odt_client_telephone}</small>
                            </td>
                            <td>
                              {sms.code == "0" ? (
                                <small><span class="badge bg-success">Entregado</span></small>
                              ) : (
                                <small><span class="badge bg-danger">No entregado</span></small>
                              )}
                            </td>
                            <td>
                              <small>{sms.date_send_sms_not}</small>
                            </td>
                            <td>
                              <small>{sms.message_sms_not}</small>
                            </td>
                            <td>
                              {sms.type_sms_not == 1 ? (
                                  <small>Mantención</small>
                              ) : (
                                <small>Aviso comercial</small>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button onClick={() => setPageNumer((page) => page - 1)} disabled={pageNumber === 1} variant="outline-primary">Volver</Button>{' '}
                    <Button onClick={() => setPageNumer((page) => page + 1)} disabled={pageNumber === lastPageFromAPI} variant="outline-primary">Siguiente</Button>{' '}
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </>
      ) : (
        <Row>
          <Col>
            <Alert key='warning' variant='warning'>
              <small>Sin notificaciones registradas.</small>
            </Alert>
          </Col>
        </Row>
      )}

      <br></br>
      <br></br>
    </>
  )
}

export default ClientListNotificationCenter;

