import React, { useState, useEffect, useContext } from "react";
import Spinner from 'react-bootstrap/Spinner';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';
import BreadCrumbs from "../../utils/BreadCrumbs";
import { Row, Col } from "react-bootstrap";

function NotificationCenter({ titleNotification, context }) {
  const [loading, setLoading] = useState(true);
  const { pendingShopping, countNotification } = useContext(UserLoggedContext);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? (
        <div>
          <br></br>
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <div>
        <br></br>
          <Row>
            <Col>
              <BreadCrumbs
                paramCurrentPage="Notificaciones"
              />
            </Col>
          </Row>
          <h5>Centro de control</h5>
          <br>
          </br>
          <Accordion defaultActiveKey="0">

            {pendingShopping < 1 ? (
              <Accordion.Item eventKey="0">
                <Accordion.Header>Tareas pendientes&nbsp;<Badge bg="success">Nuevo</Badge></Accordion.Header>
                <Accordion.Body>
                  Se ingreso una nueva Compra y para saber más y gestionar su ODT, accede desde <Link to='/gestion-de-compra'>aquí</Link>
                </Accordion.Body>
              </Accordion.Item>
            ) : (null)}

            <Accordion.Item eventKey="1">
              <Accordion.Header>Ingreso de ODT &nbsp;<Badge bg="success">Nuevo</Badge></Accordion.Header>
              <Accordion.Body>
                Se ingreso una nueva ODT y para saber más, accede desde <Link to='/gestion-de-odt'>aquí</Link>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Proxima mantención &nbsp;<Badge bg="warning">Atención</Badge></Accordion.Header>
              <Accordion.Body>
                El Cliente ... tiene muy pronto su próxima mantención, conctáctate con el desde <Link to='/gestion-de-cliente'>aquí</Link>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
    </>
  );
}
export default NotificationCenter;