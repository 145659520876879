import React from "react";
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Dashboard from "../components/dashboard/Dashboard";
import { Link } from 'react-router-dom';

function NoExists(){
    return(
        <>
        <br></br>
        <Row>
            <Col>
                <Alert key='danger' variant='danger'>
                   <small>Opción no existe. <Link to={'/dashboard'} className='nav-link'>Volver al inicio.</Link></small>
                </Alert>
            </Col>
        </Row>
        </>
    );
}

export default NoExists;