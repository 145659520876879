import React, { useState, useContext } from 'react';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import useClient from '../../hooks/useClient';
import useProduct from '../../hooks/useProduct';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { redirect, useParams, useNavigate } from "react-router-dom";
import BreadCrumbs from '../../utils/BreadCrumbs';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';

function FormUpdateProduct() {
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [codeApiHttp, setCodeApiHttp] = useState('');
    //const { store, show, update } = useClient();
    const { show, showForSku, update } = useProduct();
    const [desableUpdateProductBtn, setDesableUpdateProductBtn] = useState(true);
    const [messageSuccessUpdateProduct, setMessageSuccessUpdateProduct] = useState(false);
    const [messageErrorUpdateProduct, setMessageErrorUpdateProduct] = useState(false);
    let idProductParam = useParams().product_id;
    const queryClient = useQueryClient();
    const [client, setClient] = useState([]);
    const [product, setProduct] = useState([]);
    const { setGlobalClientIdParam, setGlobalClientTelephoneParam, globalClientIdParam, globalClientTelephoneParam } = useContext(UserLoggedContext);
    let path_test = "gestion-de-cliente/detalle/" + globalClientIdParam + "/" + globalClientTelephoneParam;
    const productBrands = ['Anwo', 'Clark', 'Gree', 'Shoot', 'Midea', 'Airolite', 'Khone', 'Nueva'];
    const productBtu = ['9.000', '12.000', '18.000', '24.000', '36.000', '48.000', '60.000'];
    const productTech = ['Inverter', 'Convencional'];
    const productModel = ['Split Muro', 'Cassette', 'Ducto', 'Ventana', 'Portátil'];
    const productVoltage = ['220', '380'];
    const productBomb = ['Aplica', 'No Aplica'];
    const productProvider = ['Anwo', 'Clark', 'Otro'];

    const { isLoading, data: product_filtered, isError } = useQuery(
        ["product_filtered", idProductParam],
        () => show(idProductParam)
    );

    const { mutate: mutateUpdateProduct, isLoading: isLoadingMutation, error } = useMutation({
        mutationFn: update,
        onSuccess: () => {
            successUpdateProduct();
            queryClient.invalidateQueries({ queryKey: ['products'] });
            queryClient.invalidateQueries({ queryKey: ['totalProductsAPI'] });
        },
        onError: () => {
            errorSuccessUpdateProduct();
        }
    });

    const successUpdateProduct = () => {
        setMessageSuccessUpdateProduct(true);
        setValidated(false);
        setTimeout(() => {
            setMessageSuccessUpdateProduct(false);
        }, 3000);
    }

    const errorSuccessUpdateProduct = () => {
        setMessageErrorUpdateProduct(true);
        setValidated(false);
        setTimeout(() => {
            setMessageErrorUpdateProduct(false);
        }, 3000);
    }

    const handeInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setProduct({ ...product, [name]: value });
        setDesableUpdateProductBtn(false);
    };

    const handleSubmit = (e) => {

        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }

        setValidated(true);

        if (form.checkValidity() === true) {
            e.preventDefault();
            mutateUpdateProduct({
                id: idProductParam,
                name: product["product_name"],
                sku: product["product_sku"],
                btu: product["product_btu"],
                tech: product["product_tech"],
                model: product["product_model"],
                brand: product["product_brand"],
                voltage: product["product_voltage"],
                bomb: product["product_bomb"],
                provider: product["product_provider"],
                maintenance_periodicity: product["product_maintperiod"],
                note: product["product_note"],
            });

            setTimeout(() => {
                navigate('/gestion-de-equipo');
            }, 2000);
        }

    };

    if (isLoading) return (
        <>
            <Row>
                <Col>
                    <br></br>
                    <Spinner animation="border" variant="secondary" />
                </Col>
            </Row>
        </>
    );

    if (isError) return (
        <>
            <Row>
                <Col>
                    <Alert key='danger' variant='danger'>
                        Hay un error. Por favor intente más tarde.
                    </Alert>
                </Col>
            </Row>
        </>
    );

    return (
        <>
            {product_filtered ? (
                <>
                    <br></br>
                    <Row>
                        <Col>
                            <BreadCrumbs
                                paramSecondPage="Gestión de equipo"
                                paramSecondPageLink="gestion-de-equipo"
                                paramCurrentPage="Actualizar equipo"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs lg="5">
                        </Col>
                        <Col md="auto">
                            <h5>Actualizar equipo</h5>
                            {product_filtered.map((product, index) =>
                                <small key={index}><Badge bg="secondary">Equipo filtrado: {product.sku}</Badge></small>
                            )}
                        </Col>
                        <Col xs lg="5">
                        </Col>
                    </Row>
                    <br></br>
                    {product_filtered.map((product, index) => (
                        <Form noValidate validated={validated} onSubmit={handleSubmit} key={index}>
                            <Row className="mb-2">
                                <Form.Group as={Col} controlId="name">
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control type="text" name="product_name" defaultValue={product.name} placeholder="Anwo 9000 btu inverter Aire Acondicionado Virus Protect" onChange={handeInputChange} required />
                                    <Form.Control.Feedback type="invalid">Ingrese nombre.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} controlId="sku">
                                    <Form.Label>SKU</Form.Label>
                                    <Form.Control type="text" name="product_sku" defaultValue={product.sku} placeholder="GES9ECO-INV-VP" onChange={handeInputChange} required />
                                    <Form.Control.Feedback type="invalid">Ingrese nombre.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className='mb-4'>

                                <Form.Group as={Col} controlId="selectProductBtu">
                                    <Form.Label>BTU</Form.Label>
                                    <Form.Select defaultValue={product.btu} name="product_btu" onChange={handeInputChange} required >
                                        <option value="">Seleccione...</option>
                                        <option value={productBtu[0]}>{productBtu[0]}</option>
                                        <option value={productBtu[1]}>{productBtu[1]}</option>
                                        <option value={productBtu[2]}>{productBtu[2]}</option>
                                        <option value={productBtu[3]}>{productBtu[3]}</option>
                                        <option value={productBtu[4]}>{productBtu[4]}</option>
                                        <option value={productBtu[5]}>{productBtu[5]}</option>
                                        <option value={productBtu[6]}>{productBtu[6]}</option>
                                        <option value={productBtu[7]}>{productBtu[7]}</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">Seleccione BTU.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} controlId="selectProductTech">
                                    <Form.Label>Tecnología</Form.Label>
                                    <Form.Select defaultValue={product.tech} name="product_tech" onChange={handeInputChange} required >
                                        <option value="">Seleccione...</option>
                                        <option value={productTech[0]}>{productTech[0]}</option>
                                        <option value={productTech[1]}>{productTech[1]}</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">Seleccione tecnología.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} controlId="selectProductModel">
                                    <Form.Label>Modelo</Form.Label>
                                    <Form.Select defaultValue={product.model} name="product_model" onChange={handeInputChange} required >
                                        <option value="">Seleccione...</option>
                                        <option value={productModel[0]}>{productModel[0]}</option>
                                        <option value={productModel[1]}>{productModel[1]}</option>
                                        <option value={productModel[2]}>{productModel[2]}</option>
                                        <option value={productModel[3]}>{productModel[3]}</option>
                                        <option value={productModel[4]}>{productModel[4]}</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">Seleccione modelo.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} controlId="selectProductBrand">
                                    <Form.Label>Marca</Form.Label>
                                    <Form.Select defaultValue={product.brand} name="product_brand" onChange={handeInputChange} required >
                                        <option value="">Seleccione...</option>
                                        <option value={productBrands[0]}>{productBrands[0]}</option>
                                        <option value={productBrands[1]}>{productBrands[1]}</option>
                                        <option value={productBrands[2]}>{productBrands[2]}</option>
                                        <option value={productBrands[3]}>{productBrands[3]}</option>
                                        <option value={productBrands[4]}>{productBrands[4]}</option>
                                        <option value={productBrands[5]}>{productBrands[5]}</option>
                                        <option value={productBrands[6]}>{productBrands[6]}</option>
                                        <option value={productBrands[7]}>{productBrands[7]}</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">Seleccione marca.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row className="mb-4">
                                <Form.Group as={Col} controlId="selectProductVoltage">
                                    <Form.Label>Voltaje</Form.Label>
                                    <Form.Select defaultValue={product.voltage} name="product_voltage" onChange={handeInputChange} required >
                                        <option value="">Seleccione...</option>
                                        <option value={productVoltage[0]}>{productVoltage[0]}</option>
                                        <option value={productVoltage[1]}>{productVoltage[1]}</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">Seleccione voltaje.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} controlId="selectProductBomb">
                                    <Form.Label>Bomba de Condensado</Form.Label>
                                    <Form.Select defaultValue={product.bomb} name="product_bomb" onChange={handeInputChange} required >
                                        <option value="">Seleccione...</option>
                                        <option value={productBomb[0]}>{productBomb[0]}</option>
                                        <option value={productBomb[1]}>{productBomb[1]}</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">Seleccione bomba.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} controlId="selectProductProvider">
                                    <Form.Label>Proveedor</Form.Label>
                                    <Form.Select defaultValue={product.provider} name="product_provider" onChange={handeInputChange}>
                                        <option>Seleccione...</option>
                                        <option value={productProvider[0]}>{productProvider[0]}</option>
                                        <option value={productProvider[1]}>{productProvider[1]}</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group as={Col} controlId="selectProductMaintPerio">
                                    <Form.Label>Periodicidad de mantención</Form.Label>
                                    <Form.Select defaultValue={product.maintenance_periodicity} name="product_maintperiod" onChange={handeInputChange} required >
                                        <option value="">Seleccione...</option>
                                        <option value="90">Cada 3 meses</option>
                                        <option value="180">Cada 6 meses</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">Seleccione periodicidad.</Form.Control.Feedback>
                                </Form.Group>

                            </Row>
                            <Row className="mb-12">
                                <Form.Group as={Col} controlId="textProductNote">
                                    <Form.Label>Observaciones</Form.Label>
                                    <Form.Control as="textarea" name="product_note" defaultValue={product.note} rows={3} placeholder="Equipo viene con kit de instalación de regalo." onChange={handeInputChange} />
                                    <Form.Control.Feedback type="invalid">Ingrese observaciones.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <br></br>
                            <Alert variant="success" show={messageSuccessUpdateProduct}>
                                <small>Equipo actualizado con éxito.</small>
                            </Alert>
                            <Alert variant="danger" show={messageErrorUpdateProduct}>
                                <small>Error al actualizar el equipo, inténtelo más tarde.</small>
                            </Alert>
                            <br></br>

                            <Row className="justify-content-md-center">
                                <Col xs lg={2}>
                                </Col>
                                <Col md="auto">
                                    <Button variant="primary" type='submit' disabled={desableUpdateProductBtn}>
                                        {isLoadingMutation ? 'Actualizando...' : 'Actualizar'}
                                    </Button>
                                </Col>
                                <Col md="auto">
                                    <Button variant="outline-primary" href={'/gestion-de-equipo'}>
                                        Volver
                                    </Button>
                                </Col>
                                <Col xs lg={2}>
                                </Col>
                            </Row>
                        </Form>
                    ))}
                </>
            ) : (
                <>
                    <br></br>
                    <Row>
                        <Col>
                            <Alert key='danger' variant='danger'>
                                <small>No existe el equipo con Id filtrado. </small> <Link to={'/gestion-de-equipo'} className='nav-link'><small>Volver a Equipos.</small></Link>
                            </Alert>
                        </Col>
                    </Row>
                </>
            )}
            <br></br>
            <br></br>
        </>
    );


}

export default FormUpdateProduct;