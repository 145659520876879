import React, { useContext, useEffect, useState } from 'react';
//import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Navigation from './utils/Navigation.jsx';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import NoExists from './utils/NotExists.jsx';
import ClientAdministrator from './components/client/ClientAdministrator.jsx';
import ODTAdministrator from './components/odt/ODTAdministrator.jsx';
import NotificationCenter from './components/controlCenter/NotificationCenter.jsx';
import Login from './utils/Login.jsx';
import UserAdministrator from './components/user/UserAdministrator.jsx';
import { UserLoggedContext } from './contexts/UserLoggedContext.jsx';
import ShoppingAdministrator from './components/shopping/ShoppingAdministrator.jsx';
import ClientAddPurchase from './components/shopping/ClientAddPurchase.jsx';
import ProductAdministrator from './components/product/ProductAdministrator.jsx';
import Client from './components/client/Client'
import User from './components/user/User.jsx';
import Shopping from './components/shopping/Shopping.jsx';
import ServiceAdministrator from './components/service/ServiceAdministrator.jsx';
import FormShoppingEdit from './components/shopping/FormShoppingEdit.jsx';
import 'rsuite/dist/rsuite.min.css';
import FormUpdateClient from './components/client/FormUpdateClient.jsx';
import FormUpdateOdt from './components/odt/FormUpdateOdt.jsx';
import HelpPage from './utils/HelpPage.jsx';
import Profile from './components/profile/Profile.jsx';
import OdtDetail from './components/odt/OdtDetail.jsx';
import RecoverAccount from './utils/RecoverAccount.jsx';
import TagAdministrator from './components/tags/TagAdministrator.jsx';
import ShoppingDetailHistory from './components/shopping/ShoppingDetailHistory.jsx';
import OdtDetailHistory from './components/odt/OdtDetailHistory.jsx';
import useClient from './hooks/useClient.jsx';
import Product from './components/product/Product.jsx';
import FormUpdateProduct from './components/product/FormUpdateProduct.jsx';
import FormUpdateService from './components/service/FormUpdateService.jsx';
import Service from './components/service/Service.jsx';
import FormUpdateUser from './components/user/FormUpdateUser.jsx';
import DashboardAdministrator from './components/dashboard/DashboardAdministrator.jsx';

function App() {
  const { token, setToken } = useContext(UserLoggedContext);
  const { setUserLogged, setUserRole, setUserId } = useContext(UserLoggedContext);
  let stringDataStorage = '';
  let arrayDataStorage = [];
  const pathname = window.location.pathname;
  const { maintenanceDayCounter } = useClient();
  
  useEffect(() => {
    stringDataStorage = localStorage.getItem("user");
    arrayDataStorage = JSON.parse(stringDataStorage);

    if (arrayDataStorage != null) {
      setToken(arrayDataStorage.authorisation.token);
      setUserLogged(arrayDataStorage.user.name);
      setUserRole(arrayDataStorage.user.role);
      setUserId(arrayDataStorage.user.id);
    } else {
      setToken('');
      setUserLogged('');
    }

    const intervalId = setInterval(() => {
      maintenanceDayCounter();
    }, 1800000);// 3600000 ms = 1 hour 10000 ms = 10 seconds //1800000 ms = 30 minutes

  }, []);

  if (!token) {
    return (
      <>
        <div className='App'>
          <div className='container'>
            {pathname == "/" || pathname == "/iniciar-sesion" ? (
              <Login />
            ) : (
              <RecoverAccount />
            )}

            <Row>
              <Col xs={12}>
                <small className="copyright">
                  ©2024 Ecoinput
                </small>
              </Col>
            </Row>

          </div>
        </div>
      </>
    )
  }

  if (token) {
    return (
      <div className='App'>
        <div className='container'>
          <Navigation />
          <Routes>
            <Route path='/' element={<DashboardAdministrator />} />
            <Route path='/dashboard' element={<DashboardAdministrator />} />
            <Route path='/gestion-de-odt/editar/:odt_id' element={<FormUpdateOdt />} />
            <Route path='/gestion-de-cliente' element={<ClientAdministrator />} />
            <Route path='/gestion-de-cliente/historial-compras/detalle/:shopping_id' element={<ShoppingDetailHistory />} />
            <Route path='/gestion-de-cliente/historial-odts/detalle/:odt_id/:client_telephone' element={<OdtDetailHistory />} />
            <Route path='/gestion-de-cliente/editar/:client_id/:client_telephone' element={<FormUpdateClient />} />
            <Route path='/gestion-de-cliente/detalle/:client_id/:client_telephone' element={<Client />} />
            <Route path='/compra/:id' element={<Shopping />} />
            <Route path='/compra/:id/editar' element={<FormShoppingEdit />} />
            <Route path='/cliente/:id/agregar-compra' element={<ClientAddPurchase />} />
            <Route path='/gestion-de-compra' element={<ShoppingAdministrator />} />
            <Route path='/gestion-de-compra/detalle/:shopping_id' element={<Shopping />} />
            <Route path='/gestion-de-usuario' element={<UserAdministrator />} />
            <Route path='/gestion-de-usuario/detalle/:user_id' element={<User />} />
            <Route path='/gestion-de-usuario/editar/:user_id' element={<FormUpdateUser />} />
            <Route path='/gestion-de-etiqueta' element={<TagAdministrator />} />
            <Route path='/gestion-de-equipo' element={<ProductAdministrator />} />
            <Route path='/gestion-de-equipo/detalle/:product_id' element={<Product />} />
            <Route path='/gestion-de-equipo/editar/:product_id' element={<FormUpdateProduct />} />
            <Route path='/gestion-de-servicio' element={<ServiceAdministrator />} />
            <Route path='/gestion-de-servicio/detalle/:service_id' element={<Service />} />
            <Route path='/gestion-de-servicio/editar/:service_id' element={<FormUpdateService />} />
            <Route path='/gestion-de-odt' element={<ODTAdministrator />} />
            <Route path='/gestion-de-odt/editar/:odt_id' element={<FormUpdateOdt />} />
            <Route path='/gestion-de-odt/detalle/:odt_id' element={<OdtDetail />} />
            <Route path='/gestion-de-odt/detalle/:odt_id/:client_telephone' element={<OdtDetail />} />
            <Route path='/notificaciones' element={<NotificationCenter />} />
            <Route path='/iniciar-sesion' element={<Login />} />
            <Route path='/recuperar-cuenta' element={<RecoverAccount />} />
            <Route path='/perfil/:profile_id' element={<Profile />} />
            <Route path='/ayuda' element={<HelpPage />} />
            <Route path='*' element={<NoExists />} />
          </Routes>

          <Row>
              <Col xs={12}>
                <small className="copyright">
                  ©2024 Ecoinput
                </small>
              </Col>
            </Row>
            
        </div>
      </div>
    );
  }

}

export default App;
