import React, { useState } from "react";
import axios from "axios";

function useSmsNotification() {
  const API_URL = process.env.REACT_APP_API_URL;
  let codeHttpApi = 0;
  const [totalDataFromAPI, setTotalDataFromAPI] = useState(0);
  const [lastPageFromAPI, setLastPageFromAPI] = useState(0);
  const msgSmsNot = `Estimado Cliente de Climatización Rancagua, \nRegistra mantenciones vencidas en Equipos de Aire Acondicionado. \nHablemos: https://api.whatsapp.com/send?phone=56967345720'`;

  const index = ($pageNumber) => {
    return axios.get(API_URL + 'sms/?' + 'limit=5' + '&page=' + $pageNumber)
      .then(function (response) {
        if (response.data.code != 204) {
          setTotalDataFromAPI(response.data.data.total);
          setLastPageFromAPI(response.data.data.last_page);
        }
        return response.data.data.data;
      })
      .catch(function (error) {
      })
      .finally(function () {
      });
  }

  const totalSmsNotifications = () => {
    return axios.get(API_URL + 'sms')
      .then(function (response) {
        //console.log(response.data.data);
        return response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
      });
  }

  const smsManualSend = (data) => {
    return axios.post(API_URL + "send-manual-sms", data)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
      });
  };

  const smsAutoSend = (client_telephone, client_id, msgSmsNot, client_type_sms, client_send_date) => {
    return axios.post(API_URL + "send-auto-sms", {
      client_telephone,
      client_id,
      msgSmsNot,
      client_type_sms,
      client_send_date,
    })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
      });
  };

  const filterSmsByTelephone = (telephone) => {
    return axios.get(API_URL + 'filter-sms-by-client-telephone' + '/' + telephone)
      .then(function (response) {
        setTotalDataFromAPI(response.data.data.total);
        setLastPageFromAPI(response.data.data.last_page);
        console.log(response);
        return response.data.data.data;
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
      });
  }

  /*
  const index = ($pageNumber) => {
    return axios.get(API_URL + 'sms/?' + 'limit=5' + '&page=' + $pageNumber)
      .then(function (response) {
        if (response.data.code != 204) {
          setTotalDataFromAPI(response.data.data.total);
          setLastPageFromAPI(response.data.data.last_page);
        }
        return response.data.data.data;
      })
      .catch(function (error) {
      })
      .finally(function () {
      });
  }
  */

  return {
    smsManualSend,
    smsAutoSend,
    totalSmsNotifications,
    index,
    lastPageFromAPI,
    filterSmsByTelephone,
    lastPageFromAPI, 
    totalDataFromAPI,
  }
}

export default useSmsNotification;