import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { Button, Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { UserLoggedContext } from '../contexts/UserLoggedContext';
import useCustomAuth from "../hooks/useCustomAuth";
import { useQuery, useMutation, useQueryClient } from 'react-query';

function RecoverAccount() {
    const [validated, setValidated] = useState(false);
    const [user_email, setUserEmail] = useState('');
    const [user_telephone, setUserTelephone] = useState('');
    const [user_new_password, setUserNewPassword] = useState('');
    const [loading, setLoading] = useState(true);
    const { token, codeLogin } = useContext(UserLoggedContext);
    const { recoverAccount, recoverAccountNew } = useCustomAuth();

    const { mutate: mutateRecoverAccount, isLoading, isError } = useMutation({
        mutationFn: recoverAccountNew,
        onSuccess: (code) => {
            console.log(code);
            //sendEmailNotification();
            //successNewUser()
            //queryClient.invalidateQueries({ queryKey: ['users'] })
        },
        onError: () => {
            //errorSuccessNewUser()
        }
    });

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const handleRecoverAccount = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);

        if (form.checkValidity() === true) {
            //recoverAccount(user_email, user_telephone, user_new_password);
            mutateRecoverAccount({
                user_email: user_email,
                user_telephone: user_telephone,
                user_new_password: user_new_password,
            });
        }
    };

    const sendEmailNotification = () => {
        if (codeLogin === 200) {
            console.log("ok");
        }
    }

    return (
        <>
            {loading ? (
                <>
                    <br></br>
                    <Spinner animation="border" variant="secondary" />
                </>
            ) : (
                <>
                    <br></br>
                    <Card>
                        <Card.Body className="login-form-card">
                            <Card.Title>ERP Grupo EHAC, Chile</Card.Title>
                            <Card.Subtitle className="mb-3 text-muted">Restablecer contraseña</Card.Subtitle>
                            <div className="login-form w-100 m-auto">
                                <Card.Text>
                                    <Row>
                                        <Col xs={12}>
                                            <Form noValidate validated={validated} onSubmit={handleRecoverAccount}>
                                                <Form.Group className="mb-2" controlId="validationEmail">
                                                    <Form.Label><small>Tu email</small></Form.Label>
                                                    <Form.Control type="email" placeholder="juan.perez@gmail.com" value={user_email} onChange={(e) => { setUserEmail(e.target.value) }} required />
                                                    <Form.Control.Feedback type="invalid">Ingresa tu email.</Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="mb-2" controlId="formBasicTelephone">
                                                    <Form.Label><small>Tu celular</small></Form.Label>
                                                    <Form.Control type="number" placeholder="56912345678" value={user_telephone} onChange={(e) => { setUserTelephone(e.target.value) }} required />
                                                    <Form.Control.Feedback type="invalid">Ingresa tu teléfono.</Form.Control.Feedback>
                                                </Form.Group>


                                                <Form.Group className="mb-2" controlId="formBasicPassword">
                                                    <Form.Label><small>Tu nueva contraseña</small></Form.Label>
                                                    <Form.Control type="password" placeholder="******" value={user_new_password} onChange={(e) => { setUserNewPassword(e.target.value) }} required />
                                                    <Form.Control.Feedback type="invalid">Ingresa tu contraseña.</Form.Control.Feedback>
                                                </Form.Group>

                                                {codeLogin === 200 ? (
                                                    <Form.Group className="mb-2">
                                                        <Alert key='success' variant='success'>
                                                            <small>Contraseña restablecida con exito. <br></br>Revise su E-mail.</small>
                                                        </Alert>
                                                    </Form.Group>
                                                ) : null}

                                                {codeLogin === 204 ? (
                                                    <Form.Group className="mb-2">
                                                        <Alert key='danger' variant='danger'>
                                                            <small>E-mail y/o teléfono incorrecto.</small>
                                                        </Alert>
                                                    </Form.Group>
                                                ) : null}
                                                <br></br>
                                                <Row className="justify-content-md-center">
                                                    <Col xs lg={1}>
                                                    </Col>
                                                    <Col md="auto">
                                                        <Button variant="primary" type="submit">
                                                            Restablecer
                                                        </Button>
                                                    </Col>
                                                    <Col md="auto">
                                                        <Button variant="outline-primary" href={'/'}>
                                                            Volver
                                                        </Button>
                                                    </Col>
                                                    <Col xs lg={1}>
                                                    </Col>
                                                </Row>
                                                <br></br>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Card.Text>
                            </div>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    );

}
export default RecoverAccount;