import React, { useState, useEffect } from "react";
import useShopping from "../../hooks/useShopping";
import { Button } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { useQuery } from 'react-query';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { AiOutlineExport } from "react-icons/ai";
import { AiOutlineFileSearch } from "react-icons/ai";
import { AiOutlineFileAdd } from "react-icons/ai";
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';

function HistoryOfShoppingsSimple({ paramClientTelephone }) {
    console.log(paramClientTelephone);
    const { showPurchasedEquipmentByClientTelephone, lastPageFromAPI, totalDataFromAPI } = useShopping();
    const [pageNumber, setPageNumer] = useState(1);

    const { isLoading, data: purchased_equipments, isError } = useQuery(
        ['purchased_equipments', pageNumber], 
        () => showPurchasedEquipmentByClientTelephone(paramClientTelephone));

        console.log(totalDataFromAPI);

    useEffect(() => {
        showPurchasedEquipmentByClientTelephone(paramClientTelephone);
    }, []);

    const handleManagerOdt = (e) => {
        const item = (e.target.value);
        console.log(item);
    }

    if (isLoading) return (
        <>
            <Row>
                <Col>
                    <br></br>
                    <Spinner animation="border" variant="secondary" />
                </Col>
            </Row>
        </>
    );

    if (isError) return (
        <>
            <Row>
                <Col>
                    <Alert key='danger' variant='danger'>
                        Hay un error. Por favor intente más tarde.
                    </Alert>
                </Col>
            </Row>
        </>
    );

    if (purchased_equipments) return (
        <>
            {totalDataFromAPI > 0 ? (
                <>
                    <Row>
                        <Col>
                            <small>Total equipos comprados&nbsp;</small><Badge bg="info">{totalDataFromAPI ? (totalDataFromAPI) : (0)}</Badge>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <br></br>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                       <th><small>Id compra</small></th>
                                        <th><small>SKU</small></th>
                                        <th><small>Equipo</small></th>
                                        <th><small>Cantidad</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {purchased_equipments.map((shop, index) =>

                                        <tr key={index}>
                                            <td>
                                                <small>{shop.shopping_id}</small>
                                            </td>
                                            <td>
                                                <small>{shop.product_sku}</small>
                                            </td>
                                            <td>
                                                <small>{shop.product_name}</small>
                                            </td>
                                            <td>
                                                <small>{shop.product_cant}</small>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button onClick={() => setPageNumer((page) => page - 1)} disabled={pageNumber === 1} variant="outline-primary">Volver</Button>{' '}
                            <Button onClick={() => setPageNumer((page) => page + 1)} disabled={pageNumber === lastPageFromAPI} variant="outline-primary">Siguiente</Button>{' '}
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <small>Sin equipos comprados.</small>
                </>
            )}
        </>
    )
}
export default HistoryOfShoppingsSimple;