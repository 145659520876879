import React from "react";
import { useParams } from "react-router-dom";

function Profile() {
    let clientIdParam = useParams().client_id;
    return (
        <>
            Profile<br></br>
            {clientIdParam}
        </>
    );
}
export default Profile;