import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import useClient from "../../hooks/useClient";
import { AiFillCloseCircle } from "react-icons/ai";
import Alert from 'react-bootstrap/Alert';
import { Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { AiFillCaretDown } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineFileSearch } from "react-icons/ai";
import { COLOR } from "rsuite/esm/utils/constants";
import useUtilities from "../../hooks/useUtilities";
import useSmsNotification from "../../hooks/useSmsNotification";

function ClientFilter() {
    const { destroy, showModal, filterClientByTelephone, filterClientFromTelephoneAPI, codeApiFilterClientForTelephone, setFilterClientFromRunAPI, setFilterClientFromTelephoneAPI } = useClient();
    const [clientToFilter, setClientToFilter] = useState("");
    const { truncateString, calculateDaysForMaintenance, formatDate } = useUtilities();
    const [clientForDelete, setCllientForDelete] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showDelete, setShowDelete] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    const [isCheckedComboBoxAllClientSelected, setIsCheckedComboBoxAllClientSelected] = useState(false);
    const queryClient = useQueryClient();
    const { smsManualSend } = useSmsNotification();
    const handleCloseClientSmsSend = () => setShowClientSmsNot(false);
    const [showClientSmsNot, setShowClientSmsNot] = useState(false);
    const [messageSuccessClientDelete, setMessageSuccessClientDelete] = useState(false);
    const [hiddenBtnNotifiForIndiClient, setHiddenBtnNotifiForIndiClient] = useState(true);
    const [selectedItems, setSelectedItems] = useState([]);
    const [nameClientSelectedToNotSms, setNameClientSelectedToNotSms] = useState();
    const [telephoneClientSelectedToNotSms, setTelephoneClientSelectedToNotSms] = useState();
    const [idClientSelectedToNotSms, setIdClientSelectedToNotSms] = useState();
    const [desableSendSmsNotclient, setDesableSendSmsNotclient] = useState(false);
    const msgSmsNot = `Estimado Cliente de Climatización Rancagua, \nUsted registra mantenciones vencidas de sus Equipos de Aire Acondicionado. \nContáctenos al Whatsapp +56 9 67345720`;

    const { mutate: mutateSms, isLoading: isLoadingMutationSms, error } = useMutation({
        mutationFn: smsManualSend,
        onSuccess: () => {
            setDesableSendSmsNotclient(true);
            queryClient.invalidateQueries(['clients']);
            queryClient.invalidateQueries(['all_sms_notifications']);
            setTimeout(() => {
                setLoading(false);
                handleCloseClientSmsSend();
                setDesableSendSmsNotclient(false);
            }, 2000);
        },
        onError: () => {
            //errorSuccessNewClient()
        }
    });

    const deleteClientMutation = useMutation({
        mutationFn: destroy,
        onSuccess: () => {
            successClientDelete();
            queryClient.invalidateQueries(['clients'])
            queryClient.invalidateQueries(['totalClientsAPI'])
        },
        onError: () => {
            //errorClientDelete()
        }
    });

    const handleCheckboxChange = (id, name, telephone) => {
        console.log(id);
        console.log(name);
        console.log(telephone);
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(id)) {
                setHiddenBtnNotifiForIndiClient(true);
                console.log("deselected");
                setNameClientSelectedToNotSms("");
                setTelephoneClientSelectedToNotSms("");
                setIdClientSelectedToNotSms("");
                return prevSelectedItems.filter((item) => item !== id);
            } else {
                setNameClientSelectedToNotSms(name);
                setTelephoneClientSelectedToNotSms(telephone);
                setIdClientSelectedToNotSms(id);
                setHiddenBtnNotifiForIndiClient(false);
                console.log("selected");
                return [...prevSelectedItems, id];
            }
        });
    };

    const showPopupClientSmsNot = () => {
        setShowClientSmsNot(true);
    }

    const handleCheckboxFiftyClientsChange = () => {
        console.log("ok");

    }

    const sayHello = () => {
        alert("Hello human friend");
    }

    const handleSendClientSmsNot = () => {
        //deleteAllClientsMutation.mutate();
        mutateSms({
          telephone_client: telephoneClientSelectedToNotSms, 
          id_client: idClientSelectedToNotSms, 
          first_name_client: nameClientSelectedToNotSms, 
          message: msgSmsNot, 
          type_sms: 1, 
          send_date: formatDate(), 
        });
    
        //fix: I need to complete history logic for save after to send a sms notification. 
        /*
        smsHistoryNotifications({
          telephone_client: telephoneClientSelectedToNotSms,
          id_client: idClientSelectedToNotSms,
          message: msgSmsNot,
        });
        */
      }

    const handleDaysCount = (data) => {
        console.log(calculateDaysForMaintenance(data));
        return calculateDaysForMaintenance(data);
    }

    const successClientDelete = () => {
        setMessageSuccessClientDelete(true);
        setClientToFilter("");
        //setDesableDeleteBtn(true);
        setTimeout(() => {
            setMessageSuccessClientDelete(false);
            setFilterClientFromTelephoneAPI([]);
            setShowDelete(false);
        }, 2000);
    }

    const showPopupDelete = (id) => {
        setCllientForDelete(id);
        showModal(id);
        setShowDelete(true);
    }

    const handleFilterClient = (e) => {
        e.preventDefault();
        filterClientByTelephone(clientToFilter);
        console.log(clientToFilter);
    }

    const deleteFilteredClient = () => {
        setClientToFilter("");
        setFilterClientFromTelephoneAPI([]);
    }

    const handleDeleteClient = () => {
        deleteClientMutation.mutate(clientForDelete);
    }

    return (
        <>
            <Row>
                <Col>
                    <Form onSubmit={handleFilterClient}>
                        <InputGroup className="mb-1">
                            <Form.Control
                                name="clientToFilter"
                                type="number"
                                value={clientToFilter}
                                onChange={(e) => { setClientToFilter(e.target.value) }}
                                placeholder="56912345678"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                            />
                            <Button type="submit" variant="outline-primary" id="button-addon2" disabled={!clientToFilter}>
                                Filtrar cliente
                            </Button>
                        </InputGroup>
                    </Form>
                </Col>
            </Row>
            {codeApiFilterClientForTelephone === 200 && filterClientFromTelephoneAPI.length > 0 ? (
                <>
                    <br></br>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={12} md={11}>
                                    Cliente filtrado
                                </Col>
                                <Col xs={12} md={1}>
                                    <Button variant="outline-danger" onClick={deleteFilteredClient} size="sm">Limpiar</Button>{' '}
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    <Row hidden={hiddenBtnNotifiForIndiClient}>
                                        <Col xs lg="8">
                                            <Card>
                                                <Card.Header>Opciones de notificación</Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col xs lg="4">
                                                                <small><b>Clientes seleccionados: {selectedItems.length}</b></small>
                                                            </Col>
                                                            <Col md="auto">
                                                                <Button variant="outline-primary" onClick={showPopupClientSmsNot} size="sm">Notificar mantención</Button>{' '}
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col md="auto"></Col>
                                    </Row>
                                </Row>
                                <br></br>
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <input
                                                    type="checkbox"
                                                    id="checkbox-fifty-client-selected"
                                                    checked={isCheckedComboBoxAllClientSelected}
                                                    onChange={handleCheckboxFiftyClientsChange}
                                                />
                                            </th>
                                            <th><small>Cliente</small></th>
                                            <th><small>Teléfono</small></th>
                                            <th><small>Últ. visita</small></th>
                                            <th><small>Estado mantención</small></th>
                                            <th><small>Estado not. mantención</small></th>
                                            <th><small>Total compras</small></th>
                                            <th><small>Estado nueva ODT</small></th>
                                            <th><small>Eliminar</small></th>
                                            <th><small>Editar</small></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filterClientFromTelephoneAPI.map((client, index) =>
                                        (
                                            <tr key={index}>
                                                <td>
                                                {client.telephone.length < 11 || client.telephone.length > 11 ? (
                                                    <input
                                                        type="checkbox"
                                                        id={`checkbox-client-selected-${client.id}`}
                                                        checked={selectedItems.includes(client.id)}
                                                        onChange={() => handleCheckboxChange(client.id, client.first_name, client.telephone)}
                                                        disabled={true}
                                                    />

                                                ) : (
                                                    <input
                                                    type="checkbox"
                                                    id={`checkbox-client-selected-${client.id}`}
                                                    checked={selectedItems.includes(client.id)}
                                                    onChange={() => handleCheckboxChange(client.id, client.first_name, client.telephone)}
                                                />
                                                )}
                                            </td>
                                                
                                                <td>
                                                <Link to={`/gestion-de-cliente/detalle/${client.id}/${client.telephone}`} className='nav-link nav-link-client'>
                                                    <small>{truncateString(client.first_name, 13)}&nbsp;
                                                        {client.last_name ? (
                                                            truncateString(client.last_name, 4)
                                                        ) : (null)}
                                                    </small>
                                                </Link>
                                            </td>
                                                {client.telephone && client.correct_cellphone == 0 ? (
                                                    <td>
                                                        <small className="error_telephone">{client.telephone}</small>
                                                    </td>
                                                ) : (null)}
                                                {client.telephone && client.correct_cellphone == null ? (
                                                    <td>
                                                        {client.telephone.length < 11 || client.telephone.length > 11 ? (
                                                            <small className="error_telephone">
                                                                {client.telephone}
                                                            </small>
                                                        ) : (
                                                            <small>
                                                                {client.telephone}
                                                            </small>
                                                        )}
                                                    </td>
                                                ) : (null)}
                                                {client.telephone && client.correct_cellphone == 1 ? (
                                                    <td>
                                                        <small>{client.telephone}</small>
                                                    </td>
                                                ) : (null)}
                                                {client.telephone == null ? (
                                                    <td>
                                                        <small>Sin datos</small>
                                                    </td>
                                                ) : (null)}

                                                {client.last_visit == null || client.last_visit == "" || client.last_visit == 0 ? (
                                                    <td>
                                                        <small>
                                                            Sin visitas
                                                        </small>
                                                    </td>
                                                ) : (
                                                    <td>
                                                        <small>
                                                            {client.last_visit}
                                                        </small>
                                                    </td>
                                                )}

                                                {client.last_visit == null ? (
                                                    <td>
                                                        <small>
                                                            No aplica
                                                        </small>
                                                    </td>
                                                ) : (null)}

                                                {client.last_visit && client.count_maintenance_days < 175 ? (
                                                    <td>
                                                        <small>
                                                            <span class="badge bg-success">Al día ({client.count_maintenance_days} días)</span>
                                                        </small>
                                                    </td>
                                                ) : (null)}

                                                {client.last_visit && client.count_maintenance_days >= 175 && client.count_maintenance_days < 180 ? (
                                                    <td>
                                                        <small>
                                                            <span class="badge bg-warning">Por vencer ({client.count_maintenance_days} días)</span>
                                                        </small>
                                                    </td>
                                                ) : (null)}

                                                {client.last_visit && client.count_maintenance_days >= 180 ? (
                                                    <td>
                                                        <small>
                                                            <span class="badge bg-danger">Vencida (hace {client.count_maintenance_days} días)</span>
                                                        </small>
                                                    </td>
                                                ) : (null)}

                                                {client.last_main_notification == 0 || client.last_main_notification == null ? (
                                                    <td>
                                                        <small>
                                                            Pendiente
                                                        </small>
                                                    </td>
                                                ) : (
                                                    <td>
                                                        <small>
                                                            <span class="badge bg-success"> (#{client.maintenance_status}) Enviada el ({client.last_main_notification})</span>
                                                        </small>
                                                    </td>
                                                )}

                                                {client.total_shopping == 0 || client.total_shopping == null ? (
                                                    <td>
                                                        <small>
                                                            0
                                                        </small>
                                                    </td>
                                                ) : (
                                                    <td>
                                                        <small>
                                                            {client.total_shopping}
                                                        </small>
                                                    </td>
                                                )}

                                                {client.last_odt == null || client.last_odt == 0 ? (
                                                    <td>
                                                        <small>
                                                            Sin nueva odt.
                                                        </small>
                                                    </td>
                                                ) : (
                                                    <td>
                                                        <small>
                                                            <span class="badge bg-success">Nueva ODT #{client.last_odt}</span>
                                                        </small>
                                                    </td>
                                                )}
                                                <td>
                                                    <Link onClick={() => { showPopupDelete(client.id) }} className='nav-link'><AiOutlineDelete /></Link>
                                                </td>
                                                <td>
                                                    <Link to={`/gestion-de-cliente/editar/${client.id}/${client.telephone}`} className='nav-link'><AiOutlineEdit /></Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </>
            ) : (null)}

            {codeApiFilterClientForTelephone === 204 ? (
                <>
                    <br></br>
                    <Alert key='danger' variant='danger'>
                        <small>El cliente con teléfono ingresado no existe.</small>
                    </Alert>
                </>
            ) : (null)}

            {codeApiFilterClientForTelephone === 200 && filterClientFromTelephoneAPI.length > 0 ? (
                <Modal show={showDelete} onHide={handleCloseDelete} >
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminación del cliente</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                ¿Quiere eliminar a&nbsp;

                                {filterClientFromTelephoneAPI.map((c, index) =>
                                (
                                    <small key={index}>
                                        <b>{c.first_name} {c.last_name}</b>
                                    </small>
                                ))
                                }?

                            </Col>
                        </Row>
                        <br></br>
                        <Alert variant="success" show={messageSuccessClientDelete}>
                            <small>Cliente eliminado con éxito.</small>
                        </Alert>
                        <br></br>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDelete}>
                            Cerrar
                        </Button>
                        <Button variant="primary" onClick={handleDeleteClient} disabled={!filterClientFromTelephoneAPI}>
                            Si, eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : (null)}

            <Modal show={showClientSmsNot} onHide={handleCloseClientSmsSend} >
                <Modal.Header closeButton>
                    <Modal.Title>Notificación SMS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            Enviará el siguiente mensaje a <b>{selectedItems.length}</b> clientes.
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col md="12">
                            <textarea name="textarea_msg_sms_notif" rows="6" cols="52" disabled="true">
                                {msgSmsNot}
                            </textarea>
                        </Col>
                    </Row>
                    <br></br>
                    {isLoadingMutationSms &&
                        <Alert variant="success">
                            <small>Procesando...</small>
                        </Alert>
                    }
                    <br></br>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseClientSmsSend}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleSendClientSmsNot}>
                        Enviar notificación
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}
export default ClientFilter;