import React, { useState } from "react";
import useShopping from "../../hooks/useShopping";
import { Button } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { useQuery } from 'react-query';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import useSmsNotification from "../../hooks/useSmsNotification";

function HistoryOfNotifications({ paramClientTelephone }) {
    const [pageNumber, setPageNumer] = useState(1);
    const { filterSmsByTelephone, lastPageFromAPI, totalDataFromAPI } = useSmsNotification();

    const { isLoading, data: history_of_sms, isError } = useQuery(
        ["history_of_sms", paramClientTelephone, pageNumber],
        () => filterSmsByTelephone(paramClientTelephone, pageNumber)
    );

    if (isLoading) return (
        <>
            <Row>
                <Col>
                    <br></br>
                    <Spinner animation="border" variant="secondary" />
                </Col>
            </Row>
        </>
    );

    if (isError) return (
        <>
            <Row>
                <Col>
                    <Alert key='danger' variant='danger'>
                        Hay un error. Por favor intente más tarde.
                    </Alert>
                </Col>
            </Row>
        </>
    );

    return (
        <>
            {history_of_sms ? (
                <>
                    <Row>
                        <Col>
                            <small>Total notificaciones&nbsp;</small><Badge bg="info">{totalDataFromAPI ? (totalDataFromAPI) : (0)}</Badge>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <br></br>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th><small>Id</small></th>
                                        <th><small>Estado</small></th>
                                        <th><small>Fecha envío</small></th>
                                        <th><small>Mensaje</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {history_of_sms.map((sms, index) =>

                                        <tr key={index}>
                                            <td>
                                                <small>{sms.id}</small>
                                            </td>
                                            <td>
                                                {sms.code ? (
                                                    <small><span class="badge bg-success">Entregado</span></small>
                                                ) : (
                                                    <small><span class="badge bg-danger">No entregado</span></small>
                                                )}
                                            </td>
                                            <td>
                                                <small>{sms.date_send}</small>
                                            </td>
                                            <td>
                                                <small>{sms.sms}</small>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button onClick={() => setPageNumer((page) => page - 1)} disabled={pageNumber === 1} variant="outline-primary">Volver</Button>{' '}
                            <Button onClick={() => setPageNumer((page) => page + 1)} disabled={pageNumber === lastPageFromAPI} variant="outline-primary">Siguiente</Button>{' '}
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <small>Sin notificaciones SMS enviadas.</small> 
                </>
            )}
        </>
    )
}
export default HistoryOfNotifications;