import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { UserLoggedContext } from '../contexts/UserLoggedContext';

function useOdtDetail() {
    const API_URL = process.env.REACT_APP_API_URL;
    const [codeApiStoreShopping, setCodeApiStoreShopping] = useState(0);
    const { idOdtSaved, setIdOdtSaved } = useContext(UserLoggedContext);

    const showOdtDetail = (id) => {
        return axios.get(API_URL + 'odt-detail' + '/' + id)
            .then(function (response) {
                //console.log(response.data.data);
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

 const deleteOdtItemsByOdt = (data) => {
    return axios.post(API_URL + "delete-odt-items-by-odt", data)
     .then(function (response) {
         console.log(response);
     })
     .catch(function (error) {
         console.log(error);
     })
     .finally(function () {
     });
}


    return {
        showOdtDetail,
        deleteOdtItemsByOdt,
    }
}

export default useOdtDetail;