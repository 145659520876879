import React, { useState, useContext } from 'react';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import useClient from '../../hooks/useClient';
import useProduct from '../../hooks/useProduct';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { redirect, useParams, useNavigate } from "react-router-dom";
import BreadCrumbs from '../../utils/BreadCrumbs';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';
import useService from '../../hooks/useService';

function FormUpdateService() {
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [codeApiHttp, setCodeApiHttp] = useState('');
    const { show, update } = useService();
    const [desableUpdateServiceBtn, setDesableUpdateServiceBtn] = useState(true);
    const [messageSuccessUpdateService, setMessageSuccessUpdateService] = useState(false);
    const [messageErrorUpdateService, setMessageErrorUpdateService] = useState(false);
    let idServiceParam = useParams().service_id;
    const queryClient = useQueryClient();
    const [service, setService] = useState([]);
    const { setGlobalClientIdParam, setGlobalClientTelephoneParam, globalClientIdParam, globalClientTelephoneParam } = useContext(UserLoggedContext);
    let path_test = "gestion-de-cliente/detalle/" + globalClientIdParam + "/" + globalClientTelephoneParam;
    const type_of_service = [
        "Instalación Básica - Equipo nuestro",
        "Instalación Básica - Equipo ajeno",
        "Mantención Preventiva",
        "Reparación",
        "Visita Técnica - Inspección",
        "Visita Técnica - Instalación, otra",
        "Instalación personalizada"
    ];


    const { isLoading, data: service_filtered, isError } = useQuery(
        ["service_filtered", idServiceParam],
        () => show(idServiceParam)
    );

    const { mutate: mutateUpdateService, isLoading: isLoadingMutation, error } = useMutation({
        mutationFn: update,
        onSuccess: () => {
            successUpdateServices();
            queryClient.invalidateQueries({ queryKey: ['services'] });
            queryClient.invalidateQueries({ queryKey: ['totalServicesAPI'] });
        },
        onError: () => {
            errorSuccessUpdateServices();
        }
    });

    const successUpdateServices = () => {
        setMessageSuccessUpdateService(true);
        setValidated(false);
        setTimeout(() => {
            setMessageSuccessUpdateService(false);
        }, 3000);
    }

    const errorSuccessUpdateServices = () => {
        setMessageErrorUpdateService(true);
        setValidated(false);
        setTimeout(() => {
            setMessageErrorUpdateService(false);
        }, 3000);
    }

    const handeInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setService({ ...service, [name]: value });
        setDesableUpdateServiceBtn(false);
    };

    const handleSubmit = (e) => {

        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }

        setValidated(true);

        if (form.checkValidity() === true) {
            e.preventDefault();
            mutateUpdateService({
                id: idServiceParam,
                name: service["service_name"],
                sku: service["service_sku"],
                hh: service["servive_hh"],
                note: service["service_note"],
            });

            setTimeout(() => {
                navigate('/gestion-de-servicio');
            }, 2000);
        }

    };

    if (isLoading) return (
        <>
            <Row>
                <Col>
                    <br></br>
                    <Spinner animation="border" variant="secondary" />
                </Col>
            </Row>
        </>
    );

    if (isError) return (
        <>
            <Row>
                <Col>
                    <Alert key='danger' variant='danger'>
                        <small>Hay un error. Por favor intente más tarde.</small>
                    </Alert>
                </Col>
            </Row>
        </>
    );

    return (
        <>
            {service_filtered ? (
                <>
                    <br></br>
                    <Row>
                        <Col>
                            <BreadCrumbs
                                paramSecondPage="Gestión de servicio"
                                paramSecondPageLink="gestion-de-servicio"
                                paramCurrentPage="Actualizar servicio"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs lg="5">
                        </Col>
                        <Col md="auto">
                            <h5>Actualizar servicio</h5>
                            {service_filtered.map((product, index) =>
                                <small key={index}><Badge bg="secondary">Servicio filtrado: {product.sku}</Badge></small>
                            )}
                        </Col>
                        <Col xs lg="5">
                        </Col>
                    </Row>
                    <br></br>
                    {service_filtered.map((service, index) => (
                        <Form noValidate validated={validated} onSubmit={handleSubmit} key={index}>
                            <Row className="mb-2">
                                <Form.Group as={Col}>
                                    <Form.Label>Tipo</Form.Label>
                                    <Form.Select defaultValue={service.name} name="service_name" onChange={handeInputChange} required >
                                        <option value="">Seleccione...</option>
                                        <option value={type_of_service[0]}>{type_of_service[0]}</option>
                                        <option value={type_of_service[1]}>{type_of_service[1]}</option>
                                        <option value={type_of_service[2]}>{type_of_service[2]}</option>
                                        <option value={type_of_service[3]}>{type_of_service[3]}</option>
                                        <option value={type_of_service[4]}>{type_of_service[4]}</option>
                                        <option value={type_of_service[5]}>{type_of_service[5]}</option>
                                        <option value={type_of_service[6]}>{type_of_service[6]}</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">Seleccione tipo.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} controlId="service_sku">
                                    <Form.Label>SKU</Form.Label>
                                    <Form.Control type="text" name="service_sku" defaultValue={service.sku} placeholder="GES9ECO-INV-VP" onChange={handeInputChange} required />
                                    <Form.Control.Feedback type="invalid">Ingrese nombre.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row className="mb-1">
                                <Form.Group as={Col} controlId="service_hh">
                                    <Form.Label>HH estimada</Form.Label>
                                    <Form.Control type="text" name="service_hh" defaultValue={service.hh} placeholder="2" onChange={handeInputChange} required />
                                    <Form.Control.Feedback type="invalid">Ingrese hh.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-12">
                                <Form.Group as={Col} controlId="service_note">
                                    <Form.Label>Observaciones</Form.Label>
                                    <Form.Control as="textarea" name="service_note" defaultValue={service.note} rows={3} placeholder="Servicio que debe acompañar a una nueva instalación." onChange={handeInputChange} />
                                    <Form.Control.Feedback type="invalid">Ingrese observaciones.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <br></br>
                            <Alert variant="success" show={messageSuccessUpdateService}>
                                <small>Servicio actualizado con éxito.</small>
                            </Alert>
                            <Alert variant="danger" show={messageErrorUpdateService}>
                                <small>Error al actualizar el servicio, inténtelo más tarde.</small>
                            </Alert>
                            <br></br>

                            <Row className="justify-content-md-center">
                                <Col xs lg={2}>
                                </Col>
                                <Col md="auto">
                                    <Button variant="primary" type='submit' disabled={desableUpdateServiceBtn}>
                                        {isLoadingMutation ? 'Actualizando...' : 'Actualizar'}
                                    </Button>
                                </Col>
                                <Col md="auto">
                                    <Button variant="outline-primary" href={'/gestion-de-equipo'}>
                                        Volver
                                    </Button>
                                </Col>
                                <Col xs lg={2}>
                                </Col>
                            </Row>
                        </Form>
                    ))}
                </>
            ) : (
                <>
                    <br></br>
                    <Row>
                        <Col>
                            <Alert key='danger' variant='danger'>
                                <small>No existe el equipo con Id filtrado. </small> <Link to={'/gestion-de-equipo'} className='nav-link'><small>Volver a Equipos.</small></Link>
                            </Alert>
                        </Col>
                    </Row>
                </>
            )}
            <br></br>
            <br></br>
        </>
    );


}

export default FormUpdateService;