import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { UserLoggedContext } from '../contexts/UserLoggedContext';

function useMailing() {
    const API_URL = process.env.REACT_APP_API_URL;

    const sendNewOdtNotifMail = (user, email, role, idOdt) => {
        return axios.post(API_URL + "send-new-odt-notif-mail", {
            user,
            email,
            role,
            idOdt,
        })
        .then(function (response) {
            //codeHttpApi = response.status;
            //setCodeApiStoreProduct(response.status);
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {
        });
    };

    const sendNewSolApprOdtNotifMail = (user, email, idOdt) => {
        return axios.post(API_URL + "send-new-appro-odt-notif-mail", {
            user,
            email,
            idOdt,
        })
        .then(function (response) {
            //codeHttpApi = response.status;
            //setCodeApiStoreProduct(response.status);
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {
        });
    };

    const sendNewOkOdtNotifTechMail = (user, email, idOdt) => {
        return axios.post(API_URL + "send-new-ok-odt-notif-tech-mail", {
            user,
            email,
            idOdt,
        })
        .then(function (response) {
            //codeHttpApi = response.status;
            //setCodeApiStoreProduct(response.status);
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {
        });
    };

    const sendNewOkOdtNotifAdminMail = (user, email, idOdt) => {
        return axios.post(API_URL + "send-new-ok-odt-notif-admin-mail", {
            user,
            email,
            idOdt,
        })
        .then(function (response) {
            //codeHttpApi = response.status;
            //setCodeApiStoreProduct(response.status);
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {
        });
    };

    const sendNotifMailErrorClientTelephone = (email, client, client_telephone) => {
        return axios.post(API_URL + "send-notif-mail-error-client-telephone", {
            email,
            client,
            client_telephone,
        })
        .then(function (response) {
            //codeHttpApi = response.status;
            //setCodeApiStoreProduct(response.status);
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {
        });
    };
    
    return {
        sendNewOdtNotifMail,
        sendNotifMailErrorClientTelephone,
        sendNewSolApprOdtNotifMail,
        sendNewOkOdtNotifTechMail,
        sendNewOkOdtNotifAdminMail,
    }
}

export default useMailing;