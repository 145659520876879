import axios from "axios";
import App from "../App";
const API_URL = process.env.REACT_APP_API_URL;
let codeHttpApi = 0;

/*test endpoint*/
const greet = () => {
    return axios.get(API_URL + 'hello')
        .then(function (response) {
            // manejar respuesta exitosa
            console.log(response);
            //const d = response.data;
        })
        .catch(function (error) {
            // manejar error
            console.log(error);
        })
        .finally(function () {
            // siempre sera executado
        });
}

const me = () => {
    return axios.get(API_URL + 'me')
        .then(function (response) {
            // manejar respuesta exitosa
            console.log(response);
            //const d = response.data;
        })
        .catch(function (error) {
            // manejar error
            console.log(error);
        })
        .finally(function () {
            // siempre sera executado
        });
}


const register = (name, email, password, role, telephone) => {
    return axios.post(API_URL + "register", {
        name,
        email,
        password,
        role,
        telephone,
    })
        .then(function (response) {
            // manejar respuesta exitosa
            console.log(response);
            //const d = response.data;
        })
        .catch(function (error) {
            // manejar error
            console.log(error);
        })
        .finally(function () {
            // siempre sera executado
        });
};

const login = (email, password) => {
    return axios
        .post(API_URL + "login", {
            email,
            password,
        })
        .then((response) => {
            if (response.data.user.email) {
                localStorage.setItem("user", JSON.stringify(response.data));
                setTimeout(() => {
                    window.location.href = "/dashboard";
                }, 2000);
            }
            console.log(response);
            return response;
        })
        .catch(function (error) {
            console.log(error.response.status);
            return error.response;
        })
        .finally(function () {

        });
};

const recoverAccount = (email, telephone, password) => {
    return axios
        .post(API_URL + "recover-account", {
            email,
            telephone,
            password,
        })
        .then((response) => {
            console.log(response);

            /*
            setTimeout(() => {
                window.location.href = "/iniciar-sesion";
            }, 2000);
            */

            return response;
        })
        .catch(function (error) {
            console.log(error.response.status);
            return error.response;
        })
        .finally(function () {

        });
};

const logout = () => {
    localStorage.removeItem("user");
    return axios.post(API_URL + "signout").then((response) => {
        setTimeout(() => {
            window.location.href = "/iniciar-sesion";
        }, 2000);
        return response.data;
    });
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
    greet,
    me,
    register,
    login,
    logout,
    getCurrentUser,
    recoverAccount,    
}

export default AuthService;