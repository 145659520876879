import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import { Card } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineFileSearch } from "react-icons/ai";
import useUser from "../../hooks/useUser";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import UserFilter from "./UserFilter";

function UserList() {
  const { index, destroy, showModal, filterUserFromAPI, lastPageFromAPI } = useUser();
  const [loading, setLoading] = useState(true);
  const [filteredClient, setFilteredClient] = useState([]);
  const { codeApiHttp } = useContext(UserLoggedContext);
  const [pageNumber, setPageNumer] = useState(1);
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const [userForDelete, setUserForDelete] = useState(0);
  const [desableDeleteBtn, setDesableDeleteBtn] = useState(false);
  const [messageSuccessUserDelete, setMessageSuccessUserDelete] = useState(false);
  const [messageErrorUserDelete, setMessageErrorUserDelete] = useState(false);
  const queryClient = useQueryClient();

  const { isLoading, data: users, isError, error } = useQuery(
    ['users', pageNumber],
    () => index(pageNumber));

  /*
      const { isLoading, data: clients, isError: isErrorDataFetchingAllClients } = useQuery(
        ['clients', pageNumber],
        () => index(pageNumber));
  */

  const deleteUserMutation = useMutation({
    mutationFn: destroy,
    onSuccess: () => {
      successUserDelete();
      queryClient.invalidateQueries({ queryKey: ['users'] })
    },
    onError: () => {
      errorUserDelete()
    }
  });

  useEffect(() => {
    index();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const showPopupDelete = (id) => {
    setUserForDelete(id);
    showModal(id);
    setShowDelete(true);
  }

  const handleDeleteUser = () => {
    deleteUserMutation.mutate(userForDelete);
  }

  const successUserDelete = () => {
    setMessageSuccessUserDelete(true);
    setDesableDeleteBtn(true);
    setTimeout(() => {
      setMessageSuccessUserDelete(false);
      setDesableDeleteBtn(false);
      setShowDelete(false);
    }, 3000);
  }

  const errorUserDelete = () => {
    setMessageErrorUserDelete(true);
    setTimeout(() => {
      setMessageErrorUserDelete(false);
    }, 3000);
  }

  if (isLoading) return (
    <>
      <Row>
        <Col>
          <br></br>
          <Spinner animation="border" variant="secondary" />
        </Col>
      </Row>
    </>
  );

  if (isError) return (
    <>
      <Row>
        <Col>
          <Alert key='danger' variant='danger'>
            Hay un error. Por favor intente más tarde.
          </Alert>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      {users ? (
        <>
          <Card>
            <Card.Header>
              <Row>
                <Col>
                  Filtro de usuarios
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <UserFilter />
              </Card.Text>
            </Card.Body>
          </Card>
          <br></br>
          <Row>
            <Col>
              <small>Total de usuarios&nbsp;</small><Badge bg="info">{users.length}</Badge>
            </Col>
          </Row>
          <Row>
            <Col>
              <br></br>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th><small>Cuenta</small></th>
                    <th><small>Nombre y apellido</small></th>
                    <th><small>Teléfono</small></th>
                    <th><small>Rol</small></th>
                    <th><small>Eliminar</small></th>
                    <th><small>Editar</small></th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) =>
                  (
                    <tr key={user.id}>
                      <td>
                        <Link className='nav-link nav-link-client' to={`/gestion-de-usuario/detalle/${user.id}`}>{user.email}</Link>
                      </td>
                      <td>
                        {user.name}
                      </td>
                      <td>
                        {user.telephone}
                      </td>
                      <td>
                        {user.role === '1' ? (
                          <>Administrador</>
                        ) : (null)}
                        {user.role === '2' ? (
                          <>Supervisor</>
                        ) : (null)}
                        {user.role === '3' ? (
                          <>Técnico</>
                        ) : (null)}
                      </td>
                      <td>
                        <Link onClick={() => { showPopupDelete(user.id) }} className='nav-link'><AiOutlineDelete /></Link>
                      </td>
                      <td>
                        <Link to={`/gestion-de-usuario/editar/${user.id}`} className='nav-link'><AiOutlineEdit /></Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
                        <Col>
                            <Button onClick={() => setPageNumer((page) => page - 1)} disabled={pageNumber === 1} variant="outline-primary">Volver</Button>{' '}
                            <Button onClick={() => setPageNumer((page) => page + 1)} disabled={pageNumber === lastPageFromAPI} variant="outline-primary">Siguiente</Button>{' '}
                        </Col>
                    </Row>
        </>
      ) : (
        <Row>
          <Col>
            <Alert key='warning' variant='warning'>
              Sin Usuarios ingresados.
            </Alert>
          </Col>
        </Row>
      )}


      {filterUserFromAPI ? (
        <Modal show={showDelete} onHide={handleCloseDelete} >
          <Modal.Header closeButton>
            <Modal.Title>Eliminación del usuario</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                ¿Quiere eliminar a&nbsp;

                {filterUserFromAPI.map((u, index) =>
                (
                  <small key={index}>
                    <b>{u.name}</b>
                  </small>
                ))
                }?

              </Col>
            </Row>
            <br></br>
            <Alert variant="success" show={messageSuccessUserDelete}>
              <small>Usuario eliminado con éxito.</small>
            </Alert>
            <Alert variant="danger" show={messageErrorUserDelete}>
              <small>Error al eliminar usuario, inténtelo más tarde.</small>
            </Alert>
            <br></br>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={handleDeleteUser} disabled={desableDeleteBtn}>
              Si, eliminar
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (null)}

    </>
  )

}

export default UserList;