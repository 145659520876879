import React, { useState, useContext } from 'react';
import { Calendar, Whisper, Popover, Badge, Panel } from 'rsuite';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { v4 as uiidv4, v4 } from 'uuid'
import es_ES from 'rsuite/locales/es_ES';
import { CustomProvider } from 'rsuite';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import useOdt from '../../hooks/useOdt';
import useMailing from '../../hooks/useMailing';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';
import useUser from '../../hooks/useUser';
import useShopping from '../../hooks/useShopping';
import useClient from '../../hooks/useClient';

const CalendarComponent = ({
  shopping,
  contactName,
  contactTelephone,
  serviceAddress,
  noteOdt,
  items,
  assignSupervisor,
  assignTech,
  client,
  admisionDate,
  admisionHour,
  telephoneClient
}) => {
  const [tasks, setTasks] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dateOdt, setDateOdt] = useState();
  const [hourOdt, setHourOdt] = useState();
  const [messageErrorPopupAgenda, setMessageErrorPopupAgenda] = useState(false);
  const [messageSuccessPopupAgenda, setMessageSuccessPopupAgenda] = useState(false);
  const [messageSuccessNewOdt, setMessageSuccessNewOdt] = useState(false);
  const [messageErrorNewOdt, setMessageErrorNewOdt] = useState(false);
  const [customDay, setCustomDay] = useState("");
  const [customMonth, setCustomMonth] = useState("");
  const [customYear, setCustomYear] = useState("");
  const { showShoppingDetailsItem, updateOdt } = useShopping();
  const { sendNewOdtNotifMail } = useMailing();
  const { storeOdt, storeDetailOdt, updateStateOdtNewNotif, filterOdtByTechnicial } = useOdt();
  const { updateClientAccoLastOdt } = useClient();
  const { show: showTechInformation, show: showSuperInformation, show: showAdminInformation, technicalFilter } = useUser();
  const queryClient = useQueryClient();
  const { userId, filterClientPhoneNewOdt, setFilterClientPhoneNewOdt, setResetDataGrid } = useContext(UserLoggedContext);
  const [shoppingFilter, setShoppingFilter] = useState(0);
  const [disabledButtonNewOdt, setDisabledButtonNewOdt] = useState(true);
  //let idItemForShoppingFilter = items[1]["id_item"];//fix
  const [idOdtSaved, setIdOdtSaved] = useState(0);
  const [idOdt, setIdOdt] = useState(0);


  const { mutate: mutateNewOdt } = useMutation({
    mutationFn: storeOdt,
    onSuccess: (odtId) => {
      setIdOdt(odtId);
      queryClient.invalidateQueries({ queryKey: ['odts'] });
      queryClient.invalidateQueries({ queryKey: ['shoppings'] });
      queryClient.invalidateQueries({ queryKey: ['history_of_shoppings'] });
      queryClient.invalidateQueries({ queryKey: ['history_of_odts'] });
      queryClient.invalidateQueries({ queryKey: ['technician_agenda'] });
      setTimeout(() => {
        items.map((item, index) => (
          <div key={index}>
            {mutateNewOdtDetail({
              odt: odtId,
              item: item.id_item,
              state_item: 0,
              note: '',
            })}
          </div>
        ));

        handleNotifications(odtId);

        shoppingUpdateOdt({
          shopping_id: shopping,
          odt: odtId,
          state_shopping: 1
        });

        updateClientLastOdt({
          client_telephone: telephoneClient,
          last_odt: odtId,
        });

        mutateUpdateStateOdtNewNotif({
          id: odtId,
          state: 1,
        });

      }, 2000);
    },
  });

  console.log(idOdt);

  const { mutate: mutateNewOdtDetail } = useMutation({ mutationFn: storeDetailOdt });

  const { mutate: shoppingUpdateOdt } = useMutation({
    mutationFn: updateOdt,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['shoppings'] })
    }
  });

  const { mutate: updateClientLastOdt } = useMutation({
    mutationFn: updateClientAccoLastOdt,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['clients'] })
    }
  });

  /*
  const { data: shopping_filtered } = useQuery(
    ["shopping_filtered", idItemForShoppingFilter],
    () => showShoppingDetailsItem(idItemForShoppingFilter)
  );
  */

  const { data: technician_information } = useQuery(
    ["technician_information", assignTech],
    () => showTechInformation(assignTech)
  );

  const { data: technician_agenda } = useQuery(
    ["technician_agenda", assignTech],
    () => filterOdtByTechnicial(assignTech)
  );

  //console.log(technician_agenda);

  const { data: supervisor_information } = useQuery(
    ["supervisor_information", assignSupervisor],
    () => showSuperInformation(assignSupervisor)
  );

  const { data: admin_information } = useQuery(
    ["admin_information", userId],
    () => showAdminInformation(userId)
  );

  const { mutate: mutateUpdateStateOdtNewNotif } = useMutation({
    mutationFn: updateStateOdtNewNotif,
    onSuccess: () => {
      //successUpdateClient()
      //queryClient.invalidateQueries({ queryKey: ['clients'] })
      //queryClient.invalidateQueries({ queryKey: ['client_filtered'] })
    },
    onError: () => {
      //errorSuccessUpdateClient()
    }
  });

  const handleDateSelection = (date) => {
    setCustomDay(date.getDate());
    setCustomMonth(date.getMonth() + 1);
    setCustomYear(date.getFullYear());
    setHourOdt()
    //dataTask = []; 
    handleShow();
  };

  const handleSuccessOdt = () => {
    if (hourOdt) {
      setTasks([...tasks, {
        id_odt: v4(),
        date_odt: customDay + '/' + customMonth + "/" + customYear,
        hour_odt: hourOdt,
        client_odt: client,
        supervisor_odt: assignSupervisor,
        technical_odt: assignTech,
        items_odt: items
      }]);

      setDisabledButtonNewOdt(false);
      setDateOdt(customDay + '/' + customMonth + "/" + customYear);
      setMessageErrorPopupAgenda(false);
      setMessageSuccessPopupAgenda(true);
      setTimeout(() => {
        handleClose();
        setMessageSuccessPopupAgenda(false);
      }, 2000);

    } else {
      setMessageErrorPopupAgenda(true);
      setMessageSuccessPopupAgenda(false);
    }
  }

  function renderCell(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const date_complete = day + '/' + month + "/" + year;
    let oldDataTask = [];
    let dataTask = [];
    if (tasks.length) {
      dataTask = tasks.filter(task => task.date_odt === date_complete);
    } else if (technician_agenda) {
      oldDataTask = technician_agenda.filter(task => task.visiting_date === date_complete);
    }

    if (dataTask.length) {
      return (
        <>
          {dataTask.length ? (
            <ul className="calendar-todo-list">
              {dataTask.map((task, index) => (
                <li key={index}>
                  <Badge /> <small><b>{task.hour_odt}</b> - Visita</small>
                </li>
              ))}
            </ul>
          ) : (<></>)}
        </>
      )
    }

    if (oldDataTask.length) {
      return (
        <>
          {oldDataTask.length ? (
            <ul className="calendar-todo-list">
              {oldDataTask.map((oldTask, index) => (
                <li key={index}>
                  <Badge /> <small><b>{oldTask.visiting_hour}</b> - Visita</small>
                </li>
              ))}
            </ul>
          ) : (<></>)}
        </>
      )
    }

    return null;
  }

  const handleSubmitNewOdt = (e) => {
    try {
      e.preventDefault();
      mutateNewOdt({
        client: client,
        supervisor: assignSupervisor,
        technical: assignTech,
        user: userId,
        contact_name: contactName,
        contact_telephone: contactTelephone,
        service_address: serviceAddress,
        admision_date: admisionDate,
        admision_hour: admisionHour,
        visiting_date: dateOdt,
        visiting_hour: hourOdt,
        note: noteOdt,
        state_odt: 0,
        shopping: shopping,
        client_telephone: telephoneClient,
      });

      setMessageSuccessNewOdt(true);
      setResetDataGrid(true);
      setTasks([]);

      setTimeout(() => {
        setFilterClientPhoneNewOdt();
        setMessageSuccessNewOdt(false);
        setDisabledButtonNewOdt(true);
      }, 2000);
    } catch (error) {
      console.log("Error: ", error);
    }
  }

  const handleNotifications = (paramOdtId) => {

    technician_information.map((t, index) => (
      <div key={index}>
        {sendNewOdtNotifMail(t.name, t.email, t.role, paramOdtId)}
      </div>
    ));

    supervisor_information.map((s, index) => (
      <div key={index}>
        {sendNewOdtNotifMail(s.name, s.email, s.role, paramOdtId)}
      </div>
    ));

    admin_information.map((a, index) => (
      <div key={index}>
        {sendNewOdtNotifMail(a.name, a.email, a.role, paramOdtId)}
      </div>
    ));


    //fix
    /*
     console.log(idOdtSaved);
     if (idOdtSaved) {
       mutateUpdateStateOdtNewNotif({
         id: idOdtSaved,
         state: 1
       });
     }
     */

  }

  return (
    <>
      <>
      </>
      <>
        {technician_agenda ? (
          <>
            <Alert variant="warning" key="warning">
              <small>Fechas no disponibles para Técnico seleccionado:</small>
            </Alert>
            <br></br>
            {technician_agenda ? (
              technician_agenda.map((agenda, index) =>
                <div key={index}>
                  <small>Día: {agenda.visiting_date} - Hora: {agenda.visiting_hour}</small>
                </div>
              )
            ) : (null)}
          </>
        ) : (
          <>
            Agenda del Técnico seleccionado:
          </>
        )}
        <Panel>
          <CustomProvider locale={es_ES}>
            <Calendar
              bordered
              onSelect={(date) => handleDateSelection(date)}
              renderCell={renderCell}
            />
          </CustomProvider>
        </Panel>
      </>

      {tasks.length ? (
        <>
          <Row className="justify-content-center">
            <Col xs={12}>
              <b>Resumen de agendamiento</b>
            </Col>
          </Row>
          <Row className="justify-content-start">
            <Col xs={12}>
              {tasks.map((task, index) => (
                <div key={index}>
                  <small>Día y hora de visita: {task.date_odt} - {task.hour_odt}</small>
                </div>
              ))}
            </Col>
          </Row>
        </>
      ) : (null)}

      <>
        <br></br>
        <Row>
          <Col>
            <Alert key='success' variant='success' show={messageSuccessNewOdt}>
              <small>
                ODT creada con éxito.
              </small>
            </Alert>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col>
            <Button variant="primary" onClick={(e) => { handleSubmitNewOdt(e) }}>
              Crear ODT
            </Button>
          </Col>
        </Row>
      </>

      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Agendar ODT</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="mb-1">
                <Form.Group as={Col} controlId="inputProductName">
                  <Form.Label><small>Ingrese hora de visita</small></Form.Label>
                  <Form.Control type="time" name="hourOdt" value={hourOdt} onChange={(e) => setHourOdt(e.target.value)} />
                </Form.Group>
              </Row>
              <Row className="mb-1">
                <Col>
                  <Alert key='success' variant='success' show={messageSuccessPopupAgenda}>
                    <small>
                      Visita agendada con éxito.
                    </small>
                  </Alert>
                </Col>
                <Alert key='danger' variant='danger' show={messageErrorPopupAgenda}>
                  <small>
                    Debe ingresar una hora.
                  </small>
                </Alert>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={() => handleSuccessOdt()} disabled={!hourOdt}>
              Agendar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
};

export default CalendarComponent;
