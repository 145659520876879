import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { AiOutlineBell } from "react-icons/ai";
import { UserLoggedContext } from '../contexts/UserLoggedContext';
import Badge from 'react-bootstrap/Badge';

function Navigation() {
  const { token, userLogged, userRole } = useContext(UserLoggedContext);
  const { pendingShopping, countNotification } = useContext(UserLoggedContext);

  const handleCloseSession = () => {
    setTimeout(() => {
      localStorage.clear();
      window.location.href = "/iniciar-sesion";
    }, 2000);
  }

  if (token) {
    return (
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Link to='/' className='navbar-brand'><h6>ERP Climatización Rancagua</h6></Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {userRole == 1 ? (
                <>
                  <Link to='/dashboard' className='nav-link'>Dashboard</Link>
                  <NavDropdown title="Cliente" id="basic-nav-dropdown">
                    <Link className='nav-link' to="/gestion-de-cliente"><small>Gestión de cliente</small></Link>
                  </NavDropdown>

                  <NavDropdown title="Compra" id="basic-nav-dropdown">
                    <Link className='nav-link' to="/gestion-de-compra"><small>Gestión de compra</small></Link>
                  </NavDropdown>


                  <NavDropdown title="ODT" id="basic-nav-dropdown">
                    <Link className='nav-link' to="/gestion-de-odt"><small>Gestión de ODT</small></Link>
                  </NavDropdown>

                  <NavDropdown title="Catálogo" id="basic-nav-dropdown">
                    <Link className='nav-link' to="/gestion-de-equipo"><small>Gestión de equipo</small></Link>
                    <NavDropdown.Divider />
                    <Link className='nav-link' to="/gestion-de-servicio"><small>Gestión de servicio</small></Link>
                  </NavDropdown>

                  <NavDropdown title="Panel de Control" id="basic-nav-dropdown">
                    <Link className='nav-link' to="/gestion-de-usuario"><small>Gestión de usuario</small></Link>
                  </NavDropdown>

                </>

              ) : (null)}

              {userRole == 2 ? (
                <>
                  <NavDropdown title="ODT" id="basic-nav-dropdown">
                    <Link className='nav-link' to="/gestion-de-odt">Gestión de ODT</Link>
                  </NavDropdown>
                </>
              ) : (null)}

              {userRole == 3 ? (
                <>
                  <NavDropdown title="ODT" id="basic-nav-dropdown">
                    <Link className='nav-link' to="/gestion-de-odt">Gestión de ODT</Link>
                  </NavDropdown>
                </>
              ) : (null)}


            </Nav>
          </Navbar.Collapse>

          {userRole == 1 ? (
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                <Link to='/notificaciones' className='nav-link'>
                  <AiOutlineBell />
                  {countNotification > 0 ? (
                    <Badge bg="danger">{countNotification}</Badge>
                  ) : (null)}
                </Link>
              </Navbar.Text>
            </Navbar.Collapse>
          ) : (null)}

          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <NavDropdown title={userLogged} id="basic-nav-dropdown">
                <NavDropdown.Item>
                  <Link className='nav-link' to="/perfil/:profile_id">
                    <small>Mi cuenta</small><br></br>
                    {userRole == 1 ? (
                      <small><Badge bg="secondary">Administrador</Badge></small>
                    ) : (null)}
                    {userRole == 2 ? (
                      <small><Badge bg="secondary">Supervisor</Badge></small>
                    ) : (null)}
                    {userRole == 3 ? (
                      <small><Badge bg="secondary">Técnico</Badge></small>
                    ) : (null)}
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link className='nav-link' to="/ayuda"><small>Ayuda</small></Link>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <Link className='nav-link' onClick={handleCloseSession}><small>Cerrar sesión</small></Link>
                </NavDropdown.Item>
              </NavDropdown>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default Navigation;