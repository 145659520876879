import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { UserLoggedProvider } from './contexts/UserLoggedContext.jsx';
import { QueryClient, QueryClientProvider } from 'react-query'

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

root.render(
    <QueryClientProvider client={queryClient}>
      <UserLoggedProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
      </UserLoggedProvider>
    </QueryClientProvider>
    )

    /* re-rendering in dev env with React.StrictMode
    root.render(
    <QueryClientProvider client={queryClient}>
      <UserLoggedProvider>
        <React.StrictMode>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </React.StrictMode>
      </UserLoggedProvider>
    </QueryClientProvider>
    )
     */