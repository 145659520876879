import React, { useEffect, useContext } from "react";
import { UserLoggedContext } from '../contexts/UserLoggedContext';
import AuthService from "../services/AuthService";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

function useCustomAuth(){
    const { userLogged, token, setToken, codeLogin, setCodeLogin } = useContext(UserLoggedContext);
    let responseHTTP;
    let codeResponse;
    
    useEffect(() => {
    }, []);

    function login(email, password){
        responseHTTP = AuthService.login(email, password);
        responseHTTP.then(function(response) {
            codeResponse = response.status;
            setCodeLogin(codeResponse);
            return codeResponse;
        })
        .catch(function(error) {
            codeResponse = error.status;
        })
        .finally(function () {

        });
    }

    const recoverAccountNew = (data) => {
        return axios.post(API_URL + "recover-account", data)
            .then(function (response) {
                console.log(response);
                console.log(response.data.code);
                setCodeLogin(response.data.code);
                return response.data.code;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    };

    function recoverAccount(email, telephone, password){
        responseHTTP = AuthService.recoverAccount(email, telephone, password);
        responseHTTP.then(function(response) {
            codeResponse = response.data.code;
            setCodeLogin(codeResponse);
            //return codeResponse;
        })
        .catch(function(error) {
            codeResponse = error.status;
        })
        .finally(function () {

        });
    }

    return {
        login,
        recoverAccount,
        recoverAccountNew,
    }
}

export default useCustomAuth;