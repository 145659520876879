import React, { useState, useEffect, useContext } from "react";
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import useProduct from "../../hooks/useProduct";
import ProductFilter from "./ProductFilter";

function ProductList() {
    const [loading, setLoading] = useState(true);
    const queryClient = useQueryClient();
    const idClientTest = 2;
    let total = [];
    const { index, deleteAll, totalProducts, destroy, lastPageFromAPI, totalProductFromAPI, showDeleteModal, filterProductFromAPIForDelModal } = useProduct();
    const [showDeleteAllProducts, setShowDeleteAllProducts] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [desableDeleteAllBtn, setDesableDeleteAllBtn] = useState(false);
    const [pageNumber, setPageNumer] = useState(1);
    const [productForDelete, setProductForDelete] = useState(0);
    const handleCloseDelete = () => setShowDelete(false);
    const handleCloseDeleteAllProducts = () => setShowDeleteAllProducts(false);
    const [desableDeleteBtn, setDesableDeleteBtn] = useState(false);
    const [messageSuccessProductDelete, setMessageSuccessProductDelete] = useState(false);
    const [messageErrorProductDelete, setMessageErrorProductDelete] = useState(false);
    const [messageSuccessAllProductsDelete, setMessageSuccessAllProductsDelete] = useState(false);
    const [messageErrorAllProductsDelete, setMessageErrorAllProductsDelete] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const { isLoading, data: products, isError, error } = useQuery(
        ['products', pageNumber],
        () => index(pageNumber));

    const { data: totalProductsAPI } = useQuery(
        ['totalProductsAPI'],
        () => totalProducts());

    const deleteProductMutation = useMutation({
        mutationFn: destroy,
        onSuccess: () => {
            successProductDelete();
            queryClient.invalidateQueries(['products']);
        },
        onError: () => {
            errorProductDelete();
        }
    });

    const deleteAllProductsMutation = useMutation({
        mutationFn: deleteAll,
        onSuccess: () => {
            successProductDeleteAll();
            queryClient.invalidateQueries(['products'])
        },
        onError: () => {
            errorProductDeleteAll()
        }
    });

    const successProductDeleteAll = () => {
        setMessageSuccessAllProductsDelete(true);
        setDesableDeleteAllBtn(true);
        setTimeout(() => {
            setMessageSuccessAllProductsDelete(false);
            setDesableDeleteAllBtn(false);
            setShowDeleteAllProducts(false);
        }, 2000);
    }

    const errorProductDeleteAll = () => {
        setMessageErrorAllProductsDelete(true);
        setTimeout(() => {
            setMessageErrorAllProductsDelete(false);
        }, 2000);
    }

    const successProductDelete = () => {
        setMessageSuccessProductDelete(true);
        setDesableDeleteBtn(true);
        setTimeout(() => {
            setMessageSuccessProductDelete(false);
            setDesableDeleteBtn(false);
            setShowDelete(false);
        }, 2000);
    }

    const errorProductDelete = () => {
        setMessageErrorProductDelete(true);
        setTimeout(() => {
            setMessageErrorProductDelete(false);
        }, 2000);
    }

    const handleDeleteProduct = () => {
        deleteProductMutation.mutate(productForDelete);
    }

    const handleDeleteAllProducts = () => {
        deleteAllProductsMutation.mutate();
    }

    const showPopupDeleteAllProducts = () => {
        setShowDeleteAllProducts(true);
    }

    const showPopupDelete = (id) => {
        setProductForDelete(id);
        showDeleteModal(id);
        setShowDelete(true);
    }

    if (isLoading) return (
        <>
            {isLoading ? (
                <Row>
                    <Col>
                        <br></br>
                        <Spinner animation="border" variant="secondary" />
                    </Col>
                </Row>
            ) : (null)}
        </>
    );

    if (isError) return (
        <>
            <Row>
                <Col>
                    <Alert key='danger' variant='danger'>
                        Hay un error. Por favor intente más tarde.
                    </Alert>
                </Col>
            </Row>
        </>
    );

    return (
        <>
            {products ? (
                <>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col>
                                    Filtro de equipos
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <ProductFilter />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <br></br>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col>
                                    Todos los equipos
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <br></br>
                                <Row>
                                    <Col></Col>
                                    <Col md="auto">
                                        <small>Total de equipos&nbsp;</small><Badge bg="info">{totalProductFromAPI ? (totalProductFromAPI) : (0)}</Badge>
                                    </Col>
                                    <Col xs lg="3">
                                        <Col xs={{ order: 12 }}><Button variant="outline-danger" onClick={showPopupDeleteAllProducts} size="sm">Eliminar todo</Button>{' '}</Col>
                                    </Col>
                                </Row>
                                <p></p>
                                <Row>
                                    <Col>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th><small>Nombre equipo</small></th>
                                                    <th><small>SKU</small></th>
                                                    <th><small>Marca</small></th>
                                                    <th><small>Modelo</small></th>
                                                    <th><small>BTU</small></th>
                                                    <th><small>Periodicidad mantención (días)</small></th>
                                                    <th><small>Eliminar</small></th>
                                                    <th><small>Editar</small></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {products.map((product) =>
                                                (
                                                    <tr key={product.id}>
                                                        <td>
                                                            <small>
                                                                <Link className='nav-link nav-link-client' to={`/gestion-de-equipo/detalle/${product.id}`}>{product.name}</Link>
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <small>{product.sku}</small>
                                                        </td>
                                                        <td>
                                                            <small>
                                                                {product.brand}
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <small>
                                                                {product.model}
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <small>
                                                                {product.btu}
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <small>{product.maintenance_periodicity}</small>
                                                        </td>
                                                        <td>
                                                            <Link onClick={() => { showPopupDelete(product.id) }} className='nav-link'><AiOutlineDelete /></Link>
                                                        </td>
                                                        <td>
                                                            <Link to={`/gestion-de-equipo/editar/${product.id}`} className='nav-link'><AiOutlineEdit /></Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button onClick={() => setPageNumer((page) => page - 1)} disabled={pageNumber === 1} variant="outline-primary">Volver</Button>{' '}
                                        <Button onClick={() => setPageNumer((page) => page + 1)} disabled={pageNumber === lastPageFromAPI} variant="outline-primary">Siguiente</Button>{' '}
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </>
            ) : (
                <Row>
                    <Col>
                        <Alert key='warning' variant='warning'>
                            Sin equipos ingresados.
                        </Alert>
                    </Col>
                </Row>
            )}

            {filterProductFromAPIForDelModal ? (
                <Modal show={showDelete} onHide={handleCloseDelete} >
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminación del equipo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                ¿Quieres eliminar a&nbsp;

                                {filterProductFromAPIForDelModal.map((product, index) =>
                                (
                                    <small key={index}>
                                        <b>{product.name}</b>
                                    </small>
                                ))
                                }?

                            </Col>
                        </Row>
                        <br></br>
                        <Alert variant="success" show={messageSuccessProductDelete}>
                            <small>Equipo eliminado con éxito.</small>
                        </Alert>
                        <Alert variant="danger" show={messageErrorProductDelete}>
                            <small>Error al eliminar equipo, inténtelo más tarde.</small>
                        </Alert>
                        <br></br>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDelete}>
                            Cerrar
                        </Button>
                        <Button variant="primary" onClick={handleDeleteProduct} disabled={desableDeleteBtn}>
                            Si, eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : (null)}

            <Modal show={showDeleteAllProducts} onHide={handleCloseDeleteAllProducts} >
                <Modal.Header closeButton>
                    <Modal.Title>Eliminación de equipos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            {totalProductsAPI ? (
                                <>
                                    ¿Quieres eliminar <b>{totalProductsAPI['total']}</b> equipos(s)?
                                </>
                            ) : (null)}
                        </Col>
                    </Row>
                    <br></br>
                    <Alert variant="success" show={messageSuccessAllProductsDelete}>
                        <small>Equipos eliminados con éxito.</small>
                    </Alert>
                    <Alert variant="danger" show={messageErrorAllProductsDelete}>
                        <small>Error al eliminar equipos, inténtelo más tarde.</small>
                    </Alert>
                    <br></br>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteAllProducts}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleDeleteAllProducts} disabled={desableDeleteAllBtn}>
                        Si, eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default ProductList;