import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AiOutlineExport } from "react-icons/ai";
import { AiOutlineFileAdd } from "react-icons/ai";
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import useClient from "../../hooks/useClient";
import useShopping from "../../hooks/useShopping";
import Alert from 'react-bootstrap/Alert';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Row } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import BreadCrumbs from "../../utils/BreadCrumbs";

function Shopping() {
    let shoppingIdParam = useParams().shopping_id;
    const { show, showItems, showShoppingDetails, filterShoppingFromAPI } = useShopping();
    console.log(shoppingIdParam);

    const { isLoading: isLoadingShopingFiltered, data: shopping_filtered, isError: isErrorShoppingFiltered } = useQuery(
        ["shopping_filtered", shoppingIdParam],
        () => show(shoppingIdParam)
    );

    const { isLoading: isLoadingShoppingItems, data: shopping_items_filtered, isError: isErrorShoppingItems } = useQuery(
        ["shopping_items_filtered", shoppingIdParam],
        () => showItems(shoppingIdParam)
    );

    console.log(shopping_filtered);

    if (isLoadingShopingFiltered) return (
        <>
            <Row>
                <Col>
                    <br></br>
                    <Spinner animation="border" variant="secondary" />
                </Col>
            </Row>
        </>
    );

    if (isErrorShoppingFiltered) return (
        <>
            <Row>
                <Col>
                    <Alert key='danger' variant='danger'>
                        <small>Hay un error. Por favor intente más tarde.</small>
                    </Alert>
                </Col>
            </Row>
        </>
    );

    return (
        <>
            {shopping_filtered ? (
                <>
                    <br></br>
                    <Row>
                        <Col>
                            <BreadCrumbs
                                paramSecondPage="Gestión de compra"
                                paramSecondPageLink="gestion-de-compra"
                                paramCurrentPage="Detalle de compra"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs lg="5">
                        </Col>
                        <Col md="auto">
                            <h5>Detalle de compra</h5><br></br>
                            {shopping_filtered.map((shopping, index) =>
                                <small key={index}><Badge bg="secondary">Compra filtrada: {shopping.id_shopping}</Badge></small>
                            )}
                        </Col>
                        <Col xs lg="5">
                        </Col>
                    </Row>
                    <br></br>
                    {shopping_filtered.map((shopping, index) =>
                        <>
                            <Card key={index}>
                                <Card.Header>Información de compra</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <Row>
                                            <Col xs={1}>
                                                <small>
                                                    <b>Id:</b>
                                                    {shopping.id_shopping}
                                                </small>
                                            </Col>
                                            <Col xs={2}>
                                                <small><b>Estado:&nbsp;</b></small>
                                                {shopping.state_shopping === 0 ? (
                                                    <small>
                                                        <Badge bg="danger">Nueva</Badge>
                                                    </small>
                                                ) : (null)}
                                                {shopping.state_shopping === 1 ? (
                                                    <small>
                                                        <Badge bg="warning">ODT asignada</Badge>
                                                    </small>
                                                ) : (null)}
                                                {shopping.state_shopping === 2 ? (
                                                    <small>
                                                        <Badge bg="success">Cerrada</Badge>
                                                    </small>
                                                ) : (null)}
                                            </Col>
                                            <Col xs={3}>
                                                <small>
                                                    <b>Cliente: </b>
                                                    {shopping.client_first_name} {shopping.client_last_name}
                                                </small>
                                            </Col>

                                            <Col xs={3}>
                                                <small>
                                                    <b>Teléfono: </b>
                                                    {shopping.client_telephone}
                                                </small>
                                            </Col>

                                            <Col xs={3}>
                                                <small>
                                                    <b>Boleta/Factura: </b>
                                                </small>
                                                {shopping.invoice ? (
                                                    <small>{shopping.invoice}</small>
                                                ) : (
                                                    <small>Sin información.</small>
                                                )}
                                            </Col>

                                        </Row>
                                        <br></br>
                                        <Row>
                                            <Col xs={3}>
                                                <small>
                                                    <b>Fecha ingreso: </b>
                                                    {shopping.date_shop}
                                                </small>
                                            </Col>
                                            <Col xs={3}>
                                                <small>
                                                    <b>Ingresado por: </b>
                                                    {shopping.user_admin}
                                                </small>
                                            </Col>
                                            <Col xs={3}>
                                                <small>
                                                    <b>ODT asignada: </b>
                                                </small>
                                                {shopping.odt ? (
                                                    <small>{shopping.odt} <Link to={`/gestion-de-odt/detalle/${shopping.odt}`} target="_blank"> <AiOutlineExport /></Link></small>
                                                ) : (
                                                    <>
                                                        <small>Sin ODT</small>&nbsp;
                                                        <Link to={`/gestion-de-odt/`}><AiOutlineFileAdd /></Link>
                                                    </>


                                                )}
                                            </Col>
                                        </Row>
                                        <br></br>
                                        <Row>
                                            <Col xs={12}>
                                                <small>
                                                    <b>Observaciones: </b><br></br>
                                                    {shopping.note_shop === "" || shopping.note_shop === null ? (
                                                        <>Sin notas.</>
                                                    ) : (shopping.note_shop)}
                                                </small>
                                            </Col>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </>
                    )}
                    <br></br>
                    {shopping_items_filtered ? (
                        <>
                            <Card>
                                <Card.Header>Desglose</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <Row>
                                            <Col>
                                                <br></br>
                                                <Table striped bordered hover responsive>
                                                    <thead>
                                                        <tr>
                                                            <th><small>Id ítem</small></th>
                                                            <th><small>Detalle ítem</small></th>
                                                            <th><small>Cantidad</small></th>
                                                            <th><small>Nota</small></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {shopping_items_filtered.map((item, index) =>
                                                            <tr key={index}>
                                                                <td>
                                                                    <small>
                                                                        {item.id}
                                                                    </small>
                                                                </td>
                                                                {item.product ? (
                                                                    <td>
                                                                        <small>
                                                                            <b>(SKU: {item.product_sku})</b> {item.product}
                                                                        </small>
                                                                    </td>
                                                                ) : (
                                                                    <td>
                                                                        <small>
                                                                            <b>(SKU: {item.service_sku})</b> {item.service}
                                                                        </small>
                                                                    </td>
                                                                )}
                                                                <td>
                                                                    <small>
                                                                        {item.cant}
                                                                    </small>
                                                                </td>
                                                                <td>
                                                                    {item.note == null ? (
                                                                        <>
                                                                            <small>
                                                                                Sin notas.
                                                                            </small>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <small>
                                                                                {item.note}
                                                                            </small>
                                                                        </>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </>
                    ) : (null)}

                    <br></br>
                    <Row className="justify-content-md-center">
                        <Col xs lg={2}>
                        </Col>
                        <Col md="auto">
                            <Button variant="outline-primary" href={'/gestion-de-compra'}>
                                Volver
                            </Button>
                        </Col>
                        <Col xs lg={2}>
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <br></br>
                    <Row>
                        <Col>
                            <Alert key='danger' variant='danger'>
                                <small>No existe la compra con id filtrado.</small> <Link to={'/gestion-de-compra'} className='nav-link'><b><small>Volver a compras.</small></b></Link>
                            </Alert>
                        </Col>
                    </Row>
                </>
            )
            }
            <br></br>
            <br></br>
        </>
    );


}
export default Shopping;