import React from "react";
import axios from "axios";

function useWorkOdt(){
    const API_URL = process.env.REACT_APP_API_URL;

    const show = (id) => {
        return axios.get(API_URL + 'show-by-odt' + '/' + id)
            .then(function (response) {
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const store = (data) => {
        return axios.post(API_URL + "task", data)
            .then(function (response) {
                console.log(response);
                if (response.data.code === 200) {
                    console.log('Ingreso correcto.');
                }
                //setCodeApi(response.data.code);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    };

    return {
        store,
        show,
    }
}

export default useWorkOdt;    
