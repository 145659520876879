import React, { useState, useContext } from 'react';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import useClient from '../../hooks/useClient';
import useProduct from '../../hooks/useProduct';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { redirect, useParams, useNavigate } from "react-router-dom";
import BreadCrumbs from '../../utils/BreadCrumbs';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';
import useUser from '../../hooks/useUser';

function FormUpdateUser() {
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const { show, update } = useUser();
    const [desableUpdateUserBtn, setDesableUpdateUserBtn] = useState(true);
    const [messageSuccessUpdateUser, setMessageSuccessUpdateUser] = useState(false);
    const [messageErrorUpdateUser, setMessageErrorUpdateUser] = useState(false);
    let userIdParam = useParams().user_id;
    const queryClient = useQueryClient();
    const [user, setUser] = useState([]);
    const roles = ['1', '2', '3'];

    const { isLoading: isLoadingUserFiltered, data: user_filtered, isError: isErrorUserFiltered } = useQuery(
        ["user_filtered", userIdParam],
        () => show(userIdParam)
    );

    const { mutate: mutateUpdateUser, isLoading: isLoadingMutation, error } = useMutation({
        mutationFn: update,
        onSuccess: () => {
            successUpdateUser();
            queryClient.invalidateQueries({ queryKey: ['users'] });
            queryClient.invalidateQueries({ queryKey: ['totalUsersAPI'] });
        },
        onError: () => {
            errorSuccessUpdateUser();
        }
    });

    const successUpdateUser = () => {
        setMessageSuccessUpdateUser(true);
        setValidated(false);
        setTimeout(() => {
            setMessageSuccessUpdateUser(false);
        }, 3000);
    }

    const errorSuccessUpdateUser = () => {
        setMessageErrorUpdateUser(true);
        setValidated(false);
        setTimeout(() => {
            setMessageErrorUpdateUser(false);
        }, 3000);
    }

    const handeInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
        setDesableUpdateUserBtn(false);
    };

    const handleSubmit = (e) => {

        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }

        setValidated(true);

        if (form.checkValidity() === true) {
            e.preventDefault();
            mutateUpdateUser({
                id: userIdParam,
                name: user["user_name"],
                email: user["user_email"],
                telephone: user["user_telephone"],
                role: user["user_role"],
            });

            setTimeout(() => {
                navigate('/gestion-de-usuario');
            }, 2000000);
        }

    };

    if (isLoadingUserFiltered) return (
        <>
            <Row>
                <Col>
                    <br></br>
                    <Spinner animation="border" variant="secondary" />
                </Col>
            </Row>
        </>
    );

    if (isErrorUserFiltered) return (
        <>
            <Row>
                <Col>
                    <Alert key='danger' variant='danger'>
                        Hay un error. Por favor intente más tarde.
                    </Alert>
                </Col>
            </Row>
        </>
    );

    return (
        <>
            {user_filtered ? (
                <>
                    <br></br>
                    <Row>
                        <Col>
                            <BreadCrumbs
                                paramSecondPage="Gestión de usuario"
                                paramSecondPageLink="gestion-de-usuario"
                                paramCurrentPage="Actualizar usuario"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs lg="5">
                        </Col>
                        <Col md="auto">
                            <h5>Actualizar usuario</h5>
                            {user_filtered.map((user, index) =>
                                <small key={index}><Badge bg="secondary">Usuario filtrado: {user.name}</Badge></small>
                            )}
                        </Col>
                        <Col xs lg="5">
                        </Col>
                    </Row>
                    <br></br>
                    {user_filtered.map((user, index) => (
                        <Form noValidate validated={validated} onSubmit={handleSubmit} key={index}>
                            <Row className="mb-2">
                                <Form.Group as={Col} controlId="user_name">
                                    <Form.Label>Nombre y apellido</Form.Label>
                                    <Form.Control type="text" name="user_name" defaultValue={user.name} placeholder="Juan Pérez" onChange={handeInputChange} required />
                                    <Form.Control.Feedback type="invalid">Ingrese nombre y apellido.</Form.Control.Feedback>
                                </Form.Group>                                

                                <Form.Group as={Col} controlId="user_email">
                                    <Form.Label>E-mail</Form.Label>
                                    <Form.Control type="text" name="user_email" defaultValue={user.email} placeholder="juan.perez@gmail.com" onChange={handeInputChange} required />
                                    <Form.Control.Feedback type="invalid">Ingrese e-mail.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className='mb-2'>

                            <Form.Group as={Col} controlId="user_telephone">
                                    <Form.Label>Teléfono</Form.Label>
                                    <Form.Control type="text" name="user_telephone" defaultValue={user.telephone} placeholder="56912345678" onChange={handeInputChange} required />
                                    <Form.Control.Feedback type="invalid">Ingrese teléfono.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} controlId="user_role">
                                    <Form.Label>Rol</Form.Label>
                                    <Form.Select defaultValue={user.role} name="user_role" onChange={handeInputChange} required >
                                        <option value="0">Seleccione...</option>
                                        <option value={roles[0]}>Administrador</option>
                                        <option value={roles[1]}>Supervisor</option>
                                        <option value={roles[2]}>Técnico</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">Seleccione un rol.</Form.Control.Feedback>
                                </Form.Group>

                            </Row>

                            <br></br>
                            <Alert variant="success" show={messageSuccessUpdateUser}>
                                <small>Usuario actualizado con éxito.</small>
                            </Alert>
                            <Alert variant="danger" show={messageErrorUpdateUser}>
                                <small>Error al actualizar el usuario, inténtelo más tarde.</small>
                            </Alert>
                            <br></br>

                            <Row className="justify-content-md-center">
                                <Col xs lg={2}>
                                </Col>
                                <Col md="auto">
                                    <Button variant="primary" type='submit' disabled={desableUpdateUserBtn}>
                                        {isLoadingMutation ? 'Actualizando...' : 'Actualizar'}
                                    </Button>
                                </Col>
                                <Col md="auto">
                                    <Button variant="outline-primary" href={'/gestion-de-usuario'}>
                                        Volver
                                    </Button>
                                </Col>
                                <Col xs lg={2}>
                                </Col>
                            </Row>
                        </Form>
                    ))}
                </>
            ) : (
                <>
                    <br></br>
                    <Row>
                        <Col>
                            <Alert key='danger' variant='danger'>
                                <small>No existe el equipo con Id filtrado. </small> <Link to={'/gestion-de-equipo'} className='nav-link'><small>Volver a Equipos.</small></Link>
                            </Alert>
                        </Col>
                    </Row>
                </>
            )}
            <br></br>
            <br></br>
        </>
    );


}

export default FormUpdateUser;