import React, { useState, useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';
import { AiOutlineExport } from "react-icons/ai";
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineFileSearch } from "react-icons/ai";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import useShopping from "../../hooks/useShopping";
import useOdtDetail from "../../hooks/useOdtDetail";
import ShoppingFilter from "./ShoppingFilter";

function ShoppingList() {
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumer] = useState(1);
    const [show, setShow] = useState(false);
    const { index, destroy, deleteAll, totalShoppings, lastPageFromAPI, totalDataFromAPI, filterShoppingForDeleteFromAPI, updateInfoClientByShopping } = useShopping();
    const { deleteOdtItemsByOdt } = useOdtDetail();
    const [showDelete, setShowDelete] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    const [desableDeleteBtn, setDesableDeleteBtn] = useState(false);
    const [messageSuccessShoppingDelete, setMessageSuccessShoppingDelete] = useState(false);
    const [messageErrorShoppingDelete, setMessageErrorShoppingDelete] = useState(false);
    const [shoppingForDelete, setShoppingForDelete] = useState(0);
    const [odtOfShoppingForDelete, setOdtOfShoppingForDelete] = useState(0);
    const [desableDeleteAllBtn, setDesableDeleteAllBtn] = useState(false);
    const [clientTelephoneShoppingForDelete, setClientTelephoneShoppingForDelete] = useState("");
    const [showDeleteAllShoppings, setShowDeleteAllShoppings] = useState(false);
    const handleCloseDeleteAllShoppings = () => setShowDeleteAllShoppings(false);
    const [messageSuccessAllShoppingsDelete, setMessageSuccessAllShoppingsDelete] = useState(false);
    const [messageErrorAllShoppingsDelete, setMessageErrorAllShoppingsDelete] = useState(false);

    const { isLoading, data: shoppings, isError, error } = useQuery(
        ['shoppings', pageNumber],
        () => index(pageNumber));


    const { data: totalShoppingsAPI } = useQuery(
        ['totalShoppingsAPI'],
        () => totalShoppings());

    const deleteShoppingMutation = useMutation({
        mutationFn: destroy,
        onSuccess: () => {
            successShoppingDelete();
            queryClient.invalidateQueries(['shoppings']);
            queryClient.invalidateQueries(['clients']);
            queryClient.invalidateQueries(['odts']);
        },
        onError: () => {
            errorShoppingDelete();
        }
    });

    const { mutate: mutateUpdateInfoClientByShopping } = useMutation({
        mutationFn: updateInfoClientByShopping,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['shoppings'] });
            queryClient.invalidateQueries(['clients']);
            queryClient.invalidateQueries(['history_of_shoppings']);
        }
    });

    const { mutate: mutateDeleteOdtItems } = useMutation({
        mutationFn: deleteOdtItemsByOdt,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['shoppings'] });
            queryClient.invalidateQueries(['clients']);
            queryClient.invalidateQueries(['history_of_shoppings']);
        }
    });


    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const showPopupDelete = (idShopping, clientTelephone, odtOfShopping) => {
        setShoppingForDelete(idShopping);
        console.log(idShopping);
        console.log(clientTelephone);
        console.log(odtOfShopping);
        setClientTelephoneShoppingForDelete(clientTelephone);
        if (odtOfShopping != 0) {
            setOdtOfShoppingForDelete(odtOfShopping);
        }
        setShowDelete(true);
    }

    const deleteAllShoppingsMutation = useMutation({
        mutationFn: deleteAll,
        onSuccess: () => {
            successShoppingsDeleteAll();
            queryClient.invalidateQueries(['shoppings'])
        },
        onError: () => {
            errorShoppingsDeleteAll()
        }
    });

    const successShoppingsDeleteAll = () => {
        setMessageSuccessAllShoppingsDelete(true);
        setDesableDeleteAllBtn(true);
        setTimeout(() => {
            setMessageSuccessAllShoppingsDelete(false);
            setDesableDeleteAllBtn(false);
            setShowDeleteAllShoppings(false);
        }, 2000);
    }

    const errorShoppingsDeleteAll = () => {
        setMessageErrorAllShoppingsDelete(true);
        setTimeout(() => {
            setMessageErrorAllShoppingsDelete(false);
        }, 2000);
    }

    //fixing
    const handleDeleteShopping = () => {
        deleteShoppingMutation.mutate(shoppingForDelete);

        setTimeout(() => {
            mutateUpdateInfoClientByShopping({
                client_telephone: clientTelephoneShoppingForDelete
            });

            mutateDeleteOdtItems({
                odt: odtOfShoppingForDelete
            });

        }, 1000);
    }

    const successShoppingDelete = () => {
        setMessageSuccessShoppingDelete(true);
        setDesableDeleteBtn(true);
        setTimeout(() => {
            setMessageSuccessShoppingDelete(false);
            setDesableDeleteBtn(false);
            setShowDelete(false);
        }, 2000);
    }

    const errorShoppingDelete = () => {
        setMessageErrorShoppingDelete(true);
        setTimeout(() => {
            setMessageErrorShoppingDelete(false);
        }, 2000);
    }

    const showPopupDeleteAllShoppings = () => {
        setShowDeleteAllShoppings(true);
    }

    const handleDeleteAllShoppings = () => {
        deleteAllShoppingsMutation.mutate();
    }

    if (isLoading) return (
        <>
            <Row>
                <Col>
                    <br></br>
                    <Spinner animation="border" variant="secondary" />
                </Col>
            </Row>
        </>
    );

    if (isError) return (
        <>
            <Row>
                <Col>
                    <Alert key='danger' variant='danger'>
                        Hay un error. Por favor intente más tarde.
                    </Alert>
                </Col>
            </Row>
        </>
    );

    return (
        <>
            {shoppings ? (
                <>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col>
                                    Filtro de compras
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <ShoppingFilter />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <br></br>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col>Todas las compras</Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <br></br>
                                <Row>
                                    <Col></Col>
                                    <Col md="auto">
                                        <small>Total de compras&nbsp;</small><Badge bg="info">{totalDataFromAPI ? (totalDataFromAPI) : (0)}</Badge>
                                    </Col>
                                    <Col xs lg="3">
                                        <Col xs={{ order: 12 }}><Button variant="outline-danger" onClick={showPopupDeleteAllShoppings} size="sm">Eliminar todo</Button>{' '}</Col>
                                    </Col>
                                </Row>
                                <p></p>
                                <Row>
                                    <Col>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th><small>Id compra</small></th>
                                                    <th><small>RUN/RUT cliente</small></th>
                                                    <th><small>Cliente</small></th>
                                                    <th><small>Teléfono cliente</small></th>
                                                    <th><small>Fecha ingreso</small></th>
                                                    <th><small>ODT asociada</small></th>
                                                    <th><small>Estado</small></th>
                                                    <th><small>Eliminar</small></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {shoppings.map((shop) =>
                                                (
                                                    <tr key={shop.id}>
                                                        <td>
                                                            <small>
                                                                <Link to={`/gestion-de-compra/detalle/${shop.id}`} className='nav-link nav-link-client'>{shop.id}</Link>
                                                            </small>
                                                        </td>
                                                        {shop.run_rut != null ? (
                                                            <td>
                                                                <small>{shop.run_rut}</small>
                                                            </td>
                                                        ) : (
                                                            <td>
                                                                <small>Sin información.</small>
                                                            </td>
                                                        )}
                                                        <td>
                                                            <small>{shop.first_name} {shop.last_name}</small>
                                                        </td>
                                                        {shop.client_telephone != null ? (
                                                            <td>
                                                                <small>{shop.client_telephone}</small>
                                                            </td>
                                                        ) : (
                                                            <td>
                                                                <small>Sin información.</small>
                                                            </td>
                                                        )}
                                                        <td>
                                                            <small>{shop.date_shop}</small>
                                                        </td>
                                                        {shop.odt == 0 ? (
                                                            <td>
                                                                <small>
                                                                    Sin ODT
                                                                </small>
                                                            </td>
                                                        ) : (
                                                            <td>
                                                                <small>
                                                                    {shop.odt} <Link to={`/gestion-de-odt/detalle/${shop.odt}`} target="_blank"> <AiOutlineExport /></Link>
                                                                </small>
                                                            </td>
                                                        )}
                                                        {shop.state_shopping == 0 ? (
                                                            <td>
                                                                <small>
                                                                    <Badge bg="danger">Nueva</Badge>
                                                                </small>
                                                            </td>
                                                        ) : (null)}
                                                        {shop.state_shopping == 1 ? (
                                                            <td>
                                                                <small>
                                                                    <Badge bg="warning">ODT asignada</Badge>
                                                                </small>
                                                            </td>
                                                        ) : (null)}
                                                        {shop.state_shopping == 2 ? (
                                                            <td>
                                                                <small>
                                                                    <Badge bg="success">Cerrada</Badge>
                                                                </small>
                                                            </td>
                                                        ) : (null)}
                                                        <td>
                                                            {shop.odt == 0 ? (
                                                                <Link onClick={() => {
                                                                    showPopupDelete(shop.id, shop.client_telephone)

                                                                }} className='nav-link'><AiOutlineDelete /></Link>
                                                            ) : (
                                                                <Link onClick={() => { showPopupDelete(shop.id, shop.client_telephone, shop.odt) }} className='nav-link'><AiOutlineDelete /></Link>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button onClick={() => setPageNumer((page) => page - 1)} disabled={pageNumber === 1} variant="outline-primary">Volver</Button>{' '}
                                        <Button onClick={() => setPageNumer((page) => page + 1)} disabled={pageNumber === lastPageFromAPI} variant="outline-primary">Siguiente</Button>{' '}
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>

                </>
            ) : (
                <Row>
                    <Col>
                        <Alert key='warning' variant='warning'>
                            <small>Sin compras ingresadas.</small>
                        </Alert>
                    </Col>
                </Row>
            )}

            <Modal show={showDelete} onHide={handleCloseDelete} >
                <Modal.Header closeButton>
                    <Modal.Title>Eliminación de compra</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            ¿Quieres eliminar la compra&nbsp;<b>{shoppingForDelete}</b>?
                        </Col>
                    </Row>
                    <br></br>
                    <Alert variant="success" show={messageSuccessShoppingDelete}>
                        <small>Compra eliminada con éxito.</small>
                    </Alert>
                    <Alert variant="danger" show={messageErrorShoppingDelete}>
                        <small>Error al eliminar compra, inténtelo más tarde.</small>
                    </Alert>
                    <br></br>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDelete}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleDeleteShopping} disabled={desableDeleteBtn}>
                        Si, eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteAllShoppings} onHide={handleCloseDeleteAllShoppings} >
                <Modal.Header closeButton>
                    <Modal.Title>Eliminación de compras</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            {totalShoppingsAPI ? (
                                <>
                                    ¿Quieres eliminar <b>{totalShoppingsAPI['total']}</b> compras(s)?
                                </>
                            ) : (null)}
                        </Col>
                    </Row>
                    <br></br>
                    <Alert variant="success" show={messageSuccessAllShoppingsDelete}>
                        <small>Compras eliminados con éxito.</small>
                    </Alert>
                    <Alert variant="danger" show={messageErrorAllShoppingsDelete}>
                        <small>Error al eliminar compras, inténtelo más tarde.</small>
                    </Alert>
                    <br></br>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteAllShoppings}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleDeleteAllShoppings} disabled={desableDeleteAllBtn}>
                        Si, eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default ShoppingList;