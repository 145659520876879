import React, { useEffect, useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TabCustom from "../../utils/TabsCustom";
import Spinner from 'react-bootstrap/Spinner';
import BreadCrumbs from "../../utils/BreadCrumbs";

function ProductAdministrator() {
  const [loading, setLoading] = useState(true);
  const containerType = 'ProductAdministrator';

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);


  return (
    <>

      {loading ? (
        <div>
          <br></br>
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <div>
          <br></br>
          <Row>
            <Col>
              <BreadCrumbs
                paramCurrentPage="Gestión catálogo de equipos"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Gestión catálogo de equipos</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <br></br>
              <TabCustom
                containerType={containerType}
                option_one='Ingresar equipo'
                option_two='Lista equipos'
              />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default ProductAdministrator;