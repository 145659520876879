import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AiOutlineExport } from "react-icons/ai";
import { AiOutlineFileAdd } from "react-icons/ai";
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import useClient from "../../hooks/useClient";
import useShopping from "../../hooks/useShopping";
import useProduct from "../../hooks/useProduct";
import Alert from 'react-bootstrap/Alert';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Row } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import BreadCrumbs from "../../utils/BreadCrumbs";
import useUser from "../../hooks/useUser";

function User() {
    let userIdParam = useParams().user_id;
    //const { show, showForSku } = useProduct();
    const { show } = useUser();

    const { isLoading: isLoadingUserFiltered, data: user_filtered, isError: isErrorUserFiltered } = useQuery(
        ["user_filtered", userIdParam],
        () => show(userIdParam)
    );

    if (isLoadingUserFiltered) return (
        <>
            <Row>
                <Col>
                    <br></br>
                    <Spinner animation="border" variant="secondary" />
                </Col>
            </Row>
        </>
    );

    if (isErrorUserFiltered) return (
        <>
            <Row>
                <Col>
                    <Alert key='danger' variant='danger'>
                        <small>Hay un error. Por favor intente más tarde.</small>
                    </Alert>
                </Col>
            </Row>
        </>
    );

    return (
        <>
            {user_filtered ? (
                <>
                    <br></br>
                    <Row>
                        <Col>
                            <BreadCrumbs
                                paramSecondPage="Gestión de usuario"
                                paramSecondPageLink="gestion-de-usuario"
                                paramCurrentPage="Detalle del usuario"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs lg="5">
                        </Col>
                        <Col md="auto">
                            <h5>Detalle del usuario</h5><br></br>
                            {user_filtered.map((user, index) =>
                                <small key={index}><Badge bg="secondary">Usuario filtrado: {user.name}</Badge></small>
                            )}
                        </Col>
                        <Col xs lg="5">
                        </Col>
                    </Row>
                    <br></br>
                    {user_filtered.map((user, index) =>
                        <>
                            <Card key={index}>
                                <Card.Header>Información del usuario</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <Row>
                                            <Col xs={4}>
                                                <small>
                                                    <b>Nombre completo:</b>
                                                    &nbsp;{user.name}
                                                </small>
                                            </Col>
                                            <Col xs={4}>
                                                <small>
                                                    <b>E-mail:</b>
                                                    &nbsp;{user.email}
                                                </small>
                                            </Col>
                                            <Col xs={3}>
                                                <small>
                                                    <b>Teléfono:</b>
                                                    &nbsp;{user.telephone}
                                                </small>
                                            </Col>
                                        </Row>
                                        <br></br>
                                        <Row>
                                        <Col xs={3}>
                                                {user.role == "1" ? (
                                                    <small>
                                                        <b>Rol:</b>
                                                        &nbsp;Administrador
                                                    </small>
                                                ) : (null)}
                                                {user.role == "2" ? (
                                                    <small>
                                                        <b>Rol:</b>
                                                        &nbsp;Supervisor
                                                    </small>
                                                ) : (null)}
                                                {user.role == "3" ? (
                                                    <small>
                                                        <b>Rol:</b>
                                                        &nbsp;Técnico
                                                    </small>
                                                ) : (null)}
                                            </Col>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </>
                    )}
                    <br></br>
                    <Row className="justify-content-md-center">
                        <Col>
                            <Button variant="outline-primary" href={'/gestion-de-usuario'}>
                                Volver
                            </Button>
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <br></br>
                    <Row>
                        <Col>
                            <Alert key='danger' variant='danger'>
                                <small>No existe el equipo con id filtrado.</small> <Link to={'/gestion-de-equipo'} className='nav-link'><b><small>Volver a equipos.</small></b></Link>
                            </Alert>
                        </Col>
                    </Row>
                </>
            )
            }
            <br></br>
            <br></br>
        </>
    );


}
export default User;