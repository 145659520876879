import React, { useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { Badge } from 'react-bootstrap';
import { v4 as uiidv4 } from 'uuid';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';
import useService from '../../hooks/useService';
import { useQuery, useMutation, useQueryClient } from 'react-query';

function FormAddService() {
    const [service_type, setServiceType] = useState('');
    const [service_sku, setServiceSku] = useState('');
    const [service_hh, setServiceHh] = useState('');
    const [service_note, setServiceNote] = useState('');
    const [validated, setValidated] = useState(false);
    const { codeApiHttp } = useContext(UserLoggedContext);
    const { store } = useService();
    const [disableAlertSuccessNewService, setDisableAlertSuccessNewService] = useState(false);
    const [disableAlertErrorNewService, setDisableAlertErrorNewService] = useState(false);


    const queryClient = useQueryClient();

    const type_of_service = [
        "Instalación Básica - Equipo nuestro",
        "Instalación Básica - Equipo ajeno",
        "Mantención Preventiva",
        "Reparación",
        "Visita Técnica - Inspección",
        "Visita Técnica - Instalación, otra",
        "Instalación personalizada"
    ];

    const { mutate: mutateNewService, isLoading: isLoadingMutationNewService, error: errorNewService } = useMutation({
        mutationFn: store,
        onSuccess: () => {
            successNewService()
            queryClient.invalidateQueries({ queryKey: ['services'] })
        },
        onError: () => {
            errorSuccessNewService()
        }
    });

    const successNewService = () => {
        setDisableAlertSuccessNewService(true);
        setValidated(false);
        setTimeout(() => {
            setDisableAlertSuccessNewService(false);
        }, 3000);
    }

    const errorSuccessNewService = () => {
        setDisableAlertErrorNewService(true);
        setValidated(false);
        setTimeout(() => {
            setDisableAlertErrorNewService(false);
        }, 3000);
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            console.log('error');
        }
        setValidated(true);

        if (form.checkValidity() === true) {
            event.preventDefault();

            const list_new_service = {
                id: uiidv4(),
                service_type: service_type,
                service_sku: service_sku,
                service_hh: service_hh,
                service_note: service_note,
            }

            console.log(list_new_service);

            mutateNewService({
                name: service_type,
                sku: service_sku,
                hh: service_hh,
                note: service_note,
            });

            console.log('ok');
            setServiceType('');
            setServiceSku('');
            setServiceHh('');
            setServiceNote('');
            setValidated(false);
        }
    };



    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <Form.Label>Tipo</Form.Label>
                        <Form.Select value={service_type} name="selectServiceType" onChange={(e) => { setServiceType(e.target.value) }} required >
                            <option value="">Seleccione...</option>
                            <option value={type_of_service[0]}>{type_of_service[0]}</option>
                            <option value={type_of_service[1]}>{type_of_service[1]}</option>
                            <option value={type_of_service[2]}>{type_of_service[2]}</option>
                            <option value={type_of_service[3]}>{type_of_service[3]}</option>
                            <option value={type_of_service[4]}>{type_of_service[4]}</option>
                            <option value={type_of_service[5]}>{type_of_service[5]}</option>
                            <option value={type_of_service[6]}>{type_of_service[6]}</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">Seleccione tipo.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>SKU</Form.Label>
                        <Form.Control type="text" name="serviceSku" value={service_sku} placeholder="S-12" onChange={(e) => { setServiceSku(e.target.value) }} required />
                        <Form.Control.Feedback type="invalid">Ingrese SKU.</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col}>
                        <Form.Label>HH estimada</Form.Label>
                        <Form.Control type="text" name="serviceHh" value={service_hh} placeholder="2" onChange={(e) => { setServiceHh(e.target.value) }} required />
                        <Form.Control.Feedback type="invalid">Ingrese hh estimada.</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <Form.Label>Observaciones</Form.Label>
                        <Form.Control as="textarea" name="textServiceNote" value={service_note} rows={3} placeholder="Servicio con tiempo de duración estimado de 2 hh y con 1 rrhh(técnico)." onChange={(e) => { setServiceNote(e.target.value) }} />
                        <Form.Control.Feedback type="invalid">Ingrese observaciones.</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <br></br>
                <Alert variant="success" show={disableAlertSuccessNewService}>
                    <small>Servicio ingresado con éxito.</small>
                </Alert>
                <Alert variant="danger" show={disableAlertErrorNewService}>
                    <small>Error al ingresar el Servicio, inténtelo más tarde.</small>
                </Alert>
                <br></br>
                <Row>
                    <Col>
                        <Button variant="primary" type='submit' disabled={isLoadingMutationNewService}>
                            {isLoadingMutationNewService ? 'Guardando...' : 'Guardar'}
                        </Button>
                    </Col>
                </Row>
            </Form>
            <br></br>
            <br></br>

        </>
    );
}

export default FormAddService;