import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { UserLoggedContext } from '../contexts/UserLoggedContext';

function useOdt() {
    const API_URL = process.env.REACT_APP_API_URL;
    const [totalItems, setTotalItems] = useState();
    const [codeApiStoreShopping, setCodeApiStoreShopping] = useState(0);
    const { idOdtSaved, setIdOdtSaved } = useContext(UserLoggedContext);
    const [lastPageFromAPI, setLastPageFromAPI] = useState(0);
    const [totalDataFromAPI, setTotalDataFromAPI] = useState(0);
    const [filterOdtFromAPI, setFilterOdtFromAPI] = useState([]);
    const [filterOdtHisFromAPI, setFilterOdtHisFromAPI] = useState([]);
    const [codeApiFilterOdtHis, setCodeApiFilterOdtHis] = useState(0);
    const [codeApiFilterOdt, setCodeApiFilterOdt] = useState(0);


    const index = ($pageNumber) => {
        return axios.get(API_URL + 'odt/?' + 'limit=5' + '&page=' + $pageNumber)
            .then(function (response) {
                console.log(response);
                if(response.data.code != 204){
                    setTotalDataFromAPI(response.data.data.total);
                    setLastPageFromAPI(response.data.data.last_page);
                }
                return response.data.data.data;
            })
            .catch(function (error) {
            })
            .finally(function () {
            });
    }

    const indexOptional = ($pageNumber) => {
        return axios.get(API_URL + 'odt-optional/?' + 'limit=5' + '&page=' + $pageNumber)
            .then(function (response) {
                console.log(response);
                if(response.data.code != 204){
                    setTotalDataFromAPI(response.data.data.total);
                    setLastPageFromAPI(response.data.data.last_page);
                }
                return response.data.data.data;
            })
            .catch(function (error) {
            })
            .finally(function () {
            });
    }

    const filterOdtHistoryByClientTelephone = ($telephone, $pageNumber) => {
        //return axios.get(API_URL + 'client/?' + 'limit=5' + '&page=' + $pageNumber)
        return axios.get(API_URL + 'filter-odt-hist-by-client-telephone' + '/' + $telephone, 'limit=5' + '&page=' + $pageNumber)
        //return axios.get(API_URL + 'filter-odt-hist-by-client-telephone' + '/' + $telephone)
            .then(function (response) {
                    console.log(response);
                    setCodeApiFilterOdtHis(response.data.code);
                    if(response.data.code != 204){
                        setFilterOdtHisFromAPI(response.data.data.data);
                        setLastPageFromAPI(response.data.data.last_page);
                        setTotalDataFromAPI(response.data.data.total);
                        console.log(response.data.data.last_page);
                    }
                    //setTotalDataFromAPI(response.data.data.total);
                    //setLastPageFromAPI(response.data.data.last_page);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const show = (id) => {
        return axios.get(API_URL + 'odt' + '/' + id)
            .then(function (response) {
                console.log(response);
                setCodeApiFilterOdt(response.data.code);
                setFilterOdtFromAPI(response.data.data);
                return response.data.data;

            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const showForOdtUpdate = (id) => {
        return axios.get(API_URL + 'filter-for-odt-update' + '/' + id)
            .then(function (response) {
                //console.log(response);
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const filterOdtByClientTelephone = ($telephone) => {
        return axios.get(API_URL + 'filter-odt-by-client-telephone' + '/' + $telephone)
            .then(function (response) {
                if(response.data.code != 204){
                    console.log(response);
                    setTotalDataFromAPI(response.data.data.total);
                    setLastPageFromAPI(response.data.data.last_page);
                }
                return response.data.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const totalOdts = () => {
        return axios.get(API_URL + 'odt')
            .then(function (response) {
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const storeOdt = (data) => {
        return axios.post(API_URL + "odt", data)
            .then(function (response) {
                setCodeApiStoreShopping(response.data.code);
                return response.data.data['id'];
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    };
    
    const storeDetailOdt = (data) => {
        return axios.post(API_URL + "odt-detail", data)
            .then(function (response) {
                console.log(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    };

    const filterOdtByTechnicial = (id) => {
        return axios.get(API_URL + 'filter-odt-by-technician' + '/' + id)
            .then(function (response) {
                //console.log(response);
                setFilterOdtFromAPI(response.data.data);
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const filterOdtByTechnicialAsig = (id) => {
        return axios.get(API_URL + 'filter-odt-by-technician-asig' + '/' + id)
            .then(function (response) {
                //console.log(response);
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const filterOdtByTechnicialAndDaySelected = async (tech_id, odt_day_scheduling) => {
        return await axios.post(API_URL + "filter-odt-by-technician-and-day", {
            tech_id,
            odt_day_scheduling, 
        })
            .then(function (response) {
                console.log(response.data.data);
                return response.data;
                /*
                if(response.code === 200) {
                    return response.data.data;
                }
                */
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const update = (data) => {
        return axios.put(API_URL + 'odt-update', data)
            .then(function (response) {
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const updateOdtDate = (data) => {
        return axios.put(API_URL + 'odt-update-date', data)
            .then(function (response) {
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const destroy = (id) => {
        return axios.delete(API_URL + 'client' + '/' + id)
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const updateStateOdt = (data) => {
        return axios.put(API_URL + 'odt-update-state', data)
            .then(function (response) {
                console.log(response.data.message);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const updateStateOdtApproved = (data) => {
        return axios.put(API_URL + 'odt-update-state-approved', data)
            .then(function (response) {
                console.log(response);
                return response.data.message;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const updateStateOdtWithImages = (formData) => {
        return axios.post(API_URL + 'odt-images-upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
              }
        })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const updateStateOdtNewNotif = (data) => {
        return axios.put(API_URL + 'odt-update-state-new-notif', data)
            .then(function (response) {
                console.log(response.data.message);
                return response.data.message;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const showModal = (id) => {
        return axios.get(API_URL + 'odt' + '/' + id)
            .then(function (response) {
                setFilterOdtFromAPI(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const deleteAll = () => {
        return axios.delete(API_URL + 'odt-delete-all')
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    return {
        index,
        destroy,
        show,
        showForOdtUpdate,
        storeOdt,
        storeDetailOdt,
        idOdtSaved,
        totalOdts,
        filterOdtFromAPI,
        showModal,
        lastPageFromAPI,
        setLastPageFromAPI,
        deleteAll,
        updateStateOdt,
        updateStateOdtWithImages,
        updateStateOdtNewNotif,
        filterOdtByTechnicial,
        updateStateOdtApproved,
        filterOdtByClientTelephone,
        totalDataFromAPI,
        update,
        updateOdtDate,
        filterOdtByTechnicialAndDaySelected,
        indexOptional,
        setFilterOdtFromAPI,
        filterOdtFromAPI,
        codeApiFilterOdt,
        filterOdtByTechnicialAsig,
        filterOdtHistoryByClientTelephone,
        filterOdtHisFromAPI,
        codeApiFilterOdtHis,
        setFilterOdtHisFromAPI,

    }
}

export default useOdt;