import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { Badge } from 'react-bootstrap';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import useUser from '../../hooks/useUser';

function FormAddUser({ onSubmit }) {
  const [validated, setValidated] = useState(false);
  const [user_name, setUserName] = useState('');
  const [user_email, setUserEmail] = useState('');
  const [user_role, setUserRole] = useState('');
  const [user_telephone, setUserTelephone] = useState('');
  const [user_password, setUserPassword] = useState('');
  const queryClient = useQueryClient();
  const { store } = useUser();
  const [messageSuccessNewUser, setMessageSuccessNewUser] = useState(false);
  const [messageErrorNewUser, setMessageErrorNewUser] = useState(false);

  const { mutate: mutateNewUser, isLoading, isError } = useMutation({
    mutationFn: store,
    onSuccess: () => {
      successNewUser()
      queryClient.invalidateQueries({ queryKey: ['users'] })
    },
    onError: () => {
      errorSuccessNewUser()
    }
  });

  const successNewUser = () => {
    setMessageSuccessNewUser(true);
    setValidated(false);
    setTimeout(() => {
      setMessageSuccessNewUser(false);
    }, 3000);
  }

  const errorSuccessNewUser = () => {
    setMessageErrorNewUser(true);
    setValidated(false);
    setTimeout(() => {
      setMessageErrorNewUser(false);
    }, 3000);
  }

  const handleSubmit = (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity() === true) {
      e.preventDefault();

      mutateNewUser({
        name: user_name,
        email: user_email,
        password: user_password,
        role: user_role,
        telephone: user_telephone,
      });
      setUserName('');
      setUserEmail('');
      setUserTelephone('');
      setUserRole('');
      setUserPassword('');
      setValidated(false);
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-2">
          <Form.Group as={Col} controlId="validationFirstName">
            <Form.Label>Nombre y apellido</Form.Label>
            <Form.Control type="text" name="user_first_name" value={user_name} placeholder="Juan Pérez" onChange={(e) => { setUserName(e.target.value) }} required />
            <Form.Control.Feedback type="invalid">Ingrese nombre y apellido.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="validationUserEmail">
            <Form.Label>E-mail</Form.Label>
            <Form.Control type="email" name="user_email" value={user_email} placeholder="juan.perez@gmail.com" onChange={(e) => { setUserEmail(e.target.value) }} required />
            <Form.Control.Feedback type="invalid">Ingrese e-mail.</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="validateUserTelephone">
            <Form.Label>Teléfono</Form.Label>
            <Form.Control type="number" name="user_telephone" value={user_telephone} placeholder="569912345678" onChange={(e) => { setUserTelephone(e.target.value) }} required />
            <Form.Control.Feedback type="invalid">Ingrese teléfono.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="validateUserRole">
            <Form.Label>Rol de usuario</Form.Label>
            <Form.Select name="user_role" value={user_role} onChange={(e) => { setUserRole(e.target.value) }} required >
              <option value="">Seleccione...</option>
              <option value="1">Administrador</option>
              <option value="2">Supervisor</option>
              <option value="3">Técnico</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">Selecciona tu rol.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="validateUserPassword">
            <Form.Label>Contraseña de acceso <span>(mínimo 6 carácteres)</span></Form.Label>
            <Form.Control type="password" name="user_password" value={user_password} minLength="6" placeholder="******" onChange={(e) => { setUserPassword(e.target.value) }} required />
            <Form.Control.Feedback type="invalid">Ingrese contraseña.</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <br></br>
        <Alert variant="success" show={messageSuccessNewUser}>
          <small>Usuario ingresado con éxito.</small>
        </Alert>
        <Alert variant="danger" show={messageErrorNewUser}>
          <small>Error al ingresar el usuario, inténtelo más tarde.</small>
        </Alert>
        <br></br>
        <Row>
          <Col>
            <Button variant="primary" type='submit' disabled={isLoading}>
              {isLoading ? 'Guardando...' : 'Guardar'}
            </Button>
          </Col>
        </Row>
      </Form>
      <br></br>
      <br></br>
    </>
  );
}

export default FormAddUser;