import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AiOutlineExport } from "react-icons/ai";
import { AiOutlineFileAdd } from "react-icons/ai";
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import useClient from "../../hooks/useClient";
import useShopping from "../../hooks/useShopping";
import useProduct from "../../hooks/useProduct";
import Alert from 'react-bootstrap/Alert';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Row } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import BreadCrumbs from "../../utils/BreadCrumbs";

function Product() {
    let productIdParam = useParams().product_id;
    const { show, showForSku } = useProduct();

    const { isLoading: isLoadingShopingFiltered, data: product_filtered, isError: isErrorShoppingFiltered } = useQuery(
        ["product_filtered", productIdParam],
        () => show(productIdParam)
    );

    if (isLoadingShopingFiltered) return (
        <>
            <Row>
                <Col>
                    <br></br>
                    <Spinner animation="border" variant="secondary" />
                </Col>
            </Row>
        </>
    );

    if (isErrorShoppingFiltered) return (
        <>
            <Row>
                <Col>
                    <Alert key='danger' variant='danger'>
                        <small>Hay un error. Por favor intente más tarde.</small>
                    </Alert>
                </Col>
            </Row>
        </>
    );

    return (
        <>
            {product_filtered ? (
                <>
                    <br></br>
                    <Row>
                        <Col>
                            <BreadCrumbs
                                paramSecondPage="Gestión de equipo"
                                paramSecondPageLink="gestion-de-equipo"
                                paramCurrentPage="Detalle del equipo"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs lg="5">
                        </Col>
                        <Col md="auto">
                            <h5>Detalle del equipo</h5><br></br>
                            {product_filtered.map((product, index) =>
                                <small key={index}><Badge bg="secondary">Equipo filtrado: {product.sku}</Badge></small>
                            )}
                        </Col>
                        <Col xs lg="5">
                        </Col>
                    </Row>
                    <br></br>
                    {product_filtered.map((product, index) =>
                        <>
                            <Card key={index}>
                                <Card.Header>Información del equipo</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <Row>
                                        <Col xs={6}>
                                                <small>
                                                    <b>Nombre equipo:</b>
                                                    &nbsp;{product.name}
                                                </small>
                                            </Col>
                                            <Col xs={2}>
                                                <small>
                                                    <b>SKU:</b>
                                                    &nbsp;{product.sku}
                                                </small>
                                            </Col>
                                            <Col xs={4}>
                                                <small>
                                                    <b>Marca:</b>
                                                    &nbsp;{product.brand}
                                                </small>
                                            </Col>
                                        </Row>
                                        <br></br>
                                        <Row>
                                            <Col xs={3}>
                                                <small>
                                                    <b>Modelo:</b>
                                                    &nbsp;{product.model}
                                                </small>
                                            </Col>
                                            <Col xs={3}>
                                                <small>
                                                    <b>BTU:</b>
                                                    &nbsp;{product.btu}
                                                </small>
                                            </Col>
                                            <Col xs={3}>
                                                <small>
                                                    <b>Tecnología:</b>
                                                    &nbsp;{product.tech}
                                                </small>
                                            </Col>
                                            <Col xs={3}>
                                                <small>
                                                    <b>Voltaje:</b>
                                                    &nbsp;{product.voltage}
                                                </small>
                                            </Col>
                                        </Row>
                                        <br></br>
                                        <Row>
                                            <Col xs={3}>
                                                <small>
                                                    <b>Periodicidad mantención (días):</b>
                                                    &nbsp;{product.maintenance_periodicity}
                                                </small>
                                            </Col>
                                            <Col xs={3}>
                                                <small>
                                                    <b>Bomba de condensado:</b>
                                                    &nbsp;{product.bomb}
                                                </small>
                                            </Col>
                                        </Row>
                                        <br></br>
                                        <Row>
                                            <Col xs={12}>
                                                <small>
                                                    <b>Observaciones:</b>
                                                    &nbsp;{product.note}
                                                </small>
                                            </Col>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </>
                    )}
                    <br></br>
                    <Row className="justify-content-md-center">
                        <Col>
                            <Button variant="outline-primary" href={'/gestion-de-equipo'}>
                                Volver
                            </Button>
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <br></br>
                    <Row>
                        <Col>
                            <Alert key='danger' variant='danger'>
                                <small>No existe el equipo con id filtrado.</small> <Link to={'/gestion-de-equipo'} className='nav-link'><b><small>Volver a equipos.</small></b></Link>
                            </Alert>
                        </Col>
                    </Row>
                </>
            )
            }
            <br></br>
            <br></br>
        </>
    );


}
export default Product;