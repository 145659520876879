import React, { useState } from "react";
import { useParams } from "react-router-dom";

function FormShoppingEdit(){
    const { id } = useParams();
    console.log(id);

    return(
        <>
        <h5>
            El Id de la compra es {id}.
        </h5>
        </>
    );
}

export default FormShoppingEdit;