import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Col } from 'react-bootstrap';
import useProduct from '../../hooks/useProduct';
import useService from '../../hooks/useService';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';

const AutoFillServiceInput = () => {
    const [queryService, setQueryService] = useState('');
    const [queryServiceSku, setQueryServiceSku] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const API_URL = process.env.REACT_APP_API_URL;
    //const { showForSku, filterProductFromAPI } = useProduct();
    const { showForSku } = useService();
    const [selectedService, setSelectedService] = useState(false);
    //const { codeApiFilterService, filterServiceFromAPI } = useContext(UserLoggedContext);
    const { globalCodeApiFilterService, globalFilterServiceFromAPI } = useContext(UserLoggedContext);

    useEffect(() => {
        const fetchSuggestions = async () => {
            try {
                const response = await axios.get(API_URL + 'all-services-auto-fill-input')
                //console.log(response.data.data);
                setSuggestions(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        /*
        if (codeApiFilterProduct == 0){
            setQueryProduct('');
        }
        */

        fetchSuggestions();
    }, [queryService]);

    const handleChange = (e) => {
        setQueryService(e.target.value);
    };

    const handleSuggestionClick = (sku, name) => {
        console.log(sku);
        console.log(name);
        setQueryService(sku + ' - ' + name);
        setQueryServiceSku(sku);
        setSuggestions([]);
    };

    const handleFilterService = () => {
        //console.log(queryProduct);
        console.log(queryServiceSku);
        setSelectedService(true);
        showForSku(queryServiceSku);
    }

    return (
        <>
            <Form.Label>SKU servicio</Form.Label>
            <div className="search-container">
                <div className="search-inner">
                    <InputGroup>
                        <Form.Control
                            placeholder="SIB-EN-1"
                            name="serviceSkuInput"
                            value={queryService}
                            onChange={handleChange} />
                        <Button variant="outline-primary" id="button-addon2" onClick={() => { handleFilterService() }} >
                            Filtrar servicio
                        </Button>
                    </InputGroup>
                </div>
                <div className="dropdown-product-search">
                    {suggestions ? (
                        <>
                            {suggestions.filter(item => {
                                const searchTterm = queryService.toLowerCase();
                                const fullNameService = item["sku"].toLowerCase();
                                return searchTterm && fullNameService.startsWith(searchTterm);
                            })
                                .map((item, index) => (
                                    <div key={index} onClick={() => handleSuggestionClick(item.sku, item.name)} className="dropdown-product-search-row">
                                        <small>
                                            <b>({item.sku})</b> - {item.name}
                                        </small>
                                    </div>
                                ))}
                        </>
                    ) : (null)}
                </div>
            </div>
            <br></br>
            { globalCodeApiFilterService === 200 && selectedService ? (
                <>
                    <Row>
                        <Col>
                            <small><b>Detalle del servicio filtrado</b></small>
                        </Col>
                    </Row>
                    {
                        globalFilterServiceFromAPI.map((service, index) =>
                        (
                            <>
                                <Row>
                                    <Col>
                                        <Table striped bordered hover responsive>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <small>
                                                            Nombre
                                                        </small>
                                                    </th>
                                                    <th>
                                                        <small>
                                                            SKU
                                                        </small>
                                                    </th>
                                                    <th>
                                                        <small>
                                                            HH
                                                        </small>
                                                    </th>
                                                    <th>
                                                        <small>
                                                            Detalle
                                                        </small>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr key={index}>
                                                    <td>
                                                        <small>
                                                            {service.name}
                                                        </small>
                                                    </td>
                                                    <td>
                                                        <small>
                                                            {service.sku}
                                                        </small>
                                                    </td>
                                                    <td>
                                                        <small>
                                                            {service.hh}
                                                        </small>
                                                    </td>
                                                    <td>
                                                        <small>
                                                            {service.note}
                                                        </small>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </>
                        ))
                    }
                </>
            ) : (null)}
        </>
    );
}

export default AutoFillServiceInput;
