import React, { useState, useEffect, useContext } from "react";
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from 'react-bootstrap/Alert';
import { Card } from 'react-bootstrap';
import ClientTableAdministrator from "../client/ClientTableAdministrator";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import useClient from "../../hooks/useClient";

function Dashboard({ user_logged }) {
  const [loading, setLoading] = useState(true);
  const { index } = useClient();
  const [pageNumber, setPageNumer] = useState(1);


  const { isLoading, data: clients, isError: isErrorDataFetchingAllClients } = useQuery(
    ['clients', pageNumber],
    () => index(pageNumber));

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);



  if (loading) return (
    <>
      <Row>
        <Col>
          <br></br>
          <Spinner animation="border" variant="secondary" />
        </Col>
      </Row>
    </>
  );

  if (isErrorDataFetchingAllClients) return (
    <>
      <Row>
        <Col>
          <Alert key='danger' variant='danger'>
            Hay un error. Por favor intente más tarde.
          </Alert>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      {clients ? (
        <>
          <Card>
            <Card.Header>
              <Row>
                <Col>
                    Clientes
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <ClientTableAdministrator />
              </Card.Text>
            </Card.Body>
          </Card>
        </>
      ) : (
        <Row>
          <Col>
            <Alert key='warning' variant='warning'>
              <small>Sin clientes ingresados.</small>
            </Alert>
          </Col>
        </Row>
      )}

      <br></br>
      <br></br>
    </>
  )  

}

export default Dashboard;