import React, { useState, useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { InputGroup } from 'react-bootstrap';
import useService from '../../hooks/useService';
import useShopping from '../../hooks/useShopping';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import AutoFillServiceInput from '../service/AutoFillServiceInput';

function FormServiceItem() {
    const [serviceItem, setServiceItem] = useState('');
    const [cantItem, setCantItem] = useState(1);
    const [noteItem, setNoteItem] = useState('');
    const [disabledInputSku, setDisabledInputSku] = useState(false);
    const [disabledBtnSkuFilter, setDisabledBtnSkuFilter] = useState(false);
    const [disabledInputNoteItem, setDisabledInputNoteItem] = useState(false);
    const { setAddedItem, idShoppingSaved, purchaseCompleted, setPurchaseCompleted, codeApiFilterService, setCodeApiFilterService, globalCodeApiFilterService, globalFilterServiceFromAPI } = useContext(UserLoggedContext);
    const [disableAlertSuccessNewItemAded, setDisabledAlertSuccessNewItemAded] = useState(false);
    const { showForSku, filterServiceFromAPI } = useService();
    const { storeItem } = useShopping();
    const queryClient = useQueryClient();

    const { mutate: mutateNewShoppingItem, isLoading: isLoadingMutationNewShopping, error: errorNewShopping } = useMutation({
        mutationFn: storeItem,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['shoppings'] })
            queryClient.invalidateQueries({ queryKey: ['itemsShopping'] })
        }
    });

    useEffect(() => {

    }, []);

    const handleFilterService = () => {
        showForSku(serviceItem);
    }

    const handleAddItem = (e) => {
        e.preventDefault();
        globalFilterServiceFromAPI.map((service) => (
            mutateNewShoppingItem({
                shopping: idShoppingSaved,
                state: 0,
                product: 0,
                service: service.id,
                cant: cantItem,
                note: noteItem,
            })
        ));
        setAddedItem(true);
        setPurchaseCompleted(false);
        setServiceItem('');
        setCantItem(1);
        setNoteItem('');
        setDisabledAlertSuccessNewItemAded(true);
        setDisabledAlertSuccessNewItemAded(true);

        setTimeout(() => {
            setCodeApiFilterService(0);
        }, 2000);

        setTimeout(() => {
            setDisabledAlertSuccessNewItemAded(false);
        }, 2000);
    }

    return (
        <>
            <Form>
                <Row>
                    <Col>
                        <Alert key='info' variant='info' show={true}>
                            <small>Si la compra será para un servicio de mantención de un equipo ya comprado por el cliente, deberá agregar en el campo "Observación del ítem" el SKU del equipo al cual se aplicará la mantención.</small>
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <AutoFillServiceInput />
                    </Col>
                </Row>   
                <br></br> 
                <Row>
                    <Col xs={2}>
                        <Form.Label>Cantidad</Form.Label>
                        <Form.Control type="number" name="clientLastName" value={cantItem} placeholder="1" onChange={(e) => { setCantItem(e.target.value) }} />
                        <Form.Control.Feedback type="invalid">Ingrese Nº uds.</Form.Control.Feedback>
                    </Col>
                    <Col xs={10}>
                        <Form.Label>Observación del ítem</Form.Label>
                        <Form.Control as="textarea" rows={3} name="inputNote" value={noteItem} placeholder="Servicio debe ser entregado en 2 hh..." onChange={(e) => { setNoteItem(e.target.value) }} disabled={disabledInputNoteItem} />
                    </Col>
                </Row>
                <br></br>
                {codeApiFilterService === 200 ? (
                    <>
                        {
                            filterServiceFromAPI.map((service) =>
                            (
                                <Row key={service.id}>
                                    <Col xs={12}>
                                        <small><b>Deralle de ítem:</b> <Badge bg="secondary">{service.sku} - {service.name}</Badge><b>&nbsp;X&nbsp;{cantItem}</b></small>
                                    </Col>
                                </Row>
                            ))
                        }
                    </>
                ) : (null)}
                <br></br>
                <Row>
                    <Col className="vertical-center-button">
                        <Button variant="outline-primary" onClick={handleAddItem} disabled={!globalCodeApiFilterService || globalCodeApiFilterService === 204}>Añadir servicio</Button>
                    </Col>
                </Row>
                <br></br>
                <Row>
                    <Col>
                        <Alert variant="success" show={disableAlertSuccessNewItemAded}>
                            <small>Ítem añadido.</small>
                        </Alert>
                    </Col>
                </Row>
                <br></br>
            </Form>
        </>
    );
}
export default FormServiceItem;