import React, { useEffect, useContext, useState } from "react";
import { UserLoggedContext } from '../contexts/UserLoggedContext';
import AuthService from "../services/AuthService";
import ClientService from "../services/ClientService";
import ProductService from "../services/ProductService";
import axios from "axios";

function useProduct(){
    const API_URL = process.env.REACT_APP_API_URL;
    let codeHTTP = 0;
    const [codeApiFilterClient, setCodeApiFilterClient] = useState(0);
    const [filterProductFromAPI, setFilterProductFromAPI] = useState([]);
    const [codeApiStoreProduct, setCodeApiStoreProduct] = useState(0);
    //const { filterProductFromAPI, setFilterProductFromAPI, codeApiFilterProduct, setCodeApiFilterProduct, setCodeApiHttp } = useContext(UserLoggedContext);
    //const { filterProductFromAPI, setFilterProductFromAPI, setCodeApiHttp } = useContext(UserLoggedContext);
    const { setGlobalFilterProductFromAPI, setGlobalCodeApiFilterProduct } = useContext(UserLoggedContext);
    const [lastPageFromAPI, setLastPageFromAPI] = useState(0);
    const [totalProductFromAPI, setTotalProductFromAPI] = useState(0);//fix
    const [codeApiFilterProduct, setCodeApiFilterProduct] = useState(0);
    //const [filterClientFromAPIForDelModal, setFilterClientFromAPIForDelModal] = useState([]);
    const [filterProductFromAPIForDelModal, setFilterProductFromAPIForDelModal] = useState([]);

/*
    const index = () => {
        return axios.get(API_URL + 'product')
            .then(function (response) {
                console.log(response.data.data);
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }
    */

    const index = ($pageNumber) => {
        return axios.get(API_URL + 'product/?' + 'limit=5' + '&page=' + $pageNumber)
            .then(function (response) {
                if(response.data.code != 204){
                    setTotalProductFromAPI(response.data.data.total);
                    setLastPageFromAPI(response.data.data.last_page);
                }
                return response.data.data.data;
            })
            .catch(function (error) {
            })
            .finally(function () {
            });
    }

    /*
    function store(name, sku, brand, btu, tech, model, voltage, bomb, provider, note, maintenance_periodicity){
        codeHTTP = ProductService.store(name, sku, brand, btu, tech, model, voltage, bomb, provider, note, maintenance_periodicity);
        codeHTTP.then(function(result) {
            console.log('code is: ' + result.status);
            setCodeApiHttp(result.status);
        });
    }
    */

    const stores = (name, sku, brand, btu, tech, model, voltage, bomb, provider, note, maintenance_periodicity) => {
        return axios.post(API_URL + "product", {
            name,
            sku, 
            brand, 
            btu, 
            tech, 
            model, 
            voltage, 
            bomb, 
            provider, 
            note,
            maintenance_periodicity,
        })
        .then(function (response) {
            //codeHttpApi = response.status;
            //setCodeApiStoreProduct(response.status);
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {
        });
    };

    const store = (data) => {
        return axios.post(API_URL + "product", data)
            .then(function (response) {
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    };

    const show = ($id) => {
        return axios.get(API_URL + 'product' +'/'+$id)
            .then(function (response) {
                //setFilterProductFromAPI(response.data);
                setCodeApiFilterProduct(response.data.code);
                setFilterProductFromAPI(response.data.data);
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const showDeleteModal = (id) => {
        return axios.get(API_URL + 'product' + '/' + id)
            .then(function (response) {
                setFilterProductFromAPIForDelModal(response.data.data)
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const showForSku = (sku) => {
        return axios.get(API_URL + 'product/filter_sku_product' + '/' + sku)
            .then(function (response) {
                console.log(response);
                setCodeApiFilterProduct(response.data.code);
                setFilterProductFromAPI(response.data.data);
                setGlobalCodeApiFilterProduct(response.data.code);
                setGlobalFilterProductFromAPI(response.data.data);
                return response.data.data;
            })
            .catch(function (error) {
                setCodeApiFilterProduct(error.response.status);
                console.log(error);
            })
            .finally(function () {
            });
    }

    const update = (data) => {
        return axios.put(API_URL + 'product-update', data)
            .then(function (response) {
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const destroy = (id) => {
        return axios.delete(API_URL + 'product' + '/' + id)
            .then(function (response) {
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const totalProducts = () => {
        return axios.get(API_URL + 'product')
            .then(function (response) {
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const deleteAll = () => {
        return axios.delete(API_URL + 'product-delete-all')
            .then(function (response) {
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    return {
        index,
        store,
        show,
        showForSku,
        codeApiFilterClient,
        codeApiStoreProduct,
        lastPageFromAPI,
        totalProductFromAPI,
        codeApiFilterProduct,
        filterProductFromAPI,
        setFilterProductFromAPI,
        update,
        showDeleteModal,
        filterProductFromAPIForDelModal,
        destroy,
        totalProducts,
        deleteAll,
    }
}

export default useProduct;