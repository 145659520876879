import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import useClient from "../../hooks/useClient";


function ClientAddPurchase() {
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const { show } = useClient();
    const [client, setClient] = useState([]);


    useEffect(() => {
        const data = show(id);
        setClient(data);

        console.log(client);

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);


    return (
        <>
            {loading ? (
                <Row>
                    <Col>
                        <br></br>
                        <Spinner animation="border" variant="secondary" />
                    </Col>
                </Row>
            ) : (
                <>
                    <br></br>
                    <Row>
                        <Col>
                            <h4>Agregar compras al cliente</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header as="h5">Información del cliente</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <Card.Title>Nombre completo</Card.Title>
                                            <Card.Text>
                                                With supporting text below as a natural lead-in to additional content.
                                            </Card.Text>

                                        </Col>
                                        <Col>
                                            <Card.Title>Dirección</Card.Title>
                                            <Card.Text>
                                                With supporting text below as a natural lead-in to additional content.
                                            </Card.Text>

                                        </Col>
                                        <Col>
                                            <Card.Title>E-mail</Card.Title>
                                            <Card.Text>
                                                With supporting text below as a natural lead-in to additional content.
                                            </Card.Text>

                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </>
            )}

        </>
    );

}

export default ClientAddPurchase;