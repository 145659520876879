import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import Alert from 'react-bootstrap/Alert';
import { Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import useProduct from "../../hooks/useProduct";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";

function ProductFilter() {
    const queryClient = useQueryClient();
    const { showForSku, showDeleteModal, destroy, codeApiFilterProduct, filterProductFromAPI, setFilterProductFromAPI, filterProductFromAPIForDelModal } = useProduct();
    const [productToFilter, setProductToFilter] = useState("");
    const [messageSuccessProductDelete, setMessageSuccessProductDelete] = useState(false);
    const [messageErrorProductDelete, setMessageErrorProductDelete] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    const [show, setShow] = useState(false);
    const [productForDelete, setProductForDelete] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    const [desableDeleteBtn, setDesableDeleteBtn] = useState(false);

    const deleteProductMutation = useMutation({
        mutationFn: destroy,
        onSuccess: () => {
            successProductDelete();
            queryClient.invalidateQueries(['products']);
        },
        onError: () => {
            errorProductDelete();
        }
    });

    const handleDeleteProduct = () => {
        deleteProductMutation.mutate(productForDelete);
    }

    const successProductDelete = () => {
        setMessageSuccessProductDelete(true);
        setDesableDeleteBtn(true);
        setProductToFilter("");
        setTimeout(() => {
            setMessageSuccessProductDelete(false);
            setDesableDeleteBtn(false);
            setFilterProductFromAPI([]);
            setShowDelete(false);
        }, 2000);
    }

    const errorProductDelete = () => {
        setMessageErrorProductDelete(true);
        setTimeout(() => {
            setMessageErrorProductDelete(false);
        }, 2000);
    }

    const handleFilterProduct = (e) => {
        e.preventDefault();
        showForSku(productToFilter);
    }

    const showPopupDelete = (id) => {
        setProductForDelete(id);
        showDeleteModal(id);
        setShowDelete(true);
    }

    const deleteFilteredProduct = () => {
        setProductToFilter("");
        setFilterProductFromAPI([]);
    }

    return (
        <>
            <Row>
                <Col>
                    <Form onSubmit={handleFilterProduct}>
                        <InputGroup className="mb-1">
                            <Form.Control
                                name="productToFilter"
                                type="string"
                                value={productToFilter}
                                onChange={(e) => { setProductToFilter(e.target.value) }}
                                placeholder="GES12ECO-INV-N"
                                aria-label="product sku"
                                aria-describedby="basic-addon2"
                            />
                            <Button type="submit" variant="outline-primary" id="button-addon2" disabled={!productToFilter}>
                                Filtrar equipo
                            </Button>
                        </InputGroup>
                    </Form>
                </Col>
            </Row>

            {codeApiFilterProduct === 200 && filterProductFromAPI.length > 0 ? (
                <>
                    <br></br>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={12} md={11}>
                                    Equipo filtrado
                                </Col>
                                <Col xs={12} md={1}>
                                    <Button variant="outline-danger" onClick={deleteFilteredProduct} size="sm">Limpiar</Button>{' '}                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <br></br>
                                <Row>
                                    <Col>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th><small>Nombre equipo</small></th>
                                                    <th><small>SKU</small></th>
                                                    <th><small>Marca</small></th>
                                                    <th><small>Modelo</small></th>
                                                    <th><small>BTU</small></th>
                                                    <th><small>Periodicidad mantención (días)</small></th>
                                                    <th><small>Eliminar</small></th>
                                                    <th><small>Editar</small></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filterProductFromAPI.map((product) =>
                                                (
                                                    <tr key={product.id}>
                                                        <td>
                                                            <small>
                                                                <Link className='nav-link nav-link-client' to={`/gestion-de-equipo/detalle/${product.id}`}>{product.name}</Link>
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <small>{product.sku}</small>
                                                        </td>
                                                        <td>
                                                            <small>
                                                                {product.brand}
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <small>
                                                                {product.model}
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <small>
                                                                {product.btu}
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <small>{product.maintenance_periodicity}</small>
                                                        </td>
                                                        <td>
                                                            <Link onClick={() => { showPopupDelete(product.id) }} className='nav-link'><AiOutlineDelete /></Link>
                                                        </td>
                                                        <td>
                                                            <Link to={`/gestion-de-equipo/editar/${product.id}`} className='nav-link'><AiOutlineEdit /></Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </>
            ) : (null)}

            {codeApiFilterProduct === 204 ? (
                <>
                    <br></br>
                    <Alert key='danger' variant='danger'>
                        <small>El equipo con el sku ingresado no existe.</small>
                    </Alert>
                </>
            ) : (null)}

            {filterProductFromAPIForDelModal ? (
                <Modal show={showDelete} onHide={handleCloseDelete} >
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminación del equipo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                ¿Quieres eliminar a&nbsp;

                                {filterProductFromAPIForDelModal.map((product, index) =>
                                (
                                    <small key={index}>
                                        <b>{product.name}</b>
                                    </small>
                                ))
                                }?

                            </Col>
                        </Row>
                        <br></br>
                        <Alert variant="success" show={messageSuccessProductDelete}>
                            <small>Equipo eliminado con éxito.</small>
                        </Alert>
                        <Alert variant="danger" show={messageErrorProductDelete}>
                            <small>Error al eliminar equipo, inténtelo más tarde.</small>
                        </Alert>
                        <br></br>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDelete}>
                            Cerrar
                        </Button>
                        <Button variant="primary" onClick={handleDeleteProduct} disabled={desableDeleteBtn}>
                            Si, eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : (null)}

        </>
    );

}
export default ProductFilter;