import React, { useState } from "react";
import useShopping from "../../hooks/useShopping";
import useOdt from "../../hooks/useOdt";
import { Button } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { useQuery } from 'react-query';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import { AiOutlineExport } from "react-icons/ai";
import { AiOutlineFileSearch } from "react-icons/ai";
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';

function HistoryOfOdts({ paramClientTelephone }) {
    //const { filterShoppingByClientTelephone, lastPageFromAPI, totalDataFromAPI } = useShopping();
    const { filterOdtByClientTelephone, totalDataFromAPI, lastPageFromAPI } = useOdt();
    const [pageNumber, setPageNumer] = useState(1);

    const { isLoading, data: history_of_odts, isError } = useQuery(
        ["history_of_odts", paramClientTelephone, pageNumber],
        () => filterOdtByClientTelephone(paramClientTelephone, pageNumber)
    );

    if (isLoading) return (
        <>
            <Row>
                <Col>
                    <br></br>
                    <Spinner animation="border" variant="secondary" />
                </Col>
            </Row>
        </>
    );

    if (isError) return (
        <>
            <Row>
                <Col>
                    <Alert key='danger' variant='danger'>
                        Hay un error. Por favor intente más tarde.
                    </Alert>
                </Col>
            </Row>
        </>
    );

    return (
        <>
            {history_of_odts ? (
                <>
                    <Row>
                        <Col>
                            <small>Total odts&nbsp;</small><Badge bg="info">{totalDataFromAPI ? (totalDataFromAPI) : (0)}</Badge>
                        </Col>
                        <Col>
                            <Link to='/gestion-de-odt'><small>Nueva ODT</small></Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <br></br>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th><small>Id ODT</small></th>
                                        <th><small>Fecha visita</small></th>
                                        <th><small>Hora visita</small></th>
                                        <th><small>Observación</small></th>
                                        <th><small>Compra asociada</small></th>
                                        <th><small>Notificación</small></th>
                                        <th><small>Estado</small></th>
                                        <th><small>Ver detalle</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {history_of_odts.map((odt, index) =>

                                        <tr key={index}>
                                            <td>
                                                <small>{odt.id}</small>
                                            </td>
                                            <td>
                                                <small>{odt.visiting_date}</small>
                                            </td>
                                            <td>
                                                <small>{odt.visiting_hour}</small>
                                            </td>
                                            <td>
                                                <small>{odt.note}</small>
                                            </td>
                                            <td>
                                                <small>
                                                    {odt.shopping} <Link to={`/gestion-de-compra/detalle/${odt.shopping}`} target="_blank"> <AiOutlineExport /></Link>
                                                </small>

                                            </td>
                                            {odt.notification_status == 0 ? (
                                                <td>
                                                    <small>
                                                        <Badge bg="danger">No enviada</Badge>
                                                    </small>
                                                </td>
                                            ) : (null)}
                                            {odt.notification_status == 1 ? (
                                                <td>
                                                    <small>
                                                        <Badge bg="success">Enviada</Badge>
                                                    </small>
                                                </td>
                                            ) : (null)}
                                            <td>
                                                {odt.state_odt === "0" ? (
                                                    <small>
                                                        <Badge bg="danger">Nueva</Badge> {/*odt recien ingresada y esperando que tecnico envie trabajo a evaluacion.*/}
                                                    </small>
                                                ) : (null)}
                                                {odt.state_odt === "1" ? (
                                                    <small>
                                                        <Badge bg="warning">En proceso</Badge>{/* técnico envio fotos y mas info para que supervisor apruebe o rechace.*/}
                                                    </small>
                                                ) : (null)}
                                                {odt.state_odt === "2" ? (
                                                    <small>
                                                        <Badge bg="success">Cerrada</Badge>
                                                    </small>
                                                ) : (null)}
                                            </td>

                                            <td>
                                                {/*<Link to={`/gestion-de-odt/detalle/${odt.id}/${paramClientTelephone}`} className='nav-link'><AiOutlineFileSearch /></Link>*/}
                                                <Link to={`/gestion-de-cliente/historial-odts/detalle/${odt.id}/${paramClientTelephone}`} className='nav-link'><AiOutlineFileSearch /></Link>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button onClick={() => setPageNumer((page) => page - 1)} disabled={pageNumber === 1} variant="outline-primary">Volver</Button>{' '}
                            <Button onClick={() => setPageNumer((page) => page + 1)} disabled={pageNumber === lastPageFromAPI} variant="outline-primary">Siguiente</Button>{' '}
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <small>Sin odts registradas.</small> <Link to='/gestion-de-odt'><small>Nueva odt</small></Link>
                </>
            )}
        </>
    )
}
export default HistoryOfOdts;