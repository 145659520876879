import React, { useEffect, useState, useContext } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TabCustom from "../../utils/TabsCustom";
import Spinner from 'react-bootstrap/Spinner';
import BreadCrumbs from "../../utils/BreadCrumbs";

function DashboardAdministrator() {
  const [loading, setLoading] = useState(true);
  const containerType = 'DashboardAdministrator';

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);


  return (
    <>
      {loading ? (
        <>
          <br></br>
          <Spinner animation="border" variant="secondary" />
        </>
      ) : (
        <>
        <br></br>
          <Row>
            <Col>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Dashboard</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <br></br>
              <TabCustom
                containerType={containerType}
                option_one='Consolidado clientes'
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default DashboardAdministrator;