import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import Alert from 'react-bootstrap/Alert';
import { Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import useProduct from "../../hooks/useProduct";
import useService from "../../hooks/useService";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";

function ServiceFilter() {
    //const { showForSku, codeApiFilterProduct, filterProductFromAPI, setFilterProductFromAPI } = useProduct();
    const queryClient = useQueryClient();
    const [showDelete, setShowDelete] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    const { showForSku, destroy, showDeleteModal, codeApiFilterService, filterServiceFromAPI, setFilterServiceFromAPI, filterServiceFromAPIForDelModal } = useService();
    const [serviceToFilter, setServiceToFilter] = useState("");
    const [serviceForDelete, setServiceForDelete] = useState(0);
    const [messageSuccessServiceDelete, setMessageSuccessServiceDelete] = useState(false);
    const [messageErrorServiceDelete, setMessageErrorServiceDelete] = useState(false);
    const [desableDeleteBtn, setDesableDeleteBtn] = useState(false);

    const deleteServiceMutation = useMutation({
        mutationFn: destroy,
        onSuccess: () => {
            successServiceDelete();
            queryClient.invalidateQueries(['services']);
        },
        onError: () => {
            errorServiceDelete();
        }
    });

    const successServiceDelete = () => {
        setMessageSuccessServiceDelete(true);
        setDesableDeleteBtn(true);
        setServiceForDelete("");
        setTimeout(() => {
            setMessageSuccessServiceDelete(false);
            setDesableDeleteBtn(false);
            setFilterServiceFromAPI([]);
            setShowDelete(false);
        }, 2000);
    }

    const errorServiceDelete = () => {
        setMessageErrorServiceDelete(true);
        setTimeout(() => {
            setMessageErrorServiceDelete(false);
        }, 2000);
    }

    const handleFilterService = (e) => {
        e.preventDefault();
        showForSku(serviceToFilter);
    }

    const showPopupDelete = (id) => {
        setServiceForDelete(id);
        showDeleteModal(id);
        setShowDelete(true);
    }

    const deleteFilteredService = () => {
        setServiceToFilter("");
        setFilterServiceFromAPI([]);
    }

    const handleDeleteService = () => {
        deleteServiceMutation.mutate(serviceForDelete);
    }

    return (
        <>
            <Row>
                <Col>
                    <Form onSubmit={handleFilterService}>
                        <InputGroup className="mb-1">
                            <Form.Control
                                name="serviceToFilter"
                                type="string"
                                value={serviceToFilter}
                                onChange={(e) => { setServiceToFilter(e.target.value) }}
                                placeholder="SI-IBEN-AAI"
                                aria-label="product sku"
                                aria-describedby="basic-addon2"
                            />
                            <Button type="submit" variant="outline-primary" id="button-addon2" disabled={!serviceToFilter}>
                                Filtrar servicio
                            </Button>
                        </InputGroup>
                    </Form>
                </Col>
            </Row>

            {codeApiFilterService === 200 && filterServiceFromAPI.length > 0 ? (
                <>
                    <br></br>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={12} md={11}>
                                    Servicio filtrado
                                </Col>
                                <Col xs={12} md={1}>
                                    <Button variant="outline-danger" onClick={deleteFilteredService} size="sm">Limpiar</Button>{' '}                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <br></br>
                                <Row>
                                    <Col>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th><small>Nombre servicio</small></th>
                                                    <th><small>SKU</small></th>
                                                    <th><small>HH promedio estimada</small></th>
                                                    <th><small>Observación</small></th>
                                                    <th><small>Eliminar</small></th>
                                                    <th><small>Editar</small></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filterServiceFromAPI.map((service, index) =>
                                                (
                                                    <tr key={index}>
                                                        <td>
                                                            <small>
                                                                <Link className='nav-link nav-link-client' to={`/gestion-de-servicio/detalle/${service.id}`}>{service.name}</Link>
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <small>{service.sku}</small>
                                                        </td>
                                                        <td>
                                                            <small>
                                                                {service.hh}
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <small>
                                                                {service.note}
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <Link onClick={() => { showPopupDelete(service.id) }} className='nav-link'><AiOutlineDelete /></Link>
                                                        </td>
                                                        <td>
                                                            <Link to={`/gestion-de-servicio/editar/${service.id}`} className='nav-link'><AiOutlineEdit /></Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </>
            ) : (null)}

            {codeApiFilterService === 204 ? (
                <>
                    <br></br>
                    <Alert key='danger' variant='danger'>
                        <small>El servicio con el sku ingresado no existe.</small>
                    </Alert>
                </>
            ) : (null)}

            {filterServiceFromAPIForDelModal ? (
                <Modal show={showDelete} onHide={handleCloseDelete} >
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminación del servicio</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                ¿Quieres eliminar a&nbsp;

                                {filterServiceFromAPIForDelModal.map((service, index) =>
                                (
                                    <small key={index}>
                                        <b>{service.name}</b>
                                    </small>
                                ))
                                }?

                            </Col>
                        </Row>
                        <br></br>
                        <Alert variant="success" show={messageSuccessServiceDelete}>
                            <small>Servicio eliminado con éxito.</small>
                        </Alert>
                        <Alert variant="danger" show={messageErrorServiceDelete}>
                            <small>Error al eliminar servicio, inténtelo más tarde.</small>
                        </Alert>
                        <br></br>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDelete}>
                            Cerrar
                        </Button>
                        <Button variant="primary" onClick={handleDeleteService} disabled={desableDeleteBtn}>
                            Si, eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : (null)}

        </>
    );

}
export default ServiceFilter;