import React, { useEffect, useContext, useState } from "react";
import { UserLoggedContext } from '../contexts/UserLoggedContext';
import ClientService from "../services/ClientService";
import axios from "axios";
import useUtilities from "./useUtilities";
import useSmsNotification from "./useSmsNotification";
import useMailing from "./useMailing";

function useClient() {
    const API_URL = process.env.REACT_APP_API_URL;
    let codeHttpApi = 0;
    const [filterClientFromAPI, setFilterClientFromAPI] = useState([]);
    const [filterClientFromRunAPI, setFilterClientFromRunAPI] = useState([]);
    const [filterClientFromTelephoneAPI, setFilterClientFromTelephoneAPI] = useState([]);
    const [codeApi, setCodeApi] = useState(0);
    const [codeApiFilterClientForRun, setCodeApiFilterClientForRun] = useState(0);
    const [codeApiFilterClientForTelephone, setCodeApiFilterClientForTelephone] = useState(0);
    const [lastPageFromAPI, setLastPageFromAPI] = useState(0);
    const [totalDataFromAPI, setTotalDataFromAPI] = useState(0);
    const colleccionClient = new Map();
    const { calculateDaysForMaintenance, formatDate } = useUtilities();
    const { smsManualSend, smsAutoSend } = useSmsNotification();
    const msgSmsNot =         `Estimado Cliente de Climatización Rancagua, \nRegistra mantenciones vencidas en Equipos de Aire Acondicionado. \nHablemos: https://api.whatsapp.com/send?phone=56967345720'`;
    const msgSmsNotMant175d = `Estimado Cliente de Climatización Rancagua, \nTiene mantenciones por vencer en Equipos de Aire Acondicionado. \nHablemos: https://api.whatsapp.com/send?phone=56967345720'`;
    const msgSmsNotMant180d = `Estimado Cliente de Climatización Rancagua, \nTiene mantenciones vencidas en Equipos de Aire Acondicionado. \nHablemos: https://api.whatsapp.com/send?phone=56967345720'`;
    let clientsListFromDb = [];
    let filterClientStateMaintBySendSmsNot = null;
    const { sendNotifMailErrorClientTelephone } = useMailing();

    const index = ($pageNumber) => {
        return axios.get(API_URL + 'client/?' + 'limit=5' + '&page=' + $pageNumber)
            .then(function (response) {
                if (response.data.code != 204) {
                    setTotalDataFromAPI(response.data.data.total);
                    setLastPageFromAPI(response.data.data.last_page);
                }
                return response.data.data.data;
            })
            .catch(function (error) {
            })
            .finally(function () {
            });
    }

    const clientWithExpMain = ($pageNumber) => {
        return axios.get(API_URL + 'client-with-exp-main/?' + 'limit=5' + '&page=' + $pageNumber)
            .then(function (response) {
                if (response.data.code != 204) {
                    setTotalDataFromAPI(response.data.data.total);
                    setLastPageFromAPI(response.data.data.last_page);
                }
                //console.log(response.data.data.data);
                return response.data.data.data;
            })
            .catch(function (error) {
            })
            .finally(function () {
            });
    }

    const clientOkMain = ($pageNumber) => {
        return axios.get(API_URL + 'client-ok-main/?' + 'limit=5' + '&page=' + $pageNumber)
            .then(function (response) {
                if (response.data.code != 204) {
                    setTotalDataFromAPI(response.data.data.total);
                    setLastPageFromAPI(response.data.data.last_page);
                }
                //console.log(response.data.data.data);
                return response.data.data.data;
            })
            .catch(function (error) {
            })
            .finally(function () {
            });
    }

    const clientDueMain = ($pageNumber) => {
        return axios.get(API_URL + 'client-due-main/?' + 'limit=5' + '&page=' + $pageNumber)
            .then(function (response) {
                if (response.data.code != 204) {
                    setTotalDataFromAPI(response.data.data.total);
                    setLastPageFromAPI(response.data.data.last_page);
                }
                //console.log(response.data.data.data);
                return response.data.data.data;
            })
            .catch(function (error) {
            })
            .finally(function () {
            });
    }

    const clientNoShopping = ($pageNumber) => {
        return axios.get(API_URL + 'client-no-shopping/?' + 'limit=5' + '&page=' + $pageNumber)
            .then(function (response) {
                if (response.data.code != 204) {
                    setTotalDataFromAPI(response.data.data.total);
                    setLastPageFromAPI(response.data.data.last_page);
                }
                //console.log(response.data.data.data);
                return response.data.data.data;
            })
            .catch(function (error) {
            })
            .finally(function () {
            });
    }

    const handleSendMailClientTelError = (client, telephone) => {
        //sendNotifMailErrorClientTelephone(client, telephone);
    }

    const maintenanceDayCounter = () => {
        return axios.get(API_URL + 'client-day-counter')
            .then(function (response) {
                let data = {};
                let id = 0;
                let date = "";
                let telephone = "";
                let maintenance_status = "";
                let income_type = "";
                clientsListFromDb = [];

                if(response.data.code == 200){
                    if (response.data.data.length > 0) {
                        for (let i = 0; i < response.data.data.length; i++) {
                            id = response.data.data[i]['id'];
                            date = response.data.data[i]['last_visit'];
                            telephone = response.data.data[i]['telephone'];
                            maintenance_status = response.data.data[i]['maintenance_status'];
                            income_type = response.data.data[i]['income_type'];
                            if (date != null) {
                                data = {
                                    client_id: id,
                                    client_telephone: telephone,
                                    client_last_visit: date,
                                    maintenance_status: maintenance_status,
                                    income_type: income_type,
                                }
                                clientsListFromDb.push(data);
                            }
                        }
                    }
                }


                if (clientsListFromDb.length > 0) {
                    hadleCalculateDayForMaintenance();
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const hadleCalculateDayForMaintenance = () => {
        let days_passed = "";
        let client = 0;
        let date = "";
        let telephone = "";
        let income_type = "";
        let maintenance_status = "";
        let clientIdToSendNotification175Days = 0;
        let clientDateDaysToSendNotification175Days = "";
        let clientTelephoneToSendNotification175Days = "";
        let clientIdToSendNotification180Days = 0;
        let clientDateDaysToSendNotification180Days = "";
        let clientTelephoneToSendNotification180Days = "";
        for (let i = 0; i < clientsListFromDb.length; i++) {
            days_passed = calculateDaysForMaintenance(clientsListFromDb[i]["client_last_visit"]);
            client = clientsListFromDb[i]["client_id"];
            date = clientsListFromDb[i]["client_last_visit"];
            telephone = clientsListFromDb[i]["client_telephone"];
            maintenance_status = clientsListFromDb[i]["maintenance_status"];
            income_type = clientsListFromDb[i]["income_type"];

            if (days_passed == 175 && maintenance_status == null && telephone.length == 11 && income_type == "app") {
                //console.log("175");
                clientIdToSendNotification175Days = client;
                clientTelephoneToSendNotification175Days = telephone;
                clientDateDaysToSendNotification175Days = date;
                if (clientIdToSendNotification175Days > 0 && clientTelephoneToSendNotification175Days != null) {
                    handleClientListWith175DaysMaint(clientIdToSendNotification175Days, clientTelephoneToSendNotification175Days);
                }
            }

            if (days_passed == 180 && maintenance_status == "1" && telephone.length == 11 && income_type == "app") {
                //console.log("180");
                clientIdToSendNotification180Days = client;
                clientTelephoneToSendNotification180Days = telephone;
                clientDateDaysToSendNotification180Days = date;
                if(clientIdToSendNotification180Days > 0 && clientTelephoneToSendNotification180Days != null) {
                    handleClientListWith180DaysMaint(clientIdToSendNotification180Days, clientTelephoneToSendNotification180Days);
                }
            }

            updateClientAccDaysPassed(client, days_passed);
        }
    }

    //const filterClientByTelephoneToSmsNot = (telephone) => {
    const filterClientByTelephoneToSmsNot = async (telephone) => {
        return axios.get(API_URL + 'client/filter_telephone_client' + '/' + telephone)
            .then(function (response) {
                let res = null;
                //console.log(response.data.data);
                if (response.data.data[0]['maintenance_status'] === null) {
                    //console.log(response.data.data[0]['maintenance_status']);
                    console.log('sin sms para mantención enviado.');
                    res = 0;
                } else if (response.data.data[0]['maintenance_status'] === "1") {
                    //console.log(response.data.data[0]['maintenance_status']);
                    console.log('1er sms para mantención enviado.');
                    res = 1;
                } else if (response.data.data[0]['maintenance_status'] === "2") {
                    //console.log(response.data.data[0]['maintenance_status']);
                    console.log('2do sms para mantención enviado.');
                    res = 2;
                }
                console.log(res);
                return res;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    async function handleCallClientSmsMantNot(param_client_telephone) {
        filterClientStateMaintBySendSmsNot = await filterClientByTelephoneToSmsNot(param_client_telephone);
        console.log(filterClientStateMaintBySendSmsNot);
    }

    const handleClientListWith175DaysMaint = (client, telephone) => {
        let client_telephone = "";
        let client_id = 0;
        let client_send_date = formatDate();
        let client_type_sms = "1";
        let res;

        colleccionClient.set(client, telephone);
        colleccionClient.forEach((value, key) => {
            console.log(`${key}: ${value}`);
            client_telephone = value;
            client_id = key;
            handleCallClientSmsMantNot(client_telephone);//fix
            console.log(filterClientStateMaintBySendSmsNot);
                if (filterClientStateMaintBySendSmsNot == 0) {
                    console.log("(175 dias trascurridos) enviar primer sms vencimiento mantención.");
                    smsAutoSend(client_telephone, client_id, msgSmsNotMant175d, client_type_sms, client_send_date);  
                }
        });
        colleccionClient.clear();
    }

    const handleClientListWith180DaysMaint = (client, telephone) => {
        console.log(client);
        console.log(telephone);
        let client_telephone = "";
        let client_id = 0;
        let client_send_date = formatDate();
        let client_type_sms = "2";
        let res;
        colleccionClient.set(client, telephone);
        colleccionClient.forEach((value, key) => {
            console.log(`${key}: ${value}`);
            client_telephone = value;
            client_id = key;
            handleCallClientSmsMantNot(client_telephone);//fix
            console.log(filterClientStateMaintBySendSmsNot);
            if (filterClientStateMaintBySendSmsNot == 1) {
                console.log("(180 dias trascurridos) enviar segundo sms vencimiento mantención.");
                smsAutoSend(client_telephone, client_id, msgSmsNotMant180d, client_type_sms, client_send_date); 
            }
        });
        colleccionClient.clear();
    }

    const updateClientAccDaysPassed = (client, days_passed) => {
        return axios.put(API_URL + "client-update-acco-days-passed", {
            client,
            days_passed,
        })
            .then(function (response) {
                //console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const updateClientNotErrorTelephone = (client, telephone, state) => {
        return axios.put(API_URL + "client-update-not-error-telephone", {
            client,
            telephone,
            state,
        })
            .then(function (response) {
                //console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const indexClientWithTelephoneOkNotSms = ($pageNumber) => {
        return axios.get(API_URL + 'client-telephone-ok-not-sms/?' + 'limit=5' + '&page=' + $pageNumber)
            .then(function (response) {
                if (response.data.code != 204) {
                    setTotalDataFromAPI(response.data.data.total);
                    setLastPageFromAPI(response.data.data.last_page);
                }
                return response.data.data.data;
            })
            .catch(function (error) {
            })
            .finally(function () {
            });
    }

    const totalClients = () => {
        return axios.get(API_URL + 'client')
            .then(function (response) {
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const totalActiveClients = () => {
        return axios.get(API_URL + 'total-active-clients')
            .then(function (response) {
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const totalInactiveClients = () => {
        return axios.get(API_URL + 'total-inactive-clients')
            .then(function (response) {
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const stores = (run, first_name, last_name, telephone, email, address, address_comune, state, note, shopping, odt) => {
        return axios.post(API_URL + "client", {
            run,
            first_name,
            last_name,
            telephone,
            email,
            address,
            address_comune,
            state,
            note,
            shopping,
            odt,
        })
            .then(function (response) {
                console.log(response);
                if (codeHttpApi === 200) {
                    console.log('Ingreso correcto.');
                }
                setCodeApi(response.data.code);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    };

    const store = (data) => {
        return axios.post(API_URL + "client", data)
            .then(function (response) {
                if (response.data.code === 200) {
                    console.log('Ingreso correcto.');
                }
                setCodeApi(response.data.code);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    };

    const update = (data) => {
        console.log(data);
        return axios.put(API_URL + 'client-update', data)
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const show = (id) => {
        return axios.get(API_URL + 'client' + '/' + id)
            .then(function (response) {
                console.log(response.data.data);
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const showModal = (id) => {
        return axios.get(API_URL + 'client' + '/' + id)
            .then(function (response) {
                setFilterClientFromAPI(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const showForRun = (run) => {
        return axios.get(API_URL + 'client/filter_run_client' + '/' + run)
            .then(function (response) {
                setCodeApiFilterClientForRun(response.data.code);
                setFilterClientFromRunAPI(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const filterClientByTelephone = (telephone) => {
        return axios.get(API_URL + 'client/filter_telephone_client' + '/' + telephone)
            .then(function (response) {
                setCodeApiFilterClientForTelephone(response.data.code);
                setFilterClientFromTelephoneAPI(response.data.data);
                console.log(response);
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const destroy = (id) => {
        return axios.delete(API_URL + 'client' + '/' + id)
            .then(function (response) {
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const deleteAll = () => {
        return axios.delete(API_URL + 'client-delete-all')
            .then(function (response) {
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const updateClientAccoLastOdtFin = (data) => {
        return axios.put(API_URL + 'client-update-acco-last-odt-fin', data)
            .then(function (response) {
                return (response.data.code);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const updateClientAccoLastOdt = (data) => {
        return axios.put(API_URL + 'client-update-acco-last-odt', data)
            .then(function (response) {
                console.log(response);
                return (response.data.code);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    return {
        store,
        stores,
        show,
        showModal,
        showForRun,
        filterClientByTelephone,
        destroy,
        deleteAll,
        index,
        totalClients,
        filterClientFromAPI,
        filterClientFromRunAPI,
        filterClientFromTelephoneAPI,
        codeApiFilterClientForRun,
        codeApiFilterClientForTelephone,
        setFilterClientFromRunAPI,
        setFilterClientFromTelephoneAPI,
        setCodeApiFilterClientForTelephone,
        update,
        lastPageFromAPI,
        totalDataFromAPI,
        totalActiveClients,
        totalInactiveClients,
        indexClientWithTelephoneOkNotSms,
        updateClientAccoLastOdtFin,
        maintenanceDayCounter,
        updateClientAccoLastOdt,
        clientWithExpMain,
        clientNoShopping,
        clientOkMain,
        clientDueMain,
    }
}

export default useClient;