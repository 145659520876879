import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';
import { Card } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { UserLoggedContext } from '../../contexts/UserLoggedContext';
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineFileSearch } from "react-icons/ai";
import useOdt from "../../hooks/useOdt";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import ClientFilter from "../client/ClientFilter";
import OdtFilter from "./OdtFilter";

function OdtList() {
  const { index, destroy, totalOdts, deleteAll, filterOdtFromAPI, showModal, lastPageFromAPI, setLastPageFromAPI } = useOdt();
  const [loading, setLoading] = useState(true);
  const { setTotalClientsGlobal, totalClientsGlobal, greet } = useContext(UserLoggedContext);
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const [showDeleteAllOdts, setShowDeleteAllOdts] = useState(false);
  const handleCloseDeleteAllOdts = () => setShowDeleteAllOdts(false);
  const [desableDeleteBtn, setDesableDeleteBtn] = useState(false);
  const [desableDeleteAllBtn, setDesableDeleteAllBtn] = useState(false);
  const queryClient = useQueryClient();
  const [odtForDelete, setOdtForDelete] = useState(0);
  const [messageSuccessClientDelete, setMessageSuccessClientDelete] = useState(false);
  const [messageErrorClientDelete, setMessageErrorClientDelete] = useState(false);
  const [messageSuccessOdtDeleteAll, setMessageSuccessOdtDeleteAll] = useState(false);
  const [messageErrorOdtDeleteAll, setMessageErrorOdtDeleteAll] = useState(false);
  const [pageNumber, setPageNumer] = useState(1);

  const { isLoading, data: odts, isError: isErrorDataFetchingAllClients } = useQuery(
    ['odts', pageNumber],
    () => index(pageNumber));

  const { data: totalOdtsAPI } = useQuery(
    ['totalOdtsAPI'],
    () => totalOdts());

  const deleteAllOdtsMutation = useMutation({
    mutationFn: deleteAll,
    onSuccess: () => {
      successOdtDeleteAll();
      queryClient.invalidateQueries(['odts']);
      queryClient.invalidateQueries(['totalOdtsAPI']);
    },
    onError: () => {
      errorOdtDeleteAll()
    }
  });

  const errorOdtDeleteAll = () => {
    setMessageErrorOdtDeleteAll(true);
    setTimeout(() => {
      setMessageErrorOdtDeleteAll(false);
    }, 2000);
  }

  const successOdtDeleteAll = () => {
    setMessageSuccessOdtDeleteAll(true);
    setDesableDeleteAllBtn(true);
    setTimeout(() => {
      setMessageSuccessOdtDeleteAll(false);
      setDesableDeleteAllBtn(false);
      setShowDeleteAllOdts(false);
    }, 2000);
  }

  useEffect(() => {
    totalOdts();
    index();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const showPopupDelete = (id) => {
    setOdtForDelete(id);
    showModal(id);
    setShowDelete(true);
  }

  const showPopupDeleteAllOdts = () => {
    setShowDeleteAllOdts(true);
  }

  const handleDeleteAllOdts = () => {
    deleteAllOdtsMutation.mutate();
  }

  if (isLoading) return (
    <>
      <Row>
        <Col>
          <br></br>
          <Spinner animation="border" variant="secondary" />
        </Col>
      </Row>
    </>
  );

  if (isErrorDataFetchingAllClients) return (
    <>
      <Row>
        <Col>
          <Alert key='danger' variant='danger'>
            Hay un error. Por favor intente más tarde.
          </Alert>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      {odts ? (
        <>
          <Card>
            <Card.Header>
              <Row>
                <Col>
                  Filtro de ODT
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <OdtFilter />
              </Card.Text>
            </Card.Body>
          </Card>
          <br></br>
          <Card>
            <Card.Header>
              <Row>
                <Col>Todas las ODT(Orden de trabajo)</Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <br></br>
                <Row>
                  <Col></Col>
                  <Col md="auto">
                    {totalOdtsAPI ? (
                    <>
                      <small>Total odts&nbsp;</small><Badge bg="info">{totalOdtsAPI['total']}</Badge>
                    </>
                    ) : (
                      <>
                        <small>Total odts&nbsp;</small><Badge bg="info">0</Badge>
                      </>
                    )}
                  </Col>
                  <Col xs lg="3">
                    <Col xs={{ order: 12 }}><Button variant="outline-danger" onClick={showPopupDeleteAllOdts} size="sm">Eliminar todo</Button>{' '}</Col>
                  </Col>
                </Row>
                <p></p>
                <Row>
                  <Col>
                    <br></br>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th><small>Id ODT</small></th>
                          <th><small>Fecha ingreso</small></th>
                          <th><small>Fecha visita</small></th>
                          <th><small>Cliente</small></th>
                          <th><small>Teléfono cliente</small></th>
                          <th><small>Supervisor</small></th>
                          <th><small>Técnico</small></th>
                          <th><small>Compra asociada</small></th>
                          <th><small>Notificación</small></th>
                          <th><small>Estado</small></th>
                          <th><small>Eliminar</small></th>
                          <th><small>Editar</small></th>
                        </tr>
                      </thead>
                      <tbody>
                        {odts.map((odt, index) =>
                        (
                          <tr key={index}>
                            <td>
                              <small>
                                <Link to={`/gestion-de-odt/detalle/${odt.id_odt}/${odt.client_telephone}`} className='nav-link nav-link-client'>{odt.id_odt}</Link>
                              </small>
                            </td>
                            <td>
                              <small>{odt.admision_date}</small>
                            </td>
                            <td>
                              <small>{odt.visiting_date} ({odt.visiting_hour})</small>
                            </td>
                            <td>
                              <small>{odt.client_first_name} {odt.client_last_name}</small>
                            </td>
                            <td>
                              <small>{odt.client_telephone}</small>
                            </td>
                            <td>
                              <small>{odt.supervisor}</small>
                            </td>
                            <td>
                              <small>{odt.technical}</small>
                            </td>
                            {odt.shopping == 0 ? (
                              <td>
                                <small>
                                  Sin ODT
                                </small>
                              </td>
                            ) : (
                              <td>
                                <small>
                                  {odt.shopping}
                                </small>
                              </td>
                            )}
                            {odt.notification_status == 0 ? (
                              <td>
                                <small>
                                  <Badge bg="danger">No enviada</Badge>
                                </small>
                              </td>
                            ) : (null)}
                            {odt.notification_status == 1 ? (
                              <td>
                                <small>
                                  <Badge bg="success">Enviada</Badge>
                                </small>
                              </td>
                            ) : (null)}
                            <td>
                              {odt.state_odt === "0" ? (
                                <small>
                                  <Badge bg="danger">Nueva</Badge>
                                </small>
                              ) : (null)}
                              {odt.state_odt === "1" ? (
                                <small>
                                  <Badge bg="warning">En proceso</Badge>
                                </small>
                              ) : (null)}
                              {odt.state_odt === "2" ? (
                                <small>
                                  <Badge bg="success">Cerrada</Badge>
                                </small>
                              ) : (null)}
                            </td>
                            <td>
                              <Link onClick={() => { showPopupDelete(odt.id_odt) }} className='nav-link'><AiOutlineDelete /></Link>
                            </td>
                            {odt.state_odt != "2" ? (
                              <td>
                                <Link to={`/gestion-de-odt/editar/${odt.id_odt}`} className='nav-link'><AiOutlineEdit /></Link>
                              </td>
                            ) : (
                              <td>
                                <small>No aplica</small>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button onClick={() => setPageNumer((page) => page - 1)} disabled={pageNumber === 1} variant="outline-primary">Volver</Button>{' '}
                    <Button onClick={() => setPageNumer((page) => page + 1)} disabled={pageNumber === lastPageFromAPI} variant="outline-primary">Siguiente</Button>{' '}
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </>
      ) : (
        <Row>
          <Col>
            <Alert key='warning' variant='warning'>
              <small>Sin odts ingresadas.</small>
            </Alert>
          </Col>
        </Row>
      )}

      {filterOdtFromAPI ? (
        <Modal show={showDelete} onHide={handleCloseDelete} >
          <Modal.Header closeButton>
            <Modal.Title>Eliminación de ODT</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                ¿Quieres eliminar la ODT &nbsp;

                {filterOdtFromAPI.map((odt, index) =>
                (
                  <small key={index}>
                    <b>{odt.id}</b>
                  </small>
                ))
                }?

              </Col>
            </Row>
            <br></br>
            <Alert variant="success" show={messageSuccessClientDelete}>
              <small>Odt eliminado con éxito.</small>
            </Alert>
            <Alert variant="danger" show={messageErrorClientDelete}>
              <small>Error al eliminar odt, inténtelo más tarde.</small>
            </Alert>
            <br></br>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
              Cerrar
            </Button>
            <Button variant="primary" disabled={desableDeleteBtn}>
              Si, eliminar
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (null)}

      <Modal show={showDeleteAllOdts} onHide={handleCloseDeleteAllOdts} >
        <Modal.Header closeButton>
          <Modal.Title>Eliminación de odts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              {totalOdtsAPI ? (
                <>
                  ¿Quieres eliminar <b>{totalOdtsAPI['total']}</b> odt(s)?
                </>
              ) : (null)}
            </Col>
          </Row>
          <br></br>
          <Alert variant="success" show={messageSuccessOdtDeleteAll}>
            <small>Odts eliminados con éxito.</small>
          </Alert>
          <Alert variant="danger" show={messageErrorOdtDeleteAll}>
            <small>Error al eliminar Odts, inténtelo más tarde.</small>
          </Alert>
          <br></br>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteAllOdts}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleDeleteAllOdts} disabled={desableDeleteAllBtn}>
            Si, eliminar
          </Button>
        </Modal.Footer>
      </Modal>

      <br></br>
      <br></br>
    </>
  )
}

export default OdtList;

